import React, { Component } from "react";
import getCurrentlyLoggedInProfileApi from "../../../api/profile/getCurrentlyLoggedInProfileApi";
class SideBarUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        companyName: "",
        userId: "",
        displayName: "",
        firstName: "",
        lastName: "",
        profileInfoComplete: false,
        profileType: "",
        userProfilePictureId: ""
      },
      avatarLink: ""
    };
  }
  componentDidMount = async () => {
    await this.getCurrentlyLoggedInProfile();
  };
  getCurrentlyLoggedInProfile = async () => {
    getCurrentlyLoggedInProfileApi().then(response => {
      this.setState({
        profile: {
          userId: response.userId,
          displayName: response.displayName,
          firstName: response.firstName,
          lastName: response.lastName,
          profileInfoComplete: response.profileInfoComplete,
          profileType: response.profileType,
          companyName: response.companyName,
          userProfilePictureId: response.userProfilePictureId
        }
      });
      setTimeout(() => {
        this.setState({
          avatarLink:
            process.env.API_URL +
            "/api/v1/gallery-service/images/profilepictures/" +
            this.state.profile.userId +
            "/" +
            this.state.profile.userProfilePictureId
        });
      }, 10);
    });
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="userAvatar"
          style={{
            backgroundImage: "url(" + this.state.avatarLink + ")",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            width: "110px",
            height: "110px"
          }}
        />
        <span className="controlTitle">
          {this.state.profile.companyName
            ? this.state.profile.companyName
            : this.state.profile.displayName
            ? this.state.profile.displayName
            : this.state.profile.firstName + " " + this.state.profile.lastName}
        </span>
      </React.Fragment>
    );
  }
}
export default SideBarUserInfo;
