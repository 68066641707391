import React, { Component } from "react";
import PropTypes from "prop-types";
import Footer from "../../common/login/Footer";

class Advertisements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Advertisements"
    };
  }
  UNSAFE_componentWillMount() {
    const changeTitle = this.props.changeTitle;
    changeTitle(this.state.title);
  }
  render() {
    return (
      <div>
        <Footer />
      </div>
    );
  }
}
Advertisements.propTypes = {
  title: PropTypes.string.isRequired,
  changeTitle: PropTypes.func.isRequired
};
export default Advertisements;
