import React, { Component } from "react";
import PropTypes from "prop-types";

export default class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePassword: true
    };
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  toggleShowPassword = () => {
    event.preventDefault();
    this.setState({ hidePassword: !this.state.hidePassword });
    //console.log("hidePassword : " + this.state.hidePassword);
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <input
          type={this.state.hidePassword ? "password" : "text"}
          name={this.props.name}
          onChange={this.props.onHandlefieldChange}
        />
        <div
          className={this.state.hidePassword ? "showHide" : "showHideActive"}
          onClick={this.toggleShowPassword}
        />
      </div>
    );
  }
}

PasswordField.propTypes = {
  onHandlefieldChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
  //passwordfieldname: PropTypes.string.isRequired
};
