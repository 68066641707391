import React, { Component } from "react";
import checkPaymentStatus from "../../api/payment/checkPaymentStatusByOrderNumber";
import getReciept from "../../api/payment/receipt";
import * as moment from "moment";
class paymentStatus extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  isLoggedIn: true,
                  title: "payment successful",
                  orderNumber: "",
                  status: "",
                  messageDescription: "",
                  subscriptionType: "",
                  paymentStatus: {
                        msgWrapper: {
                              serviceName: "",
                              msgType: "",
                              msgCode: "",
                              msgDescription: ""
                        },
                        dataObject: {
                              paymentStatus: "",
                              paymentMethodCode: "",
                              orderCurrencyCode: "",
                              orderGrossAmount: "",
                              transactionNumber: "",
                              orderNote: "",
                              orderTimestamp: ""
                        }
                  },

                  reciept: {
                        userId: "",
                        msgWrapper: {
                              serviceName: "",
                              msgType: "",
                              msgCode: "",
                              msgDescription: ""
                        },
                        dataObject: {
                              paymentId: "",
                              userId: "",
                              subscriptionType: "",
                              subscriptionName: "",
                              subscriptionCode: "",
                              directPaymentServiceName: null,
                              directPaymentType: null,
                              directPaymentTypeId: null,
                              buyerFirstName: "",
                              buyerLastName: "",
                              buyerPhoneNumber: "",
                              buyerEmailAddress: "",
                              deliveryAddressLineOne: "",
                              deliveryAddressLineTwo: "",
                              deliveryAddressLineThree: "",
                              deliveryAddressCity: "",
                              deliveryAddressPostalCode: "",
                              discountCode: "",
                              transactionNumber: "",
                              orderNumber: "",
                              purchaseDate: "",
                              subscriptionEndDate: "",
                              shoppingBasket: "",
                              unitCost: "",
                              unitCount: "",
                              netAmount: "",
                              grossAmount: "",
                              discountPercentage: "",
                              vatPercentage: ""
                        }
                  }
            };
      }

      async UNSAFE_componentWillMount() {
            // const changeTitle = this.props.changeTitle;
            // changeTitle(this.state.title);

            let urlParams = new URLSearchParams(window.location.search);
            let orderNumber = urlParams.get("orderNumber");
            let status = urlParams.get("status");
            await this.setState({ orderNumber: orderNumber, status: status });
            //console.log("order number " + this.state.orderNumber);
            //console.log("order number " + this.state.status);

            await this.getPaymentStatus(this.state.orderNumber, this.state.status);
            await this.getReciept(this.state.orderNumber, this.state.status);
      }

      async getPaymentStatus(orderNumber, status) {
            await checkPaymentStatus(orderNumber, status).then((response) => {
                  // //console.log("response : " + JSON.stringify(response));
                  this.setState({ paymentStatus: response });

                  //user canceled
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-user-canceled") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "You cancelled the payment process.";

                        this.setState({ title: canceled });
                        // const changeTitle = this.props.changeTitle;
                        // changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }
                  //payment expired
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-payment-expired") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "The payment process expired due to timeout.";

                        this.setState({ title: canceled });
                        const changeTitle = this.props.changeTitle;
                        changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }

                  //cancel system error
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-system-error") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "The payment process failed due to an error in the payment system.";

                        this.setState({ title: canceled });
                        const changeTitle = this.props.changeTitle;
                        changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }

                  //cancel system error
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-inv-payment-params") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "The payment process failed due to missing payment data from the merchant.";

                        this.setState({ title: canceled });
                        const changeTitle = this.props.changeTitle;
                        changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }

                  //cancel system error
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-external-system-error") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "They payment failed due to an external system giving an invalid authentication code.";

                        this.setState({ title: canceled });
                        const changeTitle = this.props.changeTitle;
                        changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }

                  //cancel save rejected not enrolled
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-save-rej-not-enrolled") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "Attempt to save a non 3d secure enrolled card.";

                        this.setState({ title: canceled });
                        const changeTitle = this.props.changeTitle;
                        changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }

                  //cancel save rejected not enrolled
                  if (this.state.paymentStatus.msgWrapper.msgDescription === "cancel-paym-rej-not-enrolled") {
                        let canceled = this.state.paymentStatus.msgWrapper.msgType;
                        let description = "Attempt to pay with non 3d secure enrolled card more than the maximum set.";

                        this.setState({ title: canceled });
                        const changeTitle = this.props.changeTitle;
                        changeTitle(this.state.title);

                        this.setState({ messageDescription: description });
                  }

                  //change the order note to human readable strings
                  if (this.state.paymentStatus.dataObject.orderNote === "AccountSubscription: business_yearly") {
                        let subscriptionType = "business yearly";
                        this.setState({ subscriptionType: subscriptionType });
                  }

                  //change the order note to human readable strings
                  if (this.state.paymentStatus.dataObject.orderNote === "AccountSubscription: business_mnth") {
                        let subscriptionType = "business monthly";
                        this.setState({ subscriptionType: subscriptionType });
                  }

                  //change the order note to human readable strings
                  // if (
                  //   (this.state.paymentStatus.dataObject.orderNote =
                  //     "AccountSubscription: private_yearly")
                  // ) {
                  //   let subscriptionType = "private yearly";
                  //   this.setState({ subscriptionType: subscriptionType });
                  // }

                  //change the order note to human readable strings
                  // if (
                  //   (this.state.paymentStatus.dataObject.orderNote =
                  //     "AccountSubscription: private_monthly")
                  // ) {
                  //   let subscriptionType = "private monthly";
                  //   this.setState({ subscriptionType: subscriptionType });
                  // }
            });
            //console.log("order note : " + this.state.paymentStatus.dataObject.orderNote);
      }

      async getReciept(orderNumber) {
            await getReciept(orderNumber).then((response) => {
                  this.setState({ reciept: response });
                  //console.log("reciept ==> " + JSON.stringify(response));
            });
      }

      redirectToTimeLine = () => {
            //this.props.history.push("/en-UK/timeline/");
            window.location.replace("/public-timeline/");
      };
      redirectToPaymentPage = () => {
            window.location.replace("/create-profile");
            //this.props.history.push("/create-profile");
      };

      render() {
            const messageType = this.state.paymentStatus.msgWrapper.msgType.toUpperCase();
            console.log("error : " + messageType);
            const paymentDate = this.state.reciept.dataObject.purchaseDate.toString();
            var _paymentDate = moment("" + paymentDate + "").format("DD MMMM YYYY");

            const subscriptionEndDate = this.state.reciept.dataObject.subscriptionEndDate.toString();
            var _subscriptionEndDate = moment("" + subscriptionEndDate + "").format("DD MMMM YYYY");

            //console.log("date : " + _paymentDate);
            const Reciepts = (
                  <React.Fragment>
                        {(() => {
                              switch (messageType) {
                                    case "ERROR": {
                                          return (
                                                <div className="container textBackground">
                                                      <div className="row sections" style={{ background: "rgb(200, 0, 0)" }}>
                                                            <div className="col-12" style={{}}>
                                                                  <div
                                                                        className="mainTitleGreen"
                                                                        style={{
                                                                              color: "rgba(255,255,255,1)",
                                                                              marginTop: "0px"
                                                                        }}
                                                                  >
                                                                        Error
                                                                  </div>
                                                                  <div className="paymentFailureIcon" />
                                                                  <h2
                                                                        style={{
                                                                              textAlign: "center",
                                                                              fontSize: "1.5em",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontFamily: "Open Sans Condensed, sans-serif",
                                                                              fontWeight: "bold",
                                                                              textTransform: "uppercase"
                                                                        }}
                                                                  >
                                                                        {this.state.messageDescription}
                                                                  </h2>
                                                            </div>
                                                      </div>
                                                      <div className="row sections">
                                                            <div className="col-12">
                                                                  <div
                                                                        className="createAccount"
                                                                        style={{
                                                                              textAlign: "center",
                                                                              marginLeft: "auto",
                                                                              marginRight: "auto"
                                                                        }}
                                                                  >
                                                                        <button className="goldButton" onClick={this.redirectToPaymentPage}>
                                                                              retry payment
                                                                        </button>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          );
                                    }
                                    case "SUCCESS": {
                                          return (
                                                <React.Fragment>
                                                      <div className="container textBackground">
                                                            <div className="row sections" style={{ background: "rgb(135, 185, 100)" }}>
                                                                  <div className="col-12" style={{}}>
                                                                        <div
                                                                              className="mainTitleGreen"
                                                                              style={{
                                                                                    color: "rgba(255,255,255,1)",
                                                                                    marginTop: "0px"
                                                                              }}
                                                                        >
                                                                              Success
                                                                        </div>
                                                                        <h2 style={{ textAlign: "center" }}>{this.state.messageDescription}</h2>

                                                                        <div className="paymentSuccessIcon" />
                                                                        <h2
                                                                              style={{
                                                                                    textAlign: "center",
                                                                                    fontSize: "1.5em",
                                                                                    color: "rgba(255,255,255,1)",
                                                                                    fontFamily: "Open Sans Condensed, sans-serif",
                                                                                    fontWeight: "bold",
                                                                                    textTransform: "uppercase"
                                                                              }}
                                                                        >
                                                                              {"Net "}
                                                                              {/* {" € "} */}

                                                                              {this.state.reciept.dataObject.netAmount +
                                                                                    " " +
                                                                                    this.state.reciept.dataObject.currency}
                                                                        </h2>

                                                                        <h2
                                                                              style={{
                                                                                    textAlign: "center",
                                                                                    fontSize: "1.5em",
                                                                                    color: "rgba(255,255,255,1)",
                                                                                    fontFamily: "Open Sans Condensed, sans-serif",
                                                                                    fontWeight: "bold",
                                                                                    textTransform: "uppercase"
                                                                              }}
                                                                        >
                                                                              {"Vat "}

                                                                              {this.state.reciept.dataObject.vatPercentage}
                                                                              {" % "}
                                                                        </h2>
                                                                        <h2
                                                                              style={{
                                                                                    textAlign: "center",
                                                                                    fontSize: "1.5em",
                                                                                    color: "rgba(255,255,255,1)",
                                                                                    fontFamily: "Open Sans Condensed, sans-serif",
                                                                                    fontWeight: "bold",
                                                                                    textTransform: "uppercase"
                                                                              }}
                                                                        >
                                                                              {"Total "}
                                                                              {/* {"€ "} */}

                                                                              {this.state.paymentStatus.dataObject.orderGrossAmount +
                                                                                    " " +
                                                                                    this.state.reciept.dataObject.currency}
                                                                        </h2>
                                                                  </div>
                                                            </div>
                                                            <div className="row sections">
                                                                  <div className="col-2" />
                                                                  <div className="col-4">
                                                                        <div className="borderRight" />
                                                                        <h2 style={{ fontSize: "1.5em" }}>Payment details </h2>
                                                                        <span className="formLabel alignLeft" style={{ marginLeft: "10px" }}>
                                                                              payment method: {this.state.paymentStatus.dataObject.paymentMethodCode}
                                                                              <br />
                                                                              your transaction number is:{" "}
                                                                              {this.state.paymentStatus.dataObject.transactionNumber}
                                                                              <br />
                                                                              Subscription type: {this.state.subscriptionType}
                                                                        </span>
                                                                        <h2 style={{ fontSize: "1.5em" }}>Subscription details </h2>
                                                                        <h4>
                                                                              Payment date:{" "}
                                                                              <span className="formLabel alignLeft">{_paymentDate}</span>
                                                                        </h4>
                                                                        <h4>
                                                                              Your subscription ends on:{" "}
                                                                              <span className="formLabel alignLeft">{_subscriptionEndDate}</span>
                                                                        </h4>
                                                                  </div>

                                                                  <div className="col-4">
                                                                        <div className="borderRight" />
                                                                        <h2 style={{ fontSize: "1.5em" }}>Personal details </h2>
                                                                        <h4>
                                                                              Name
                                                                              <span className="formLabel alignLeft">
                                                                                    {this.state.reciept.dataObject.buyerFirstName}{" "}
                                                                                    {this.state.reciept.dataObject.buyerLastName}
                                                                              </span>
                                                                        </h4>

                                                                        <h4>
                                                                              Address: <br />
                                                                              <span className="formLabel alignLeft">
                                                                                    {this.state.reciept.dataObject.deliveryAddressLineThree} <br />
                                                                                    {this.state.reciept.dataObject.deliveryAddressLineTwo} <br />
                                                                                    {this.state.reciept.dataObject.deliveryAddressLineOne}{" "}
                                                                              </span>
                                                                        </h4>

                                                                        <h4>
                                                                              Email:{" "}
                                                                              <span className="formLabel alignLeft">
                                                                                    {this.state.reciept.dataObject.buyerEmailAddress}
                                                                              </span>
                                                                              <span>A reciept will be sent to this email address</span>
                                                                        </h4>
                                                                  </div>
                                                                  <div className="col-2">
                                                                        <div className="borderRight" />
                                                                  </div>
                                                            </div>

                                                            <div className="row sections">
                                                                  <div className="col-12">
                                                                        <div
                                                                              className="createAccount"
                                                                              style={{
                                                                                    textAlign: "center",
                                                                                    marginLeft: "auto",
                                                                                    marginRight: "auto"
                                                                              }}
                                                                        >
                                                                              <button
                                                                                    className="goldButton"
                                                                                    onClick={() => this.redirectToTimeLine()}
                                                                              >
                                                                                    continue
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </React.Fragment>
                                          );
                                    }
                              }
                        })()}
                  </React.Fragment>
            );

            return <React.Fragment>{Reciepts}</React.Fragment>;
      }
}

export default paymentStatus;
