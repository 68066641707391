import React, { Component } from "react";
import loadable from "react-loadable";
import StaticPreLoader from "../../../common/login/SmallComponents/staticPreloader";
// contact route component
const LoadingComponent = () => {
  return <StaticPreLoader />;
};

const LoginComponentPage = () => {
  return import("../../../pages/login-logout/termsOfService/userTerms");
};
const AsyncUserTerms = loadable({
  loader: LoginComponentPage,
  loading: LoadingComponent
});
export default AsyncUserTerms;
