import axios from "axios";
import { getAccessToken, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import getApiURL from "../../common/getAPIUrl";

/**
 *
 * STRING "VALID" = DISCOUNT CODE IS WITHIN VALID DATES, "INVALID" = DISCOUNT CODE IS NOT WITHIN VALID DATE.
 * @param discountCode
 * @return "valid"/"invalid"
 * */

const validateDiscountCodeApi = async function(discountCode) {
      const accessToken = getAccessToken();
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/payment-service/check_DiscountCode_Or_CouponCode_Validation/";
      const apiName = "validateDiscountCodeApi";
      const serviceName = "payment-service";

      var params = new URLSearchParams();
      params.append("discountCode", discountCode);

      const baseUrl = rootURL + "/api/v1/payment-service/check_DiscountCode_Or_CouponCode_Validation/" + discountCode;

      const headers = {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };

      return await axios({
            method: "GET",
            url: baseUrl,
            //params: params,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default validateDiscountCodeApi;
