/*
 * the config file for detectib mobile devices
 *
 *
 */
const checkDevice = () => {
  let isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    iPad: function() {
      console.log(navigator.userAgent.match(/iPad/));
      return navigator.userAgent.match(/iPad/);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
    Edge: function() {
      return navigator.userAgent.match(/Edge/i);
    },
    ieNew: function() {
      return navigator.userAgent.match(/Trident/i);
    },
    ieOld: function() {
      return navigator.userAgent.match(/MSIE/i);
    },
    any: function() {
      console.log(navigator.userAgent.match());
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.iPad() ||
        isMobile.Windows()
      );
    }
  };
  if (isMobile.iPad()) {
    return "iPad";
  }
  if (isMobile.any()) {
    //console.log("Mobile");
    return "Mobile";
  }
  if (isMobile.Android()) {
    //console.log("Android");
    return "Android";
  }
  if (isMobile.BlackBerry()) {
    //console.log("Blackberry");
    return "Blackberry";
  }
  if (isMobile.iOS()) {
    //console.log("iOS");
    return "iOS";
  }
  if (isMobile.Opera()) {
    //console.log("Opera");
    return "Opera";
  }
  if (isMobile.Windows()) {
    //console.log("Windows phone");
    return "Windows phone";
  }
  if (isMobile.Edge()) {
    //console.log("Microsoft edge");
    return "Edge";
  }
  if (isMobile.ieOld()) {
    //console.log("InternetExpolorer Old");
    return "InternetExpolorer";
  }
  if (isMobile.ieNew()) {
    //console.log("Internet Expolorer New");
    return "InternetExpolorer";
  }
  if (!isMobile.any()) {
    //console.log("Computer");
    return "Computer";
  }
};
export default checkDevice;
