import React from "react";
import { render } from "react-dom";
import App from "./components/App";
//import async.js
/* import("./asyncTest.js").then(data => {
  //console.log(data);
}); */
//main entry point for the app

render(<App />, document.getElementById("app"));
