import React, { Component } from "react";
import priceComparisonApi from "../../api/payment/priceComparisonApi";
class priceComparison extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  comparison: [],
                  yearlyPrice: "",
                  monthlyPrice: "",
                  currency: ""
            };
      }
      componentDidMount = async () => {
            this.getPriceComparison();
      };

      getPriceComparison = async () => {
            await priceComparisonApi().then(async (response) => {
                  await this.setState({ comparison: response });

                  await this.state.comparison.map((prices, index) => {
                        if (prices.subscriptionName === "business_yearly_subscription") {
                              this.setState({ yearlyPrice: prices.netPrice });
                        }
                        if (prices.subscriptionName === "business_monthly_subscription") {
                              this.setState({ monthlyPrice: prices.netPrice });
                        }
                        this.setState({ currency: prices.currency });
                  });
            });
      };

      render() {
            return (
                  <section className="priceComparisonWrapper">
                        <div className="priceComparison">
                              <div
                                    style={{
                                          float: "left",
                                          width: "calc(50% - 1px)",
                                          overflow: "hidden"
                                    }}
                              >
                                    <span className="price_header" style={{ backgroundColor: "rgba(110, 140, 72, 1)" }}>
                                          private
                                    </span>
                              </div>
                              <div
                                    style={{
                                          float: "left",
                                          width: "calc(50% + 1px)",
                                          overflow: "hidden"
                                    }}
                              >
                                    <span className="price_header" style={{ backgroundColor: "rgba(176, 112, 41, 1)" }}>
                                          business
                                    </span>
                              </div>

                              {/* col2 */}
                              <div
                                    style={{
                                          float: "left",
                                          width: "calc(50% - 1px)",
                                          borderBottom: "1px solid rgba(94, 94, 96, 0.5)",
                                          borderRight: "1px solid rgba(94, 94, 96, 0.5)",
                                          overflow: "hidden"
                                    }}
                              >
                                    <span className="price_body">free</span>
                              </div>
                              <div
                                    style={{
                                          float: "left",
                                          width: "calc(50% - 1px)",
                                          borderBottom: "1px solid rgba(94, 94, 96, 0.5)",
                                          overflow: "hidden"
                                    }}
                              >
                                    <span className="price_body">
                                          {" "}
                                          {this.state.monthlyPrice + "0 " + this.state.currency}
                                          <span style={{ fontSize: "0.6em" }}> {" / monthly"} </span>
                                    </span>
                              </div>

                              {/* col3 */}

                              <div
                                    style={{
                                          float: "left",
                                          width: "calc(50% - 1px)",
                                          borderRight: "1px solid rgba(94, 94, 96, 0.5)",
                                          overflow: "hidden"
                                    }}
                              >
                                    <span className="price_body">free</span>
                              </div>
                              <div
                                    style={{
                                          float: "left",
                                          width: "calc(50% - 1px)",

                                          overflow: "hidden"
                                    }}
                              >
                                    <span className="price_body">
                                          {this.state.yearlyPrice + "0 " + this.state.currency}
                                          <span style={{ fontSize: "0.6em" }}>{" / yearly"} </span>
                                    </span>
                              </div>
                        </div>
                  </section>
            );
      }
}

export default priceComparison;
