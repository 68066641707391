import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor, setAccessTokensToCookie } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const refreshTokenApi = async refreshToken => {
      const headers = {
            Authorization: "Basic YnJvd3Nlcjo="
      };
      var rootURL = getApiURL();

      const apiEndPoint = "/api/v1/auth-service/oauth/token";
      const apiName = "refreshTokenApi";
      const serviceName = "auth-service";
      const baseUrl = rootURL + apiEndPoint;

      var formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("refresh_token", refreshToken);

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers,
            data: formData
      })
            .then(function(response) {
                  return setAccessTokensToCookie(response);

                  //return true;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default refreshTokenApi;
