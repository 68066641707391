import React from "react";
import { withRouter } from "react-router-dom";
//import components required in login

import PropTypes from "prop-types";

import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import changePassApi from "../../../components/api/login-logout/changePasswordApi";

import { isFormValid, validateSinglePassword, validatePasswords } from "../../common/validation";
import setInitialPassWordChangedToTrueApi from "../../api/profile/setInitialPassWordChangedToTrueApi";
import { sleep } from "../../common/goodieUtils";
import SendResetPassordLinkPopup from "../../common/login/SendResetPassordLinkPopup";

class InitialChangePasswordLandingPage extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  oldPass: "",
                  newPass: "",
                  newPassVerify: "",
                  /* success/error-handling */
                  errors: {},
                  responseMsgWrapper: {},
                  passWordUpdatingStatus: "",
                  isValid: true
            };
      }

      UNSAFE_componentWillMount = () => {
            let urlParams = new URLSearchParams(window.location.search);
            console.log("%curlParams =>> " + JSON.stringify(urlParams, null, "\t"), "color:#00c9a7");
            const oldPass = urlParams.get("oldPass");
            this.setState({ oldPass });
      };

      cleanErrorSuccessMessage = () => {
            this.setState({
                  responseMsgWrapper: {}
            });
            //console.log("SET_MESSAGE_TYPE_TO_NULL : " + this.state.messageType);
            //this.props.history.push("/");
      };

      handleChange = async (event) => {
            const { name, value } = event.target;
            console.log("%cName and Value => " + name + " | " + value, "color:#00c9a7");

            await this.setState({
                  [name]: value
            });

            let { errors } = this.state;
            // name = item jsx name in child
            if (name === "oldPasst") {
                  errors = await validateSinglePassword(this.state.oldPass, errors);
            } else if (name === "newPass") {
                  errors = await validatePasswords(this.state.newPass, this.state.newPassVerify, errors);
            } else if (name === "newPassVerify") {
                  errors = await validatePasswords(this.state.newPass, this.state.newPassVerify, errors);
            }

            await this.setErrorsAndIsValidToState(errors);
      };

      setErrorsAndIsValidToState = async (errors) => {
            this.setState({ errors: errors });
            this.setState({ isValid: await isFormValid(this.state.errors) });
      };
      validate = async () => {
            let { errors } = this.state;

            errors = await validateSinglePassword(this.state.oldPass, errors);
            errors = await validatePasswords(this.state.newPass, this.state.newPassVerify, errors);

            await this.setErrorsAndIsValidToState(errors);

            if (this.state.isValid == true) {
                  this.handleSubmit();
                  //this.updateCustomersData();
            } else {
                  await this.setState({
                        responseMsgWrapper: {
                              msgType: "ERROR",
                              msgDescription: "Cannot submit form. Please make sure that all the fields are correctly formatted!"
                        }
                  });
            }
      };

      handleSubmit = async () => {
            let changePassPayload = { oldPass: this.state.oldPass, newPass: this.state.newPassVerify };

            console.log("%csubmittin", "color:#00c9a7");
            console.log("%cpayLoad" + JSON.stringify(changePassPayload, null, "\t"), "color:#00c9a7");

            let accountServiceResp = await changePassApi(changePassPayload);

            if (accountServiceResp.msgWrapper.msgType === "SUCCESS" || accountServiceResp.msgWrapper.msgType === "success") {
                  console.log("%cSUCCESS IT IS", "color:#00c9a7");

                  // SET THIS SO THAT WE DONT GET HERE ANYMORE
                  let profileServiceResponse = await setInitialPassWordChangedToTrueApi();
                  console.log("%cprofileServiceResponse" + JSON.stringify(profileServiceResponse, null, "\t"), "color:#00c9a7");

                  await this.setState({
                        responseMsgWrapper: {
                              msgType: "SUCCESS",
                              msgDescription: "Password changed. Redirecting to create profile!"
                        }
                  });

                  await sleep(1000);

                  window.location.replace("/create-profile");
            } else {
                  await this.setState({
                        responseMsgWrapper: accountServiceResp.msgWrapper
                  });
            }
      };

      render() {
            /* const messageType = this.state.messageType; */
            const Alerts = (
                  <React.Fragment>
                        {(() => {
                              switch (this.state.responseMsgWrapper.msgType) {
                                    case "ERROR": {
                                          return (
                                                <ErrorWrapper
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                      Message={this.state.responseMsgWrapper.msgDescription}
                                                      messageType={this.state.responseMsgWrapper.msgType}
                                                />
                                          );
                                    }
                                    case "error": {
                                          return (
                                                <ErrorWrapper
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                      Message={this.state.responseMsgWrapper.msgDescription}
                                                      messageType={this.state.responseMsgWrapper.msgType}
                                                />
                                          );
                                    }
                                    case "SUCCESS": {
                                          return (
                                                <SuccessWrapper
                                                      Message={this.state.responseMsgWrapper.msgDescription}
                                                      messageType={this.state.responseMsgWrapper.msgType}
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                />
                                          );
                                    }
                                    case "success": {
                                          return (
                                                <SuccessWrapper
                                                      Message={this.state.responseMsgWrapper.msgDescription}
                                                      messageType={this.state.responseMsgWrapper.msgType}
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                />
                                          );
                                    }
                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );

            let isValid = this.state.isValid ? "no-errors" : "errors";

            return (
                  <div className="horseBackground-support">
                        {/*  <LoginNavigation /> */}

                        <div className="popupResetPassword">
                              <div className="popup_inner">
                                    <div className="popup_header">
                                          <span className="popup_title">UPDATE PASSWORD</span>
                                    </div>

                                    <div className="popup_body">
                                          <span className="message">*old password</span>
                                          <input
                                                type="password"
                                                name="oldPass"
                                                value={this.state.oldPass}
                                                placeholder="old password"
                                                //onChange={this.handleChange}
                                                readOnly
                                          />
                                          <span className={isValid}>{this.state.errors.passwordSingle}</span>

                                          <span className="message">*new password</span>
                                          <input type="password" name="newPass" placeholder="new password" onChange={this.handleChange} />
                                          <span className={isValid}>{this.state.errors.password}</span>

                                          <span className="message">*verify new password</span>
                                          <input
                                                type="password"
                                                name="newPassVerify"
                                                placeholder="verify new password"
                                                onChange={this.handleChange}
                                          />
                                          <span className={isValid}>{this.state.errors.passwordVerify}</span>

                                          <button className="goldButton" onClick={this.validate}>
                                                {" "}
                                                {this.state.passWordUpdatingStatus ? "updating..." : "update password"}
                                          </button>
                                    </div>
                              </div>
                        </div>

                        {Alerts}

                        {/* <Footer /> */}
                  </div>
            );
      }
}
InitialChangePasswordLandingPage.propTypes = {
      match: PropTypes.object.isRequired
};
export default withRouter(InitialChangePasswordLandingPage);
