import getAPIUrl from "../../components/common/getAPIUrl";
import uuidv4 from "uuid/v4";

/**
 * This functions sleeps
 * for ms ammount of milliseconds
 * @param {*} ms
 */

export const sleep = ms => {
      return new Promise(resolve => setTimeout(resolve, ms));
};
/**
 * return a link to profilePicture for a user
 * Uses uuid for front end cache not to cache the profileImage
 * @param {*} userId
 */
export const getProfileImageUrl = async userId => {
      const apiURL = await getAPIUrl();

      let random = await uuidv4();

      const url = apiURL + "/api/v1/gallery-service/images/profilepictures/" + userId + "/0/" + random + ".jpg";
      console.log("%cprofileImageURl ->> " + url, "color:#00C9A7");
      return url;
};
