import React from "react";
import LoginNavigation from "../../common/login/loginNavigation";
import { withRouter } from "react-router-dom";
import Footer from "../../common/login/Footer";
import PropTypes from "prop-types";
import activateAccountApi from "../../api/account-service/activateAccountApi";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import FadeIn from "react-fade-in";
class ActivationLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Message: "",
      messageType: ""
    };
  }

  UNSAFE_componentWillMount = () => {
    this.handleSubmit();
  };
  goToLoginPage = () => {
    this.props.history.push("/");
  };

  handleSubmit = () => {
    activateAccountApi(this.props.match.params.activationToken).then(
      function(response) {
        //console.log("resetPasswordApi response: " + JSON.stringify(response));
        //console.log(
        //  "resetPasswordApi response: " + JSON.stringify(response.msgType)
        //);
        //console.log(
        //    "resetPasswordApi response: " +
        //    JSON.stringify(response.msgDescription)
        //);
        this.setState({
          messageType: response.msgType,
          Message: response.msgDescription
        });
      }.bind(this)
    );

    try {
      setTimeout(async () => {
        this.goToLoginPage();
      }, 3000);
    } catch (e) {
      console.log("error " + e);
    }
  };

  render() {
    //console.log("ActivationToken = " + this.props.match.params.activationToken);

    const messageType = this.state.messageType;

    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "ERROR": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "SUCCESS":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    return (
      <FadeIn transitionDuration={100} delay={20}>
        <div className="horseBackground-support">
          <div className="popupActivateAccount">
            <div className="popup_inner">
              <div className="popup_header">
                <span className="popup_title">ACTIVATE YOUR ACCOUNT</span>
              </div>

              <div className="popup_body">
                <span className="message">{this.state.messageType}</span>
                <br />
                <span className="description">{this.state.Message}</span>
                <span className="description">
                  You will be redirected to the login page automatically.
                </span>
              </div>
            </div>
          </div>
          {/* {Alerts} */}
          {/* <Footer /> */}
        </div>
      </FadeIn>
    );
  }
}
ActivationLandingPage.propTypes = {
  match: PropTypes.object.isRequired
};
export default withRouter(ActivationLandingPage);
