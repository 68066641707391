import React, { Component } from "react";
import Img from "react-fix-image-orientation";
import getApiUrl from "../../../common/getAPIUrl";
import { setUserProfilePictureIdToCookie } from "../../../common/cookieAndAccessHandling";
import addProfilePictureApi from "../../../api/gallery-service/addProfilePictureApi";

class ChangeAvatarImageComponent extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  updated: false,
                  profileType: "",
                  file: "",
                  imagePreviewUrl: {},
                  rootApiUrl: ""
            };
      }
      componentDidMount = () => {
            this.setState({ rootApiUrl: getApiUrl() });
      };
      handleSubmit = (event) => {
            event.preventDefault();
      };

      handleImageChange = async (event) => {
            event.preventDefault();

            let imgdata = event.target.files[0];

            addProfilePictureApi(imgdata).then((response) => {
                  this.setState({ updated: true });
                  this.props.triggerAvatarToReloadProfilePicture(this.props.userId);
            });

            let reader = new FileReader();
            let file = event.target.files[0];

            reader.onloadend = () => {
                  this.setState({
                        file: file,
                        imagePreviewUrl: reader.result
                  });
            };
            reader.readAsDataURL(file);

            setUserProfilePictureIdToCookie(this.props.userProfilePictureId);
      };

      render() {
            let { imagePreviewUrl } = this.state;
            let $imagePreview = null;
            if (imagePreviewUrl) {
                  $imagePreview = (
                        <Img
                              style={{
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    width: "100px",
                                    height: "100px",
                                    backgroundImage: "url(" + imagePreviewUrl + ")"
                              }}
                        />
                  );
            }

            const profileType = this.props.profileType;
            console.log("user avatar uploader : " + this.props.userProfilePictureId);
            return (
                  <React.Fragment>
                        {(() => {
                              switch (profileType) {
                                    case "user":
                                          return (
                                                <div className="previewComponent">
                                                      {(() => {
                                                            if (
                                                                  this.props.userProfilePictureId == null ||
                                                                  this.props.userProfilePictureId == undefined ||
                                                                  this.props.userProfilePictureId === ""
                                                            ) {
                                                                  return (
                                                                        <div
                                                                              className="profileImageWrapper"
                                                                              style={{
                                                                                    backgroundImage:
                                                                                          "url(" +
                                                                                          this.state.rootApiUrl +
                                                                                          "/api/v1/gallery-service/images/profilepictures/" +
                                                                                          this.props.userId +
                                                                                          "/0/xxx.jpg)",
                                                                                    backgroundPosition: "center center",
                                                                                    backgroundSize: "cover"
                                                                              }}
                                                                        >
                                                                              {" "}
                                                                              {$imagePreview}
                                                                        </div>
                                                                  );
                                                            } else {
                                                                  return (
                                                                        <div
                                                                              className="profileImageWrapper"
                                                                              style={{
                                                                                    backgroundImage:
                                                                                          "url(" +
                                                                                          this.state.rootApiUrl +
                                                                                          "/api/v1/gallery-service/images/profilepictures/" +
                                                                                          this.props.userId +
                                                                                          "/" +
                                                                                          this.props.userProfilePictureId +
                                                                                          ")",
                                                                                    backgroundPosition: "center center",
                                                                                    backgroundSize: "cover"
                                                                              }}
                                                                        >
                                                                              {" "}
                                                                              {$imagePreview}
                                                                        </div>
                                                                  );
                                                            }
                                                      })()}
                                                      <label className="imageUploadBtn goldButton">
                                                            upload your profile image
                                                            <input className="fileInput" type="file" onChange={this.handleImageChange} />
                                                      </label>
                                                </div>
                                          );
                                    case "business":
                                          return (
                                                <div className="previewComponent">
                                                      <div
                                                            className="profileImageWrapper"
                                                            style={{
                                                                  backgroundImage:
                                                                        "url(" +
                                                                        process.env.API_URL +
                                                                        "/api/v1/gallery-service/images/profilepictures/" +
                                                                        this.props.userId +
                                                                        "/" +
                                                                        this.props.userProfilePictureId +
                                                                        ")",
                                                                  backgroundPosition: "center center",
                                                                  backgroundSize: "cover"
                                                            }}
                                                      >
                                                            {" "}
                                                            {$imagePreview}
                                                      </div>
                                                      <label className="imageUploadBtn goldButton" style={{ marginBottom: "0px" }}>
                                                            upload your company logo
                                                            <input className="fileInput" type="file" onChange={this.handleImageChange} />
                                                      </label>
                                                </div>
                                          );

                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );
      }
}

export { ChangeAvatarImageComponent };
