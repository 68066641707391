import axios from "axios";
import getApiURL from "../../../common/getAPIUrl";
import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../../common/getErrorResponseEntity";

const initialLoginUpdateUserInfoApi = async function(initialLoginUserInfoPayLoad) {
      const token = getAccessToken();

      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };

      //console.log("Headers:" + JSON.stringify(headers));
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/profile-service/updateprofile_info_initial_login_WithGlobalWrapper";
      const apiName = "initialLoginUpdateUserInfoApi";
      const serviceName = "profile-service";

      const baseUrl = rootURL + apiEndPoint;

      return await axios({
            method: "post",
            url: baseUrl,
            data: initialLoginUserInfoPayLoad,
            headers: headers
      })
            .then(function(response) {
                  return response.data; // GlobalWrapper with "Info" as object Payload
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default initialLoginUpdateUserInfoApi;
