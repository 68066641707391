import React, { Component } from "react";
import { getCurrentlyLoggedInProfileFunc } from "../../../common/getCurrentlyLoggedInProfile";
import { getProfileImageUrl } from "../../goodieUtils";

class Avatar extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  profile: {
                        companyName: "",
                        userId: "",
                        displayName: "",
                        firstName: "",
                        lastName: "",
                        profileInfoComplete: false,
                        profileType: "",
                        userProfilePictureId: ""
                  },
                  friendCounterIcon: false,
                  avatarLink: "",
                  dropDownNotificationSwitch: false,
                  friendNotificationNavigationSwitch: false,
                  notificationNavigationSwitch: false
            };
      }
      UNSAFE_componentWillReceiveProps = async newProps => {
            console.log(
                  "%cRecieving Props : " + JSON.stringify(newProps.triggerAvatarToReloadProfilePictureUserId, null, "\t"),
                  "background:black;color:yellow"
            );
            //let link = "";
            if (newProps.triggerAvatarToReloadProfilePictureUserId !== "") {
                  //console.log('%c != ""  ', "background:black;color:yellow");
                  //link = await getProfileImageUrl(newProps.triggerAvatarToReloadProfilePictureUserId);
                  //console.log("%clink --> " + link, "background:black;color:magenta");
                  //await sleep(3000);
                  await this.setState({
                        avatarLink: await getProfileImageUrl(newProps.triggerAvatarToReloadProfilePictureUserId)
                  });
            }
      };
      componentDidMount = async () => {
            const profile = await getCurrentlyLoggedInProfileFunc();
            this.setState({ profile: profile });

            if (this.state.profile.profileType == "user") {
                  this.setState({ friendCounterIcon: true });
            } else {
                  this.setState({ friendCounterIcon: false });
            }
            //console.log("%cProfile " + JSON.stringify(profile, null,"\t"),"background:black; color:#00ff00");

            console.log("USER ID :" + profile.userId);
            let avatarUrl = await getProfileImageUrl(profile.userId);
            //console.log("%cAvatar url " + avatarUrl, "background:black; color:#00ff00");

            await this.setState({
                  avatarLink: avatarUrl
            });
      };

      render() {
            const { avatarLink } = this.state;
            return (
                  <span className="userBadge">
                        <a href="/account-settings" style={{ textDecoration: "none" }} className="mainNav-userName">
                              {this.state.profile.companyName
                                    ? this.state.profile.companyName
                                    : this.state.profile.displayName
                                          ? this.state.profile.displayName
                                          : this.state.profile.firstName + " " + this.state.profile.lastName}
                        </a>

                        <div
                              className="avatarsWrapper"
                              style={{
                                    backgroundImage: "url(" + avatarLink + ")",
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    position: "relative",
                                    display: "block"
                                    // top: "16px"
                              }}
                        />
                  </span>
            );
      }
}

export default Avatar;
