import React, { Component } from "react";
import getTempWizzardProfileInfoForBusinessUserApi from "../../../../api/account-service/stepperApis/getTempProfileInfo";
//import getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi from "../../../../api/payment/getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi";
import checkIfAccountEmailIsAlreadyUsedApi from "../../../../api/account-service/checkIfAccountEmailIsAlreadyUsedApi";

class Step4 extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  tempWizardProfileData: {},
                  subscriptionMonthly: {}, // SubscriptionData price and currency code yearly
                  subscriptionYearly: {}, // SubscriptionData price and currency code monthly
                  emailsExistInServicesPaymentAndOrAccount: {}
            };
      }
      UNSAFE_componentWillMount = async () => {
            const accountServiceResponse = await getTempWizzardProfileInfoForBusinessUserApi(2); //EVen though we send in 4 here its just because i want data object back so that i can do more logic here
            await this.setState({ tempWizardProfileData: accountServiceResponse.dataObject });

            // Check if email exists in account and payment service
            // If email exists in payment means that a salesClerk created this customer
            const accountServiceResponse_ = await checkIfAccountEmailIsAlreadyUsedApi(this.state.tempWizardProfileData.email);

            await this.setState({ emailsExistInServicesPaymentAndOrAccount: accountServiceResponse_ });
            console.log(
                  "%cemailsExistInServicesPaymentAndOrAccount : " + JSON.stringify(this.state.emailsExistInServicesPaymentAndOrAccount, null, "\t"),
                  "color:cyan"
            );
            // Set the discountCode_Or_couponCode in parent step when customer is created from salesClerk
            if (
                  this.state.emailsExistInServicesPaymentAndOrAccount.emailExistInBrokerCustomerRecord &&
                  this.state.tempWizardProfileData.brokerIssuedCouponCodeAndUnused
            ) {
                  await this.props.setCouponCode(this.state.tempWizardProfileData.brokerIssuedCouponCode);
            }
      };

      renderSubscriptionFormData = () => {
            /**
             *
             * tempWizardProfileData : {
             *    "userId": "5e8c38752ab79c000c4665ff",
             *    "email": "mafot35640@itiomail.com",
             *    "country": "Sweden",
             *    "region": "Dalarna",
             *    "streetAddress": null,
             *    "postalCode": null,
             *    "phoneNumber": null,
             *    "vatNumber": null,
             *    "companyName": "guerilla attack fighter ninja",
             *    "brokerIssuedCouponCode": "a617-3U0v-2231448",
             *    "termsAndConditionsAccepted": true,
             *    "createdDate": "4/7/20 8:23 AM",	"modifiedDate": "4/7/20 8:23 AM",
             *    "brokerIssuedCouponCodeAndUnused": true,
             *    "subscribedForNewsLetters": true}
             */

            if (this.state.emailsExistInServicesPaymentAndOrAccount.emailExistInBrokerCustomerRecord) {
                  if (this.state.tempWizardProfileData.brokerIssuedCouponCodeAndUnused) {
                        return (
                              <>
                                    {/* <h2 style={{ textAlign: "center" }}>Choose payment type.</h2> */}
                                    <label style={{ textAlign: "center" }} className="formLabel">
                                          your subscription is finished after the payment is complete.
                                          <br />
                                          The payment is recurring.
                                    </label>

                                    <div
                                          style={{
                                                marginTop: "20px",
                                                width: "220px",
                                                border: "1ps solid rgba(204,204,204,1)",
                                                //textAlign: "center"
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                          }}
                                    >
                                          <label className="checkLabel alignCenter">
                                                {/* <input
                                                type="radio"
                                                value="yearly"
                                                name="subscription"
                                                //onChange={this.props.handlePaymentSubscriptionChange}
                                                checked={this.props.subscription == "yearly"}
                                                readOnly
                                          /> */}
                                                Yearly subscription net price
                                                <br />
                                                <h2>
                                                      {/* {" € "} */}
                                                      {this.props.businessYearlyCurrency} {this.props.businessYearlyNetPrice}0
                                                      {/* {this.state.subscriptionYearly.currency} {this.state.subscriptionYearly.netPrice}0 */}
                                                </h2>
                                          </label>
                                    </div>

                                    <div
                                          style={{
                                                width: "530px",
                                                marginTop: "20px",

                                                display: "block",
                                                /* textAlign: "center", */
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                paddingLeft: "auto",
                                                paddigRight: "auto",
                                                marginBottom: "10px"
                                          }}
                                    >
                                          <label className="formLabel alignCenter">
                                                Your discount code is: <br />
                                          </label>

                                          <input
                                                type="text"
                                                className="textinput alignCenter"
                                                name="discountCode"
                                                placeholder="please enter your code ..."
                                                value={this.state.tempWizardProfileData.brokerIssuedCouponCode}
                                                //onChange={this.props.handleChange}
                                                style={
                                                      {
                                                            /* float: "left", */
                                                            //marginLeft: "auto",
                                                            //marginRight: "auto",
                                                            /*   textAlign: "center", */
                                                            //width: "300px"
                                                      }
                                                }
                                                readOnly
                                          />
                                    </div>
                              </>
                        );
                  }
            } else {
                  return (
                        <>
                              <h2 style={{ textAlign: "center" }}>Choose payment type.</h2>
                              <label style={{ textAlign: "center" }} className="formLabel">
                                    your subscription is finished after the payment is complete.
                                    <br />
                                    the payment is recurring, please choose your payment plan.
                              </label>

                              <div
                                    style={{
                                          marginTop: "20px",
                                          width: "220px",
                                          border: "1ps solid rgba(204,204,204,1)",
                                          // textAlign: "center",
                                          marginLeft: "auto",
                                          marginRight: "auto"
                                    }}
                              >
                                    <label className="checkLabel">
                                          <input
                                                type="radio"
                                                value="yearly"
                                                name="subscription"
                                                onChange={this.props.handlePaymentSubscriptionChange}
                                                checked={this.props.subscription == "yearly"}
                                          />
                                          Yearly subscription net price
                                          <br />
                                          <h2>
                                                {/* {" € "} */}
                                                {this.props.businessYearlyCurrency} {this.props.businessYearlyNetPrice}0
                                                {/* {this.state.subscriptionYearly.currency} {this.state.subscriptionYearly.netPrice}0 */}
                                          </h2>
                                    </label>

                                    <label className="checkLabel">
                                          <input
                                                type="radio"
                                                value="monthly"
                                                name="subscription"
                                                onChange={this.props.handlePaymentSubscriptionChange}
                                                checked={this.props.subscription == "monthly"}
                                          />
                                          Monthly subscription net price <br />
                                          <h2>
                                                {/* {" € "} */}
                                                {this.props.businessMonthlyCurrency} {this.props.businessMonthlyNetPrice}0
                                                {/*   {this.state.subscriptionMonthly.currency} {this.state.subscriptionMonthly.netPrice}0 */}
                                          </h2>
                                    </label>
                              </div>

                              <div
                                    style={{
                                          width: "530px",
                                          marginTop: "20px",

                                          display: "block",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          marginBottom: "10px"
                                    }}
                              >
                                    <label className="formLabel alignLeft">
                                          do you have a discount or coupon code? <br />
                                    </label>

                                    <input
                                          type="text"
                                          className="textinput"
                                          name="discountCode"
                                          placeholder="please enter your code ..."
                                          value={this.props.discountCode}
                                          onChange={this.props.handleChange}
                                          style={{
                                                float: "left",
                                                width: "300px"
                                          }}
                                    />
                                    <button
                                          className="goldButton"
                                          onClick={() => this.props.validateDiscountCode()}
                                          style={{
                                                float: "left",
                                                width: "200px",
                                                marginTop: "0px",
                                                marginLeft: "10px",
                                                height: "48px"
                                          }}
                                    >
                                          Validate your code
                                    </button>
                              </div>
                        </>
                  );
            }
      };
      render() {
            const profileType = this.props.profileType;
            const messageType = this.props.messageType;

            if (this.props.currentStep !== 4) {
                  return null;
            } else {
                  if (this.props.subscriptionPaid == true && profileType === "business") {
                        console.log(" ===== subscription paid" + this.props.subscriptionPaid);

                        return (
                              <React.Fragment>
                                    <h2 style={{ textAlign: "center" }}>You have already paid.</h2>
                                    <label style={{ textAlign: "center" }} className="formLabel">
                                          Click on proceed button to get into the site. <br />
                                    </label>

                                    <div
                                          className="createAccount"
                                          style={{
                                                textAlign: "center",
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                          }}
                                    >
                                          <button className="goldButton" onClick={() => this.props.submitData()}>
                                                Proceed
                                          </button>
                                    </div>
                              </React.Fragment>
                        );
                  } else {
                        return (
                              <React.Fragment>
                                    {(() => {
                                          switch (profileType) {
                                                case "user":
                                                      return (
                                                            <React.Fragment>
                                                                  {(() => {
                                                                        switch (messageType) {
                                                                              case "SUCCESS":
                                                                                    return (
                                                                                          <React.Fragment>
                                                                                                <h2 style={{ textAlign: "center" }}>
                                                                                                      {this.props.messageType}
                                                                                                </h2>
                                                                                                <label
                                                                                                      style={{ textAlign: "center" }}
                                                                                                      className="formLabel"
                                                                                                >
                                                                                                      {this.props.Message}
                                                                                                      <br />
                                                                                                      <br />
                                                                                                      Welcome to HorseMetro.com
                                                                                                </label>
                                                                                                <div
                                                                                                      className="createAccount"
                                                                                                      style={{
                                                                                                            textAlign: "center",
                                                                                                            marginLeft: "auto",
                                                                                                            marginRight: "auto"
                                                                                                      }}
                                                                                                >
                                                                                                      <button
                                                                                                            className="goldButton"
                                                                                                            onClick={this.props.redirectToLoginHome}
                                                                                                      >
                                                                                                            Continue
                                                                                                      </button>
                                                                                                </div>
                                                                                          </React.Fragment>
                                                                                    );
                                                                              case "ERROR":
                                                                                    return (
                                                                                          <React.Fragment>
                                                                                                <h2 style={{ textAlign: "center" }}>
                                                                                                      {this.props.messageType}
                                                                                                </h2>
                                                                                                <label
                                                                                                      style={{ textAlign: "center" }}
                                                                                                      className="formLabel"
                                                                                                >
                                                                                                      {this.props.Message}
                                                                                                      <br />
                                                                                                      <br />
                                                                                                      please try again later.
                                                                                                </label>
                                                                                          </React.Fragment>
                                                                                    );
                                                                              default:
                                                                                    return (
                                                                                          <React.Fragment>
                                                                                                <h2 style={{ textAlign: "center" }}>
                                                                                                      Finish your account creation.
                                                                                                </h2>
                                                                                                <label
                                                                                                      style={{ textAlign: "center" }}
                                                                                                      className="formLabel"
                                                                                                >
                                                                                                      Click on create account to complete your
                                                                                                      registration. <br />
                                                                                                      <br />
                                                                                                      Welcome to HorseMetro.com
                                                                                                </label>

                                                                                                <div
                                                                                                      className="createAccount"
                                                                                                      style={{
                                                                                                            textAlign: "center",
                                                                                                            marginLeft: "auto",
                                                                                                            marginRight: "auto"
                                                                                                      }}
                                                                                                >
                                                                                                      <button
                                                                                                            className="goldButton"
                                                                                                            onClick={() => this.props.submitData()}
                                                                                                      >
                                                                                                            Create Account
                                                                                                      </button>
                                                                                                </div>
                                                                                          </React.Fragment>
                                                                                    );
                                                                        }
                                                                  })()}
                                                            </React.Fragment>
                                                      );
                                                case "business":
                                                      return (
                                                            <React.Fragment>
                                                                  {(() => {
                                                                        switch (messageType) {
                                                                              case "ERROR":
                                                                                    return (
                                                                                          <React.Fragment>
                                                                                                <h2 style={{ textAlign: "center" }}>
                                                                                                      {this.props.messageType}
                                                                                                </h2>
                                                                                                <label
                                                                                                      style={{ textAlign: "center" }}
                                                                                                      className="formLabel"
                                                                                                >
                                                                                                      {this.props.Message}
                                                                                                      <br />
                                                                                                      <br />
                                                                                                      please try again later
                                                                                                </label>
                                                                                                <button
                                                                                                      className="goldButton"
                                                                                                      onClick={this.props.redirectToLoginHome}
                                                                                                >
                                                                                                      back to login
                                                                                                </button>
                                                                                          </React.Fragment>
                                                                                    );

                                                                              default:
                                                                                    return (
                                                                                          <React.Fragment>
                                                                                                {this.renderSubscriptionFormData()}

                                                                                                <div
                                                                                                      style={{
                                                                                                            marginTop: "20px",
                                                                                                            width: "180px",
                                                                                                            border: "1ps solid rgba(204,204,204,1)",
                                                                                                            textAlign: "center",
                                                                                                            marginLeft: "auto",
                                                                                                            marginRight: "auto"
                                                                                                      }}
                                                                                                >
                                                                                                      <div
                                                                                                            className="createAccount"
                                                                                                            style={{
                                                                                                                  textAlign: "center",
                                                                                                                  marginLeft: "auto",
                                                                                                                  marginRight: "auto"
                                                                                                            }}
                                                                                                      >
                                                                                                            <button
                                                                                                                  // className="goldButton"
                                                                                                                  style={{
                                                                                                                        backgroundColor:
                                                                                                                              "rgba 255,255,255,1",
                                                                                                                        position: "absolute",
                                                                                                                        zIndex: "400",
                                                                                                                        bottom: "-105px",
                                                                                                                        right: "10px",
                                                                                                                        width: "40px",
                                                                                                                        height: "40px",
                                                                                                                        overflow: "hidden",
                                                                                                                        borderRadius: "2px",
                                                                                                                        margin: "0px"
                                                                                                                  }}
                                                                                                                  onClick={() =>
                                                                                                                        this.props.submitData()
                                                                                                                  }
                                                                                                            >
                                                                                                                  {/* Proceed to payment */}
                                                                                                                  <span className="nextIcon" />
                                                                                                            </button>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </React.Fragment>
                                                                                    );
                                                                        }
                                                                  })()}
                                                            </React.Fragment>
                                                      );
                                                default:
                                                      return null;
                                          }
                                    })()}
                              </React.Fragment>
                        );
                  }
            }
      }
}

export { Step4 };
