import React, { Component } from "react";

class Next extends Component {
      render() {
            return (
                  <button
                        style={{
                              width: "40px",
                              height: "40px",
                              overflow: "hidden",
                              position: "relative",
                              borderRadius: "2px",
                              margin: "0px",
                              background: "rgba(255,255,255,1) !important"
                        }}
                        // className="goldButton"
                        onClick={() => this.props.goToNextStep()}
                  >
                        <span className="nextIcon" />
                  </button>
            );
      }
}

export { Next };
