import React, { Component } from "react";
import getAllowedCountriesApi from "../../../api/localization/getAllowedCountriesApi";
import getStatesInCountryApi from "../../../api/localization/getStatesInCountryApi";
import GetFullProfile from "../../../api/profile/getCurrentlyLoggedInProfile_fullApi";
import updateBusinessUserInfoApi from "../../../api/profile/info/updateBusinessUserInfoApi";
import updateUserInfoApi from "../../../api/profile/info/updateUserInfoApi";
import { ErrorWrapper, SuccessWrapper } from "../../alerts";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Select from "react-select";
import { ChangeAvatarImageComponent, HorseIntrestsComponent, AboutMeComponent, SubscriptionsComponent, PurchasesComponent } from "../my-profile";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";
import "moment/locale/en-gb";
import moment from "moment";
import { setUserNameToCookie } from "../../../common/cookieAndAccessHandling";

class ProfileComponent extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  errors: {},
                  messageType: "",
                  Message: "",
                  placeholderDate: "",
                  placeHolderCountry: "Please choose a country",
                  placeHolderRegion: "Please choose a region",
                  profile: [],
                  info: [],
                  countries: [],
                  regions: [],
                  //form elements
                  countrySelectedValue: { label: "", value: "" },
                  regionSelectedValue: { label: "", value: "" },
                  firstName: "",
                  lastName: "",
                  displayName: "",
                  streetAdress: "",
                  companyName: "",
                  vatNumber: "",
                  phoneNumber: "",
                  postalCode: "",
                  latitude: "",
                  longitude: "",
                  vatNumber: "",
                  webSite: "",
                  selectedDay: "",
                  thirteenYearsAgo: new Date(moment().subtract(13, "years").add(1, "days")),
                  userBirthYear: "",
                  test: "SE",
                  gender: "other",
                  updateButtonLoading: false,
                  // Values coming in from horseInterstComponent and from initialLoad
                  horseInterestCheckBoxVals: {
                        trainAndCompete: false,
                        trainNotCompete: false,
                        rideToRelax: false,
                        otherIntrests: false,
                        trainerInstructor: false,
                        ownHorse: false,
                        trainWithOtherHorses: false,
                        coRider: false,
                        ridingSchool: false,
                        casualHorseRider: false
                  },
                  Associations: "",
                  OtherInterests: "",
                  checkboxVals: {
                        /* private account */
                        Allround: false,
                        Distance: false,
                        Dressage: false,
                        Eventing: false,
                        Gallop: false,
                        Jumping: false,
                        Hunting: false,
                        Driving: false,
                        Trotting: false,
                        Vaulting: false,
                        Western: false,
                        Other: false,

                        /* business account */

                        /* breeding */
                        Breeding: false,
                        BreedingChild: false,
                        Stallions: false,
                        Insamination: false,
                        Nutritionist: false,
                        BreedingOther: false,

                        /* construction and maintenance */
                        ConstructionAndMaintenance: false,
                        ConstructionServices: false,
                        ConstructionStore: false,
                        ConstructionManufacturer: false,
                        ConstructionOther: false,

                        /* finance and law */
                        FinanceAndLaw: false,
                        LegalServices: false,
                        InsuranceCompany: false,
                        Bank: false,
                        LegalOther: false,

                        /* electronics computer technology */
                        ElectronicsComputerTech: false,
                        ElectronicsStore: false,
                        ElectronicsManufacturer: false,
                        ElectronicsOther: false,

                        /* feed and bait */
                        FeedAndBait: false,
                        FeedAndBaitStore: false,
                        FeedAndBaitManufacturer: false,
                        FeedAndBaitOther: false,

                        /* vehicle */
                        Vehicle: false,
                        CarSales: false,
                        VehicleRepairs: false,
                        MachineDealers: false,
                        VehicleManufacturer: false,
                        VehicleOther: false,

                        /* organisation */
                        Organization: false,

                        /* home household and garden */
                        HomeHouseholdGarden: false,
                        Architect: false,
                        GardenDesigner: false,
                        Broker: false,
                        Designer: false,
                        FarmShop: false,
                        HomeHouseholdGardenManufacturer: false,
                        HomeHouseholdGardenOther: false,

                        /* hotels and travel */
                        HotelsAndTravel: false,
                        BedAndBreakfast: false,
                        Hotel: false,
                        TravelAgents: false,
                        Vacations: false,
                        Accommodation: false,
                        Conferences: false,
                        HotelsAndTravelOther: false,

                        /* health and beauty */
                        HealthAndBeauty: false,
                        HealthAndBeautyStore: false,
                        HealthAndBeautyManufacturer: false,
                        HealthAndBeautyOther: false,

                        /* horse accesories */
                        HorseAccessories: false,
                        RidingHousesAndStallSupplies: false,
                        EquestrianShop: false,
                        Saddler: false,
                        HorseAccessoriesStore: false,
                        HorseAccessoriesManufacturer: false,
                        HorseAccessoriesOther: false,

                        /* toys */
                        Toys: false,
                        ToyStore: false,
                        ToyManufacturer: false,
                        ToyOther: false,

                        /* media and advertisement */
                        MediaAdvertisement: false,
                        MediaStore: false,
                        MediaEvents: false,
                        Photographer: false,
                        Journalist: false,
                        Advertiser: false,
                        Publisher: false,
                        Newspaper: false,
                        MediaOther: false,

                        /* fashion */
                        Fashion: false,
                        FashionStore: false,
                        FashionManufacturer: false,
                        FashionOther: false,

                        /* leasure */
                        Leisure: false,
                        LeisureEvents: false,
                        LeisureOther: false,

                        /* education */
                        SchoolEducation: false,
                        RidingSchool: false,
                        TrainigInstructor: false,
                        College: false,
                        University: false,
                        SchoolEducationOther: false,

                        /* horse related services */
                        HorseRelatedServices: false,
                        Breaking: false,
                        Dentist: false,
                        Driver: false,
                        Farrier: false,
                        InRunning: false,
                        Masseur: false,
                        hrNutritionist: false, // There is another "Nutritionist" in checkbox vals hence the hrNutritionist
                        HorseRelatedServicesStore: false,
                        Therapist: false,
                        Transport: false,
                        Vet: false,
                        HorseRelatedServicesOther: false,

                        /*Organization */
                        Organization: false,
                        //OrganizationChild: false,

                        /*other business */
                        businessOther: false
                  },
                  formIsValid: true,
                  breedingOtherServices: "",
                  constructionOtherServices: "",
                  legalOtherServices: "",
                  electronicsOtherServices: "",
                  feedAndBaitOtherServices: "",
                  vehicleOtherServices: "",
                  legalOtherServices: "",
                  homeHouseholdGardenOtherServices: "",
                  hotelsAndTraveOtherServices: "",
                  healthAndBeautyOthterServices: "",
                  horseAccessoriesOtherServices: "",
                  toysOtherServices: "",
                  mediaOtherServices: "",
                  fashionOtherServices: "",
                  leisureOtherServices: "",
                  schoolOtherServices: "",
                  horseRelatedOtherServices: "",
                  businessOtherServices: ""
            };
      }

      handleHorseInterestCheckBoxVals = (checkBoxVals) => {
            this.setState({ horseInterestCheckBoxVals: checkBoxVals });
            //console.group("HandleHorseInterestCheckBoxVals");
            //console.log(this.state.horseInterestCheckBoxVals);
            //console.groupEnd();
      };

      handleChange = (event) => {
            const name = event.target.name,
                  val = event.target.value;

            this.setState({
                  [name]: val
            });
      };

      handleCheckChange = (event) => {
            const target = event.target;
            const value = target.type === "checkbox" ? target.checked : target.value;
            const name = target.name;
            // //console.log("check changed : " + name);
            // //console.log("check changed : " + value);
            //this.setState({ checkboxVals: name, value });

            this.setState((prevState) => ({
                  checkboxVals: {
                        // object that we want to update
                        ...prevState.checkboxVals, // keep all other key-value pairs
                        [target.name]: value // update the value of specific key
                  }
            }));
      };

      handleCountryChange = (countrySelectedValue) => {
            this.setState({ countrySelectedValue: countrySelectedValue });
            this.getRegions(countrySelectedValue.value);

            const clearRegionSelectedValue = { label: "", value: "" };
            this.setState({ regionSelectedValue: clearRegionSelectedValue });
            return this.state.countrySelectedValue;
      };

      handleRegionChange = (regionSelectedValue) => {
            this.setState({ regionSelectedValue: regionSelectedValue });
            return this.state.regionSelectedValue;
      };
      handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
            const input = dayPickerInput.getInput();

            this.setState({
                  selectedDay,
                  step1IsEmpty: !input.value.trim(),
                  step1IsDisabled: modifiers.disabled === true
            });
      };

      cleanErrorSuccessMessage = () => {
            this.setState({
                  messageType: null
            });
      };
      submitData = () => {
            // //console.log("submit data");

            const profileType = this.state.profile.profileType;
            const businessUserInfoPayLoad = {
                  country: this.state.countrySelectedValue.label,
                  companyName: this.state.companyName,
                  region: this.state.regionSelectedValue.label,
                  streetAdress: this.state.streetAdress,
                  postalCode: this.state.postalCode,
                  latitude: this.state.latitude,
                  longitude: this.state.longitude,
                  phoneNumber: this.state.phoneNumber,
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  vatNumber: this.state.vatNumber,
                  webSiteUrl: this.state.webSite,
                  providingServices: {
                        breeding: {
                              parentChecked: this.state.checkboxVals.Breeding,
                              breeding: this.state.checkboxVals.BreedingChild,
                              stallions: this.state.checkboxVals.Stallions,
                              insamination: this.state.checkboxVals.Insamination,
                              nutritionist: this.state.checkboxVals.Nutritionist,
                              otherChecked: this.state.checkboxVals.BreedingOther,
                              other: this.state.breedingOtherServices
                        },
                        constructionAndMaintance: {
                              parentChecked: this.state.checkboxVals.ConstructionAndMaintenance,
                              construction_Services_Craftsmen: this.state.checkboxVals.ConstructionServices,
                              store: this.state.checkboxVals.ConstructionStore,
                              manufacturer: this.state.checkboxVals.ConstructionManufacturer,
                              otherChecked: this.state.checkboxVals.ConstructionOther,
                              other: this.state.constructionOtherServices
                        },
                        financeAndLaw: {
                              parentChecked: this.state.checkboxVals.FinanceAndLaw,
                              legalServices: this.state.checkboxVals.LegalServices,
                              insuranceCompany: this.state.checkboxVals.InsuranceCompany,
                              bank: this.state.checkboxVals.Bank,
                              otherChecked: this.state.checkboxVals.LegalOther,
                              other: this.state.legalOtherServices
                        },
                        electronicsComputerTechnology: {
                              parentChecked: this.state.checkboxVals.ElectronicsComputerTech,
                              store: this.state.checkboxVals.ElectronicsStore,
                              manufacturer: this.state.checkboxVals.ElectronicsManufacturer,
                              otherChecked: this.state.checkboxVals.ElectronicsOther,
                              other: this.state.electronicsOtherServices
                        },
                        feedAndBait: {
                              parentChecked: this.state.checkboxVals.FeedAndBait,
                              store: this.state.checkboxVals.FeedAndBaitStore,
                              manufacturer: this.state.checkboxVals.FeedAndBaitManufacturer,
                              otherChecked: this.state.checkboxVals.FeedAndBaitOther,
                              other: this.state.feedAndBaitOtherServices
                        },
                        vehicles: {
                              parentChecked: this.state.checkboxVals.Vehicle,
                              carSales: this.state.checkboxVals.CarSales,
                              machineDealers: this.state.checkboxVals.MachineDealers,
                              vehicleRepairs: this.state.checkboxVals.VehicleRepairs,
                              manufacturer: this.state.checkboxVals.VehicleManufacturer,
                              otherChecked: this.state.checkboxVals.VehicleOther,
                              other: this.state.vehicleOtherServices
                        },
                        organization: {
                              organization: this.state.checkboxVals.Organization
                        },
                        homeHouseholdAndGarden: {
                              parentChecked: this.state.checkboxVals.HomeHouseholdGarden,
                              architect: this.state.checkboxVals.Architect,
                              gardenDesigner: this.state.checkboxVals.GardenDesigner,
                              broker: this.state.checkboxVals.Broker,
                              farmShop: this.state.checkboxVals.FarmShop,
                              manufacturer: this.state.checkboxVals.HomeHouseholdGardenManufacturer,
                              otherChecked: this.state.checkboxVals.HomeHouseholdGardenOther,
                              other: this.state.homeHouseholdGardenOtherServices
                        },
                        hotelsAndTravel: {
                              parentChecked: this.state.checkboxVals.HotelsAndTravel,
                              hotel: this.state.checkboxVals.Hotel,
                              travelAgents: this.state.checkboxVals.TravelAgents,
                              accommodation_: {
                                    accommodation: this.state.checkboxVals.Accommodation,
                                    withOrWithoutHorse: this.state.withHorse
                              },
                              conferences: this.state.checkboxVals.Conferences,
                              b_and_B: this.state.checkboxVals.BedAndBreakfast,
                              otherChecked: this.state.checkboxVals.HotelsAndTravelOther,
                              other: this.state.hotelsAndTraveOtherServices
                        },
                        healthAndBeauty: {
                              parentChecked: this.state.checkboxVals.HealthAndBeauty,
                              store: this.state.checkboxVals.HealthAndBeautyStore,
                              manufacturer: this.state.checkboxVals.HealthAndBeautyManufacturer,
                              otherChecked: this.state.checkboxVals.HealthAndBeautyOther,
                              other: this.state.healthAndBeautyOthterServices
                        },
                        horseAccessories: {
                              parentChecked: this.state.checkboxVals.HorseAccessories,
                              ridingHousesAndStallSupplies: this.state.checkboxVals.RidingHousesAndStallSupplies,
                              equestrianShop: this.state.checkboxVals.EquestrianShop,
                              store: this.state.checkboxVals.HorseAccessoriesStore,
                              saddler: this.state.checkboxVals.Saddler,
                              manufacturer: this.state.checkboxVals.HorseAccessoriesManufacturer,
                              otherChecked: this.state.checkboxVals.HorseAccessoriesOther,
                              other: this.state.horseAccessoriesOtherServices
                        },
                        toys: {
                              parentChecked: this.state.checkboxVals.Toys,
                              store: this.state.checkboxVals.ToyStore,
                              manufacturer: this.state.checkboxVals.ToyManufacturer,
                              otherChecked: this.state.checkboxVals.ToyOther,
                              other: this.state.toysOtherServices
                        },
                        mediaAndAdvertising: {
                              parentChecked: this.state.checkboxVals.MediaAdvertisement,
                              store: this.state.checkboxVals.MediaStore,
                              events: this.state.checkboxVals.MediaEvents,
                              photographer: this.state.checkboxVals.Photographer,
                              adevertiser: this.state.checkboxVals.Advertiser,
                              journalist: this.state.checkboxVals.Journalist,
                              publisher: this.state.checkboxVals.Publisher,
                              newsPaper: this.state.checkboxVals.Newspaper,
                              otherChecked: this.state.checkboxVals.MediaOther,
                              other: this.state.mediaOtherServices
                        },
                        fashionClothingAndAccessories: {
                              parentChecked: this.state.checkboxVals.Fashion,
                              store: this.state.checkboxVals.FashionStore,
                              manufacturer: this.state.checkboxVals.FashionManufacturer,
                              otherChecked: this.state.checkboxVals.FashionOther,
                              other: this.state.fashionOtherServices
                        },
                        funAndLeisure: {
                              parentChecked: this.state.checkboxVals.Leisure,
                              events: this.state.checkboxVals.LeisureEvents,
                              otherChecked: this.state.checkboxVals.LeisureOther,
                              other: this.state.leisureOtherServices
                        },
                        schoolAndEducation: {
                              parentChecked: this.state.checkboxVals.SchoolEducation,
                              ridingSchool: this.state.checkboxVals.RidingSchool,
                              trainingInstructor: this.state.checkboxVals.TrainigInstructor,
                              college: this.state.checkboxVals.College,
                              university: this.state.checkboxVals.University,
                              otherChecked: this.state.checkboxVals.SchoolEducationOther,
                              other: this.state.schoolOtherServices
                        },
                        horseRelatedServices: {
                              parentChecked: this.state.checkboxVals.HorseRelatedServices,
                              breaking: this.state.checkboxVals.Breaking,
                              denstist: this.state.checkboxVals.Dentist,
                              driver: this.state.checkboxVals.Driver,
                              farrier: this.state.checkboxVals.Farrier,
                              inRunning: this.state.checkboxVals.InRunning,
                              masseur: this.state.checkboxVals.Masseur,
                              nutritionist: this.state.checkboxVals.hrNutritionist,
                              store: this.state.checkboxVals.HorseRelatedServicesStore,
                              therapist: this.state.checkboxVals.Therapist,
                              transport: this.state.checkboxVals.Transport,
                              vet: this.state.checkboxVals.Vet,
                              otherChecked: this.state.checkboxVals.HorseRelatedServicesOther,
                              other: this.state.horseRelatedOtherServices
                        },
                        otherServices: this.state.businessOtherServices
                  }
            };

            const userInfoPayload = {
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  displayName: this.state.displayName,
                  email: this.state.email,
                  phoneNumber: this.state.phoneNumber,
                  city: this.state.city,
                  streetAdress: this.state.streetAdress,
                  postalCode: this.state.postalCode,
                  country: this.state.countrySelectedValue.label,
                  region: this.state.regionSelectedValue.label,
                  gender: this.state.gender,
                  dateOfBirth: this.state.selectedDay,
                  interests: {
                        allround: this.state.checkboxVals.Allround,
                        distance: this.state.checkboxVals.Distance,
                        dressage: this.state.checkboxVals.Dressage,
                        eventing: this.state.checkboxVals.Eventing,
                        gallop: this.state.checkboxVals.Gallop,
                        jumping: this.state.checkboxVals.Jumping,
                        hunting: this.state.checkboxVals.Hunting,
                        driving: this.state.checkboxVals.Driving,
                        trotting: this.state.checkboxVals.Trotting,
                        vaulting: this.state.checkboxVals.Vaulting,
                        western: this.state.checkboxVals.Western,
                        associations: this.state.Associations,
                        competing: this.state.competing,
                        horseInterests: this.state.horseInterestCheckBoxVals,
                        otherInterests: {
                              other: this.state.checkboxVals.Other,
                              otherInterests: this.state.OtherInterests
                        }
                  }

                  /**
 *  Other: false,
        OtherInterests: "",
        Associations: "",
 */
                  //---------------------------------------------------------------------------------------------------------------------------------
            };

            if (profileType == "user") {
                  //----------------------------------------------------------------------------------------------------------------------------------------------->

                  if (this.state.displayName === "" || this.state.displayName == null || this.state.displayName == null) {
                        setUserNameToCookie(this.state.firstName + " " + this.state.lastName);
                  } else {
                        setUserNameToCookie(this.state.displayName);
                  }

                  //console.group("userInfoPayload before sent");
                  this.setState({ updateButtonLoading: true });
                  //console.log(userInfoPayload);
                  //console.groupEnd();
                  updateUserInfoApi(userInfoPayload).then(
                        function (response) {
                              //console.group("Response from updateUserInfoApi: ");
                              //console.log(JSON.stringify(response));
                              //console.groupEnd();

                              const message = response.msgWrapper.msgDescription,
                                    messageType = response.msgWrapper.msgType;
                              this.setState({ Message: message });

                              this.setState({ messageType: messageType });
                              this.setState({ updateButtonLoading: false });
                              return message, messageType;
                        }.bind(this)
                  );
            }
            if (profileType == "business") {
                  if (this.state.companyName === "" || this.state.companyName == null || this.state.companyName == null) {
                        setUserNameToCookie(this.state.firstName + " " + this.state.lastName);
                  } else {
                        setUserNameToCookie(this.state.companyName);
                  }

                  this.setState({ updateButtonLoading: true });
                  //console.group("profileType == business");
                  ////console.log("profie type : " + profileType);
                  //console.log("====== Business Info PayLoad ======");
                  //console.log(businessUserInfoPayLoad);
                  //console.log("====== response ======");
                  updateBusinessUserInfoApi(businessUserInfoPayLoad).then(
                        function (response) {
                              //console.group("Response from updateBusinessUserInfoApi: ");
                              //console.log(JSON.stringify(response));
                              //console.groupEnd();

                              const message = response.msgWrapper.msgDescription,
                                    messageType = response.msgWrapper.msgType;
                              this.setState({ Message: message });

                              this.setState({ messageType: messageType });
                              this.setState({ updateButtonLoading: false });
                              return message, messageType;
                        }.bind(this)
                  );

                  //console.groupEnd();
            }
      };

      setDate = (date) => {
            console.log(" set the date : " + date);
      };

      UNSAFE_componentWillMount = async () => {
            this.getCountries();

            await GetFullProfile().then(
                  function (response) {
                        this.setState({
                              profile: response.dataObject
                        });

                        this.setState({
                              info: response.dataObject.info
                        });
                  }.bind(this)
            );

            setTimeout(() => {
                  this.setCountryField();
            }, 100);
            setTimeout(() => {
                  this.setRegionField();
            }, 100);

            await this.setInitialState();
            //await ;
      };

      getCountries = async () => {
            await getAllowedCountriesApi().then(async (response) => {
                  let options = Object.assign(response.data).map(function (countries) {
                        return { value: countries.countryCode, label: countries.name };
                  });

                  await this.setState({ countries: options });
            });
      };

      getRegions = async (countryCode) => {
            await getStatesInCountryApi(countryCode).then(async (response) => {
                  let options = Object.assign(response.data).map(function (regions) {
                        return { value: regions.name, label: regions.name };
                  });
                  await this.setState({ regions: options });
            });
      };

      setCountryField = async () => {
            const countryValueToSetUponPageLoad = {
                  value: "",
                  label: ""
            };
            // Map through the countries and find country name
            await this.state.countries.map(async (country) => {
                  if (country.label == this.state.profile.info.country) {
                        countryValueToSetUponPageLoad.value = country.value; // COUNTRY CODE
                        countryValueToSetUponPageLoad.label = country.label; // COUNTRY NAME
                  }
            });

            await this.setState({
                  countrySelectedValue: {
                        value: countryValueToSetUponPageLoad.value,
                        label: countryValueToSetUponPageLoad.label
                  }
            });
      };
      setRegionField = async () => {
            await this.getRegions(this.state.countrySelectedValue.value);
            // State/Region
            const regionValueToSetUponPageLoad = { value: "", label: "" };

            await this.state.regions.map(async (region) => {
                  // //console.log("Region value--->" + region.value);
                  if (region.value == this.state.profile.info.region) {
                        regionValueToSetUponPageLoad.value = region.value;
                        regionValueToSetUponPageLoad.label = region.label;
                  }
            });
            await this.setState({
                  regionSelectedValue: {
                        value: regionValueToSetUponPageLoad.value,
                        label: regionValueToSetUponPageLoad.label
                  }
            });
      };

      setInitialState = async () => {
            // Country
            const date = new Date(Date.parse(this.state.info.dateOfBirth));

            this.setState({
                  firstName: this.state.info.firstName,
                  lastName: this.state.info.lastName,
                  selectedDay: date
            });

            if (this.state.info.gender != null) {
                  this.setState({ gender: this.state.info.gender });
            }

            if (this.state.info.interests != null) {
                  await this.setState({
                        horseInterestCheckBoxVals: this.state.info.interests.horseInterests,
                        Associations: this.state.info.interests.associations,
                        OtherInterests: this.state.info.interests.otherInterests.otherInterests,
                        checkboxVals: {
                              /* private account */
                              Allround: this.state.info.interests.allround,
                              Distance: this.state.info.interests.distance,
                              Dressage: this.state.info.interests.dressage,
                              Eventing: this.state.info.interests.eventing,
                              Gallop: this.state.info.interests.gallop,
                              Jumping: this.state.info.interests.jumping,
                              Hunting: this.state.info.interests.hunting,
                              Driving: this.state.info.interests.driving,
                              Trotting: this.state.info.interests.trotting,
                              Vaulting: this.state.info.interests.vaulting,
                              Western: this.state.info.interests.western,
                              Other: this.state.info.interests.otherInterests.other
                        }
                  });
            }

            const providingServices = this.state.info.providingServices;
            var otherServicesChecked;
            // //console.log("providing services : " + JSON.stringify(providingServices));
            if (providingServices != null) {
                  if (this.state.info.providingServices.otherServices != null && this.state.info.providingServices.otherServices != "") {
                        otherServicesChecked = true;
                  }
                  await this.setState({
                        checkboxVals: {
                              /* Breeding */
                              Breeding: this.state.info.providingServices.breeding.parentChecked,
                              BreedingChild: this.state.info.providingServices.breeding.breeding,
                              Stallions: this.state.info.providingServices.breeding.stallions,
                              Insamination: this.state.info.providingServices.breeding.insamination,
                              Nutritionist: this.state.info.providingServices.breeding.nutritionist,
                              BreedingOther: this.state.info.providingServices.breeding.otherChecked,

                              /* Construction And Maintenence */
                              ConstructionAndMaintenance: this.state.info.providingServices.constructionAndMaintance.parentChecked,
                              ConstructionServices: this.state.info.providingServices.constructionAndMaintance.construction_Services_Craftsmen,
                              ConstructionStore: this.state.info.providingServices.constructionAndMaintance.store,
                              ConstructionManufacturer: this.state.info.providingServices.constructionAndMaintance.manufacturer,
                              ConstructionOther: this.state.info.providingServices.constructionAndMaintance.otherChecked,

                              /* finance and law */
                              FinanceAndLaw: this.state.info.providingServices.financeAndLaw.parentChecked,
                              LegalServices: this.state.info.providingServices.financeAndLaw.legalServices,
                              InsuranceCompany: this.state.info.providingServices.financeAndLaw.insuranceCompany,
                              Bank: this.state.info.providingServices.financeAndLaw.bank,
                              LegalOther: this.state.info.providingServices.financeAndLaw.otherChecked,

                              /* electronics computer technology */
                              ElectronicsComputerTech: this.state.info.providingServices.electronicsComputerTechnology.parentChecked,
                              ElectronicsStore: this.state.info.providingServices.electronicsComputerTechnology.store,
                              ElectronicsManufacturer: this.state.info.providingServices.electronicsComputerTechnology.manufacturer,
                              ElectronicsOther: this.state.info.providingServices.electronicsComputerTechnology.otherChecked,

                              /* feed and bait */
                              FeedAndBait: this.state.info.providingServices.feedAndBait.parentChecked,
                              FeedAndBaitStore: this.state.info.providingServices.feedAndBait.store,
                              FeedAndBaitManufacturer: this.state.info.providingServices.feedAndBait.manufacturer,
                              FeedAndBaitOther: this.state.info.providingServices.feedAndBait.otherChecked,

                              /* vehicle */
                              Vehicle: this.state.info.providingServices.vehicles.parentChecked,
                              CarSales: this.state.info.providingServices.vehicles.carSales,
                              VehicleRepairs: this.state.info.providingServices.vehicles.vehicleRepairs,
                              MachineDealers: this.state.info.providingServices.vehicles.machineDealers,
                              VehicleManufacturer: this.state.info.providingServices.vehicles.manufacturer,
                              VehicleOther: this.state.info.providingServices.vehicles.otherChecked,

                              /* organisation */
                              Organization: this.state.info.providingServices.organization.parentChecked,
                              //OrganizationChild: this.state.info.providingServices.organization
                              //  .organization,
                              /* home household and garden */
                              HomeHouseholdGarden: this.state.info.providingServices.homeHouseholdAndGarden.parentChecked,
                              Architect: this.state.info.providingServices.homeHouseholdAndGarden.architect,
                              GardenDesigner: this.state.info.providingServices.homeHouseholdAndGarden.gardenDesigner,
                              Broker: this.state.info.providingServices.homeHouseholdAndGarden.broker,
                              FarmShop: this.state.info.providingServices.homeHouseholdAndGarden.farmShop,
                              HomeHouseholdGardenManufacturer: this.state.info.providingServices.homeHouseholdAndGarden.manufacturer,
                              HomeHouseholdGardenOther: this.state.info.providingServices.homeHouseholdAndGarden.otherChecked,

                              /* hotels and travel */
                              HotelsAndTravel: this.state.info.providingServices.hotelsAndTravel.parentChecked,
                              BedAndBreakfast: this.state.info.providingServices.hotelsAndTravel.B_and_B,
                              Hotel: this.state.info.providingServices.hotelsAndTravel.hotel,
                              TravelAgents: this.state.info.providingServices.hotelsAndTravel.travelAgents,
                              Vacations: this.state.info.providingServices.hotelsAndTravel.parentChecked,
                              Accommodation: this.state.info.providingServices.hotelsAndTravel.accommodation_.parentChecked,
                              Conferences: this.state.info.providingServices.hotelsAndTravel.conferences,
                              HotelsAndTravelOther: this.state.info.providingServices.hotelsAndTravel.otherChecked,

                              /* health and beauty */
                              HealthAndBeauty: this.state.info.providingServices.healthAndBeauty.parentChecked,
                              HealthAndBeautyStore: this.state.info.providingServices.healthAndBeauty.store,
                              HealthAndBeautyManufacturer: this.state.info.providingServices.healthAndBeauty.manufacturer,
                              HealthAndBeautyOther: this.state.info.providingServices.healthAndBeauty.otherChecked,

                              /* horse accesories */
                              HorseAccessories: this.state.info.providingServices.horseAccessories.parentChecked,
                              RidingHousesAndStallSupplies: this.state.info.providingServices.horseAccessories.ridingHousesAndStallSupplies,
                              EquestrianShop: this.state.info.providingServices.horseAccessories.equestrianShop,
                              Saddler: this.state.info.providingServices.horseAccessories.saddler,
                              HorseAccessoriesStore: this.state.info.providingServices.horseAccessories.store,
                              HorseAccessoriesManufacturer: this.state.info.providingServices.horseAccessories.manufacturer,
                              HorseAccessoriesOther: this.state.info.providingServices.horseAccessories.otherChecked,

                              /* toys */
                              Toys: this.state.info.providingServices.toys.parentChecked,
                              ToyStore: this.state.info.providingServices.toys.store,
                              ToyManufacturer: this.state.info.providingServices.toys.manufacturer,
                              ToyOther: this.state.info.providingServices.toys.otherChecked,

                              /* media and advertisement */
                              MediaAdvertisement: this.state.info.providingServices.mediaAndAdvertising.parentChecked,
                              MediaStore: this.state.info.providingServices.mediaAndAdvertising.store,
                              MediaEvents: this.state.info.providingServices.mediaAndAdvertising.events,
                              Photographer: this.state.info.providingServices.mediaAndAdvertising.photographer,
                              Journalist: this.state.info.providingServices.mediaAndAdvertising.journalist,
                              Advertiser: this.state.info.providingServices.mediaAndAdvertising.adevertiser,
                              Publisher: this.state.info.providingServices.mediaAndAdvertising.publisher,
                              Newspaper: this.state.info.providingServices.mediaAndAdvertising.newsPaper,
                              MediaOther: this.state.info.providingServices.mediaAndAdvertising.otherChecked,

                              /* fashion */
                              Fashion: this.state.info.providingServices.fashionClothingAndAccessories.parentChecked,
                              FashionStore: this.state.info.providingServices.fashionClothingAndAccessories.store,
                              FashionManufacturer: this.state.info.providingServices.fashionClothingAndAccessories.manufacturer,
                              FashionOther: this.state.info.providingServices.fashionClothingAndAccessories.otherChecked,

                              /* leasure */
                              Leisure: this.state.info.providingServices.funAndLeisure.parentChecked,
                              LeisureEvents: this.state.info.providingServices.funAndLeisure.events,
                              LeisureOther: this.state.info.providingServices.funAndLeisure.otherChecked,

                              /* education */
                              SchoolEducation: this.state.info.providingServices.schoolAndEducation.parentChecked,
                              RidingSchool: this.state.info.providingServices.schoolAndEducation.ridingSchool,
                              TrainigInstructor: this.state.info.providingServices.schoolAndEducation.trainingInstructor,
                              College: this.state.info.providingServices.schoolAndEducation.college,
                              University: this.state.info.providingServices.schoolAndEducation.university,
                              SchoolEducationOther: this.state.info.providingServices.schoolAndEducation.otherChecked,

                              /* horse related services */
                              HorseRelatedServices: this.state.info.providingServices.horseRelatedServices.parentChecked,
                              Breaking: this.state.info.providingServices.horseRelatedServices.breaking,
                              Dentist: this.state.info.providingServices.horseRelatedServices.dentist,
                              Driver: this.state.info.providingServices.horseRelatedServices.driver,
                              Farrier: this.state.info.providingServices.horseRelatedServices.farrier,
                              InRunning: this.state.info.providingServices.horseRelatedServices.inRunning,
                              Masseur: this.state.info.providingServices.horseRelatedServices.masseur,
                              hrNutritionist: this.state.info.providingServices.horseRelatedServices.nutritionist,
                              HorseRelatedServicesStore: this.state.info.providingServices.horseRelatedServices.store,
                              Therapist: this.state.info.providingServices.horseRelatedServices.therapist,
                              Transport: this.state.info.providingServices.horseRelatedServices.transport,
                              Vet: this.state.info.providingServices.horseRelatedServices.vet,
                              HorseRelatedServicesOther: this.state.info.providingServices.horseRelatedServices.otherChecked,

                              /*other business */
                              businessOther: otherServicesChecked
                        },

                        breedingOtherServices: this.state.info.providingServices.breeding.other,

                        constructionOtherServices: this.state.info.providingServices.constructionAndMaintance.other,

                        legalOtherServices: this.state.info.providingServices.financeAndLaw.other,

                        electronicsOtherServices: this.state.info.providingServices.electronicsComputerTechnology.other,

                        feedAndBaitOtherServices: this.state.info.providingServices.feedAndBait.other,
                        vehicleOtherServices: this.state.info.providingServices.vehicles.other,
                        legalOtherServices: this.state.info.providingServices.financeAndLaw.other,
                        homeHouseholdGardenOtherServices: this.state.info.providingServices.homeHouseholdAndGarden.other,
                        hotelsAndTraveOtherServices: this.state.info.providingServices.hotelsAndTravel.other,
                        healthAndBeautyOthterServices: this.state.info.providingServices.healthAndBeauty.other,
                        horseAccessoriesOtherServices: this.state.info.providingServices.horseAccessories.other,
                        toysOtherServices: this.state.info.providingServices.toys.other,
                        mediaOtherServices: this.state.info.providingServices.mediaAndAdvertising.other,
                        fashionOtherServices: this.state.info.providingServices.fashionClothingAndAccessories.other,
                        leisureOtherServices: this.state.info.providingServices.funAndLeisure.other,
                        schoolOtherServices: this.state.info.providingServices.schoolAndEducation.other,
                        horseRelatedOtherServices: this.state.info.providingServices.horseRelatedServices.other,
                        businessOtherServices: this.state.info.providingServices.otherServices
                  });
            }

            if (this.state.info.displayName != null) {
                  this.setState({ displayName: this.state.info.displayName });
            }

            if (this.state.info.streetAdress != null) {
                  this.setState({ streetAdress: this.state.info.streetAdress });
            }

            if (this.state.info.companyName != null) {
                  this.setState({ companyName: this.state.info.companyName });
            }

            if (this.state.info.vatNumber != null) {
                  this.setState({ vatNumber: this.state.info.vatNumber });
            }

            if (this.state.info.vatNumber != null) {
                  this.setState({ vatNumber: this.state.info.vatNumber });
            }

            if (this.state.info.phoneNumber != null) {
                  this.setState({ phoneNumber: this.state.info.phoneNumber });
            }

            if (this.state.info.postalCode != null) {
                  this.setState({ postalCode: this.state.info.postalCode });
            }

            if (this.state.info.latitude != null) {
                  this.setState({ latitude: this.state.info.latitude });
            }

            if (this.state.info.longitude != null) {
                  this.setState({ longitude: this.state.info.longitude });
            }

            if (this.state.info.webSite != null) {
                  this.setState({ webSite: this.state.info.webSite });
            }

            if (this.state.info.vatNumber != null) {
                  this.setState({ vatNumber: this.state.info.vatNumber });
            }
      };

      validate = async () => {
            let errors = {};

            var date = "";
            if (this.state.selectedDay) {
                  var date = this.state.selectedDay.toString();
            }

            console.log("selectedDate : " + this.state.selectedDay);

            const profileType = this.state.profile.profileType;
            if (profileType === "user") {
                  if (this.state.countrySelectedValue.label === undefined) {
                        errors["country"] = "Please select your country.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.regionSelectedValue.label === undefined) {
                        errors["state"] = "Please select your state.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.firstName === "") {
                        errors["firstName"] = "First name is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.lastName === "") {
                        errors["lastName"] = "Last name is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.userBirthYear != "" && this.state.userBirthYear != null && this.state.userBirthYear != undefined) {
                        if (date === "" || date === "Invalid Date") {
                              errors["date"] = "Please choose your date of birth.";
                              this.setState({ formIsValid: false });
                        }
                  }
            }
            if (profileType === "business") {
                  if (this.state.countrySelectedValue.label === undefined) {
                        errors["country"] = "Please select your country.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.regionSelectedValue.label === undefined) {
                        errors["region"] = "Please select your region.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.firstName === "") {
                        errors["firstName"] = "First name is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.lastName === "") {
                        errors["lastName"] = "Last name is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.companyName === "") {
                        errors["companyName"] = "Company name is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.vatNumber === "") {
                        errors["vatNumber"] = "VAT number is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.streetAdress === "") {
                        errors["streetAdress"] = "Street address is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.postalCode === "") {
                        errors["postalCode"] = "Postal code is required.";
                        this.setState({ formIsValid: false });
                  }
                  if (this.state.phoneNumber === "") {
                        errors["phoneNumber"] = "Phone number is required.";
                        this.setState({ formIsValid: false });
                  }
            }
            this.setState({ errors });
            console.log("errors  : " + JSON.stringify(errors));
            console.log(Object.keys(errors).length);
            if (Object.keys(errors).length <= 0) {
                  console.log("empty===================>>>>" + JSON.stringify(this.state.checkboxVals));
                  console.log("validate");
                  this.submitData();
            }
      };

      render() {
            let isValid = this.state.isValid ? "no-errors" : "errors";
            const customStyles = {
                  option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? "red" : "blue",
                        borderBottom: "1px solid rgba(240,240,240,1)",
                        backgroundColor: "rgba(240,240,240,0.2)",
                        padding: "10px"
                  }),
                  control: () => ({
                        border: "1px solid rgba(240,240,240,1)",
                        webkitAlignItems: "center",
                        webkitBoxAlign: "center",
                        msFlexAlign: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(240,240,240,0.2)",
                        border: " 1px solid rgba(240,240,240,1)",
                        display: "-webkit-box",
                        display: "-webkit-flex",
                        display: "-ms-flexbox",
                        webkitFlexWrap: "wrap",
                        webkitJustifyContent: "space-between",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        minHeight: "48px",
                        position: "relative",
                        webkitTransition: "all 100ms",
                        transition: "all 100ms",
                        boxSizing: "border-box",
                        padding: "8px"
                  }),
                  indicatorsContainer: () => ({
                        position: "absolute",
                        top: "4px",
                        right: "10px"
                  }),
                  menu: () => ({
                        backgroundColor: "rgba(255,255,255,1)",
                        border: "1px solid rgba(204,204,204, 0.2)",
                        position: "absolute",
                        width: "99.7%",
                        zIndex: "1001"
                  }),
                  option: () => ({
                        padding: "10px",
                        borderBottom: "1px solid rgba(240, 240, 240, 1)",
                        "&:hover": { backgroundColor: "rgba(240,240,240,0.2)" }
                  })
            };

            const profileType = this.state.profile.profileType;
            const { selectedDay, isDisabled, isEmpty } = this.state;

            const businessProfile = (
                  <React.Fragment>
                        <section className="section">
                              <div className="header">
                                    <h2>basic profile info</h2>
                              </div>

                              <div className="body" style={{ display: "block" }}>
                                    <form style={{ marginBottom: "15px" }}>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* first name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="firstName"
                                                            placeholder="contact first name"
                                                            value={this.state.firstName}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.firstName}</span>
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* last name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="lastName"
                                                            placeholder="contact first name"
                                                            value={this.state.lastName}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.lastName}</span>
                                                </div>
                                          </div>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* company name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="companyName"
                                                            placeholder="company name"
                                                            value={this.state.companyName}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.companyName}</span>
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* vat number</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="vatNumber"
                                                            placeholder="company name"
                                                            value={this.state.vatNumber}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.vatNumber}</span>
                                                </div>
                                          </div>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">website url</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="webSite"
                                                            placeholder="website url"
                                                            value={this.state.webSite}
                                                            onChange={this.handleChange}
                                                      />
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* country</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"country"}
                                                            label={this.state.countrySelectedValue}
                                                            value={this.state.countrySelectedValue}
                                                            onChange={this.handleCountryChange}
                                                            options={this.state.countries}
                                                            placeholder={this.state.placeHolderCountry}
                                                      />
                                                      <span className={isValid}>{this.state.errors.country}</span>
                                                </div>
                                          </div>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* region</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"state"}
                                                            label={this.state.regionSelectedValue}
                                                            value={this.state.regionSelectedValue}
                                                            onChange={this.handleRegionChange}
                                                            options={this.state.regions}
                                                            placeholder={this.state.placeHolderRegion}
                                                      />
                                                      <span className={isValid}>{this.state.errors.region}</span>
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* street</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="streetAdress"
                                                            placeholder="street adress"
                                                            value={this.state.streetAdress}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.streetAdress}</span>
                                                </div>
                                          </div>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* postal code</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="postalCode"
                                                            placeholder="postal code"
                                                            value={this.state.postalCode}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.postalCode}</span>
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* phone number</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="phoneNumber"
                                                            placeholder="phone number"
                                                            value={this.state.phoneNumber}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.phoneNumber}</span>
                                                </div>
                                          </div>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">GPS Latitude</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="latitude"
                                                            placeholder="gps latitude"
                                                            value={this.state.latitude}
                                                            onChange={this.handleChange}
                                                      />
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">GPS Longitude</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="longitude"
                                                            placeholder="gps longitude"
                                                            value={this.state.longitude}
                                                            onChange={this.handleChange}
                                                      />
                                                </div>
                                          </div>
                                          <label className="formLabel" style={{ marginLeft: "15px", marginTop: "15px" }}>
                                                * = required field
                                          </label>
                                    </form>
                              </div>
                        </section>
                  </React.Fragment>
            );
            const businessIntrests = (
                  <React.Fragment>
                        <section className="section">
                              <div className="header">
                                    <h2>business interests</h2>
                              </div>
                              <div className="body" style={{ display: "block", overflow: "hidden" }}>
                                    <form style={{ margin: "10px" }}>
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="formLabel alignLeft">{"Help people to find your business!"}</label>
                                                      <span className="introText">
                                                            {
                                                                  "Check the boxes that match your business offers so that people looking for your kind of goods and services can find you."
                                                            }
                                                      </span>
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Breeding"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Breeding}
                                                                  checked={this.state.checkboxVals.Breeding}
                                                            />
                                                            Breeding
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.Breeding) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <div className="row">
                                                                        <div className="col-12">
                                                                              <label className="checkLabelIndented">
                                                                                    <input
                                                                                          type="checkbox"
                                                                                          name="BreedingChild"
                                                                                          onChange={this.handleCheckChange}
                                                                                          value={this.state.checkboxVals.BreedingChild}
                                                                                          checked={this.state.checkboxVals.BreedingChild}
                                                                                    />
                                                                                    Breeding
                                                                              </label>
                                                                              <label className="checkLabelIndented">
                                                                                    <input
                                                                                          type="checkbox"
                                                                                          name="Stallions"
                                                                                          onChange={this.handleCheckChange}
                                                                                          value={this.state.checkboxVals.Stallions}
                                                                                          checked={this.state.checkboxVals.Stallions}
                                                                                    />
                                                                                    Stallions
                                                                              </label>

                                                                              <label className="checkLabelIndented">
                                                                                    <input
                                                                                          type="checkbox"
                                                                                          name="Insamination"
                                                                                          onChange={this.handleCheckChange}
                                                                                          value={this.state.checkboxVals.Insamination}
                                                                                          checked={this.state.checkboxVals.Insamination}
                                                                                    />
                                                                                    Insamination
                                                                              </label>

                                                                              <label className="checkLabelIndented">
                                                                                    <input
                                                                                          type="checkbox"
                                                                                          name="Nutritionist"
                                                                                          onChange={this.handleCheckChange}
                                                                                          value={this.state.checkboxVals.Nutritionist}
                                                                                          checked={this.state.checkboxVals.Nutritionist}
                                                                                    />
                                                                                    Nutritionist
                                                                              </label>

                                                                              <label className="checkLabelIndented">
                                                                                    <input
                                                                                          type="checkbox"
                                                                                          className="textbox"
                                                                                          name="BreedingOther"
                                                                                          onChange={this.handleCheckChange}
                                                                                          value={this.state.checkboxVals.BreedingOther}
                                                                                          checked={this.state.checkboxVals.BreedingOther}
                                                                                    />
                                                                                    Other
                                                                              </label>
                                                                        </div>
                                                                  </div>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          {(() => {
                                                switch (this.state.checkboxVals.BreedingOther) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <div className="row">
                                                                        <div className="col-6">
                                                                              <label className="formLabel alignLeft">
                                                                                    {"Please specify (max 25 characters)"}
                                                                              </label>
                                                                              <input
                                                                                    type="text"
                                                                                    className="textinput"
                                                                                    name="breedingOtherServices"
                                                                                    maxLength={25}
                                                                                    onChange={this.handleChange}
                                                                                    placeholder="please specify"
                                                                                    value={this.state.breedingOtherServices}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="ConstructionAndMaintenance"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.ConstructionAndMaintenance} //22222222222222222222222222222222222222222222
                                                                  checked={this.state.checkboxVals.ConstructionAndMaintenance}
                                                            />
                                                            {"Construction & Maintenance"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.ConstructionAndMaintenance) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ConstructionServices"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ConstructionServices}
                                                                                                checked={this.state.checkboxVals.ConstructionServices}
                                                                                          />
                                                                                          {"Construction Services / Craftsmen"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ConstructionStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ConstructionStore}
                                                                                                checked={this.state.checkboxVals.ConstructionStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ConstructionManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals.ConstructionManufacturer
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals.ConstructionManufacturer
                                                                                                }
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ConstructionOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ConstructionOther}
                                                                                                checked={this.state.checkboxVals.ConstructionOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.ConstructionOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="constructionOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .constructionOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="FinanceAndLaw"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.FinanceAndLaw}
                                                                  checked={this.state.checkboxVals.FinanceAndLaw}
                                                            />
                                                            {"Finance & Law"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (
                                                      this.state.checkboxVals.FinanceAndLaw //22222222222222222222222222222222222222222222222222222222222222222222222222222
                                                ) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-6">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="LegalServices"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.LegalServices}
                                                                                                checked={this.state.checkboxVals.LegalServices}
                                                                                          />
                                                                                          {"Legal services"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="InsuranceCompany"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.InsuranceCompany}
                                                                                                checked={this.state.checkboxVals.InsuranceCompany}
                                                                                          />
                                                                                          {"Insurance company"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Bank"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Bank}
                                                                                                checked={this.state.checkboxVals.Bank}
                                                                                          />
                                                                                          {"Bank"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="LegalOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.LegalOther}
                                                                                                checked={this.state.checkboxVals.LegalOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.LegalOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="legalOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state.legalOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="ElectronicsComputerTech"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.ElectronicsComputerTech}
                                                                  checked={this.state.checkboxVals.ElectronicsComputerTech}
                                                            />
                                                            {"Electronics, Computer & Technology"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.ElectronicsComputerTech) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ElectronicsStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ElectronicsStore}
                                                                                                checked={this.state.checkboxVals.ElectronicsStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ElectronicsManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals.ElectronicsManufacturer
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals.ElectronicsManufacturer
                                                                                                }
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ElectronicsOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ElectronicsOther}
                                                                                                checked={this.state.checkboxVals.ElectronicsOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.ElectronicsOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="electronicsOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .electronicsOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="FeedAndBait"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.FeedAndBait}
                                                                  checked={this.state.checkboxVals.FeedAndBait}
                                                            />
                                                            {"Feed & bait"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.FeedAndBait) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FeedAndBaitStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.FeedAndBaitStore}
                                                                                                checked={this.state.checkboxVals.FeedAndBaitStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FeedAndBaitManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals.FeedAndBaitManufacturer
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals.FeedAndBaitManufacturer
                                                                                                }
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FeedAndBaitOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.FeedAndBaitOther}
                                                                                                checked={this.state.checkboxVals.FeedAndBaitOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>{" "}
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.FeedAndBaitOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="feedAndBaitOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .feedAndBaitOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Vehicle"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Vehicle}
                                                                  checked={this.state.checkboxVals.Vehicle}
                                                            />
                                                            {"Vehicles"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.Vehicle) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="CarSales"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.CarSales}
                                                                                                checked={this.state.checkboxVals.CarSales}
                                                                                          />
                                                                                          {"Car sales"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="MachineDealers"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.MachineDealers}
                                                                                                checked={this.state.checkboxVals.MachineDealers}
                                                                                          />
                                                                                          {"Machine dealers"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="VehicleRepairs"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.VehicleRepairs}
                                                                                                checked={this.state.checkboxVals.VehicleRepairs}
                                                                                          />
                                                                                          {"Vehicle reapirs"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="VehicleManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.VehicleManufacturer}
                                                                                                checked={this.state.checkboxVals.VehicleManufacturer}
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="VehicleOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.VehicleOther}
                                                                                                checked={this.state.checkboxVals.VehicleOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.VehicleOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="vehicleOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .vehicleOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Organization"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Organization}
                                                                  checked={this.state.checkboxVals.Organization}
                                                            />
                                                            {"Organization"}
                                                      </label>
                                                </div>
                                          </div>
                                          {/* (() => {
                switch (this.state.checkboxVals.Organization) {
                  case false:
                    return null;
                  case true:
                    return (
                      <React.Fragment>
                        <label className="checkLabelIndented">
                          <input
                            type="checkbox"
                            name="Organization"
                            onChange={this.handleCheckChange}
                            value={this.state.checkboxVals.OrganizationChild}
                            checked={this.state.checkboxVals.OrganizationChild}
                          />
                          {"Organization"}
                        </label>
                      </React.Fragment>
                    );
                  default:
                    return null;
                }
              })() */}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="HomeHouseholdGarden"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.HomeHouseholdGarden}
                                                                  checked={this.state.checkboxVals.HomeHouseholdGarden}
                                                            />
                                                            {"Home, Household & Garden"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.HomeHouseholdGarden) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Architect"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Architect}
                                                                                                checked={this.state.checkboxVals.Architect}
                                                                                          />
                                                                                          {"Architect"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="GardenDesigner"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.GardenDesigner}
                                                                                                checked={this.state.checkboxVals.GardenDesigner}
                                                                                          />
                                                                                          {"Garden designer"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Broker"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Broker}
                                                                                                checked={this.state.checkboxVals.Broker}
                                                                                          />
                                                                                          {"Broker"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FarmShop"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.FarmShop}
                                                                                                checked={this.state.checkboxVals.FarmShop}
                                                                                          />
                                                                                          {"Farm shop"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HomeHouseholdGardenManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals
                                                                                                            .HomeHouseholdGardenManufacturer
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals
                                                                                                            .HomeHouseholdGardenManufacturer
                                                                                                }
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HomeHouseholdGardenOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals.HomeHouseholdGardenOther
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals.HomeHouseholdGardenOther
                                                                                                }
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.HomeHouseholdGardenOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="homeHouseholdGardenOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .homeHouseholdGardenOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="HotelsAndTravel"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.HotelsAndTravel}
                                                                  checked={this.state.checkboxVals.HotelsAndTravel}
                                                            />
                                                            {"Hotels & Travel"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.HotelsAndTravel) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="BedAndBreakfast"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.BedAndBreakfast}
                                                                                                checked={this.state.checkboxVals.BedAndBreakfast}
                                                                                          />
                                                                                          {"B&B's"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Hotel"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Hotel}
                                                                                                checked={this.state.checkboxVals.Hotel}
                                                                                          />
                                                                                          {"Hotel"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="TravelAgents"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.TravelAgents}
                                                                                                checked={this.state.checkboxVals.TravelAgents}
                                                                                          />
                                                                                          {"Travel agents"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Accommodation"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Accommodation}
                                                                                                checked={this.state.checkboxVals.Accommodation}
                                                                                          />
                                                                                          {"Accommodation"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.Accommodation) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-12">
                                                                                                            <label className="checkLabelIndented">
                                                                                                                  <input
                                                                                                                        type="radio"
                                                                                                                        value="with horse"
                                                                                                                        name="withHorse"
                                                                                                                        onChange={this.handleChange}
                                                                                                                        checked={
                                                                                                                              this.state.withHorse ==
                                                                                                                              "with horse"
                                                                                                                        }
                                                                                                                  />
                                                                                                                  With horse
                                                                                                            </label>
                                                                                                            <label className="checkLabelIndented">
                                                                                                                  <input
                                                                                                                        type="radio"
                                                                                                                        value="without horse"
                                                                                                                        name="withHorse"
                                                                                                                        onChange={this.handleChange}
                                                                                                                        checked={
                                                                                                                              this.state.withHorse ==
                                                                                                                              "without horse"
                                                                                                                        }
                                                                                                                  />
                                                                                                                  Without horse
                                                                                                            </label>
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Conferences"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Conferences}
                                                                                                checked={this.state.checkboxVals.Conferences}
                                                                                          />
                                                                                          {"Conferences"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HotelsAndTravelOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.HotelsAndTravelOther}
                                                                                                checked={this.state.checkboxVals.HotelsAndTravelOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.HotelsAndTravelOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="hotelsAndTraveOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .hotelsAndTraveOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="HealthAndBeauty"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.HealthAndBeauty}
                                                                  checked={this.state.checkboxVals.HealthAndBeauty}
                                                            />
                                                            {"Health & Beauty"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.HealthAndBeauty) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HealthAndBeautyStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.HealthAndBeautyStore}
                                                                                                checked={this.state.checkboxVals.HealthAndBeautyStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HealthAndBeautyManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals
                                                                                                            .HealthAndBeautyManufacturer
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals
                                                                                                            .HealthAndBeautyManufacturer
                                                                                                }
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HealthAndBeautyOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.HealthAndBeautyOther}
                                                                                                checked={this.state.checkboxVals.HealthAndBeautyOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.HealthAndBeautyOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="healthAndBeautyOthterServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .healthAndBeautyOthterServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="HorseAccessories"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.HorseAccessories}
                                                                  checked={this.state.checkboxVals.HorseAccessories}
                                                            />
                                                            {"Horse Accessories"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.HorseAccessories) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="RidingHousesAndStallSupplies"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals
                                                                                                            .RidingHousesAndStallSupplies
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals
                                                                                                            .RidingHousesAndStallSupplies
                                                                                                }
                                                                                          />
                                                                                          {"Riding houses & stall supplies"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="EquestrianShop"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.EquestrianShop}
                                                                                                checked={this.state.checkboxVals.EquestrianShop}
                                                                                          />
                                                                                          {"Equestrian shop"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Saddler"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Saddler}
                                                                                                checked={this.state.checkboxVals.Saddler}
                                                                                          />
                                                                                          {"Saddler"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HorseAccessoriesStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.HorseAccessoriesStore}
                                                                                                checked={
                                                                                                      this.state.checkboxVals.HorseAccessoriesStore
                                                                                                }
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HorseAccessoriesManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals
                                                                                                            .HorseAccessoriesManufacturer
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals
                                                                                                            .HorseAccessoriesManufacturer
                                                                                                }
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HorseAccessoriesOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.HorseAccessoriesOther}
                                                                                                checked={
                                                                                                      this.state.checkboxVals.HorseAccessoriesOther
                                                                                                }
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.HorseAccessoriesOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="horseAccessoriesOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .horseAccessoriesOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Toys"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Toys}
                                                                  checked={this.state.checkboxVals.Toys}
                                                            />
                                                            {"Toys"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.Toys) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ToyStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ToyStore}
                                                                                                checked={this.state.checkboxVals.ToyStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="ToyOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.ToyOther}
                                                                                                checked={this.state.checkboxVals.ToyOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.ToyOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="toysOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={this.state.toysOtherServices}
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="MediaAdvertisement"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.MediaAdvertisement}
                                                                  checked={this.state.checkboxVals.MediaAdvertisement}
                                                            />
                                                            {"Media & Advertising"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.MediaAdvertisement) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="MediaStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.MediaStore}
                                                                                                checked={this.state.checkboxVals.MediaStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="MediaEvents"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.MediaEvents}
                                                                                                checked={this.state.checkboxVals.MediaEvents}
                                                                                          />
                                                                                          {"Events"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Photographer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Photographer}
                                                                                                checked={this.state.checkboxVals.Photographer}
                                                                                          />
                                                                                          {"Photographer"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Journalist"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Journalist}
                                                                                                checked={this.state.checkboxVals.Journalist}
                                                                                          />
                                                                                          {"Journalist"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Advertiser"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Advertiser}
                                                                                                checked={this.state.checkboxVals.Advertiser}
                                                                                          />
                                                                                          {"Advertiser"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Publisher"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Publisher}
                                                                                                checked={this.state.checkboxVals.Publisher}
                                                                                          />
                                                                                          {"Publisher"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Newspaper"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Newspaper}
                                                                                                checked={this.state.checkboxVals.Newspaper}
                                                                                          />
                                                                                          {"Newspaper"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="MediaOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.MediaOther}
                                                                                                checked={this.state.checkboxVals.MediaOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.MediaOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-12">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="mediaOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state.mediaOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Fashion"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Fashion}
                                                                  checked={this.state.checkboxVals.Fashion}
                                                            />
                                                            {"Fashion, clothing and accessories"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.Fashion) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FashionStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.FashionStore}
                                                                                                checked={this.state.checkboxVals.FashionStore}
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FashionManufacturer"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.FashionManufacturer}
                                                                                                checked={this.state.checkboxVals.FashionManufacturer}
                                                                                          />
                                                                                          {"Manufacturer"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="FashionOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.FashionOther}
                                                                                                checked={this.state.checkboxVals.FashionOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.FashionOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="fashionOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .fashionOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Leisure"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Leisure}
                                                                  checked={this.state.checkboxVals.Leisure}
                                                            />
                                                            {"Fun & Leisure"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.Leisure) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="LeisureEvents"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.LeisureEvents}
                                                                                                checked={this.state.checkboxVals.LeisureEvents}
                                                                                          />
                                                                                          {"Events"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="LeisureOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.LeisureOther}
                                                                                                checked={this.state.checkboxVals.LeisureOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>

                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.LeisureOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="leisureOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .leisureOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="SchoolEducation"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.SchoolEducation}
                                                                  checked={this.state.checkboxVals.SchoolEducation}
                                                            />
                                                            {"School & Education"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.SchoolEducation) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="RidingSchool"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.RidingSchool}
                                                                                                checked={this.state.checkboxVals.RidingSchool}
                                                                                          />
                                                                                          {"Riding school"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="TrainigInstructor"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.TrainigInstructor}
                                                                                                checked={this.state.checkboxVals.TrainigInstructor}
                                                                                          />
                                                                                          {"Trainig instructor"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="College"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.College}
                                                                                                checked={this.state.checkboxVals.College}
                                                                                          />
                                                                                          {"College"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="University"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.University}
                                                                                                checked={this.state.checkboxVals.University}
                                                                                          />
                                                                                          {"University"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="SchoolEducationOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.SchoolEducationOther}
                                                                                                checked={this.state.checkboxVals.SchoolEducationOther}
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.SchoolEducationOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="schoolOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state.schoolOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="HorseRelatedServices"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.HorseRelatedServices}
                                                                  checked={this.state.checkboxVals.HorseRelatedServices}
                                                            />
                                                            {"Horse related services"}
                                                      </label>
                                                </div>
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.HorseRelatedServices) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <React.Fragment>
                                                                        <div className="row">
                                                                              <div className="col-12">
                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Breaking"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Breaking}
                                                                                                checked={this.state.checkboxVals.Breaking}
                                                                                          />
                                                                                          {"Breaking"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Dentist"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Dentist}
                                                                                                checked={this.state.checkboxVals.Dentist}
                                                                                          />
                                                                                          {"Dentist"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Driver"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Driver}
                                                                                                checked={this.state.checkboxVals.Driver}
                                                                                          />
                                                                                          {"Driver"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Farrier"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Farrier}
                                                                                                checked={this.state.checkboxVals.Farrier}
                                                                                          />
                                                                                          {"Farrier"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="InRunning"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.InRunning}
                                                                                                checked={this.state.checkboxVals.InRunning}
                                                                                          />
                                                                                          {"In-running"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Masseur"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Masseur}
                                                                                                checked={this.state.checkboxVals.Masseur}
                                                                                          />
                                                                                          {"Masseur"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="hrNutritionist"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.hrNutritionist}
                                                                                                checked={this.state.checkboxVals.hrNutritionist}
                                                                                          />
                                                                                          {"Nutritionist"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HorseRelatedServicesStore"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals
                                                                                                            .HorseRelatedServicesStore
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals
                                                                                                            .HorseRelatedServicesStore
                                                                                                }
                                                                                          />
                                                                                          {"Store"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Therapist"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Therapist}
                                                                                                checked={this.state.checkboxVals.Therapist}
                                                                                          />
                                                                                          {"Therapist"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Transport"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Transport}
                                                                                                checked={this.state.checkboxVals.Transport}
                                                                                          />
                                                                                          {"Transport"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="Vet"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={this.state.checkboxVals.Vet}
                                                                                                checked={this.state.checkboxVals.Vet}
                                                                                          />
                                                                                          {"Vet"}
                                                                                    </label>

                                                                                    <label className="checkLabelIndented">
                                                                                          <input
                                                                                                type="checkbox"
                                                                                                name="HorseRelatedServicesOther"
                                                                                                onChange={this.handleCheckChange}
                                                                                                value={
                                                                                                      this.state.checkboxVals
                                                                                                            .HorseRelatedServicesOther
                                                                                                }
                                                                                                checked={
                                                                                                      this.state.checkboxVals
                                                                                                            .HorseRelatedServicesOther
                                                                                                }
                                                                                          />
                                                                                          {"Other"}
                                                                                    </label>
                                                                              </div>
                                                                        </div>
                                                                        {(() => {
                                                                              switch (this.state.checkboxVals.HorseRelatedServicesOther) {
                                                                                    case false:
                                                                                          return null;
                                                                                    case true:
                                                                                          return (
                                                                                                <div className="row">
                                                                                                      <div className="col-6">
                                                                                                            <label className="formLabel alignLeft">
                                                                                                                  {
                                                                                                                        "Please specify (max 25 characters)"
                                                                                                                  }
                                                                                                            </label>
                                                                                                            <input
                                                                                                                  type="text"
                                                                                                                  className="textinput"
                                                                                                                  name="horseRelatedOtherServices"
                                                                                                                  maxLength={25}
                                                                                                                  onChange={this.handleChange}
                                                                                                                  placeholder="please specify"
                                                                                                                  value={
                                                                                                                        this.state
                                                                                                                              .horseRelatedOtherServices
                                                                                                                  }
                                                                                                            />
                                                                                                      </div>
                                                                                                </div>
                                                                                          );
                                                                                    default:
                                                                                          return null;
                                                                              }
                                                                        })()}
                                                                  </React.Fragment>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <div className="row">
                                                <div className="col-12">
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="businessOther"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.businessOther} //333333333333333333333333333333333333333333333333333333333333333
                                                                  checked={this.state.checkboxVals.businessOther}
                                                            />
                                                            {"Other"}
                                                      </label>
                                                </div>
                                          </div>
                                          {(() => {
                                                switch (this.state.checkboxVals.businessOther) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <div className="row">
                                                                        <div className="col-6">
                                                                              <label className="formLabel alignLeft">
                                                                                    {"Please specify (max 25 characters)"}
                                                                              </label>
                                                                              <input
                                                                                    type="text"
                                                                                    className="textinput"
                                                                                    name="businessOtherServices"
                                                                                    maxLength={25}
                                                                                    onChange={this.handleChange}
                                                                                    placeholder="please specify"
                                                                                    value={this.state.businessOtherServices}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                    </form>
                              </div>
                        </section>
                  </React.Fragment>
            );
            var _date = new Date();
            console.log("date test: " + _date.toString());
            console.log("state : " + this.state.selectedDays);
            console.log("state : " + this.state.selectedDay);
            const usersProfile = (
                  <React.Fragment>
                        <section className="section">
                              <div className="header">
                                    <h2>basic profile info</h2>
                              </div>

                              <div className="body" style={{ display: "block" }}>
                                    <form style={{ marginBottom: "15px" }}>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* first name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="firstName"
                                                            placeholder="contact first name"
                                                            value={this.state.firstName}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.firstName}</span>
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* last name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="lastName"
                                                            placeholder="contact first name"
                                                            value={this.state.lastName}
                                                            onChange={this.handleChange}
                                                      />
                                                      <span className={isValid}>{this.state.errors.lastName}</span>
                                                </div>
                                          </div>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">display name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="displayName"
                                                            placeholder="display name"
                                                            value={this.state.displayName}
                                                            onChange={this.handleChange}
                                                      />
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* country</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"country"}
                                                            label={this.state.countrySelectedValue}
                                                            value={this.state.countrySelectedValue}
                                                            onChange={this.handleCountryChange}
                                                            options={this.state.countries}
                                                            placeholder={this.state.placeHolderCountry}
                                                      />
                                                      <span className={isValid}>{this.state.errors.country}</span>
                                                </div>
                                          </div>

                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">Date of Birth</label>
                                                      {/* {(() => {
                    if (this.state.selectedDay == "Invalid Date") {
                      return (
                        <DayPickerInput
                          value={undefined}
                          onDayClick={this.props.handleDayClick}
                          placeholder="click to choose a date"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          format="LL"
                          onDayChange={this.handleDayChange}
                          dayPickerProps={{
                            selectedDays: selectedDay,
                            locale: "en-gb",
                            localeUtils: MomentLocaleUtils
                          }}
                        />
                      );
                    } else {
                      return (
                        <DayPickerInput
                          value={this.state.selectedDay}
                          onDayClick={this.props.handleDayClick}
                          placeholder="23 september 2019"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          format="LL"
                          onDayChange={this.handleDayChange}
                          dayPickerProps={{
                            selectedDays: selectedDay,
                            locale: "en-gb",
                            localeUtils: MomentLocaleUtils
                          }}
                        />
                      );
                    }
                  })()} */}
                                                      <React.Fragment>
                                                            {(() => {
                                                                  const year = this.state.thirteenYearsAgo.getFullYear();
                                                                  return this.state.selectedDay != "Invalid Date" &&
                                                                        this.state.selectedDay !== undefined ? (
                                                                        <div className="select-wrapper">
                                                                              <select
                                                                                    className="costom"
                                                                                    defaultValue={
                                                                                          `${this.state.selectedDay}`
                                                                                                ? `${new Date(this.state.selectedDay).getFullYear()}`
                                                                                                : `${this.state.userBirthYear}`
                                                                                                ? `${this.state.userBirthYear.getFullYear()}`
                                                                                                : "Select the year you were born"
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                          this.setState({
                                                                                                selectedDay: "",
                                                                                                userBirthYear: new Date(e.target.value)
                                                                                          });
                                                                                    }}
                                                                              >
                                                                                    <option disabled hidden>
                                                                                          {`${this.state.selectedDay}`
                                                                                                ? `${new Date(this.state.selectedDay).getFullYear()}`
                                                                                                : `${this.state.userBirthYear}`
                                                                                                ? `${this.state.userBirthYear.getFullYear()}`
                                                                                                : "Select the year you were born"}
                                                                                    </option>
                                                                                    {Array.from(new Array(110), (v, i) => (
                                                                                          <option key={i} value={year - i}>
                                                                                                {year - i}
                                                                                          </option>
                                                                                    ))}
                                                                              </select>
                                                                        </div>
                                                                  ) : (
                                                                        <div className="select-wrapper">
                                                                              <select
                                                                                    className="costom"
                                                                                    defaultValue={"Select the year you were born"}
                                                                                    onChange={(e) => {
                                                                                          this.setState({
                                                                                                selectedDay: "",
                                                                                                userBirthYear: new Date(e.target.value)
                                                                                          });
                                                                                    }}
                                                                              >
                                                                                    <option disabled>Select the year you were born</option>
                                                                                    {Array.from(new Array(110), (v, i) => (
                                                                                          <option key={i} value={year - i}>
                                                                                                {year - i}
                                                                                          </option>
                                                                                    ))}
                                                                              </select>
                                                                        </div>
                                                                  );
                                                            })()}
                                                            {this.state.selectedDay != "Invalid Date" && this.state.selectedDay !== undefined && (
                                                                  <DayPickerInput
                                                                        value={this.state.selectedDay}
                                                                        onDayClick={this.props.handleDayClick}
                                                                        placeholder="Select the month and day you were born"
                                                                        formatDate={formatDate}
                                                                        parseDate={parseDate}
                                                                        format="LL"
                                                                        // placeholder={`${formatDate(new Date(), "LL", "en-gb")}`}
                                                                        onDayChange={this.handleDayChange}
                                                                        dayPickerProps={{
                                                                              locale: "en-gb",
                                                                              localeUtils: MomentLocaleUtils,
                                                                              initialMonth: this.state.userBirthYear
                                                                                    ? this.state.userBirthYear
                                                                                    : new Date(new Date(this.state.selectedDay).getFullYear(), 0, 1),
                                                                              fromMonth: new Date(
                                                                                    moment(
                                                                                          this.state.userBirthYear
                                                                                                ? new Date(
                                                                                                        this.state.userBirthYear.getFullYear(),
                                                                                                        0,
                                                                                                        1
                                                                                                  )
                                                                                                : new Date(
                                                                                                        new Date(
                                                                                                              this.state.selectedDay
                                                                                                        ).getFullYear(),
                                                                                                        0,
                                                                                                        1
                                                                                                  )
                                                                                    )
                                                                              ),
                                                                              toMonth: new Date(
                                                                                    moment(
                                                                                          this.state.userBirthYear
                                                                                                ? new Date(
                                                                                                        this.state.userBirthYear.getFullYear(),
                                                                                                        11,
                                                                                                        1
                                                                                                  )
                                                                                                : new Date(
                                                                                                        new Date(
                                                                                                              this.state.selectedDay
                                                                                                        ).getFullYear(),
                                                                                                        11,
                                                                                                        1
                                                                                                  )
                                                                                    )
                                                                              ),
                                                                              disabledDays: {
                                                                                    from: this.state.thirteenYearsAgo,
                                                                                    to: new Date()
                                                                              }
                                                                        }}
                                                                  />
                                                            )}
                                                      </React.Fragment>
                                                      <span className={isValid}>{this.state.errors.date}</span>
                                                </div>
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">* region</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"state"}
                                                            label={this.state.regionSelectedValue}
                                                            value={this.state.regionSelectedValue}
                                                            onChange={this.handleRegionChange}
                                                            options={this.state.regions}
                                                            placeholder={this.state.placeHolderRegion}
                                                      />
                                                      <span className={isValid}>{this.state.errors.region}</span>
                                                </div>
                                          </div>

                                          <div className="row">
                                                <div className="col-6">
                                                      <div style={{ height: "100px" }}>
                                                            <label className="formLabel alignLeft">Gender</label>
                                                            <label className="checkLabel">
                                                                  <input
                                                                        type="radio"
                                                                        value="male"
                                                                        name="gender"
                                                                        onChange={this.handleChange}
                                                                        checked={this.state.gender == "male"}
                                                                  />
                                                                  Male
                                                            </label>
                                                            <label className="checkLabel">
                                                                  <input
                                                                        type="radio"
                                                                        value="female"
                                                                        name="gender"
                                                                        onChange={this.handleChange}
                                                                        checked={this.state.gender == "female"}
                                                                  />
                                                                  Female
                                                            </label>
                                                            <label className="checkLabel">
                                                                  <input
                                                                        type="radio"
                                                                        value="other"
                                                                        name="gender"
                                                                        onChange={this.handleChange}
                                                                        checked={this.state.gender == "other"}
                                                                  />
                                                                  Other
                                                            </label>
                                                      </div>
                                                </div>
                                          </div>
                                          <label className="formLabel" style={{ marginLeft: "15px", marginTop: "15px" }}>
                                                * = required field
                                          </label>
                                    </form>
                              </div>
                        </section>
                  </React.Fragment>
            );

            const userInterests = (
                  <React.Fragment>
                        <section className="section">
                              <div className="header">
                                    <h2>user interests</h2>
                              </div>
                              <div className="body" style={{ display: "block", overflow: "hidden" }}>
                                    <form style={{ marginBottom: "15px" }}>
                                          <div className="row">
                                                <div className="col-6">
                                                      <label className="formLabel alignLeft">Choose your interests</label>
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Allround"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Allround}
                                                                  checked={this.state.checkboxVals.Allround}
                                                            />
                                                            Allround
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Distance"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Distance}
                                                                  checked={this.state.checkboxVals.Distance}
                                                            />
                                                            Distance
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Dressage"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Dressage}
                                                                  checked={this.state.checkboxVals.Dressage}
                                                            />
                                                            Dressage
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Eventing"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Eventing}
                                                                  checked={this.state.checkboxVals.Eventing}
                                                            />
                                                            Eventing
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Gallop"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Gallop}
                                                                  checked={this.state.checkboxVals.Gallop}
                                                            />
                                                            Gallop
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Jumping"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Jumping}
                                                                  checked={this.state.checkboxVals.Jumping}
                                                            />
                                                            Jumping
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Hunting"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Hunting}
                                                                  checked={this.state.checkboxVals.Hunting}
                                                            />
                                                            Hunting
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Driving"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Driving}
                                                                  checked={this.state.checkboxVals.Driving}
                                                            />
                                                            Driving
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Trotting"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Trotting}
                                                                  checked={this.state.checkboxVals.Trotting}
                                                            />
                                                            Trotting
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Vaulting"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Vaulting}
                                                                  checked={this.state.checkboxVals.Vaulting}
                                                            />
                                                            Vaulting
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Western"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Western}
                                                                  checked={this.state.checkboxVals.Western}
                                                            />
                                                            Western
                                                      </label>

                                                      <label className="checkLabel">
                                                            <input
                                                                  type="checkbox"
                                                                  name="Other"
                                                                  onChange={this.handleCheckChange}
                                                                  value={this.state.checkboxVals.Other}
                                                                  checked={this.state.checkboxVals.Other}
                                                            />
                                                            Other
                                                      </label>
                                                </div>
                                                <div className="col-6" />
                                          </div>

                                          {(() => {
                                                switch (this.state.checkboxVals.Other) {
                                                      case false:
                                                            return null;
                                                      case true:
                                                            return (
                                                                  <div className="row">
                                                                        <div className="col-6">
                                                                              <label className="formLabel alignLeft">
                                                                                    {"Please specify (max 25 characters)"}
                                                                              </label>
                                                                              <input
                                                                                    type="text"
                                                                                    className="textinput"
                                                                                    name="OtherInterests"
                                                                                    //maxLength={25}
                                                                                    onChange={this.handleChange}
                                                                                    placeholder="please specify"
                                                                                    value={this.state.OtherInterests}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                            );
                                                      default:
                                                            return null;
                                                }
                                          })()}

                                          <div className="row">
                                                <div className="col-11">
                                                      <label className="formLabel alignLeft">associations</label>
                                                      <textarea
                                                            // style={{ width: "94.5%" }}
                                                            name="Associations"
                                                            onChange={this.handleChange}
                                                            placeholder="enter comments here"
                                                            value={this.state.Associations}
                                                      />
                                                </div>
                                          </div>
                                    </form>
                              </div>
                        </section>
                  </React.Fragment>
            );

            const SubmitButton = (
                  <div className="row">
                        <div
                              style={{
                                    width: "150px",
                                    marginTop: "-20px",
                                    marginBottom: "-10px",
                                    float: "right",
                                    display: "block"
                              }}
                        >
                              <button className="goldButton" onClick={this.validate}>
                                    {this.state.updateButtonLoading ? "updating..." : "update"}
                              </button>
                        </div>
                  </div>
            );

            const messageType = this.state.messageType;

            const Alerts = (
                  <React.Fragment>
                        {(() => {
                              switch (messageType) {
                                    case "ERROR": {
                                          return (
                                                <ErrorWrapper
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                      Message={this.state.Message}
                                                      messageType={this.state.messageType}
                                                />
                                          );
                                    }
                                    case "SUCCESS":
                                          return (
                                                <SuccessWrapper
                                                      Message={this.state.Message}
                                                      messageType={this.state.messageType}
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                />
                                          );
                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );

            return (
                  <React.Fragment>
                        {(() => {
                              switch (profileType) {
                                    case "business":
                                          return (
                                                <React.Fragment>
                                                      {(() => {
                                                            if (this.props.basicProfileInfo == true) {
                                                                  return (
                                                                        <React.Fragment>
                                                                              {businessProfile}
                                                                              {SubmitButton}
                                                                              {Alerts}
                                                                        </React.Fragment>
                                                                  );
                                                            }
                                                      })()}

                                                      {(() => {
                                                            if (this.props.userInterests == true) {
                                                                  return (
                                                                        <React.Fragment>
                                                                              {businessIntrests}
                                                                              {SubmitButton}
                                                                              {Alerts}
                                                                        </React.Fragment>
                                                                  );
                                                            }
                                                      })()}
                                                </React.Fragment>
                                          );
                                    case "user":
                                          return (
                                                <React.Fragment>
                                                      {(() => {
                                                            if (this.props.basicProfileInfo == true) {
                                                                  return (
                                                                        <React.Fragment>
                                                                              {usersProfile}
                                                                              {SubmitButton}
                                                                              {Alerts}
                                                                        </React.Fragment>
                                                                  );
                                                            }
                                                      })()}

                                                      {(() => {
                                                            if (this.props.userInterests == true) {
                                                                  return (
                                                                        <React.Fragment>
                                                                              {userInterests}
                                                                              {SubmitButton}
                                                                              {Alerts}
                                                                        </React.Fragment>
                                                                  );
                                                            }
                                                      })()}

                                                      {(() => {
                                                            if (this.props.horseInterests == true) {
                                                                  return (
                                                                        <React.Fragment>
                                                                              <HorseIntrestsComponent
                                                                                    profileType={this.state.profile.profileType}
                                                                                    handleHorseInterestCheckBoxVals={
                                                                                          this.handleHorseInterestCheckBoxVals
                                                                                    }
                                                                                    horseInterestCheckBoxVals={this.state.horseInterestCheckBoxVals}
                                                                              />
                                                                              {SubmitButton}
                                                                              {Alerts}
                                                                        </React.Fragment>
                                                                  );
                                                            }
                                                      })()}
                                                </React.Fragment>
                                          );
                                    default:
                                          return null;
                              }
                        })()}

                        {(() => {
                              if (this.props.aboutMe == true) {
                                    return <AboutMeComponent profileType={this.state.profile.profileType} />;
                              }
                        })()}
                  </React.Fragment>
            );
      }
}

export { ProfileComponent };
