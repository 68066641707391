import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const createNewAccountApi = async function(jsonData, accountType) {
      const headers = {
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };

      var rootURL = getApiURL();

      const apiName = "createNewAccountApi";
      const serviceName = "account-service";
      var baseUrl;
      if (accountType == "user") {
            const apiEndPoint = "/api/v1/account-service/";
            baseUrl = rootURL + apiEndPoint;
      } else if (accountType == "business") {
            const apiEndPoint = "/api/v1/account-service/business/createBusinessAccount";
            baseUrl = rootURL + apiEndPoint;
      }

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers,
            data: jsonData
      })
            .then(function(response) {
                  //console.log(response.data);

                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default createNewAccountApi;
