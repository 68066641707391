import React, { Component } from "react";
import { Link } from "react-router-dom";
import randomizeImageInterval from "./randomiseSidebarHeaderImages";
import "../../../../css/sidebar.css";
import SideBarUserInfo from "./SideBarUserInfo";
class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHideNav: false,
      accountSettingsLinkActive: true,
      findNewUsersLinkActive: false
    };
  }
  UNSAFE_componentWillMount() {
    randomizeImageInterval();
    //console.group("AccountSettigs");
    //console.log(this.props.accountSettingsLinkActive);
    if (this.props.accountSettingsLinkActive == true) {
      this.setState({
        accountSettingsLinkActive: true,
        findNewUsersLinkActive: false
      });
    }
    //console.log(this.props.findNewUsersLinkActive);
    if (this.props.findNewUsersLinkActive == true) {
      this.setState({
        accountSettingsLinkActive: false,
        findNewUsersLinkActive: true
      });
    }
    //console.groupEnd();
  }

  showHideLinks = () => {
    this.setState({ showHideNav: !this.state.showHideNav });
    // //console.log("show hide links " + this.state.showHideNav);
  };
  render() {
    //const accountSettingsActive = this.props.accountSettingsActive;
    return (
      <div className="sidebar">
        <button
          style={{
            position: "absolute",
            right: "0px",
            top: "0px",
            cursor: "pointer",
            marginTop: "0px",
            marginBottom: "0px",
            width: "47px",
            backgroundColor: "rgba(255,255,255,1)",
            border: "1px solid rgba(204,204,204,0.5)"
          }}
          onClick={this.showHideLinks}
        >
          <span className="sidebarDropdownIcon" />
        </button>

        {this.state.showHideNav ? (
          <div
            style={{
              width: "300px",
              position: "absolute",
              right: "1px",
              top: "47px",
              zIndex: "4000",
              backgroundColor: "rgba(255,255,255,1)"
            }}
          >
            <nav className="sidebarNav">
              {/* <SideBarUserInfo /> */}

              <div
                id="findNewUsers"
                className={
                  this.state.findNewUsersLinkActive ? "linkActive" : "link"
                }
                //onMouseDown={this.changeActiveLink}
              >
                <Link
                  id="findNewUsers"
                  className="linkTitle"
                  to="/friends-and-followers/find-new-private-users"
                >
                  <span className="navUsersIcon" />
                  Find friends
                </Link>
              </div>
              <div className="link">
                <Link
                  className="linkTitle"
                  to="/friends-and-followers/my-friends"
                >
                  <span className="navMyFriendsIcon" />
                  Connections
                </Link>
              </div>
              <div className="link">
                <Link className="linkTitle" to="/all-followers">
                  <span className="navFollowersIcon" />
                  Followers
                </Link>
              </div>
              <div className="link">
                <Link className="linkTitle" to="/all-followers">
                  <span className="navFollowingIcon" />
                  users i follow
                </Link>
              </div>
              <div className="link">
                <Link className="linkTitle" to="/companies">
                  <span className="navCompaniesIcon" />
                  Companies i follow
                </Link>
              </div>
            </nav>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Sidebar;
