import React, { Component } from "react";
import PropTypes from "prop-types";
import RegisterButton from "../../common/login/SmallComponents/RegisterButton";
import CreateNewUserAccountPopup from "../../common/login/CreateNewUserAccountPopup";
import RegisterOptionsPopup from "../../common/login/RegisterOptionsPopup";
import Footer from "../../common/login/Footer";
import FadeIn from "react-fade-in";
class ServiceUnavailable extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  title: "",
                  showRegisterOptions: false,
                  showCreateNewUserAccountPopup: false,
                  serviceNameOnError: "",
                  secs: 0,
                  mins: 0
            };
            this.toggleShowCreateUserAccountPopup = this.toggleShowCreateUserAccountPopup.bind(this);
      }

      componentDidMount() {
            setInterval(() => {
                  this.changeSeconds();
            }, 1000);
      }

      changeSeconds = () => {
            let { secs, mins } = this.state;
            if (secs == 29) {
                  this.goBackToPrevPage();
            }
            if (secs == 59) {
                  this.setState({
                        secs: 1
                  });
            } else {
                  this.setState((prevState) => ({
                        secs: prevState.secs + 1
                  }));
            }
            //set mins
            if (secs == 59) {
                  this.setState((prevState) => ({
                        mins: prevState.mins + 1
                  }));
            }
            //mins set to 2 max
            if (mins == 2) {
                  this.setState({ mins: 0 });
            }
      };
      goBackToPrevPage = () => {
            this.props.history.goBack();
      };
      UNSAFE_componentWillMount() {
            const changeTitle = this.props.changeTitle;
            changeTitle(this.state.title);
            this.setState({ serviceNameOnError: this.props.match.params.serviceName });
      }

      toggleShowRegisterOptionsLinkPopup = () => {
            this.setState({
                  showRegisterOptions: !this.state.showRegisterOptions
            });
      };

      toggleShowCreateUserAccountPopup = (event) => {
            console.log("event.target.id: " + event.target.value);
            this.setState({
                  accountType: event.currentTarget.getAttribute("id")
            });

            this.setState({
                  showCreateNewUserAccountPopup: !this.state.showCreateNewUserAccountPopup,
                  showRegisterOptions: false
            });
      };

      render() {
            const { mins, secs } = this.state;
            //console.log("seconds tik::" + secs);

            return (
                  <React.Fragment>
                        <section
                              className="horseBackground"
                              style={{
                                    height: "100vh",
                                    textAlign: "center",
                                    overflow: "hidden",
                                    position: "fixed",
                                    marginTop: "-100px"
                              }}
                        >
                              <h1 className="mainTitle" style={{ color: "rgba(255,255,255,1)", paddingTop: "10px" }}>
                                    Service Down
                              </h1>
                              <h2 style={{ color: "rgba(255,255,255,1)", fontSize: "0.9em" }}>
                                    {"Right now we are experiencing some problems with our services, we will try to auto connect."}
                              </h2>
                              <section className="countdownContainer">
                                    <span className="timer">
                                          {mins}
                                          <label
                                                className="checkLabel"
                                                style={{
                                                      fontSize: "0.3em",
                                                      fontWeight: "normal",
                                                      color: "rgba(255,255,255,1)"
                                                }}
                                          >
                                                minutes
                                          </label>
                                    </span>
                                    <span
                                          className="timer"
                                          style={{
                                                width: "20px",
                                                paddingLeft: "10px",
                                                paddingRight: "10px"
                                          }}
                                    >
                                          :
                                    </span>
                                    <span className="timer">
                                          {secs}
                                          <label
                                                className="checkLabel"
                                                style={{
                                                      fontSize: "0.3em",
                                                      fontWeight: "normal",
                                                      color: "rgba(255,255,255,1)"
                                                }}
                                          >
                                                seconds
                                          </label>
                                    </span>
                              </section>
                        </section>

                        <Footer />
                  </React.Fragment>
            );
      }
}
/* Pricing.propTypes = {
  title: PropTypes.string.isRequired,
  changeTitle: PropTypes.func.isRequired
}; */

export default ServiceUnavailable;
