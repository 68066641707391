import React, { Component } from "react";
import PropTypes from "prop-types";
class blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "my blog"
    };
  }

  componentDidMount = async () => {
    const changeTitle = this.props.changeTitle;

    changeTitle(this.state.title);
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3">
            <section className="section">
              <div className="settingsMenuTitle">
                <span>lINKS</span>
              </div>
              <div
                className="settingsMenu"
                style={{ width: "100% !important" }}
              >
                <div className="main-navLink">
                  <a href="#" className="mainNav-linkTitle">
                    create a new post
                  </a>
                </div>

                <div className="main-navLink">
                  <a href="#" className="mainNav-linkTitle">
                    drafts
                  </a>
                </div>

                <div className="main-navLink">
                  <a href="#" className="mainNav-linkTitle">
                    my blog
                  </a>
                </div>

                <div className="main-navLink">
                  <a href="#" className="mainNav-linkTitle">
                    friends blogs
                  </a>
                </div>

                <div className="main-navLink">
                  <a href="#" className="mainNav-linkTitle">
                    blogs i follow
                  </a>
                </div>

                <div className="main-navLink">
                  <a href="#" className="mainNav-linkTitle">
                    my archive
                  </a>
                </div>
              </div>
            </section>
          </div>
          <div className="col-6">dsdfsdfsd</div>
          <div className="col-3">sdasdasd</div>
        </div>
      </div>
    );
  }
}

blog.propTypes = {
  title: PropTypes.string.isRequired,
  changeTitle: PropTypes.func.isRequired
};

export default blog;
