import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getSessionStorageItem,
  getCurrentlyLoggedInProfileFunc
} from "../.././../common/getCurrentlyLoggedInProfile";
class dropdownNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      running: false,
      breakRemainingSeconds: 1,
      currentlyLoggedInProfile: {}
    };
    // this.menuToggle = this.menuToggle.bind(this);
  }

  menuToggle = () => {
    this.setState(previousState => {
      return { showMenu: !previousState.showMenu }
    });
    this.props.handleCloseOtherDropDowns("dropdown", !this.state.showMenu);
  };

  startStop() {
    const { running } = this.state;

    if (running) {
      this.setState({
        running: false,
        breakRemainingSeconds: 1
      });
      clearInterval(this.timer);
    } else {
      this.setState({
        running: true
      });

      this.timer = setInterval(() => {
        if (this.state.breakRemainingSeconds === 0) {
          this.setState({
            showMenu: false,
          });
          clearInterval(this.timer);
        }
        this.setState(previousState => {
          return {
            breakRemainingSeconds: previousState.breakRemainingSeconds - 1
          };
        });
        console.log("breakRemainingSeconds: " + this.state.breakRemainingSeconds)
      }, 500);
    }
  }

  dropDownNavigationOnLeave = async () => {
    this.startStop();
    this.props.handleCloseOtherDropDowns("dropdown", false);
  };

  dropDownNavigationOnEnter = async () => {
    if (this.state.running) {
      this.startStop();
      if (this.state.breakRemainingSeconds > 0) {
        this.setState({
          showMenu: true
        });
      }
    }
  };

  UNSAFE_componentWillReceiveProps = () => { };

  UNSAFE_componentWillMount = async () => {
    const profile = await getCurrentlyLoggedInProfileFunc();
    this.setState({ currentlyLoggedInProfile: profile });
  };

  componentWillUnmount = () => {
    clearTimeout();
  };

  componentDidMount = () => {
    // handle switch between the drop downs so that several cant be open at the same time
    console.log(
      "process.env.CONFIG_NAME===>" + JSON.stringify(process.env.CONFIG_NAME)
    );
    setTimeout(() => {
      if (this.props.dropDownNotificationSwitch == false) {
        this.setState({
          showMenu: false
        });
      }
    }, 50);
    window.addEventListener("click", this.handleLoad);
  };

  handleLoad = async e => {
    if (e.target.className !== "dropdownIcon") {
      this.setState({
        showMenu: false
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <nav onClick={this.menuToggle} className="dropdownIcon">
          <div
            id="settingsmenuContainer"
            className={
              this.state.showMenu
                ? "settingsmenuContainer showMenu"
                : " settingsmenuContainer hideMenu"
            }
            onMouseLeave={this.dropDownNavigationOnLeave}
            onMouseEnter={this.dropDownNavigationOnEnter}
          >
            <div id="settingsMenuAll" className="settingsMenu">
              <div className="settingsMenuTitle">
                <span>profile information</span>
              </div>
              <div className="main-navLink">
                <Link
                  to="/account-settings"
                  className="mainNav-linkTitle"
                  onClick={() => window.location.replace("/account-settings")}
                >
                  {" "}
                  <span className="navAccountSettingsIcon" />
                  account settings
                </Link>
              </div>
              <div className="settingsMenuTitle">
                <span>connections</span>
              </div>
              <div className="main-navLink">
                <Link
                  to="/friends-and-followers/find-new-private-users"
                  className="mainNav-linkTitle"
                  onClick={() =>
                    window.location.replace(
                      "/friends-and-followers/find-new-private-users"
                    )
                  }
                >
                  {" "}
                  <span className="navUsersIcon" />
                  {(() => {
                    if (
                      this.state.currentlyLoggedInProfile &&
                      this.state.currentlyLoggedInProfile.profileType == "user"
                    ) {
                      return " find friends";
                    } else {
                      return " find users";
                    }
                  })()}
                </Link>
              </div>
              <div className="main-navLink">
                <Link
                  to="/friends-and-followers/find-new-business-users"
                  className="mainNav-linkTitle"
                  onClick={() =>
                    window.location.replace(
                      "/friends-and-followers/find-new-business-users"
                    )
                  }
                >
                  {" "}
                  <span className="navCompaniesIcon" />
                  find companies
                </Link>
              </div>

              {(() => {
                if (
                  this.state.currentlyLoggedInProfile &&
                  this.state.currentlyLoggedInProfile.profileType == "user"
                ) {
                  return (
                    <div className="main-navLink">
                      <Link
                        to="/friends-and-followers/my-friends"
                        className="mainNav-linkTitle"
                        onClick={() =>
                          window.location.replace(
                            "/friends-and-followers/my-friends"
                          )
                        }
                      >
                        {" "}
                        <span className="navMyFriendsIcon" />
                        my friends
                      </Link>
                    </div>
                  );
                }
              })()}

              <div className="main-navLink">
                <Link
                  to="/friends-and-followers/my-followers"
                  className="mainNav-linkTitle"
                  onClick={() =>
                    window.location.replace(
                      "/friends-and-followers/my-followers"
                    )
                  }
                >
                  {" "}
                  <span className="navFollowersIcon" />
                  followers
                </Link>
              </div>
              <div className="main-navLink">
                <Link
                  to="/friends-and-followers/my-users-following"
                  className="mainNav-linkTitle"
                  onClick={() =>
                    window.location.replace(
                      "/friends-and-followers/my-users-following"
                    )
                  }
                >
                  {" "}
                  <span className="navFollowingIcon" />
                  users i follow
                </Link>
              </div>
              <div className="main-navLink">
                <Link
                  to="/friends-and-followers/my-business-following"
                  className="mainNav-linkTitle"
                  onClick={() =>
                    window.location.replace(
                      "/friends-and-followers/my-business-following"
                    )
                  }
                >
                  {" "}
                  <span className="navCompaniesIcon" />
                  companies i follow
                </Link>
              </div>
              <div className="settingsMenuTitle">
                <span>horsemetro information</span>
              </div>
              <div className="main-navLink">
                <Link
                  to="/terms-of-service"
                  className="mainNav-linkTitle"
                  onClick={() => window.location.replace("/terms-of-service")}
                >
                  <span className="navInfoIcon" />
                  terms of service
                </Link>
              </div>
              <div className="main-navLink">
                <Link
                  to="/bugreport"
                  className="mainNav-linkTitle"
                  onClick={() => window.location.replace("/bugreport")}
                >
                  <span className="navBugIcon" />
                  report a bug
                </Link>
              </div>
              <div className="main-navLink">
                <Link
                  to="/help"
                  className="mainNav-linkTitle"
                  onClick={() => window.location.replace("/help")}
                >
                  <span className="navHelpIcon" />
                  help
                </Link>
              </div>
              <div
                className="main-navLink"
                onClick={this.props.handleOnLogOutButtonClick}
              >
                <a className="mainNav-linkTitle">
                  {" "}
                  <span className="navLogoutIcon" />
                  logout
                </a>
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default dropdownNavigation;
