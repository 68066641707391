import React, { Component } from "react";
import loadable from "react-loadable";
import StaticPreLoader from "../../common/login/SmallComponents/staticPreloader";

const LoadingComponent = () => {
  return <StaticPreLoader />;
  //return <>loadin</>;
};

const ComponentPromise = () => {
  return import("../../pages/newsFeeds/newsFeeds");
};
const AsyncNewsFeeds = loadable({
  loader: ComponentPromise,
  loading: LoadingComponent
});
export default AsyncNewsFeeds;
