import React, { Component } from "react";
import PropTypes from "prop-types";
import checkDevice from "../../checkDevice";
import {getIsLoggedIn} from "../../cookieAndAccessHandling"

class RegisterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: "",
      isLoggedIn: ""
    };
  }
  componentDidMount = async () => {
    var device = checkDevice();
    var cookie = getIsLoggedIn();
    await this.setState({ device: device, isLoggedIn: cookie });
  };
  render() {
    console.log("device : " + this.state.device);
    console.log("cookies : " + this.state.isLoggedIn);
    return (
      <>
        {(() => {
          if (this.state.isLoggedIn === "true") return null;
          if (location.pathname === "/" || this.state.device === "Computer") {
            return (
              <span
                className="registerLink greenButton"
                style={{
                  float: "left"
                }}
                onClick={this.props.showActivationLinkPopup}
              >
                join now
              </span>
            );
          } else {
            return (
              <span
                className="registerLink2 greenButton"
                style={{
                  top: "12px",
                  width: "69px",
                  zIndex: "5000"
                }}
                onClick={this.props.showActivationLinkPopup}
              >
                join now
              </span>
            );
          }
        })()}
      </>
    );
  }
}
RegisterButton.propTypes = {
  showActivationLinkPopup: PropTypes.func.isRequired
};
export default RegisterButton;
