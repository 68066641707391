import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";
/* This end point in the back just returns true or false if a user is allowed to create an account or not */
const booleanCheckCountryAccessByIPApi = async function() {
      const headers = {
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/localization-service/accesscontroller/booleanCheckCountryAccessByIP";
      const apiName = "booleanCheckCountryAccessByIPApi";
      const serviceName = "localization-service";
      const baseUrl = rootURL + "/api/v1/localization-service/accesscontroller/booleanCheckCountryAccessByIP";

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers
      })
            .then(function(response) {
                  //console.log(response.data);

                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        _responseErrorInterceptor(errorResponseEntity);
                        return null;
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default booleanCheckCountryAccessByIPApi;
