import getCurrentlyLoggedInProfileApi from "../api/profile/getCurrentlyLoggedInProfileApi";

/**
 * fetches and stores right now a profile if its sepecified by string varibable sessionStoreageItemName
 * But later maybe shlould pass an object in to get stored
 * @param {*} sessionStorageItemName
 */
export const setSessionStorageItem = async sessionStorageItemName => {
  if (sessionStorageItemName == "profile") {
    const profile = await getCurrentlyLoggedInProfileApi();
    sessionStorage.setItem("profile", JSON.stringify(profile));
    return true;
  }
};
/**
 * returns an item specified by the string variable "sessionStorageItemName"
 * that is stored in session storage
 * @param {*} sessionStorageItemName
 */
export const getSessionStorageItem = async sessionStorageItemName => {
  if (sessionStorageItemName == "profile") {
    return await JSON.parse(sessionStorage.getItem("profile"));
  }
};

/** Since getCurrentlyLoggedInProfileApi.js is a async function we can simple do
 * return await getCurrentlyLoggedInProfileApi() here and call
 * it from another function in some other class or whatever like
 * this "var profile = await getCurrentlyLoggedInProfileFunction();"
 * this could be a centralized place if we wanted to rewrite this to
 * fetch from session storage later. This oone also checks if current user has payed if business user.
 *
 */
export const getCurrentlyLoggedInProfileFunc = async () => {
  return await getCurrentlyLoggedInProfileApi();
};

/**
 * This functions sleeps
 * for ms ammount of milliseconds
 * @param {*} ms
 */
export const realSleep = ms => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve("timeout");
    }, ms);
  });
};
