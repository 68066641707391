import React, { Component } from "react";
import FadeIn from "react-fade-in";
import getFriendSuggetstionsApi from "../../api/profile/notifications/getFriendSuggestionsApi";
import sendFriendRequestsApi from "../../api/profile/friends/sendFriendRequestApi";
import getMarkFriendSuggestionAsRemovedApi from "../../api/profile/notifications/getMarkFriendSuggestionAsRemovedApi";
import abortFriendRequestApi from "../../api/profile/friends/abortFriendRequestApi";
import CommonFriendsPopup from "../../common/login/SmallComponents/commonFriendsPopup";
import debounce from "lodash.debounce";
import getApiURL from "../../common/getAPIUrl";
import QuickLinks from "../notifications/QuickLinks";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import {
  removeElementFromArray,
  updateFieldInElementInArray,
  getElementInArrayByfieldNameTofindBy
} from "../../common/stateArrayUtils";
import { getCurrentlyLoggedInProfileFunc } from "../../common/getCurrentlyLoggedInProfile";
class FriendsAndFriendSuggestionNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Friends",
      Message: "",
      messageType: "",
      friendRequestCount: 0,
      friendRequests: [],
      friendSuggestions: [],
      newNotifications: [],
      friendRequestNotificationClicked: false,
      rootURL: "",
      friendRequestNotificationsIsLoading: false,
      friendSuggestionsIsLoading: false,
      toggleMutualFriendsPopup: false,
      notificationsIsLoading: false,
      friendSuggestionId: "",

      page: 0,
      itemsPerPage: 10,
      noMoreDataToRetrieve: false,
      currentlyLoggedInProfile: ""
    };
  }

  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
  };
  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleOnScroll);
  };

  handleOnScroll = debounce(() => {
    // if (this.props.level == "singlePost") return;
    if (this.state.noMoreDataToRetrieve == true) return;

    let offset = 1000;

    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement &&
        document.documentElement.scrollHeight - offset) ||
      document.body.scrollHeight - offset;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;

    var scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - offset;

    console.group("Scrolled to Bottom");
    console.log("scrollTop: " + scrollTop);
    console.log("scrollHeight: " + scrollHeight);
    console.log("scrollclientHeight: " + clientHeight);
    console.log("scrolledToBottom: " + scrolledToBottom);
    console.groupEnd();

    this.getNextPage(scrolledToBottom);
  }, 50);

  getNextPage = scrolledToBottom => {
    if (!scrolledToBottom) return false;
    //this.setState({ preloading: true });
    let { page } = this.state;
    let _page = page + 1;
    console.log("page :" + _page + " page in state : " + page);
    this.setState({ page: _page });

    let markAsRemovedClicked = false;
    this.getNewFriendSuggestions(_page);
  };

  UNSAFE_componentWillMount = async () => {
    //var profile = await getSessionStorageItem("profile");
    const profile = await getCurrentlyLoggedInProfileFunc();
    this.setState({ currentlyLoggedInProfile: profile });
    const changeTitle = this.props.changeTitle;
    const baseUrl = getApiURL();
    this.setState({ rootURL: baseUrl });
    changeTitle(this.state.title);
    this.getNewFriendSuggestions(0);
  };

  // get friend suggestions
  getNewFriendSuggestions = async page => {
    var itemsPerPage;
    itemsPerPage = this.state.itemsPerPage;
    await getFriendSuggetstionsApi(page, itemsPerPage).then(
      async function(response) {
        console.log(
          "Response dataObject:" + JSON.stringify(response.dataObject)
        );
        console.log("data length : ===>" + response.dataObject.length);
        if (response.dataObject.length <= 9) {
          await this.setState({
            noMoreDataToRetrieve: true
          });
        }

        await this.setState({
          friendSuggestions: [
            ...this.state.friendSuggestions,
            ...response.dataObject
          ]
        });
      }.bind(this)
    );
  };

  // remove friend suggestion
  //used
  markFriendSuggestionAsRemoved = async event => {
    const friendSuggestionId = event.target.getAttribute("id");

    console.log("ID = " + friendSuggestionId);

    await getMarkFriendSuggestionAsRemovedApi(friendSuggestionId).then(
      function(response) {
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;

        if (messageType == "success") {
          const newfriendSuggestionStateArray = removeElementFromArray(
            "id",
            friendSuggestionId,
            this.state.friendSuggestions
          );

          this.setState({ friendSuggestions: newfriendSuggestionStateArray });
          let { page } = this.state;
          this.getNewFriendSuggestions(page);
        }

        this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        });
        return message, messageType;
      }.bind(this)
    );
  };

  //used
  getTargetUserProfile = event => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    const userId = event.target.id;
    window.location.replace("/friends-and-followers/user-profile/" + userId);
  };
  //USED
  sendFriendRequests = async event => {
    const targetUserId = event.target.getAttribute("id");

    const updatedFriendSuggestionsArray = updateFieldInElementInArray(
      "targetUserId",
      targetUserId,
      this.state.friendSuggestions,
      "hasSentFriendRequest",
      true
    );

    this.setState({ friendSuggestions: updatedFriendSuggestionsArray });
    await sendFriendRequestsApi(targetUserId).then(
      function(response) {
        // console.log("response : -------> " + JSON.stringify(response));
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        });
        return message, messageType;
      }.bind(this)
    );
  };
  //USED
  abortSentFriendRequest = event => {
    const targetUserId = event.target.getAttribute("id");
    let updatedFriendSuggestions = [];

    abortFriendRequestApi(targetUserId).then(
      function(response) {
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        this.setState({ Message: response.msgWrapper.msgDescription });
        this.setState({ messageType: response.msgWrapper.msgType });

        if (response.msgWrapper.msgType == "success") {
          updatedFriendSuggestions = updateFieldInElementInArray(
            "targetUserId",
            targetUserId,
            this.state.friendSuggestions,
            "hasSentFriendRequest",
            false
          );

          this.setState({ friendSuggestions: updatedFriendSuggestions });
        }
        return message, messageType;
      }.bind(this)
    );
    //this.getNewFriendRequestNotifications();
  };
  //USED
  toggleMutualFriendsPopup = async event => {
    await this.setState({
      toggleMutualFriendsPopup: !this.state.toggleMutualFriendsPopup,
      friendSuggestionId: event.target.id
    });
  };

  render() {
    const friendSuggestions = this.state.friendSuggestions.map(
      (suggestion, index) => (
        <React.Fragment key={"newfriends" + index}>
          <div className="notificationNavLink">
            <div
              id={suggestion.targetUserId}
              onClick={this.getTargetUserProfile}
              className="avatarsWrapperNotification"
              style={{
                backgroundImage:
                  "url(" +
                  this.state.rootURL +
                  "/api/v1/gallery-service/" +
                  suggestion.friendSuggestionProfileImageThumUrl +
                  ".jpg)",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                float: "left",
                margin: "0px 0px 5px 10px"
              }}
            />
            <span
              style={{ float: "left", width: "auto", marginTop: "6px" }}
              className="friendRequestLinkTitle"
              id={suggestion.targetUserId}
              onClick={this.getTargetUserProfile}
            >
              {/* {suggestion.friendsuggestionUserName} */}
              {(suggestion.friendsuggestionUserName + "").length > 20
                ? suggestion.friendsuggestionUserName.substring(0, 20) + "..."
                : suggestion.friendsuggestionUserName + ""}
            </span>
            <p
              style={{
                float: "left",
                width: "100%",
                marginLeft: "59px",
                marginTop: "-20px",
                display: "block",
                fontSize: "0.8em",
                textDecoration: "underline",
                cursor: "pointer"
              }}
              id={suggestion.id}
              onClick={this.toggleMutualFriendsPopup}
            >
              Mutual friends
              {" " + suggestion.friendsInCommonSize}
            </p>
            <button
              style={{
                width: "55px",
                position: "absolute",
                right: "5px",
                top: "13px",
                margin: "0px 0px 0px 0px",
                padding: "5px",
                fontSize: "0.8em",
                color: "rgba(140,140,140,1)"
              }}
              id={suggestion.id}
              onClick={this.markFriendSuggestionAsRemoved}
              className="greyButton"
            >
              remove
            </button>
            {(() => {
              const hasSentFriendRequest = suggestion.hasSentFriendRequest;
              if (hasSentFriendRequest === false)
                return (
                  <button
                    style={{
                      width: "100px",
                      position: "absolute",
                      right: "65px",
                      top: "13px",
                      margin: "0px 0px 0px 0px",
                      padding: "5px",
                      fontSize: "0.8em"
                    }}
                    id={suggestion.targetUserId}
                    onClick={this.sendFriendRequests}
                    className="greenButton"
                  >
                    add friend
                  </button>
                );
              if (hasSentFriendRequest === true)
                return (
                  <button
                    style={{
                      width: "100px",
                      position: "absolute",
                      right: "65px",
                      top: "13px",
                      margin: "0px 0px 0px 0px",
                      padding: "5px",
                      fontSize: "0.8em"
                    }}
                    id={suggestion.targetUserId}
                    onClick={this.abortSentFriendRequest}
                    className="greenButton"
                  >
                    pending..
                  </button>
                );
            })()}
          </div>
        </React.Fragment>
      )
    );

    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "error": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "success":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {(() => {
          if (this.state.toggleMutualFriendsPopup == true) {
            return (
              <CommonFriendsPopup
                friendSuggestionId={this.state.friendSuggestionId}
                toggleMutualFriendsPopup={this.toggleMutualFriendsPopup}
              />
            );
          }
        })()}
        <FadeIn transitionDuration={200} delay={200}>
          <div className="container">
            {Alerts}
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <QuickLinks />
                {(() => {
                  if (
                    this.state.currentlyLoggedInProfile &&
                    this.state.currentlyLoggedInProfile.profileType == "user"
                  ) {
                    return (
                      <FadeIn transitionDuration={200} delay={200}>
                        <section
                          className="section"
                          style={{ borderRadius: "0px 3px 3px 3px" }}
                        >
                          <div className="header">
                            <h2>people you may know</h2>
                          </div>
                          <div className="body">{friendSuggestions}</div>
                        </section>
                      </FadeIn>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </FadeIn>
      </React.Fragment>
    );
  }
}

export default FriendsAndFriendSuggestionNotifications;
