import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";
const sendResetPassWordLink = async function(emailAdress) {
      const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "cache-control": "no-cache"
      };

      var params = new URLSearchParams();
      params.append("email", emailAdress);
      params.append("baseUrl", window.location.origin + "/resetPassword/");

      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/account-service/sendPasswordResetLinkNewForReact";
      const apiName = "sendResetPassWordLink";
      const serviceName = "account-service";
      const baseUrl = rootURL + apiEndPoint;

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers,
            params: params
      })
            .then(function(response) {
                  return response;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default sendResetPassWordLink;
