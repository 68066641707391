import axios from "axios";
import { getAccessToken, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";
import getApiURL from "../../common/getAPIUrl";

const getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi = async function() {
      const accountSubscription = "account_subscription";
      const accessToken = getAccessToken();
      var rootURL = getApiURL();
      //const apiEndPoint = "/api/v1/payment-service/getSubscriptionTypeMetaData" old ;
      const apiEndPoint = "/api/v1/payment-service/getPriceByCurrenctlyLoginUserAndSubscriptionType";
      //getPriceByUserIdAndSubscriptionType
      const apiName = "getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi";
      const serviceName = "payment-service";
      const baseUrl = rootURL + apiEndPoint + "?subscriptionType=" + accountSubscription;

      const headers = {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };

      return await axios({
            method: "GET",
            url: baseUrl,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi;
