import React, { Component } from "react";
import getUrl from "../../common/getAPIUrl";
import GetMyAlbums from "../../api/gallery-service/getMyAlbumsApi";
import SettingsMenu from "../../common/settingsMenu/setingsMenuDropdown";

import FadeIn from "react-fade-in";
import debounce from "lodash.debounce";
class myGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "my Gallery",
      itemsPerPage: 10,
      page: 1,
      myGalleryItems: [],
      link: "",
      UserId: "",
      clicked: ""
      //like counter
    };
  }
  UNSAFE_componentWillMount = async () => {
    await this.setState({ link: getUrl() });
    const changeTitle = this.props.changeTitle;
    changeTitle(this.state.title);

    await this.getMyAlbums(0);
  };

  handleOnScroll = debounce(() => {
    if (this.state.noMoreDataToRetrieve == true) return;

    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - 200;
    this.getNextPage(scrolledToBottom);
  }, 200);

  getNextPage = scrolledToBottom => {
    this.setState({ preloading: true });
    var page = this.state.page + 1;
    this.setState({ page: page });
    this.getMyAlbums(page);
  };

  //updates the state
  getMyAlbums = async page => {
    await GetMyAlbums(page, this.state.itemsPerPage).then(
      async function(response) {
        console.log("response length : " + JSON.stringify(response));

        await this.setState(prevState => ({
          myGalleryItems: [...prevState.myGalleryItems, ...response.dataObject],
          preloading: false
        }));
      }.bind(this)
    );
  };

  menuItemActivate = async event => {
    var id = event.target.id;
    console.log("clicked");
    await this.setState({ clicked: !this.state.clicked, UserId: id });
    console.log("menu item clicked : " + this.state.clicked);
  };
  // click the item in the menu function
  menuItemsDeactivate = async id => {
    console.log("deactivate clicked");
    await this.setState({ clicked: false });
  };

  render() {
    console.log("gallery items : " + JSON.stringify(this.state.myGalleryItems));

    const myGalleries = this.state.myGalleryItems.map((galleries, index) => {
      return (
        <li
          key={galleries.id}
          id={galleries.id}
          className="profileCardUsersWrapper"
        >
          <FadeIn key={index} transitionDuration={200} delay={200}>
            <div className="galleryHeader">
              <span className="newsTitle">
                {(galleries.albumName + "").length > 20
                  ? galleries.albumName.substring(0, 20) + "..."
                  : galleries.albumName}
              </span>
              <SettingsMenu
                UserId={this.state.UserId}
                id={galleries.id}
                menuItemActivate={this.menuItemActivate}
                menuItemsDeactivate={this.menuItemsDeactivate}
                clicked={this.state.clicked}
                albumName={galleries.albumName}
                albumDescription={galleries.albumDescription}
                visibility={galleries.visibility}
              />
            </div>
            <div className="galleryBackground">
              <img
                src={
                  "" +
                  this.state.link +
                  "/api/v1/gallery-service/" +
                  galleries.backGroundImageUrl +
                  ""
                }
              ></img>
            </div>
            <div className="galleryFooter" style={{ overflow: "hidden" }}>
              <span className="footerText">
                {(galleries.albumDescription + "").length > 20
                  ? galleries.albumDescription.substring(0, 20) + "..."
                  : galleries.albumDescription}
              </span>
            </div>
          </FadeIn>
        </li>
      );
    });

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="grid">{myGalleries}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default myGallery;
