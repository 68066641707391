import React, { Component } from "react";

class Step3 extends Component {
  render() {
    if (this.props.currentStep !== 3) {
      return null;
    }
    //console.log("props : " + this.props.checkboxVals.Allround);
    let profileType = this.props.profileType;
    return (
      <React.Fragment>
        {(() => {
          switch (profileType) {
            case "user":
              return (
                <React.Fragment>
                  <label className="formLabel alignLeft">
                    Choose your interests
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Allround"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Allround}
                      checked={this.props.checkboxVals.Allround}
                    />
                    Allround
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Distance"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Distance}
                      checked={this.props.checkboxVals.Distance}
                    />
                    Distance
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Dressage"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Dressage}
                      checked={this.props.checkboxVals.Dressage}
                    />
                    Dressage
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Eventing"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Eventing}
                      checked={this.props.checkboxVals.Eventing}
                    />
                    Eventing
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Gallop"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Gallop}
                      checked={this.props.checkboxVals.Gallop}
                    />
                    Gallop
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Jumping"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Jumping}
                      checked={this.props.checkboxVals.Jumping}
                    />
                    Jumping
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Hunting"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Hunting}
                      checked={this.props.checkboxVals.Hunting}
                    />
                    Hunting
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Driving"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Driving}
                      checked={this.props.checkboxVals.Driving}
                    />
                    Driving
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Trotting"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Trotting}
                      checked={this.props.checkboxVals.Trotting}
                    />
                    Trotting
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Vaulting"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Vaulting}
                      checked={this.props.checkboxVals.Vaulting}
                    />
                    Vaulting
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Western"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Western}
                      checked={this.props.checkboxVals.Western}
                    />
                    Western
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Other"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Other}
                      checked={this.props.checkboxVals.Other}
                    />
                    Other
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.Other) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="formLabel alignLeft">
                              {"Please specify (max 25 characters)"}
                            </label>
                            <input
                              type="text"
                              className="textinput"
                              name="otherInterests"
                              maxLength={25}
                              onChange={this.props.handleChange}
                              placeholder="please specify"
                              value={this.props.otherInterests}
                            />
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}
                  <label className="formLabel alignLeft">associations</label>
                  <textarea
                    name="associations"
                    onChange={this.props.handleChange}
                    placeholder="enter comments here"
                    value={this.props.associations}
                  />
                  <label className="formLabel alignLeft">
                    Are you competing ?
                  </label>
                  <label className="checkLabel">
                    <input
                      type="radio"
                      value="yes"
                      name="competing"
                      onChange={this.props.handleChange}
                      checked={this.props.competing == "yes"}
                    />
                    Yes
                  </label>
                  <label className="checkLabel">
                    <input
                      type="radio"
                      value="no"
                      name="competing"
                      onChange={this.props.handleChange}
                      checked={this.props.competing == "no"}
                    />
                    No
                  </label>
                </React.Fragment>
              );
            case "business":
              return (
                <React.Fragment>
                  <label className="formLabel alignLeft">
                    {"Help people to find your business!"}
                  </label>
                  <span className="introText">
                    {
                      "Check the boxes that match your business offers so that people looking for your kind of goods and services can find you."
                    }
                  </span>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Breeding"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Breeding}
                      checked={this.props.checkboxVals.Breeding}
                    />
                    Breeding
                  </label>

                  {(() => {
                    switch (this.props.checkboxVals.Breeding) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Breeding2"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Breeding2}
                                checked={this.props.checkboxVals.Breeding2}
                              />
                              Breeding
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Stallions"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Stallions}
                                checked={this.props.checkboxVals.Stallions}
                              />
                              Stallions
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Insamination"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Insamination}
                                checked={this.props.checkboxVals.Insamination}
                              />
                              Insamination
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Nutritionist"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Nutritionist}
                                checked={this.props.checkboxVals.Nutritionist}
                              />
                              Nutritionist
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                className="textbox"
                                name="breedingOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.breedingOther}
                                checked={this.props.checkboxVals.breedingOther}
                              />
                              Other
                            </label>
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}
                  {(() => {
                    switch (this.props.checkboxVals.breedingOther) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="formLabel alignLeft">
                              {"Please specify (max 25 characters)"}
                            </label>
                            <input
                              type="text"
                              className="textinput"
                              name="breedingOtherServices"
                              maxLength={25}
                              onChange={this.props.handleChange}
                              placeholder="please specify"
                              value={this.props.breedingOtherServices}
                            />
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="ConstructionAndMaintenance"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.ConstructionAndMaintenance}
                      checked={
                        this.props.checkboxVals.ConstructionAndMaintenance
                      }
                    />
                    {"Construction & Maintenance"}
                  </label>
                  {(() => {
                    switch (
                      this.props.checkboxVals.ConstructionAndMaintenance
                    ) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ConstructionServices"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.ConstructionServices
                                }
                                checked={
                                  this.props.checkboxVals.ConstructionServices
                                }
                              />
                              {"Construction Services / Craftsmen"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ConstructionStore"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.ConstructionStore
                                }
                                checked={
                                  this.props.checkboxVals.ConstructionStore
                                }
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ConstructionManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .ConstructionManufacturer
                                }
                                checked={
                                  this.props.checkboxVals
                                    .ConstructionManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ConstructionOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.ConstructionOther
                                }
                                checked={
                                  this.props.checkboxVals.ConstructionOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.ConstructionOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="constructionOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props.constructionOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="FinanceAndLaw"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.FinanceAndLaw}
                      checked={this.props.checkboxVals.FinanceAndLaw}
                    />
                    {"Finance & Law"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.FinanceAndLaw) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="LegalServices"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.LegalServices}
                                checked={this.props.checkboxVals.LegalServices}
                              />
                              {"Legal services"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="InsuranceCompany"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.InsuranceCompany}
                                checked={
                                  this.props.checkboxVals.InsuranceCompany
                                }
                              />
                              {"Insurance company"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Bank"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Bank}
                                checked={this.props.checkboxVals.Bank}
                              />
                              {"Bank"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="LegalOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.LegalOther}
                                checked={this.props.checkboxVals.LegalOther}
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (this.props.checkboxVals.LegalOther) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="legalOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.legalOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="ElectronicsComputerTech"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.ElectronicsComputerTech}
                      checked={this.props.checkboxVals.ElectronicsComputerTech}
                    />
                    {"Electronics, Computer & Technology"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.ElectronicsComputerTech) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ElectronicsStore"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.ElectronicsStore}
                                checked={
                                  this.props.checkboxVals.ElectronicsStore
                                }
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ElectronicsManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .ElectronicsManufacturer
                                }
                                checked={
                                  this.props.checkboxVals
                                    .ElectronicsManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ElectronicsOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.ElectronicsOther}
                                checked={
                                  this.props.checkboxVals.ElectronicsOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.ElectronicsOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="electronicsOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props.electronicsOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="FeedAndBait"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.FeedAndBait}
                      checked={this.props.checkboxVals.FeedAndBait}
                    />
                    {"Feed & bait"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.FeedAndBait) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FeedAndBaitStore"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.FeedAndBaitStore}
                                checked={
                                  this.props.checkboxVals.FeedAndBaitStore
                                }
                              />
                              {"Store"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FeedAndBaitManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .FeedAndBaitManufacturer
                                }
                                checked={
                                  this.props.checkboxVals
                                    .FeedAndBaitManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FeedAndBaitOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.FeedAndBaitOther}
                                checked={
                                  this.props.checkboxVals.FeedAndBaitOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.FeedAndBaitOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="feedAndBaitOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props.feedAndBaitOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Vehicle"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Vehicle}
                      checked={this.props.checkboxVals.Vehicle}
                    />
                    {"Vehicles"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.Vehicle) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="CarSales"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.CarSales}
                                checked={this.props.checkboxVals.CarSales}
                              />
                              {"Car sales"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="MachineDealers"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.MachineDealers}
                                checked={this.props.checkboxVals.MachineDealers}
                              />
                              {"Machine dealers"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="VehicleRepairs"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.VehicleRepairs}
                                checked={this.props.checkboxVals.VehicleRepairs}
                              />
                              {"Vehicle reapirs"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="VehicleManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.VehicleManufacturer
                                }
                                checked={
                                  this.props.checkboxVals.VehicleManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="VehicleOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.VehicleOther}
                                checked={this.props.checkboxVals.VehicleOther}
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (this.props.checkboxVals.VehicleOther) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="vehicleOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.vehicleOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Organization"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Organization}
                      checked={this.props.checkboxVals.Organization}
                    />
                    {"Organization"}
                  </label>

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="HomeHouseholdGarden"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.HomeHouseholdGarden}
                      checked={this.props.checkboxVals.HomeHouseholdGarden}
                    />
                    {"Home, Household & Garden"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.HomeHouseholdGarden) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Architect"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Architect}
                                checked={this.props.checkboxVals.Architect}
                              />
                              {"Architect"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="GardenDesigner"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.GardenDesigner}
                                checked={this.props.checkboxVals.GardenDesigner}
                              />
                              {"Garden designer"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Broker"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Broker}
                                checked={this.props.checkboxVals.Broker}
                              />
                              {"Broker"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FarmShop"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.FarmShop}
                                checked={this.props.checkboxVals.FarmShop}
                              />
                              {"Farm shop"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HomeHouseholdGardenManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .HomeHouseholdGardenManufacturer
                                }
                                checked={
                                  this.props.checkboxVals
                                    .HomeHouseholdGardenManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HomeHouseholdGardenOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .HomeHouseholdGardenOther
                                }
                                checked={
                                  this.props.checkboxVals
                                    .HomeHouseholdGardenOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.HomeHouseholdGardenOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="homeHouseholdGardenOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props
                                            .homeHouseholdGardenOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="HotelsAndTravel"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.HotelsAndTravel}
                      checked={this.props.checkboxVals.HotelsAndTravel}
                    />
                    {"Hotels & Travel"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.HotelsAndTravel) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="BedAndBreakfast"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.BedAndBreakfast}
                                checked={
                                  this.props.checkboxVals.BedAndBreakfast
                                }
                              />
                              {"B&B's"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Hotel"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Hotel}
                                checked={this.props.checkboxVals.Hotel}
                              />
                              {"Hotel"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="TravelAgents"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.TravelAgents}
                                checked={this.props.checkboxVals.TravelAgents}
                              />
                              {"Travel agents"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Accommodation"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Accommodation}
                                checked={this.props.checkboxVals.Accommodation}
                              />
                              {"Accommodation"}
                            </label>

                            {(() => {
                              switch (this.props.checkboxVals.Accommodation) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="checkLabelIndented">
                                        <input
                                          type="radio"
                                          value="with horse"
                                          name="withHorse"
                                          onChange={this.props.handleChange}
                                          checked={
                                            this.props.withHorse == "with horse"
                                          }
                                        />
                                        With horse
                                      </label>
                                      <label className="checkLabelIndented">
                                        <input
                                          type="radio"
                                          value="without horse"
                                          name="withHorse"
                                          onChange={this.props.handleChange}
                                          checked={
                                            this.props.withHorse ==
                                            "without horse"
                                          }
                                        />
                                        Without horse
                                      </label>
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Conferences"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Conferences}
                                checked={this.props.checkboxVals.Conferences}
                              />
                              {"Conferences"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HotelsAndTravelOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.HotelsAndTravelOther
                                }
                                checked={
                                  this.props.checkboxVals.HotelsAndTravelOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.HotelsAndTravelOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="hotelsAndTraveOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props.hotelsAndTraveOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="HealthAndBeauty"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.HealthAndBeauty}
                      checked={this.props.checkboxVals.HealthAndBeauty}
                    />
                    {"Health & Beauty"}
                  </label>

                  {(() => {
                    switch (this.props.checkboxVals.HealthAndBeauty) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HealthAndBeautyStore"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.HealthAndBeautyStore
                                }
                                checked={
                                  this.props.checkboxVals.HealthAndBeautyStore
                                }
                              />
                              {"Store"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HealthAndBeautyManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .HealthAndBeautyManufacturer
                                }
                                checked={
                                  this.props.checkboxVals
                                    .HealthAndBeautyManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HealthAndBeautyOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.HealthAndBeautyOther
                                }
                                checked={
                                  this.props.checkboxVals.HealthAndBeautyOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.HealthAndBeautyOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="healthAndBeautyOthterServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props
                                            .healthAndBeautyOthterServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="HorseAccessories"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.HorseAccessories}
                      checked={this.props.checkboxVals.HorseAccessories}
                    />
                    {"Horse Accessories"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.HorseAccessories) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="RidingHousesAndStallSupplies"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .RidingHousesAndStallSupplies
                                }
                                checked={
                                  this.props.checkboxVals
                                    .RidingHousesAndStallSupplies
                                }
                              />
                              {"Riding houses & stall supplies"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="EquestrianShop"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.EquestrianShop}
                                checked={this.props.checkboxVals.EquestrianShop}
                              />
                              {"Equestrian shop"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Saddler"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Saddler}
                                checked={this.props.checkboxVals.Saddler}
                              />
                              {"Saddler"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HorseAccessoriesStore"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.HorseAccessoriesStore
                                }
                                checked={
                                  this.props.checkboxVals.HorseAccessoriesStore
                                }
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HorseAccessoriesManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .HorseAccessoriesManufacturer
                                }
                                checked={
                                  this.props.checkboxVals
                                    .HorseAccessoriesManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HorseAccessoriesOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.HorseAccessoriesOther
                                }
                                checked={
                                  this.props.checkboxVals.HorseAccessoriesOther
                                }
                              />
                              {"Other"}
                            </label>

                            {(() => {
                              switch (
                                this.props.checkboxVals.HorseAccessoriesOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="horseAccessoriesOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props
                                            .horseAccessoriesOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Toys"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Toys}
                      checked={this.props.checkboxVals.Toys}
                    />
                    {"Toys"}
                  </label>

                  {(() => {
                    switch (this.props.checkboxVals.Toys) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ToyStore"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.ToyStore}
                                checked={this.props.checkboxVals.ToyStore}
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="ToyOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.ToyOther}
                                checked={this.props.checkboxVals.ToyOther}
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (this.props.checkboxVals.ToyOther) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="toysOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.toysOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="MediaAdvertisement"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.MediaAdvertisement}
                      checked={this.props.checkboxVals.MediaAdvertisement}
                    />
                    {"Media & Advertising"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.MediaAdvertisement) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="MediaStore"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.MediaStore}
                                checked={this.props.checkboxVals.MediaStore}
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="MediaEvents"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.MediaEvents}
                                checked={this.props.checkboxVals.MediaEvents}
                              />
                              {"Events"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Photographer"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Photographer}
                                checked={this.props.checkboxVals.Photographer}
                              />
                              {"Photographer"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Journalist"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Journalist}
                                checked={this.props.checkboxVals.Journalist}
                              />
                              {"Journalist"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Advertiser"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Advertiser}
                                checked={this.props.checkboxVals.Advertiser}
                              />
                              {"Advertiser"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Publisher"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Publisher}
                                checked={this.props.checkboxVals.Publisher}
                              />
                              {"Publisher"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Newspaper"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Newspaper}
                                checked={this.props.checkboxVals.Newspaper}
                              />
                              {"Newspaper"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="MediaOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.MediaOther}
                                checked={this.props.checkboxVals.MediaOther}
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (this.props.checkboxVals.MediaOther) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="mediaOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.mediaOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Fashion"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Fashion}
                      checked={this.props.checkboxVals.Fashion}
                    />
                    {"Fashion, clothing and accessories"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.Fashion) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FashionStore"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.FashionStore}
                                checked={this.props.checkboxVals.FashionStore}
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FashionManufacturer"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.FashionManufacturer
                                }
                                checked={
                                  this.props.checkboxVals.FashionManufacturer
                                }
                              />
                              {"Manufacturer"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="FashionOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.FashionOther}
                                checked={this.props.checkboxVals.FashionOther}
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (this.props.checkboxVals.FashionOther) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="fashionOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.fashionOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="Leisure"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.Leisure}
                      checked={this.props.checkboxVals.Leisure}
                    />
                    {"Fun & Leisure"}
                  </label>

                  {(() => {
                    switch (this.props.checkboxVals.Leisure) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="LeisureEvents"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.LeisureEvents}
                                checked={this.props.checkboxVals.LeisureEvents}
                              />
                              {"Events"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="LeisureOther"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.LeisureOther}
                                checked={this.props.checkboxVals.LeisureOther}
                              />
                              {"Other"}
                            </label>

                            {(() => {
                              switch (this.props.checkboxVals.LeisureOther) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="leisureOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.leisureOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="SchoolEducation"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.SchoolEducation}
                      checked={this.props.checkboxVals.SchoolEducation}
                    />
                    {"School & Education"}
                  </label>

                  {(() => {
                    switch (this.props.checkboxVals.SchoolEducation) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="RidingSchool"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.RidingSchool}
                                checked={this.props.checkboxVals.RidingSchool}
                              />
                              {"Riding school"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="TrainigInstructor"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.TrainigInstructor
                                }
                                checked={
                                  this.props.checkboxVals.TrainigInstructor
                                }
                              />
                              {"Trainig instructor"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="College"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.College}
                                checked={this.props.checkboxVals.College}
                              />
                              {"College"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="University"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.University}
                                checked={this.props.checkboxVals.University}
                              />
                              {"University"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="SchoolEducationOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals.SchoolEducationOther
                                }
                                checked={
                                  this.props.checkboxVals.SchoolEducationOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals.SchoolEducationOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="schoolOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={this.props.schoolOtherServices}
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="HorseRelatedServices"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.HorseRelatedServices}
                      checked={this.props.checkboxVals.HorseRelatedServices}
                    />
                    {"Horse related services"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.HorseRelatedServices) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Breaking"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Breaking}
                                checked={this.props.checkboxVals.Breaking}
                              />
                              {"Breaking"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Dentist"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Dentist}
                                checked={this.props.checkboxVals.Dentist}
                              />
                              {"Dentist"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Driver"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Driver}
                                checked={this.props.checkboxVals.Driver}
                              />
                              {"Driver"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Farrier"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Farrier}
                                checked={this.props.checkboxVals.Farrier}
                              />
                              {"Farrier"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="InRunning"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.InRunning}
                                checked={this.props.checkboxVals.InRunning}
                              />
                              {"In-running"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Masseur"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Masseur}
                                checked={this.props.checkboxVals.Masseur}
                              />
                              {"Masseur"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="hrNutritionist"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.hrNutritionist} // There is another "Nutritionist" in checkbox vals hence the hrNutritionist
                                checked={this.props.checkboxVals.hrNutritionist} //// There is another "Nutritionist" in checkbox vals hence the hrNutritionist
                              />
                              {"Nutritionist"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HorseRelatedServicesStore"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .HorseRelatedServicesStore
                                }
                                checked={
                                  this.props.checkboxVals
                                    .HorseRelatedServicesStore
                                }
                              />
                              {"Store"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Therapist"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Therapist}
                                checked={this.props.checkboxVals.Therapist}
                              />
                              {"Therapist"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Transport"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Transport}
                                checked={this.props.checkboxVals.Transport}
                              />
                              {"Transport"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="Vet"
                                onChange={this.props.handleStep3Change}
                                value={this.props.checkboxVals.Vet}
                                checked={this.props.checkboxVals.Vet}
                              />
                              {"Vet"}
                            </label>

                            <label className="checkLabelIndented">
                              <input
                                type="checkbox"
                                name="HorseRelatedServicesOther"
                                onChange={this.props.handleStep3Change}
                                value={
                                  this.props.checkboxVals
                                    .HorseRelatedServicesOther
                                }
                                checked={
                                  this.props.checkboxVals
                                    .HorseRelatedServicesOther
                                }
                              />
                              {"Other"}
                            </label>
                            {(() => {
                              switch (
                                this.props.checkboxVals
                                  .HorseRelatedServicesOther
                              ) {
                                case false:
                                  return null;
                                case true:
                                  return (
                                    <React.Fragment>
                                      <label className="formLabel alignLeft">
                                        {"Please specify (max 25 characters)"}
                                      </label>
                                      <input
                                        type="text"
                                        className="textinput"
                                        name="horseRelatedOtherServices"
                                        maxLength={25}
                                        onChange={this.props.handleChange}
                                        placeholder="please specify"
                                        value={
                                          this.props.horseRelatedOtherServices
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      name="businessOther"
                      onChange={this.props.handleStep3Change}
                      value={this.props.checkboxVals.businessOther}
                      checked={this.props.checkboxVals.businessOther}
                    />
                    {"Other"}
                  </label>
                  {(() => {
                    switch (this.props.checkboxVals.businessOther) {
                      case false:
                        return null;
                      case true:
                        return (
                          <React.Fragment>
                            <label className="formLabel alignLeft">
                              {"Please specify (max 25 characters)"}
                            </label>
                            <input
                              type="text"
                              className="textinput"
                              name="businessOtherServices"
                              maxLength={25}
                              onChange={this.props.handleChange}
                              placeholder="please specify"
                              value={this.props.businessOtherServices}
                            />
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })()}
                </React.Fragment>
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );
  }
}

export { Step3 };
