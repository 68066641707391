/**
 * Splits a string delimeted by the specified delimiter
 * Returns a substring of the substringnumber specified
 * @param {*} string
 * @param {*} substringnumber
 * @param {*} delimiter
 */
export const splitStringAndReturnSubStringFunc = (
  string,
  substringnumber,
  delimiter
) => {
  const temp = string.split(delimiter);
  const subString = temp[substringnumber];
  //console.log("substring-->"+substring);
  return subString;
};
