//console.log("get api url");
import $ from "jquery";
/*
 * the config file for getting the right url api
 *
 *
 */

const getRootUrl = () => {
  var link = "";
  const rootUrl = $(location).attr("hostname");
  const proto = location.protocol;
  const port = location.port;
  const rootUrlWithPort = location.origin;

  if (rootUrl == "www.horsemetro.com") {
    link = proto + "//" + rootUrl;
  }
  if (rootUrl == "horsemetro.com") {
    link = proto + "//" + rootUrl;
  }

  if (rootUrl == "pre-stage.horsemetro.com") {
    link = proto + "//" + rootUrl;
  }
  if (rootUrl == "stage.horsemetro.com") {
    link = proto + "//" + rootUrl;
  }

  if (rootUrlWithPort == "https://01i.dg:24152") {
    link = "https://01i.dg:24152";
  }
  if (rootUrlWithPort == "https://01i.dg:24150") {
    link = "http://01i.dg:24100";
  }
  if (rootUrlWithPort == "http://01i.dg:24100") {
    link = "http://01i.dg:24100";
  }
  if (rootUrlWithPort == "https://01i.dg:24101") {
    link = "https://01i.dg:24101";
  }

  if (rootUrlWithPort == "http://localhost:8080") {
    link = "http://localhost:8080";
  }

  if (rootUrlWithPort == "http://localhost:8081") {
    link = "http://localhost:8081";
  }
  //link =window.location.origin;
  return link;
};

export default getRootUrl;
