import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { withRouter } from "react-router-dom";
import getRootURL from "../../../common/getRootURl";
import getApiURL from "../../../common/getAPIUrl";
import getUnreadNotificationsCountApi from "../../../api/profile/notifications/getNotificationsCountApi";
import getNotificationsApi from "../../../api/profile/notifications/getNotificationsApi";
import { ErrorWrapper, SuccessWrapper } from "../../../common/alerts";
import getAvatarThumbOrOthersUrlByUserId from "../../getAvatarThumbOrOthersUrlByUserId";
import markNotificationAsReadApi from "../../../api/profile/notifications/markNotificationAsReadApi";
import markLastFiveNotificationAsReadApi from "../../../api/profile/notifications/markLastFiveNotificationAsReadApi";
import NotificationsPreloader from "../../login/SmallComponents/notificationsPreloader";
import { splitStringAndReturnSubStringFunc } from "../../splitStringFunc";
import {
  removeElementFromArray,
  updateFieldInElementInArray,
  getStringArraOfSpecifiedFieldValues
} from "../../stateArrayUtils";
import {
  getSessionStorageItem,
  getCurrentlyLoggedInProfileFunc,
  realSleep
} from "../../../common/getCurrentlyLoggedInProfile";

class notificationNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Message: "",
      messageType: "",
      unreadNotificationsCount: 0,
      newNotifications: [],
      notificationClicked: false,
      isMounted: false,
      notificationsIsLoading: false,
      currentlyLoggedInprofile: {}
    };
  }

  intervalId = 0;
  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
  };

  UNSAFE_componentWillMount = async () => {
    const profile = await getCurrentlyLoggedInProfileFunc();
    this.setState({
      currentlyLoggedInprofile: profile,
      isMounted: true
    });
  };

  componentDidMount = async () => {
    // handle switch between the drop downs so that several cant be open at the same time
    setTimeout(() => {
      if (this.props.notificationNavigationSwitch == false) {
        if (this.state.isMounted == true) {
          this.setState({
            notificationClicked: false
          });
        }
      }
    }, 50);

    //await this.getNewNotifications();
    await this.getUnreadNotificationsCount();
    window.addEventListener("click", this.handleLoad);
    this.intervalId = setInterval(() => {
      this.getUnreadNotificationsCount();
    }, 10000);
  };

  handleLoad = async e => {
    if (
      e.target.className !== "navNotificationsIcon" &&
      e.target.className !== "greyButton"
    ) {
      this.setState({
        notificationClicked: false
      });
    }
  };

  getUnreadNotificationsCount = async () => {
    getUnreadNotificationsCountApi().then(
      function(response) {
        this.setState({ unreadNotificationsCount: response });
      }.bind(this)
    );
  };

  getNewNotifications = async () => {
    const page = 0;
    const itemsPerPage = 5;
    this.setState({ notificationsIsLoading: true });
    await getNotificationsApi(page, itemsPerPage).then(
      function(response) {
        /*  //console.log(
          "new notifications : " + JSON.stringify(response.dataObject)
        ); */
        this.setState({ newNotifications: response.dataObject });
      }.bind(this)
    );
    this.setState({ notificationsIsLoading: false });
  };

  notificationNavigationClicked = async () => {
    this.getNewNotifications();
    this.setState({
      notificationClicked: !this.state.notificationClicked
    });
    this.props.handleCloseOtherDropDowns(
      "notification",
      !this.state.notificationClicked
    );
  };

  notificationNavigationLeft = async () => {
    this.setState({
      notificationClicked: false
    });

    this.props.handleCloseOtherDropDowns("notificaition", false);
  };

  getAvatarUrl = (userId, scale) => {
    var url = getAvatarThumbOrOthersUrlByUserId(userId, scale);
    ////console.log(url);
    return url;
  };

  getTargetUserProfile = event => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    const userId = event.currentTarget.getAttribute("id");
    const profileType = event.currentTarget.getAttribute("data-profiletype");
    //console.log("profile type ========== : " + profileType);
    if (profileType === "user") {
      window.location.replace("/friends-and-followers/user-profile/" + userId);
    }
    if (profileType === "business") {
      window.location.replace(
        "/friends-and-followers/business-user-profile/" + userId
      );
    }
  };

  viewImage = event => {
    const imageId = event.target.id;
    //console.log("GET TO VIEW IMAGE IN OLD FRONT IMAGEID = " + imageId);
  };
  getTimeLinePost = async event => {
    event.persist();
    const rootURL = await getRootURL();
    const timeLinePostId = event.target.getAttribute("data-postid");
    const port = location.port;
    //console.log("rootUrl===>" + rootURL);
    window.location.replace(
      rootURL + "/viewSinglePost-timeline/" + timeLinePostId + "/commentId"
    );
  };
  getTimeLineComment = async event => {
    event.persist();
    const rootURL = await getRootURL();
    const timeLineCommentId = event.target.getAttribute("data-commentid");
    //console.log("rootUrl===>" + rootURL);
    window.location.replace(
      rootURL + "/viewSinglePost-timeline/postId/" + timeLineCommentId
    );
    /* const port = location.port;
    if (port === "24152") {
      window.location.replace(
        rootURL + ":" + port + "/en-UK/timeline?commentid=" + timeLineCommentId
      );
    } */
    /* window.location.replace(
      rootURL + "/en-UK/timeline?commentid=" + timeLineCommentId
    ); */
  };

  markLastNotificationsAsRead = async () => {
    const notificationIds = getStringArraOfSpecifiedFieldValues(
      "id",
      this.state.newNotifications
    );
    //console.group("MARK ALL REMOVED");
    //console.log(notificationIds);
    //console.groupEnd();
    markLastFiveNotificationAsReadApi(notificationIds).then(
      function(response) {
        //console.log(
        //  "Returns true or false: " + JSON.stringify(response.dataObject)
        //);
        if (response == true) {
          this.setState({
            unreadNotificationsCount:
              this.state.unreadNotificationsCount - notificationIds.length
          });
          this.getNewNotifications();
        }
      }.bind(this)
    );
  };

  markNotificationAsRead = async event => {
    //const notificationId = event.target.id;
    const notificationId = event.currentTarget.getAttribute(
      "data-notificationid"
    );
    var newNotifications = removeElementFromArray(
      "id",
      notificationId,
      this.state.newNotifications
    );

    await this.setState({
      newNotifications: newNotifications,
      unreadNotificationsCount: this.state.unreadNotificationsCount - 1
    });

    await markNotificationAsReadApi(notificationId).then(
      function(response) {
        //console.log(
        //  "Success or error mark Notification as read : " +
        //    JSON.stringify(response.dataObject)
        //);
        //this.setState({ newNotifications: response.dataObject });
      }.bind(this)
    );

    this.getNewNotifications();
  };

  /// RENDERS
  renderRemoveButton = notificationId => {
    //console.log("==================>" + notificationId);
    const removeButton = (
      <button
        style={{
          width: "55px",
          position: "absolute",
          right: "5px",
          bottom: "3px",
          display: "block",
          margin: "0px 0px 0px 0px",
          padding: "5px",
          fontSize: "0.8em",
          color: "rgba(140,140,140,1)",
          zIndex: "2000",
          overflow: "none"
        }}
        data-notificationid={notificationId}
        onClick={this.markNotificationAsRead}
        className="greyButton"
      >
        remove
      </button>
    );
    return removeButton;
  };
  renderAvatar = (fromUserId, fromUserProfileType) => {
    /* console.log(
      "fromUserId: " +
        fromUserId +
        " fromUserProfileType: " +
        fromUserProfileType
    ); */
    const avatar = (
      <div
        id={fromUserId}
        data-profiletype={fromUserProfileType}
        onClick={this.getTargetUserProfile}
        className="avatarsWrapperNotification"
        style={{
          backgroundImage:
            "url(" + getAvatarThumbOrOthersUrlByUserId(fromUserId, 0) + ")",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          float: "left",
          margin: "10px 0px 5px 10px"
        }}
      />
    );
    return avatar;
  };
  renderNotificationMessage = (
    fromUserProfileType,
    fromUserId,
    notificationMessage
  ) => {
    let subStringMessage = splitStringAndReturnSubStringFunc(
      notificationMessage,
      0,
      ":"
    );
    const notificationMessageSpan = (
      <span
        data-profiletype={fromUserProfileType}
        onClick={this.getTargetUserProfile}
        style={{
          heigth: "auto",
          overflow: "none",
          float: "left",
          width: "70%",
          marginTop: "6px"
        }}
        className="notificationLinkTitle"
        id={fromUserId}
      >
        {subStringMessage}
      </span>
    );
    return notificationMessageSpan;
  };

  renderViewPost = (targetEntityId, id) => {
    //console.log("asdklfjasdflkjlaksdf =>>>" + targetEntityId);
    const viewPost = (
      <p
        style={{
          float: "left",
          width: "100%",
          margin: "-9px 0px 0px 60px",

          fontSize: "0.8em",
          textDecoration: "underline",
          cursor: "pointer"
        }}
        id={id}
        data-postid={targetEntityId}
        onClick={this.getTimeLinePost}
      >
        view post
      </p>
    );
    return viewPost;
  };
  renderViewComment = (targetEntityId, id) => {
    //console.log("asdklfjasdflkjlaksdf =>>>" + targetEntityId);
    const viewPostCommentItem = (
      <p
        style={{
          float: "left",
          width: "100%",
          margin: "-9px 0px 0px 60px",

          fontSize: "0.8em",
          textDecoration: "underline",
          cursor: "pointer"
        }}
        id={id}
        data-commentid={targetEntityId}
        onClick={this.getTimeLineComment}
      >
        view comment
      </p>
    );
    return viewPostCommentItem;
  };
  componentWillUnmount = () => {
    this.setState({ isMounted: false });
    clearInterval(this.intervalId);
    clearTimeout();
  };
  render() {
    const newNotifications = this.state.newNotifications.map(notification => (
      <React.Fragment key={notification.id}>
        <FadeIn transitionDuration={200} delay={200}>
          {(() => {
            //FRIEND ACCEPT AND FOLLOWER NOTIFICATIONS
            if (
              notification.notificationType === "friend-accept" ||
              notification.notificationType === "follow" ||
              notification.notificationType === "follower"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}

                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div style={{ width: "70%", float: "right" }}>
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            /////////////////////////////////////////////////////////// GALLERY-SERVICE ///////////////////////////////////////////////////////////////////////////////////////////////////
            // IMAGE LIKE NOTIFICATIONS
            if (notification.notificationType === "image-like") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}

                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // ALBUM-UPDATE NOTIFICATION
            if (notification.notificationType === "album-update") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            //ALBUM FOLLOW
            if (notification.notificationType === "album-follow") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // ALBUM-UNFOLLOW
            if (notification.notificationType === "album-unfollow") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // ALBUM LIKES
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "album"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            ////////////////////////////////////////////////////////////// TIMELINE-SERVICE   /////////////////////////////////////////////////////////////////////
            // POST LIKE NOTIFICATIONS
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "post"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewPost(
                      notification.targetEntityId,
                      notification.id
                    )}

                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // COMMENT LIKE NOTIFICATIONS
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "comment"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // SUB COMMENT LIKE NOTIFICATIONS
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "subcomment"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // COMMENT  NOTIFICATIONS
            if (notification.notificationType === "comment") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            if (notification.notificationType === "share") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewPost(
                      notification.targetEntityId,
                      notification.id
                    )}

                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            if (notification.notificationType === "taggedInPost") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewPost(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            if (notification.notificationType === "taggedInComment") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
          })()}
        </FadeIn>
      </React.Fragment>
    ));

    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "error": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "success":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );
    //console.log("notificaiton icon " + this.props.counterIcon);
    return (
      <React.Fragment>
        <FadeIn transitionDuration={200} delay={200}>
          <div style={{ position: "relative" }}>
            <div
              id="notificationNav"
              className="navNotificationsIcon"
              onClick={this.notificationNavigationClicked}
            />

            {(() => {
              if (this.state.unreadNotificationsCount == 0) {
                return null;
              } else {
                if (
                  this.state.currentlyLoggedInprofile &&
                  this.state.currentlyLoggedInprofile.profileType == "user"
                ) {
                  return (
                    <div className="notificationsCouterContainer ">
                      <span className="notificationCounter">
                        {this.state.unreadNotificationsCount}
                      </span>
                    </div>
                  );
                } else if (
                  this.state.currentlyLoggedInprofile &&
                  this.state.currentlyLoggedInprofile.profileType == "business"
                ) {
                  return (
                    <div className="notificationsCouterContainerBusiness ">
                      <span className="notificationCounter">
                        {this.state.unreadNotificationsCount}
                      </span>
                    </div>
                  );
                } else {
                  return null;
                }
              }
            })()}
            {(() => {
              if (
                this.state.currentlyLoggedInprofile &&
                this.state.currentlyLoggedInprofile.profileType == "user"
              ) {
                return (
                  <div
                    id="notificationsMenuContainerId"
                    className={
                      this.state.notificationClicked
                        ? "notificationsMenuContainer showMenu"
                        : "notificationsMenuContainer hideMenu"
                    }
                    style={{ marginTop: "10px" }}
                    onMouseLeave={this.notificationNavigationLeft}
                  >
                    <div id="notificationsMenuId" className="notificationsMenu">
                      <div className="settingsMenuTitle">Notifications</div>
                      {(() => {
                        /*  if (this.state.newNotifications.length > 0) {
                        return (
                          <button
                            style={{
                              width: "72px",
                              align: "right",
                             
                              padding: "5px",
                              fontSize: "0.8em",
                              color: "rgba(140,140,140,1)",
                              zIndex: "5000"
                            }}
                            onClick={this.markLastNotificationsAsRead}
                            className="greyButtonRemoveAll"
                          >
                            remove all
                          </button>
                        );
                      } */
                      })()}
                      {(() => {
                        if (!this.state.notificationsIsLoading) {
                          if (
                            this.state.newNotifications &&
                            this.state.newNotifications.length <= 0
                          ) {
                            return (
                              <div className="notificationNavLink">
                                <span className="linkTitle">
                                  no new notifications
                                </span>
                              </div>
                            );
                          } else {
                            return <>{newNotifications}</>;
                          }
                        } else {
                          //not loading
                          return <NotificationsPreloader />;
                        }
                      })()}
                      <button
                        style={{
                          margin: "0px 0px 0px 0px",
                          padding: "10px",
                          fontSize: "0.8em",
                          borderRadius: "0px !important"
                        }}
                        className="goldButton removeBRadius"
                        onClick={() => {
                          window.location.replace("/notifications");
                        }}
                      >
                        view all
                      </button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    id="notificationsMenuContainerId"
                    className={
                      this.state.notificationClicked
                        ? "notificationsMenuContainer showMenu"
                        : "notificationsMenuContainer hideMenu"
                    }
                    onMouseLeave={this.notificationNavigationLeft}
                  >
                    <div id="notificationsMenuId" className="notificationsMenu">
                      <div className="settingsMenuTitle">Notifications</div>
                      {(() => {
                        /* if (this.state.newNotifications.length > 0) {
                        return (
                          <button
                            style={{
                              width: "72px",
                              align: "right",
                              right: "5px",
                              bottom: "3px",
                              margin: "5px 5px 5px 327px",
                              padding: "5px",
                              fontSize: "0.8em",
                              color: "rgba(140,140,140,1)",
                              zIndex: "5000"
                            }}
                            onClick={this.markLastNotificationsAsRead}
                            className="greyButtonRemoveAll"
                          >
                            remove all
                          </button>
                        );
                      } */
                      })()}
                      {(() => {
                        if (!this.state.notificationsIsLoading) {
                          if (
                            this.state.newNotifications &&
                            this.state.newNotifications.length <= 0
                          ) {
                            return (
                              <div className="notificationNavLink">
                                <span className="linkTitle">
                                  no new notifications
                                </span>
                              </div>
                            );
                          } else {
                            return <>{newNotifications}</>;
                          }
                        } else {
                          //not loading
                          return <NotificationsPreloader />;
                        }
                      })()}
                      <button
                        onClick={() => {
                          window.location.replace("/notifications");
                        }}
                        style={{
                          margin: "0px 0px 0px 0px",
                          padding: "10px",
                          fontSize: "0.8em",
                          borderRadius: "0px !important"
                        }}
                        className="goldButton removeBRadius"
                      >
                        view all
                      </button>
                    </div>
                  </div>
                );
              }
            })()}
          </div>
          {Alerts}
        </FadeIn>
      </React.Fragment>
    );
  }
}

export default withRouter(notificationNavigation);
