import React, { Component } from "react";
import PropTypes, { element } from "prop-types";
//child component of app.js
class pageTitle extends Component {
      render() {
            console.log(" location page title: " + this.props.location);

            return (
                  <React.Fragment>
                        {(() => {
                              if (
                                    this.props.location === "/friends-and-followers/user-profile" ||
                                    this.props.location === "/friends-and-followers/user-timeline" ||
                                    this.props.location === "/friends-and-followers/business-gallery" ||
                                    this.props.location === "/friends-and-followers/business-followers" ||
                                    this.props.location === "/friends-and-followers/user-gallery" ||
                                    this.props.location === "/friends-and-followers/business-timeline" ||
                                    this.props.location === "/friends-and-followers/user-friends" ||
                                    this.props.location === "/friends-and-followers/business-user-profile" ||
                                    this.props.location === "/friends-and-followers/business-bazaar" ||
                                    this.props.location === "/friends-and-followers/user-bazaar"
                              ) {
                                    return (
                                          <div className="mainTitleWhite" id="mainTitleWhite">
                                                {this.props.title.length > 40 ? this.props.title.substring(0, 40) + "..." : this.props.title}
                                          </div>
                                    );
                              } else if (this.props.location === "/payment") {
                                    return (
                                          <div className="mainTitleGreen">
                                                {this.props.title.length > 40 ? this.props.title.substring(0, 40) + "..." : this.props.title}
                                          </div>
                                    );
                              } else {
                                    return (
                                          <div className="mainTitle" id="mainTitle">
                                                {this.props.title.length > 40 ? this.props.title.substring(0, 40) + "..." : this.props.title}
                                          </div>
                                    );
                              }
                        })()}
                  </React.Fragment>
            );
      }
}
pageTitle.propTypes = {
      title: PropTypes.string.isRequired
};

export default pageTitle;
