import React, { Component } from "react";
import FadeIn from "react-fade-in";
import getFriendRequestsApi from "../../../api/profile/notifications/getFriendRequestsApi";
import getFriendRequestCountApi from "../../../api/profile/notifications/getFriendRequestCounterApi";
import sendFriendRequestsApi from "../../../api/profile/friends/sendFriendRequestApi";
import getFriendSuggetstionsApi from "../../../api/profile/notifications/getFriendSuggestionsApi";
import acceptFriendRequestApi from "../../../api/profile/friends/acceptFriendRequestApi";
import declineFriendRequestApi from "../../../api/profile/friends/declineFriendRequestApi";
import getMarkFriendSuggestionAsRemovedApi from "../../../api/profile/notifications/getMarkFriendSuggestionAsRemovedApi";
import NotificationsPreloader from "../../login/SmallComponents/notificationsPreloader";
import { withRouter } from "react-router-dom";
import getApiURL from "../../../common/getAPIUrl";
import { ErrorWrapper, SuccessWrapper } from "../../../common/alerts";
import abortFriendRequestApi from "../../../api/profile/friends/abortFriendRequestApi";
import getAvatarThumbOrOthersUrlByUserId from "../../../common/getAvatarThumbOrOthersUrlByUserId";

import {
  removeElementFromArray,
  updateFieldInElementInArray,
  getElementInArrayByfieldNameTofindBy
} from "../../stateArrayUtils";

class friendNotificationNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Message: "",
      messageType: "",
      friendRequestCount: 0,
      friendRequests: [],
      friendSuggestions: [],
      friendRequestNotificationClicked: false,
      rootURL: "",
      friendRequestNotificationsIsLoading: false,
      friendSuggestionsIsLoading: false
    };
  }
  intervalId = 0;
  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
  };
  componentDidMount = () => { };

  handleLoad = async e => {
    if (
      e.target.className !== "navFriendsIcon" &&
      e.target.className !== "greyButton" &&
      e.target.className !== "greenButton"
    ) {
      this.setState({
        friendRequestNotificationClicked: false
      });
    }
  };

  componentDidMount = async () => {
    // handle switch between the drop downs so that several cant be open at the same time
    setTimeout(() => {
      if (this.props.friendNotificationNavigationSwitch == false) {
        this.setState({
          friendRequestNotificationClicked: false
        });
      }
    }, 50);
    await this.getFriendRequestCount();
    window.addEventListener("click", this.handleLoad);
    this.intervalId = setInterval(() => {
      this.getFriendRequestCount();
    }, 10000);
  };

  UNSAFE_componentWillMount = async () => {
    await this.getNewFriendRequestNotifications();
    await this.getNewFriendSuggestions();
  };
  getNewFriendRequestNotifications = async () => {
    this.setState({ friendRequestNotificationsIsLoading: true });

    const page = 0;
    const itesPerPage = 5;

    await getFriendRequestsApi(page, itesPerPage).then(
      function (response) {
        //console.log("response : " + JSON.stringify(response));
        if (response.dataObject == null || response.dataObject == "") {
          //console.log("data object is null");
        }
        this.setState({
          friendRequests: response.dataObject
        });
      }.bind(this)
    );

    this.setState({ friendRequestNotificationsIsLoading: false });
  };

  getNewFriendSuggestions = async () => {
    this.setState({ friendSuggestionsIsLoading: true });
    const page = 0;
    const itemsPerPage = 5;

    await getFriendSuggetstionsApi(page, itemsPerPage).then(
      function (response) {
        //console.log("response friend suggestions : " + response.dataObject);
        this.setState({ friendSuggestions: response.dataObject });
      }.bind(this)
    );

    this.setState({ friendSuggestionsIsLoading: false });
  };
  markFriendSuggestionAsRemoved = async event => {
    const friendSuggestionId = event.target.getAttribute("id");

    await getMarkFriendSuggestionAsRemovedApi(friendSuggestionId).then(
      function (response) {
        //console.log("response : " + JSON.stringify(response));
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        /* this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        }); */
        /* return message, messageType; */
      }.bind(this)
    );
    this.getNewFriendSuggestions();
  };

  getFriendRequestCount = async () => {
    getFriendRequestCountApi().then(
      function (response) {
        /*  console.log(
          "Interval Get Friend Request Count" + JSON.stringify(response)
        ); */
        this.setState({ friendRequestCount: response.dataObject });
      }.bind(this)
    );
  };

  acceptFriendRequest = async event => {
    const targetUserId = event.target.getAttribute("id");

    var newfriendRequestsArray = removeElementFromArray(
      "friendRequestSourceUserId",
      targetUserId,
      this.state.friendRequests
    );

    await this.setState({
      friendRequests: newfriendRequestsArray,
      friendRequestCount: this.state.friendRequestCount - 1
    });

    //console.log("taraget user id :" + targetUserId);
    await acceptFriendRequestApi(targetUserId).then(
      function (response) {
        //console.log("response : " + JSON.stringify(response));
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        /* this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        }); */
        /* return message, messageType; */
      }.bind(this)
    );
  };

  sendFriendRequests = async event => {
    const targetUserId = event.target.getAttribute("id");

    console.log("targetUserId --->" + targetUserId);

    const updatedFriendSuggestionsArray = updateFieldInElementInArray(
      "targetUserId",
      targetUserId,
      this.state.friendSuggestions,
      "hasSentFriendRequest",
      true
    );

    this.setState({ friendSuggestions: updatedFriendSuggestionsArray });
    await sendFriendRequestsApi(targetUserId).then(
      function (response) {
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
      }.bind(this)
    );
  };

  abortSentFriendRequest = event => {
    const targetUserId = event.target.getAttribute("id");
    let updatedFriendSuggestions = [];

    abortFriendRequestApi(targetUserId).then(
      function (response) {
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        /* this.setState({ Message: response.msgWrapper.msgDescription });
        this.setState({ messageType: response.msgWrapper.msgType }); */

        if (response.msgWrapper.msgType == "success") {
          updatedFriendSuggestions = updateFieldInElementInArray(
            "targetUserId",
            targetUserId,
            this.state.friendSuggestions,
            "hasSentFriendRequest",
            false
          );

          this.setState({ friendSuggestions: updatedFriendSuggestions });
        }
        /*  return message, messageType; */
      }.bind(this)
    );
    this.getNewFriendRequestNotifications();
  };

  declineFriendRequest = async event => {
    const targetUserId = event.target.getAttribute("id");

    var newfriendRequestsArray = removeElementFromArray(
      "friendRequestSourceUserId",
      targetUserId,
      this.state.friendRequests
    );

    await this.setState({
      friendRequests: newfriendRequestsArray,
      friendRequestCount: this.state.friendRequestCount - 1
    });

    await declineFriendRequestApi(targetUserId).then(
      function (response) {
        //console.log("response : " + JSON.stringify(response));
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        if (messageType == "success") {
        }
        this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        });
        return message, messageType;
      }.bind(this)
    );
    this.getNewFriendRequestNotifications();
  };

  notificationNavigationClicked = async () => {
    //Should only load new when we are opening the nav
    if (this.state.friendRequestNotificationClicked === false) {
      this.getNewFriendRequestNotifications();
      this.getNewFriendSuggestions();
    }
    this.setState({
      friendRequestNotificationClicked: !this.state
        .friendRequestNotificationClicked
    });

    this.props.handleCloseOtherDropDowns("frequest", true);
  };

  friendNotificationNavigationLeft = async () => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    this.props.handleCloseOtherDropDowns("frequest", false);
  };

  getTargetUserProfile = event => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    const userId = event.target.id;
    //TODO check this out later why history push doesnt refresh
    //this.props.history.push("/friends-and-followers/user-profile/" + userId);
    window.location.replace("/friends-and-followers/user-profile/" + userId);

    //console.log("user id : " + userId);
  };

  showMutualFriends = event => {
    const friendSuggestionId = event.target.id;
    const friendSuggestion = getElementInArrayByfieldNameTofindBy(
      "id",
      friendSuggestionId,
      this.state.friendSuggestions
    );
    //console.log(JSON.stringify(friendSuggestion.friendsInCommon));
  };
  renderAvatar = fromUserId => {
    /* console.log(
      "fromUserId: " +
        fromUserId +
        " fromUserProfileType: " +
        fromUserProfileType
    ); */
    const avatar = (
      <div
        id={fromUserId}
        onClick={this.getTargetUserProfile}
        className="avatarsWrapperNotification"
        style={{
          backgroundImage:
            "url(" + getAvatarThumbOrOthersUrlByUserId(fromUserId, 0) + ")",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          float: "left",
          margin: "0px 0px 5px 10px"
        }}
      />
    );
    return avatar;
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
    clearTimeout();
  };
  render() {
    //console.log("request : " + this.state.friendRequests);

    const friendRequests = this.state.friendRequests.map((requests, index) => (
      <React.Fragment key={"newfriends" + index}>
        <FadeIn transitionDuration={200} delay={200}>
          <div className="notificationNavLink">
            {this.renderAvatar(requests.friendRequestSourceUserId)}
            <span
              style={{ float: "left", width: "auto", marginTop: "6px" }}
              className="linkTitle"
              id={requests.friendRequestSourceUserId}
              onClick={this.getTargetUserProfile}
            >
              {/* {requests.friendRequestSourceName} */}

              {/* {requests.friendRequestSourceName} */}

              {(requests.friendRequestSourceName + "").length > 20
                ? requests.friendRequestSourceName.substring(0, 20) + "..."
                : requests.friendRequestSourceName + ""}
            </span>
            <p
              style={{
                float: "left",
                width: "100%",
                marginLeft: "48px",
                marginTop: "-14px",
                display: "block",
                fontSize: "0.7em"
              }}
            ></p>
            <button
              style={{
                width: "55px",
                position: "absolute",
                right: "5px",
                top: "13px",
                margin: "0px 0px 0px 0px",
                padding: "5px",
                fontSize: "0.8em",
                color: "rgba(140,140,140,1)",
                zIndex: "5000"
              }}
              id={requests.friendRequestSourceUserId}
              onClick={this.declineFriendRequest}
              className="greyButton"
            >
              decline
            </button>

            <button
              style={{
                width: "60px",
                position: "absolute",
                right: "65px",
                top: "13px",
                margin: "0px 0px 0px 0px",
                padding: "5px",
                fontSize: "0.8em",
                zIndex: "5000"
              }}
              loading={this.state.loading}
              id={requests.friendRequestSourceUserId}
              onClick={this.acceptFriendRequest}
              className="greenButton"
            >
              confirm
            </button>
          </div>
        </FadeIn>
      </React.Fragment>
    ));

    const friendSuggestions = this.state.friendSuggestions.map(
      (suggestion, index) => (
        //;

        <React.Fragment key={"newfriends" + index}>
          <FadeIn transitionDuration={200} delay={200}>
            <div className="notificationNavLink">
              {this.renderAvatar(suggestion.targetUserId)}
              <span
                style={{ float: "left", width: "auto", marginTop: "6px" }}
                className="friendRequestLinkTitle"
                id={suggestion.targetUserId}
                onClick={this.getTargetUserProfile}
              >
                {/* {suggestion.friendsuggestionUserName} */}
                {(suggestion.friendsuggestionUserName + "").length > 20
                  ? suggestion.friendsuggestionUserName.substring(0, 20) + "..."
                  : suggestion.friendsuggestionUserName + ""}
              </span>
              {/* <p
                style={{
                  float: "left",
                  width: "100%",
                  marginLeft: "59px",
                  marginTop: "-20px",
                  display: "block",
                  fontSize: "0.8em",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                id={suggestion.id}
                onClick={this.showMutualFriends}
              >
                Mutual friends
                {" " + suggestion.friendsInCommonSize}
              </p> */}
              <button
                style={{
                  width: "55px",
                  position: "absolute",
                  right: "5px",
                  top: "13px",
                  margin: "0px 0px 0px 0px",
                  padding: "5px",
                  fontSize: "0.8em",
                  color: "rgba(140,140,140,1)",
                  zIndex: "5000"
                }}
                id={suggestion.id}
                onClick={this.markFriendSuggestionAsRemoved}
                className="greyButton"
              >
                remove
              </button>
              {(() => {
                const hasSentFriendRequest = suggestion.hasSentFriendRequest;
                if (hasSentFriendRequest === false)
                  return (
                    <button
                      style={{
                        width: "100px",
                        position: "absolute",
                        right: "65px",
                        top: "13px",
                        margin: "0px 0px 0px 0px",
                        padding: "5px",
                        fontSize: "0.8em",
                        zIndex: "5000"
                      }}
                      loading={this.state.loading}
                      id={suggestion.targetUserId}
                      onClick={this.sendFriendRequests}
                      className="greenButton"
                    >
                      add friend
                    </button>
                  );
                if (hasSentFriendRequest === true)
                  return (
                    <button
                      style={{
                        width: "100px",
                        position: "absolute",
                        right: "65px",
                        top: "13px",
                        margin: "0px 0px 0px 0px",
                        padding: "5px",
                        fontSize: "0.8em",
                        zIndex: "5000"
                      }}
                      loading={this.state.loading}
                      id={suggestion.targetUserId}
                      onClick={this.abortSentFriendRequest}
                      className="greenButton"
                    >
                      pending..
                    </button>
                  );
              })()}
            </div>
          </FadeIn>
        </React.Fragment>
      )
    );

    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "error": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "success":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <FadeIn transitionDuration={200} delay={200}>
          <div style={{ position: "relative" }}>
            <div
              id="notificationNav"
              className="navFriendsIcon"
              onClick={this.notificationNavigationClicked}
            />
            {(() => {
              if (this.state.friendRequestCount == 0) {
                return null;
              } else {
                return (
                  <div className="friendsNotificationsCouterContainer ">
                    <span className="notificationCounter">
                      {this.state.friendRequestCount}
                    </span>
                  </div>
                );
              }
            })()}

            <div
              id="friendsNotificationMenuContainerId"
              className={
                this.state.friendRequestNotificationClicked
                  ? "friendsNotificationMenuContainer showMenu"
                  : "friendsNotificationMenuContainer hideMenu"
              }
              style={{ marginTop: "10px" }}
              onMouseLeave={this.friendNotificationNavigationLeft}
            >
              <div
                id="friendsNotificationMenuId"
                className="friendsNotificationMenu"
              >
                <div className="settingsMenuTitle">new friend requests</div>
                {(() => {
                  if (this.state.friendRequestNotificationsIsLoading) {
                    //is loading
                    return <NotificationsPreloader />;
                  } else {
                    if (
                      this.state.friendRequests &&
                      this.state.friendRequests.length <= 0
                    ) {
                      return (
                        <div className="notificationNavLink">
                          <span className="linkTitle">
                            no new friend requests
                          </span>
                        </div>
                      );
                    } else {
                      return <>{friendRequests}</>;
                    }
                  }
                })()}
                <div className="settingsMenuTitle">people you may know</div>
                {(() => {
                  if (this.state.friendSuggestionsIsLoading) {
                    //is loading
                    return <NotificationsPreloader />;
                  } else {
                    if (
                      this.state.friendSuggestions &&
                      this.state.friendSuggestions.length <= 0
                    ) {
                      return (
                        <div className="notificationNavLink">
                          <span className="linkTitle">
                            no new friend sugestions
                          </span>
                        </div>
                      );
                    } else {
                      return <>{friendSuggestions}</>;
                    }
                  }
                })()}
                <button
                  style={{
                    margin: "0px 0px 0px 0px",
                    padding: "10px",
                    fontSize: "0.8em"
                  }}
                  className="goldButton removeBRadius"
                  onClick={() => {
                    //this.props.history.push("/friendRequestsAndSuggestions");
                    window.location.replace("/friendRequestsAndSuggestions");
                  }}
                >
                  view all
                </button>
              </div>
            </div>
          </div>
          {Alerts}
        </FadeIn>
      </React.Fragment>
    );
  }
}

export default withRouter(friendNotificationNavigation);
