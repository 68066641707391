import React, { Component } from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import { BlockPicker } from "react-color";
import {
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
  EditorState
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import getProfileAboutApi from "../../../api/profile/about/getProfileAboutApi";
import updateProfileAboutApi from "../../../api/profile/about/updateProfileAboutApi";
import { ErrorWrapper, SuccessWrapper } from "../../alerts";
//import ColorPic from "../../wiziwyg/ColorPic";

export default class AboutMeComponent extends Component {
  constructor(props) {
    super(props);

    //const contentState = convertFromRaw(content);
    //const rawContentState = convertToRaw(contentState);
    this.state = {
      about: "",
      buttonUpdating: false,
      msgWrapper: {},
      editorState: {},

      contentState: {}
      //rawContentState
    };
    // this.onChange = editorState => this.setState({ editorState });
  }

  UNSAFE_componentWillMount = async () => {
    await this.getAboutEditorStatwJsonString();
  };

  /**
   * 1.convert contentStateRawStringified to object
   * 2.convert object from raw to not raw then
   * 3. create Editor from that contentstate
   */
  getAboutEditorStatwJsonString = async () => {
    await this.setState({ editorState: EditorState.createEmpty() });
    const aboutGlobalWrapper = await getProfileAboutApi();
    const aboutContentStateJSONstringified =
      aboutGlobalWrapper.dataObject.about;
    const rawContentState = JSON.parse(aboutContentStateJSONstringified);
    const contentState = convertFromRaw(rawContentState);

    this.setState({
      editorState: EditorState.createWithContent(contentState)
    });
  };
  /**
   * 1.convert currentContenState to raw
   * 2.convert to string before sending it in to endpoint
   */
  onAboutSubmitConvertStateToJson = async () => {
    this.setState({ buttonUpdating: true });

    var jsonString = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    console.log("data : " + jsonString);
    await updateProfileAboutApi(jsonString).then(
      function(response) {
        this.setState({
          msgWrapper: response.msgWrapper,
          buttonUpdating: false
        });
      }.bind(this)
    );

    //this.getAboutEditorStatwJsonString();
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState: editorState
    });
  };

  cleanErrorSuccessMessage = () => {
    this.setState({
      msgWrapper: {}
    });
  };

  handleReturn = () => {
    console.log("HANDLE RETURN");
    return null;
  };

  render() {
    const messageType = this.state.msgWrapper.msgType;
    const { editorState } = this.state;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "ERROR": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.msgWrapper.msgDescription}
                  messageType={this.state.msgWrapper.msgType}
                />
              );
            }
            case "SUCCESS":
              return (
                <SuccessWrapper
                  Message={this.state.msgWrapper.msgDescription}
                  messageType={this.state.msgWrapper.msgType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <section className="section">
          <div className="header">
            {(() => {
              switch (this.props.profileType) {
                case "user":
                  return (
                    <React.Fragment>
                      <h2>about me</h2>
                    </React.Fragment>
                  );
                case "business":
                  return (
                    <React.Fragment>
                      <h2>about us</h2>
                    </React.Fragment>
                  );
                default:
                  return null;
              }
            })()}
          </div>
          <div className="body">
            <div style={{ margin: "10px" }}>
              {(() => {
                switch (this.props.profileType) {
                  case "user":
                    return (
                      <React.Fragment>
                        <label className="formLabel alignLeft">about me</label>
                      </React.Fragment>
                    );
                  case "business":
                    return (
                      <React.Fragment>
                        <label className="formLabel alignLeft">about us</label>
                      </React.Fragment>
                    );
                  default:
                    return null;
                }
              })()}
              <div>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  handleReturn={this.handleReturn}
                  wrapperClassName=""
                  editorClassName=""
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "colorPicker",
                      //"link",
                      //"embedded",
                      "emoji",
                      //"image",
                      "remove",
                      "history"
                    ]

                    /*  image: {
                      //component: undefined,
                      //popupClassName: "wiziwygDropDown",
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: undefined,
                      previewImage: false,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "100%"
                      }
                    } */
                    //for full customization
                    //https://jpuri.github.io/react-draft-wysiwyg/#/docs
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="row">
          <div
            style={{
              width: "150px",
              marginTop: "-20px",
              marginBottom: "-10px",
              float: "right",
              display: "block"
            }}
          >
            <button
              className="goldButton"
              onClick={this.onAboutSubmitConvertStateToJson}
            >
              {this.state.buttonUpdating ? "updating..." : "update"}
            </button>
          </div>
        </div>
        {Alerts}
      </React.Fragment>
    );
  }
}

export { AboutMeComponent };
