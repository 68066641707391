import axios from "axios";
import getApiURL from "../../../common/getAPIUrl";

import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../../common/getErrorResponseEntity";

const updateBusinessUserInfoApi = async function(businessUserInfoPayLoad) {
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/profile-service/business/updateBusinessProfileInfoWithGlobalWrapper_NEW";
      const apiName = "updateBusinessUserInfoApi";
      const serviceName = "profile-service";
      const baseUrl = rootURL + apiEndPoint;
      const token = getAccessToken();

      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };

      //console.log("Headers:" + JSON.stringify(headers));

      return await axios({
            method: "post",
            url: baseUrl,
            data: businessUserInfoPayLoad,
            headers: headers
      })
            .then(function(response) {
                  return response.data; // GlobalWrapper with "Info" as object Payload
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default updateBusinessUserInfoApi;
