import React, { Component } from "react";
import getFriendSuggestiondApi from "../../../api/profile/notifications/getFriendSuggestionByIdApi";
import getAvatarThumbOrOthersUrlByUserId from "../../../common/getAvatarThumbOrOthersUrlByUserId";
import getApiURL from "../../../common/getAPIUrl";
class commonFriendsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      friendsInCommon: []
    };
  }
  componentDidMount = () => {
    this.getFriendSuggestions();
  };

  getFriendSuggestions = async () => {
    //console.log(
    //  "friend suggestion id =========== " + this.props.friendSuggestionId
    //);
    const baseUrl = getApiURL();
    this.setState({ rootURL: baseUrl });

    this.setState({ friendRequestNotificationsIsLoading: true });

    const friendSuggestionId = this.props.friendSuggestionId;

    await getFriendSuggestiondApi(friendSuggestionId).then(
      function(response) {
        //console.log("response : " + JSON.stringify(response));
        if (response.dataObject == null || response.dataObject == "") {
          //console.log("data object is null");
        }
        this.setState({ friendsInCommon: response });
      }.bind(this)
    );
  };

  getTargetUserProfile = event => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    const userId = event.target.id;
    window.location.replace("/friends-and-followers/user-profile/" + userId);
    //TODO check this out later why history push doesnt refresh
    //this.props.history.push("/friends-and-followers/user-profile/" + userId);
    //console.log("user id : " + userId);
  };

  render() {
    const friendsInCommom = this.state.friendsInCommon.map((friend, index) => (
      <React.Fragment key={friend.commonFriendUserId}>
        <div className="notificationNavLink">
          <div
            id={friend.commontargetUserId}
            onClick={this.getTargetUserProfile}
            className="avatarsWrapperNotification"
            style={{
              backgroundImage:
                "url(" +
                getAvatarThumbOrOthersUrlByUserId(
                  friend.commontargetUserId,
                  0
                ) +
                ")",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              float: "left",
              margin: "0px 0px 5px 10px"
            }}
          />
          <span
            style={{ float: "left", width: "auto", marginTop: "6px" }}
            className="linkTitle"
            id={friend.commontargetUserId}
            onClick={this.getTargetUserProfile}
          >
            {friend.commonFriendUserName}
          </span>
        </div>
      </React.Fragment>
    ));

    return (
      <div className="popup">
        <div className="popup_inner_light">
          <div className="popup_header_light">
            <span className="popup_title_light">mutual friends</span>
            <span
              className="closeButton closeButton_light"
              onClick={this.props.toggleMutualFriendsPopup}
            >
              &times;
            </span>
          </div>
          <div style={{ padding: "0px !important" }}>{friendsInCommom}</div>
        </div>
      </div>
    );
  }
}

export default commonFriendsPopup;
