import axios from "axios";

import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const activateAccountApi = async function(activationCode) {
      const headers = {
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/account-service/activateAccount";
      const apiName = "activateAccountApi";
      const serviceName = "account-service";

      const baseUrl = rootURL + apiEndPoint;
      var params = new URLSearchParams();
      params.append("activationCode", activationCode);

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers,
            params: params
      })
            .then(function(response) {
                  // //console.log(response);

                  return response.data.msgWrapper;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default activateAccountApi;
