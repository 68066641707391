import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const resetPasswordApi = async function(newPassword, passwordResetToken) {
      const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/account-service/passwordReset";
      const apiName = "resetPasswordApi";
      const serviceName = "account-service";
      const baseUrl = rootURL + "/api/v1/account-service/passwordReset";

      var formData = new FormData();
      formData.append("passwordResetToken", passwordResetToken);
      formData.append("newPassword", newPassword);

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers,
            data: formData
      })
            .then(function(response) {
                  //console.log(response.data);

                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default resetPasswordApi;
