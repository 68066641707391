import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { getAccessToken, getRefreshToken, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const setInitialPassWordChangedToTrueApi = async () => {
      const token = getAccessToken();
      const refreshToken = getRefreshToken();

      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/profile-service/setInitialPassWordChangedToTrue";
      const apiName = "setInitialPassWordChangedToTrueApi";
      const serviceName = "profile-service";

      const baseUrl = rootURL + apiEndPoint;

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers
            /* data: passwordPayLoad */
      })
            .then(function (response) {
                  return response.data;
            })
            .catch(function (error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default setInitialPassWordChangedToTrueApi;
