import React, { Component } from "react";
import getCurrentlyLoggedInProfileApi from "../../../api/profile/getCurrentlyLoggedInProfileApi";
import ChangeAvatarImageComponent from "./ChangeAvatarImageComponent";
import FadeIn from "react-fade-in";
import checkDevice from "../../checkDevice";
class NavigationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        profileType: "",
        device: ""
      },
      openOrClosedNavigation: false
    };
  }

  componentDidMount = async () => {
    await this.getCurrentlyLoggedInProfile();
    var detectDevice = checkDevice();
    console.log("detect device : " + detectDevice);
    await this.setState({
      device: detectDevice,
      openOrClosedNavigation: false
    });
  };

  openCloseNav = async () => {
    this.setState({
      openOrClosedNavigation: !this.state.openOrClosedNavigation
    });
  };
  getCurrentlyLoggedInProfile = async () => {
    getCurrentlyLoggedInProfileApi().then(response => {
      this.setState({
        profile: {
          profileType: response.profileType
        }
      });
    });
  };

  render() {
    console.group("INFO");
    console.log("device : >>>>>>>>>>>>>>>>>" + this.state.device);
    console.log(
      "closed or open navigation " + this.state.openOrClosedNavigation
    );
    console.log("profile type " + this.state.profile.profileType);
    console.log("userId " + this.state.profile.userId);
    console.log(
      "user profile picture id: " + this.state.profile.userProfilePictureId
    );

    console.groupEnd();
    const profileType = this.state.profile.profileType;

    const BusinessNavMobile = (
      <section
        className={
          this.state.openOrClosedNavigation
            ? "searchFilters2 searchFilterSidebarOpen"
            : "searchFilters2 searchFilterSidebarClosed"
        }
      >
        <div className="settingsMenuTitle">
          Menu
          <span
            className="closeButton closeButton_light"
            style={{ marginTop: "-5px", marginRight: "-5px" }}
            onClick={this.openCloseNav}
          >
            ×
          </span>
        </div>
        <div
          className="links"
          style={{ borderBottom: "1px solid rgba(204, 204, 204, 0.5)" }}
        >
          <div className={this.props.basicProfileInfo ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="basicProfileInfo"
              className="linkTitle"
            >
              basic profile info
            </span>
          </div>

          <div className={this.props.userInterests ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="userInterests"
              className="linkTitle"
            >
              business interests
            </span>
          </div>

          <div className={this.props.aboutMe ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="aboutMe"
              className="linkTitle"
            >
              about us
            </span>
          </div>

          <div className={this.props.subscriptions ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="subscriptions"
              className="linkTitle"
            >
              subscriptions
            </span>
          </div>

          <div className={this.props.purchases ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="purchases"
              className="linkTitle"
            >
              purchases
            </span>
          </div>
        </div>
      </section>
    );
    const BusinessNav = (
      <div className="sidebarNavTabs">
        <div className={this.props.basicProfileInfo ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="basicProfileInfo"
            className="linkTitle"
          >
            basic profile info
          </span>
        </div>

        <div className={this.props.userInterests ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="userInterests"
            className="linkTitle"
          >
            business interests
          </span>
        </div>

        <div className={this.props.aboutMe ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="aboutMe"
            className="linkTitle"
          >
            about us
          </span>
        </div>

        <div className={this.props.subscriptions ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="subscriptions"
            className="linkTitle"
          >
            subscriptions
          </span>
        </div>

        <div className={this.props.purchases ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="purchases"
            className="linkTitle"
          >
            purchases
          </span>
        </div>
      </div>
    );
    const userNavMobile = (
      <section
        className={
          this.state.openOrClosedNavigation
            ? "searchFilters2 searchFilterSidebarOpen"
            : "searchFilters2 searchFilterSidebarClosed"
        }
      >
        <div className="settingsMenuTitle">
          Menu
          <span
            className="closeButton closeButton_light"
            style={{ marginTop: "-5px", marginRight: "-5px" }}
            onClick={this.openCloseNav}
          >
            ×
          </span>
        </div>

        <div
          className="links"
          style={{ borderBottom: "1px solid rgba(204, 204, 204, 0.5)" }}
        >
          <div className={this.props.basicProfileInfo ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="basicProfileInfo"
              className="linkTitle"
            >
              basic profile info
            </span>
          </div>

          <div className={this.props.userInterests ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="userInterests"
              className="linkTitle"
            >
              user interests
            </span>
          </div>

          <div className={this.props.horseInterests ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="horseInterests"
              className="linkTitle"
            >
              horse interests {this.props.horseInterests}
            </span>
          </div>

          <div className={this.props.aboutMe ? "linkActive" : "link"}>
            <span
              onMouseDown={this.openCloseNav}
              onClick={this.props.handleChange}
              id="aboutMe"
              className="linkTitle"
            >
              about me
            </span>
          </div>
        </div>
      </section>
    );

    const userNav = (
      <div className="sidebarNavTabs">
        <div className={this.props.basicProfileInfo ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="basicProfileInfo"
            className="linkTitle"
          >
            basic profile info
          </span>
        </div>

        <div className={this.props.userInterests ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="userInterests"
            className="linkTitle"
          >
            user interests
          </span>
        </div>

        <div className={this.props.horseInterests ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="horseInterests"
            className="linkTitle"
          >
            horse interests {this.props.horseInterests}
          </span>
        </div>

        <div className={this.props.aboutMe ? "linkActive" : "link"}>
          <span
            onClick={this.props.handleChange}
            id="aboutMe"
            className="linkTitle"
          >
            about me
          </span>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <FadeIn transitionDuration={200} delay={200}>
          {(() => {
            if (this.state.device === "Computer") {
              return (
                <React.Fragment>
                  {(() => {
                    switch (profileType) {
                      case "business":
                        return <React.Fragment> {BusinessNav}</React.Fragment>;
                      case "user":
                        return <React.Fragment>{userNav}</React.Fragment>;
                    }
                  })()}
                </React.Fragment>
              );
            }
            if (
              this.state.device === "Mobile" ||
              this.state.device === "iPad"
            ) {
              return (
                <React.Fragment>
                  <span
                    style={{
                      position: "fixed",
                      left: "0px",
                      top: "48px",
                      zIndex: "3000"
                    }}
                    className="dropdownIconLeft"
                    onClick={this.openCloseNav}
                  ></span>

                  {(() => {
                    switch (profileType) {
                      case "business":
                        return (
                          <React.Fragment> {BusinessNavMobile}</React.Fragment>
                        );

                      case "user":
                        return <React.Fragment>{userNavMobile}</React.Fragment>;
                    }
                  })()}
                </React.Fragment>
              );
            }
          })()}
        </FadeIn>
      </React.Fragment>
    );
  }
}

export { NavigationComponent };
