import React, { Component } from "react";
import Img from "react-fix-image-orientation";
import addProfilePictureApi from "../../../../api/gallery-service/addProfilePictureApi";

class Step2 extends Component {
      constructor(props) {
            super(props);
            this.state = { file: "", imagePreviewUrl: "" };
      }

      handleSubmit = event => {
            event.preventDefault();
      };

      handleImageChange = event => {
            event.preventDefault();
            let imgdata = event.target.files[0];

            addProfilePictureApi(imgdata).then(
                  function (response) {
                        //console.log("gallery service response: " + JSON.stringify(response));
                  }.bind(this)
            );

            let reader = new FileReader();
            let file = event.target.files[0];

            reader.onloadend = () => {
                  this.setState({
                        file: file,
                        imagePreviewUrl: reader.result
                  });
            };
            reader.readAsDataURL(file);
      };

      render() {
            let isValid = this.state.isValid ? "no-errors" : "errors";
            if (this.props.currentStep !== 2) {
                  return null;
            }
            let { imagePreviewUrl } = this.state;
            let $imagePreview = null;
            if (imagePreviewUrl) {
                  $imagePreview = (
                        <Img
                              style={{
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    width: "150px",
                                    height: "150px",
                                    backgroundImage: "url(" + imagePreviewUrl + ")"
                              }}
                        />
                  );
            }
            const profileType = this.props.profileType;
            console.log(" user prodile picture id : " + this.props.userProfilePictureId);
            return (
                  <React.Fragment>
                        {(() => {
                              switch (profileType) {
                                    case "user":
                                          return (
                                                <div
                                                      className="previewComponent"
                                                      style={{
                                                            marginTop: "20px",
                                                            marginBottom: "10px",
                                                            borderRadius: "3px",
                                                            border: "1px solid rgba(204, 204, 204, 0.5)"
                                                      }}
                                                >
                                                      <div className="formLabel alignCenter">upload your profile image</div>
                                                      {(() => {
                                                            if (
                                                                  this.props.userProfilePictureId === "" ||
                                                                  this.props.userProfilePictureId == undefined ||
                                                                  this.props.userProfilePictureId == null
                                                            ) {
                                                                  return (
                                                                        <div
                                                                              className="profileImageWrapper"
                                                                              style={{
                                                                                    backgroundImage:
                                                                                          "url(" +
                                                                                          process.env.API_URL +
                                                                                          "/api/v1/gallery-service/images/profilepictures/" +
                                                                                          this.props.userId +
                                                                                          "/1/xxx.jpg)",
                                                                                    backgroundPosition: "center center",
                                                                                    backgroundSize: "cover"
                                                                              }}
                                                                        >
                                                                              {" "}
                                                                              {$imagePreview}
                                                                        </div>
                                                                  );
                                                            } else {
                                                                  return (
                                                                        <div
                                                                              className="profileImageWrapper"
                                                                              style={{
                                                                                    backgroundImage:
                                                                                          "url(" +
                                                                                          process.env.API_URL +
                                                                                          "/api/v1/gallery-service/images/profilepictures/" +
                                                                                          this.props.userId +
                                                                                          "/" +
                                                                                          this.props.userProfilePictureId +
                                                                                          ")",
                                                                                    backgroundPosition: "center center",
                                                                                    backgroundSize: "cover"
                                                                              }}
                                                                        >
                                                                              {" "}
                                                                              {$imagePreview}
                                                                        </div>
                                                                  );
                                                            }
                                                      })()}

                                                      <label className="imageUploadBtn goldButton">
                                                            upload your profile image
                                                            <input className="fileInput" type="file" onChange={this.handleImageChange} />
                                                      </label>
                                                </div>
                                          );
                                    case "business":
                                          return (
                                                <React.Fragment>
                                                      {/* <label className="formLabel alignLeft">
                    * Contact first name
                  </label>
                  <input
                    type="text"
                    className="textinput"
                    name="contactFirstName"
                    placeholder="contact first name"
                    value={this.props.contactFirstName}
                    onChange={this.props.handleChange}
                  />
                  <span className={isValid}>{this.props.firstNameError}</span>

                  <label className="formLabel alignLeft">
                    * Contact last name
                  </label>
                  <input
                    type="text"
                    className="textinput"
                    name="contactLastName"
                    placeholder="contact last name"
                    value={this.props.contactLastName}
                    onChange={this.props.handleChange}
                  />
                  <span className={isValid}>{this.props.lastNameError}</span> */}

                                                      <label className="formLabel alignLeft">* Phone number</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="phoneNumber"
                                                            placeholder="phone number"
                                                            value={this.props.phoneNumber}
                                                            onChange={this.props.handleChange}
                                                      />
                                                      <span className={isValid}>{this.props.phoneNumberError}</span>

                                                      <label className="formLabel alignLeft">* Vat number</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="vatNumber"
                                                            placeholder="vat number"
                                                            value={this.props.vatNumber}
                                                            onChange={this.props.handleChange}
                                                      />
                                                      <span className={isValid}>{this.props.vatNumberError}</span>

                                                      <div
                                                            style={{
                                                                  marginTop: "20px",
                                                                  marginBottom: "10px",
                                                                  borderRadius: "3px",
                                                                  border: "1px solid rgba(204, 204, 204, 0.5)"
                                                            }}
                                                            className="previewComponent"
                                                      >
                                                            <div className="formLabel alignCenter">upload your company logo</div>
                                                            <div
                                                                  className="profileImageWrapper"
                                                                  style={{
                                                                        backgroundImage:
                                                                              "url(" +
                                                                              process.env.API_URL +
                                                                              "/api/v1/gallery-service/images/profilepictures/" +
                                                                              this.props.userId +
                                                                              "/" +
                                                                              this.props.userProfilePictureId +
                                                                              ")",
                                                                        backgroundPosition: "center center",
                                                                        backgroundSize: "cover"
                                                                  }}
                                                            >
                                                                  {" "}
                                                                  {$imagePreview}
                                                            </div>
                                                            <label className="imageUploadBtn goldButton">
                                                                  upload your company logo
                                                                  <input className="fileInput" type="file" onChange={this.handleImageChange} />
                                                            </label>
                                                      </div>
                                                </React.Fragment>
                                          );

                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );
      }
}

export { Step2 };
