import React, { Component } from "react";
import PropTypes from "prop-types";
import Footer from "../../common/login/Footer";
import Stepper from "../../../components/common/login/Stepper/Steps";

class ProfileCreation extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  title: "Complete Profile Info",
                  profileCompleted: false
            };
      }
      UNSAFE_componentWillMount() {
            const changeTitle = this.props.changeTitle;
            changeTitle(this.state.title);
            const isLoggedIn = this.props.showLogoutButton;
            //console.log("is logged in: " + isLoggedIn);
            // await this.checkProfileIsCompleted();
      }

      render() {
            // console.log(" profile completed : " + this.state.profileCompleted);
            return (
                  <React.Fragment>
                        <Stepper />
                        <Footer />
                  </React.Fragment>
            );
      }
}
ProfileCreation.propTypes = {
      title: PropTypes.string.isRequired,
      changeTitle: PropTypes.func.isRequired
};

export default ProfileCreation;
