////console.log("get api url");
import $ from "jquery";
/*
 * the config file for getting the right url api
 *
 *
 */

const getAPIUrl = () => {
      var link = "";
      const baseUrl = $(location).attr("hostname");

      const proto = location.protocol;
      const urlWithPort = location.origin;
      // const port = location.port;
      // console.log("url with port  :  " + urlWithPort);
      if (baseUrl == "www.horsemetro.com") {
            link = proto + "//" + baseUrl + "/social-platform";
      }
      if (baseUrl == "horsemetro.com") {
            link = proto + "//" + baseUrl + "/social-platform";
      }

      if (baseUrl == "pre-stage.horsemetro.com") {
            link = proto + "//" + baseUrl + "/social-platform";
      }
      if (baseUrl == "stage.horsemetro.com") {
            link = proto + "//" + baseUrl + "/social-platform";
      }
      if (baseUrl == "01.devgarden.se") {
            link = proto + "//" + baseUrl + ":24010";
      }

      if (urlWithPort == "http://localhost:8080") {
            link = "http://01i.dg:24010";
      }

      if (baseUrl == "localhost") {
            link = "http://01i.dg:24010";
      }

      if (urlWithPort == "http://01i.dg:24150") {
            link = proto + "//01i.dg:24010";
      }
      if (urlWithPort == "https://01i.dg:24152") {
            link = proto + "//01i.dg:24011";
      }
      if (urlWithPort == "http://01i.dg:24100") {
            link = proto + "//01i.dg:24010";
      }
      if (urlWithPort == "https://01i.dg:24101") {
            link = proto + "//01i.dg:24011";
      }
      return link;
};

export default getAPIUrl;
