import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const getAllowedCountryLanguagesApi = async function() {
      const headers = {
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/localization-service/country/getAllowedCountryLanguages";
      const apiName = "getAllowedCountryLanguagesApi";
      const serviceName = "localization-service";

      const baseUrl = rootURL + "/api/v1/localization-service/country/getAllowedCountryLanguages";

      return await axios({
            method: "get",
            url: baseUrl,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default getAllowedCountryLanguagesApi;
