import React, { Component } from "react";
import logout from "../../../../api/login-logout/logoutApi";
class Logout extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  showPopup: false
            };
      }

      showHidePopup = () => {
            console.log("popup");
            this.setState({ showPopup: !this.state.showPopup });
      };

      handleOnLogOutButtonClick = event => {
            event.preventDefault();
            this.props.storeTempData(this.props.currentStep);
            setTimeout(() => {
                  logout().then(
                        function(response) {
                              // This is handled in cookieAndAccessHandling.js
                              //console.log(JSON.stringify(response));
                              //this.props.history.push("/");
                        }.bind(this)
                  );
            }, 200);
      };

      render() {
            console.log("showPopup: " + this.state.showPopup);

            const modal = (
                  <React.Fragment>
                        {(() => {
                              if (this.state.showModal) {
                                    return (
                                          <div className="modal display-block">
                                                <section className="modal-main">
                                                      <p
                                                            style={{
                                                                  textAlign: "center",
                                                                  marginLeft: "auto",
                                                                  marginRight: "auto",
                                                                  fontSize: "160%"
                                                            }}
                                                      >
                                                            Confirm
                                                      </p>
                                                      <p
                                                            style={{
                                                                  align: "center",
                                                                  width: "300px",
                                                                  margin: "10px auto"
                                                            }}
                                                      >
                                                            {this.state.modalText}{" "}
                                                      </p>

                                                      <div
                                                            style={{
                                                                  textAlign: "center",
                                                                  marginLeft: "auto",
                                                                  marginRight: "auto"
                                                            }}
                                                      >
                                                            <button
                                                                  className="goldButton"
                                                                  style={{
                                                                        width: "150px",
                                                                        margin: "auto",
                                                                        margin: "20px 20px",
                                                                        display: "inline-block"
                                                                  }}
                                                                  onClick={this.hideModal}
                                                            >
                                                                  cancel
                                                            </button>
                                                            {(() => {
                                                                  if (this.state.showAcceptButton) {
                                                                        //if (true) {
                                                                        return (
                                                                              <button
                                                                                    className="goldButton"
                                                                                    style={{
                                                                                          width: "150px",
                                                                                          margin: "auto",
                                                                                          display: "inline-block"
                                                                                    }}
                                                                                    onClick={this.handleSwitchChange}
                                                                              >
                                                                                    accept
                                                                              </button>
                                                                        );
                                                                  }
                                                            })()}
                                                      </div>
                                                </section>
                                          </div>
                                    );
                              } else {
                                    return null;
                              }
                        })()}
                  </React.Fragment>
            );
            return (
                  <>
                        <span
                              onClick={this.showHidePopup}
                              className="goldButton logoutBtn"
                              style={{ marginTop: "12px", padding: "3.5px" }}
                              type="submit"
                        >
                              <span className="profileCreationLogoutIcon" />
                        </span>

                        {(() => {
                              if (this.state.showPopup == true) {
                                    return (
                                          <div className="popup">
                                                <div className="popup_inner_light">
                                                      <div className="popup_header_light">
                                                            <span className="popup_title_light">Logout</span>
                                                      </div>

                                                      <div className="popup_body" style={{ overflow: "hidden", height: "111px" }}>
                                                            <span className="description" style={{ color: "rgba(160,160,160,1)" }}>
                                                                  Are you sure you want to log out? <br />
                                                                  Only the required fields will be saved.
                                                            </span>

                                                            <div>
                                                                  <button
                                                                        onClick={this.handleOnLogOutButtonClick}
                                                                        style={{ width: "100px", float: "left" }}
                                                                        className="goldButton"
                                                                        type="submit"
                                                                  >
                                                                        Logout
                                                                  </button>
                                                                  <button
                                                                        onClick={this.showHidePopup}
                                                                        style={{ width: "100px", float: "right" }}
                                                                        className="goldButton"
                                                                        type="submit"
                                                                  >
                                                                        cancel
                                                                  </button>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    );
                              }
                        })()}
                  </>
            );
      }
}

export { Logout };
