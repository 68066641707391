import React, { Component } from "react";
import { Link } from "react-router-dom";
class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLocation: ""
    };
  }
  componentDidMount = () => {
    var currentLocation = window.location.pathname;
    this.setState({ currentLocation: currentLocation });
  };
  render() {
    //console.log("current location : " + this.state.currentLocation);
    return (
      <div className="footer transparentBg">
        <div
          style={{
            padding: "10px",
            paddingBottom: "15px",
            zIndex: "10000"
          }}
          className="footer transparentBg"
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              style={{
                width: "390px",
                overflow: "hidden",
                height: "40px",
                margin: "auto",
                textAlign: "center"
              }}
            >
              {(() => {
                if (this.state.currentLocation === "/about-horsemetro") {
                  return (
                    <Link to="about-horsemetro" className="mainNavLinks ">
                      <span className="aboutUsIconFooter aboutUsIconFooterActive"></span>
                      <span className="navigationText mainNavLinksActive">
                        about
                      </span>
                    </Link>
                  );
                } else if (this.state.currentLocation === "/create-profile") {
                  return null;
                } else {
                  return (
                    <Link to="about-horsemetro" className="mainNavLinks">
                      <span className="aboutUsIconFooter"></span>
                      <span className="navigationText">about</span>
                    </Link>
                  );
                }
              })()}

              {(() => {
                if (this.state.currentLocation === "/business") {
                  return (
                    <Link to="/business" className="mainNavLinks">
                      <span className="companiesIcon companiesIconActive "></span>
                      <span className="navigationText mainNavLinksActive">
                        business
                      </span>
                    </Link>
                  );
                } else if (this.state.currentLocation === "/create-profile") {
                  return null;
                } else {
                  return (
                    <Link to="/business" className="mainNavLinks">
                      <span className="companiesIcon"></span>
                      <span className="navigationText">business</span>
                    </Link>
                  );
                }
              })()}

              {(() => {
                if (this.state.currentLocation === "/pricing") {
                  return (
                    <Link to="/pricing" className="mainNavLinks">
                      <span className="pricingIconFooter pricingIconFooterActive"></span>
                      <span className="navigationText mainNavLinksActive">
                        pricing
                      </span>
                    </Link>
                  );
                } else if (this.state.currentLocation === "/create-profile") {
                  return null;
                } else {
                  return (
                    <Link to="/pricing" className="mainNavLinks">
                      <span className="pricingIconFooter"></span>
                      <span className="navigationText">pricing</span>
                    </Link>
                  );
                }
              })()}

              {(() => {
                if (
                  this.state.currentLocation === "/terms-of-service" ||
                  this.state.currentLocation === "/privacy-policy" ||
                  this.state.currentLocation === "/bazaar-user-terms" ||
                  this.state.currentLocation === "/user-terms" ||
                  this.state.currentLocation === "/cookie-policy"
                ) {
                  return (
                    <Link to="/terms-of-service" className="mainNavLinks">
                      <span className="termsIconFooter termsIconFooterActive"></span>
                      <span className="navigationText mainNavLinksActive">
                        terms
                      </span>
                    </Link>
                  );
                } else if (this.state.currentLocation === "/create-profile") {
                  return null;
                } else {
                  return (
                    <Link to="/terms-of-service" className="mainNavLinks">
                      <span className="termsIconFooter"></span>
                      <span className="navigationText">terms</span>
                    </Link>
                  );
                }
              })()}

              {(() => {
                if (this.state.currentLocation === "/help") {
                  return (
                    <Link to="/help" className="mainNavLinks">
                      <span className="helpIconFooter helpIconFooterActive"></span>
                      <span className="navigationText mainNavLinksActive">
                        help
                      </span>
                    </Link>
                  );
                } else if (this.state.currentLocation === "/create-profile") {
                  return null;
                } else {
                  return (
                    <Link to="/help" className="mainNavLinks">
                      <span className="helpIconFooter"></span>
                      <span className="navigationText">help</span>
                    </Link>
                  );
                }
              })()}
            </div>
          </div>

          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              paddingBottom: "0",
              width: "100%",
              float: "left"
            }}
          >
            <p style={{ margin: "0px", color: "rgba(255, 255, 255, 1)" }}>
              Copyright {"\u00A9"} {new Date().getFullYear()} HorseMetro. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
