// Author: Oscar Persson

import axios from "axios";
import getApiURL from "../common/getAPIUrl";
import { getAccessToken, _responseErrorInterceptor } from "../common/cookieAndAccessHandling";
import { getCurrentlyLoggedInProfileFunc } from "../common/getCurrentlyLoggedInProfile";
import { getErrorResponseEntity } from "../common/getErrorResponseEntity";

const bugReportApi = async function(message) {
      const token = getAccessToken();
      const profile = await getCurrentlyLoggedInProfileFunc();
      const name =
            "Company: " +
            profile.companyName +
            "\nName: " +
            profile.firstName +
            " " +
            profile.lastName +
            "\nDisplayName: " +
            profile.displayName +
            "\nUserID: " +
            profile.userId;
      const email = profile.email;

      // let messageType = null;

      const rootURL = getApiURL();
      const apiEndPoint = "/api/v1/notification-service/sendBugReportEmail";
      const apiName = "bugReportApi";
      const serviceName = "notification-service";

      const baseUrl = rootURL + apiEndPoint;

      const headers = {
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
      };

      return await axios({
            method: "POST",
            url: baseUrl,
            headers: headers,
            data: {
                  name: name,
                  email: email,
                  message: message
            }
      })
            .then(function(response) {
                  // console.log(response);
                  return response.data;
            })
            .catch(function(error) {
                  // console.log(error);
                  if (error.response) {
                        const errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default bugReportApi;
