import axios from "axios";
import getApiURL from "../../../common/getAPIUrl";

import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../../common/getErrorResponseEntity";

const getFriendRequestsApi = async function(page, itemsPerPage) {
      const token = getAccessToken();
      const refreshToken = getRefreshToken();
      // //console.log("apin full token : " + token);
      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };

      var params = new URLSearchParams();
      params.append("page", page);
      params.append("itemsPerPage", itemsPerPage);

      var rootUrl = getApiURL();
      const apiEndPoint = "/api/v1/profile-service/getFriendRequests_NEW";
      const apiName = "getFriendRequestsApi";
      const serviceName = "profile-service";
      const baseUrl = rootUrl + apiEndPoint;

      return await axios({
            method: "get",
            url: baseUrl,
            params: params,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default getFriendRequestsApi;
