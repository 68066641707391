import React from "react";
import getApiURL from "./getAPIUrl";

// Backend is constructed as following
// "/images/profilepictures/{username:.+}/{scale:.+}/{id:.+}"
/**
 * _Backend whants this -> "/images/profilepictures/{username:.+}/{scale:.+}/{id:.+}"
 * @param {*} userId - The userid we want to fetch the profile image for
 * @param {*} scale
 */
const getAvatarThumbOrOthersUrlByUserId = (userId, scale) => {
  const baseUrl = getApiURL();
  //Add random uuid for no caching
  const uuidv4 = require("uuid/v4");
  var url =
    baseUrl +
    "/api/v1/gallery-service/images/profilepictures/" +
    userId +
    "/" +
    scale +
    "/" +
    uuidv4();
  return url;
};
export default getAvatarThumbOrOthersUrlByUserId;
