import axios from "axios";
import getApiURL from "../../../common/getAPIUrl";
import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../../common/cookieAndAccessHandling";

import { getErrorResponseEntity } from "../../../common/getErrorResponseEntity";

const getProfileAboutApi = async function() {
      const token = getAccessToken();

      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };

      var rootUrl = getApiURL();
      const apiEndPoint = "/api/v1/profile-service/getprofileAbout";
      const apiName = "getProfileAboutApi";
      const serviceName = "profile-service";
      const baseUrl = rootUrl + apiEndPoint;

      return await axios({
            method: "get",
            url: baseUrl,
            //data: data,
            headers: headers
      })
            .then(function(response) {
                  return response.data; // returns GlobalWrapper
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default getProfileAboutApi;
