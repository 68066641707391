import React, { Component } from "react";

class HorseIntrestsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {      
                 
      checkboxVals: {
        trainAndCompete: false,
        trainNotCompete: false,
        rideToRelax: false,
        otherIntrests: false,
        trainerInstructor: false,
        ownHorse: false,
        trainWithOtherHorses: false,
        coRider: false,
        ridingSchool: false,
        casualHorseRider: false
      }
    };
  }

  handleCheckChange = async event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("check changed : " + name);
    //console.log("check changed : " + value);
    //this.setState({ checkboxVals: name, value });

    await this.setState(prevState => ({
      checkboxVals: {
        // object that we want to update
        ...prevState.checkboxVals, // keep all other key-value pairs
        [target.name]: value // update the value of specific key
      }
    }));
    //send all checkboxVals to parent
    this.props.handleHorseInterestCheckBoxVals(this.state.checkboxVals);
  };

  handleRadioChange = async event => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    //console.log("check changed : " + name);
    //console.log("check changed : " + value);
    //this.setState({ checkboxVals: name, value });
    
    switch(name){

      case 'trainAndCompete': 
        await this.setState(prevState => ({
          checkboxVals: {

            ...prevState.checkboxVals,  // keep all other key-value pairs

            // object elements that we want to update
            trainAndCompete: true,
            trainNotCompete: false,
            rideToRelax: false,
            otherIntrests: false,
            [target.name]: value // update the value of specific key
          }      
        }));
        //send all checkboxVals to parent
        console.log("dfdsdf => " + JSON.stringify(this.state.checkboxVals));
        this.props.handleHorseInterestCheckBoxVals(this.state.checkboxVals);
      break;

      case 'trainNotCompete': 
      await this.setState(prevState => ({
        checkboxVals: {

          ...prevState.checkboxVals,  // keep all other key-value pairs

          // object elements that we want to update
          trainAndCompete: false,
          trainNotCompete: true,
          rideToRelax: false,
          otherIntrests: false,
          [target.name]: value // update the value of specific key
        }      
      }));
      //send all checkboxVals to parent
      console.log("dfdsdf => " + JSON.stringify(this.state.checkboxVals));
      this.props.handleHorseInterestCheckBoxVals(this.state.checkboxVals);
      break;

      case 'rideToRelax': 
      await this.setState(prevState => ({
        checkboxVals: {

          ...prevState.checkboxVals,  // keep all other key-value pairs

          // object elements that we want to update
          trainAndCompete: false,
          trainNotCompete: false,
          rideToRelax: true,
          otherIntrests: false,
          [target.name]: value // update the value of specific key
        }      
      }));
      //send all checkboxVals to parent
      console.log("dfdsdf => " + JSON.stringify(this.state.checkboxVals));
      this.props.handleHorseInterestCheckBoxVals(this.state.checkboxVals);
      break;

      case 'otherIntrests': 
      await this.setState(prevState => ({
        checkboxVals: {

          ...prevState.checkboxVals,  // keep all other key-value pairs

          // object that we want to update          
          trainAndCompete: false,
          trainNotCompete: false,
          rideToRelax: false,
          otherIntrests: true,
          [target.name]: value // update the value of specific key
        }      
      }));
      //send all checkboxVals to parent
      console.log("dfdsdf => " + JSON.stringify(this.state.checkboxVals));
      this.props.handleHorseInterestCheckBoxVals(this.state.checkboxVals);
      break;
    }
  };
 
  /*  submitsubmitData = () => {
    //console.log("submit data");
  }; */

  UNSAFE_componentWillMount = async () => {
    await this.setState({ checkboxVals: this.props.horseInterestCheckBoxVals });
    //console.group("this.props.horseInterestCheckBoxVals");
    //console.log(this.props.horseInterestCheckBoxVals);
    //console.groupEnd();
  };

  render() {
   
    const horseIntrests = (
      <section className="section">
        <div className="header">
          <h2>Horse interests</h2>
        </div>
        <div className="body" style={{ display: "block", overflow: "hidden" }}>
          <form style={{ margin: "10px" }}>
            <label className="formLabel alignLeft">
              choose what best describes you and your horse's interests.
            </label>
          
            <label className="checkLabel">
              <input
                type="radio"
                name="trainAndCompete"
                value={this.state.checkboxVals.trainAndCompete}
                onChange={this.handleRadioChange}                                
                checked={this.state.checkboxVals.trainAndCompete}
              />
              I train and compete.
            </label>

            <label className="checkLabel">
              <input
                type="radio"
                name="trainNotCompete"
                value= {this.state.checkboxVals.trainNotCompete}
                onChange={this.handleRadioChange}                
                checked={this.state.checkboxVals.trainNotCompete}
              />
              I train but do not compete.
            </label>

            <label className="checkLabel">
              <input
                type="radio"
                name="rideToRelax"
                value={this.state.checkboxVals.rideToRelax}
                onChange={this.handleRadioChange}                
                checked={this.state.checkboxVals.rideToRelax}
              />
              I only ride to relax.
            </label>

            <label className="checkLabel" style={{ marginBottom: "20px" }}>
              <input
                type="radio"
                name="otherIntrests"
                value={this.state.checkboxVals.otherIntrests}
                onChange={this.handleRadioChange}                
                checked={this.state.checkboxVals.otherIntrests}
              />
              I do not ride / have other intrest in Horses.
            </label>

            <label className="checkLabel" style={{ marginBottom: "20px" }}>
              <input
                type="checkbox"
                name="trainerInstructor"
                onChange={this.handleCheckChange}
                value={this.state.checkboxVals.trainerInstructor}
                checked={this.state.checkboxVals.trainerInstructor}
              />
              {"I'm a trainer / instructor."}
            </label>

            <label className="checkLabel">
              <input
                type="checkbox"
                name="ownHorse"
                onChange={this.handleCheckChange}
                value={this.state.checkboxVals.ownHorse}
                checked={this.state.checkboxVals.ownHorse}
              />
              {"I have my own horse."}
            </label>

            <label className="checkLabel">
              <input
                type="checkbox"
                name="trainWithOtherHorses"
                onChange={this.handleCheckChange}
                value={this.state.checkboxVals.trainWithOtherHorses}
                checked={this.state.checkboxVals.trainWithOtherHorses}
              />
              {"I train / compete with other horses."}
            </label>

            <label className="checkLabel">
              <input
                type="checkbox"
                name="coRider"
                onChange={this.handleCheckChange}
                value={this.state.checkboxVals.coRider}
                checked={this.state.checkboxVals.coRider}
              />
              {"I'm a fellowrider / co-rider."}
            </label>

            <label className="checkLabel">
              <input
                type="checkbox"
                name="ridingSchool"
                onChange={this.handleCheckChange}
                value={this.state.checkboxVals.ridingSchool}
                checked={this.state.checkboxVals.ridingSchool}
              />
              {"I ride horses at a riding school."}
            </label>

            <label className="checkLabel" style={{ marginBottom: "10px" }}>
              <input
                type="checkbox"
                name="casualHorseRider"
                onChange={this.handleCheckChange}
                value={this.state.checkboxVals.casualHorseRider}
                checked={this.state.checkboxVals.casualHorseRider}
              />
              {"I ride horses from time to time."}
            </label>
          </form>
        </div>
      </section>
    );

    const SubmitButton = (
      <div className="row">
        <div
          style={{
            width: "150px",
            marginTop: "-20px",
            marginBottom: "-10px",
            float: "right",
            display: "block"
          }}
        >
          <button className="goldButton" onClick={this.submitData}>
            update
          </button>
        </div>
      </div>
    );
    const profileType = this.props.profileType;
    return (
      <React.Fragment>
        {(() => {
          switch (profileType) {
            case "user":
              return <React.Fragment>{horseIntrests}</React.Fragment>;
            case "business":
              return null;
          }
        })()}
      </React.Fragment>
    );
  }
}

export { HorseIntrestsComponent };
