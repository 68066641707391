import React, { Component } from "react";
import loadable from "react-loadable";
import StaticPreLoader from "../../common/login/SmallComponents/staticPreloader";
// contact route component
const LoadingComponent = () => {
  return <StaticPreLoader />;
  //return <>loadin</>;
};

const ComponentPromise = () => {
  return import("../../pages/friends-and-followers/BusinessUserProfile");
};
const AsyncBusinessUserProfile = loadable({
  loader: ComponentPromise,
  loading: LoadingComponent
});
export default AsyncBusinessUserProfile;
