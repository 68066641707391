import React, { Component } from "react";
import PropTypes from "prop-types";
import Footer from "../../../common/login/Footer";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import RegisterButton from "../../../common/login/SmallComponents/RegisterButton";
import CreateNewUserAccountPopup from "../../../common/login/CreateNewUserAccountPopup";
import RegisterOptionsPopup from "../../../common/login/RegisterOptionsPopup";
class privacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "privacy policy",
      showRegisterOptions: false,
      showCreateNewUserAccountPopup: false
    };
    this.toggleShowCreateUserAccountPopup = this.toggleShowCreateUserAccountPopup.bind(
      this
    );
  }
  toggleShowRegisterOptionsLinkPopup = () => {
    this.setState({
      showRegisterOptions: !this.state.showRegisterOptions
    });
    //console.log("hidePassword : " + this.state.showRegisterOptions);
  };

  toggleShowCreateUserAccountPopup = event => {
    console.log("event.target.id: " + event.target.value);
    this.setState({
      accountType: event.currentTarget.getAttribute("id")
    });

    this.setState({
      showCreateNewUserAccountPopup: !this.state.showCreateNewUserAccountPopup,
      showRegisterOptions: false
    });

    //console.log(
    //  "showCreateNewUserAccountPopup : " +
    //    this.state.showCreateNewUserAccountPopup
    //);
  };
  UNSAFE_componentWillMount() {
    const changeTitle = this.props.changeTitle;
    changeTitle(this.state.title);
  }
  render() {
    return (
      <>
        <RegisterButton
          style={{
            top: "12px",
            width: "69px",
            paddingLeft: "10px",
            right: "100px",
            left: "52.7%"
          }}
          showActivationLinkPopup={this.toggleShowRegisterOptionsLinkPopup.bind(
            this
          )}
        />
        {this.state.showRegisterOptions ? (
          <RegisterOptionsPopup
            closeRegisterOptionsPopup={this.toggleShowRegisterOptionsLinkPopup}
            closeShowCreateUserAccountPopup={
              this.toggleShowCreateUserAccountPopup
            }
          />
        ) : null}
        {this.state.showCreateNewUserAccountPopup ? (
          <CreateNewUserAccountPopup
            accountType={this.state.accountType}
            closeCreateNewUserAccountPopup={
              this.toggleShowCreateUserAccountPopup
            }
          />
        ) : null}
        <div>
          <FadeIn transitionDuration={200} delay={200}>
            <div className="container textBackground">
              <div className="row">
                <div className="col-12">
                  <p>
                    <a href="/terms-of-service">terms of use</a> &#8827; privacy
                    policy
                  </p>
                </div>
              </div>
              <div className="row sections">
                <div className="col-12">
                  <p>
                    At HorseMetro, we collect and use some of Your personal
                    data. In this policy we explain how and why we collect,
                    store, process and share Your personal data. In this policy
                    we also inform You of Your rights in relation to the
                    personal data which is collected or given to Us from You.
                    <br />
                    <br />
                    For more information on which cookies we use, please see our{" "}
                    <Link to="/cookie-policy">Cookie Policy</Link> For more.
                    <br />
                    <br />
                    information about the Services we provide, please see our{" "}
                    <Link to="/user-terms">HorseMetro User Terms</Link>
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-5">
                  <div className="iconContainer violetColor">
                    <span>1</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"About the Privacy Policy"}</h3>
                  </div>
                  <p>
                    This Privacy Policy applies to HorseMetro and the services
                    offered therewith.
                    <br />
                    <br />
                    Our use of personal data is regulated by the Regulation (EU)
                    2016/679 of the European Parliament and of the Council of 27
                    April 2016 on the protection of natural persons with regard
                    to the processing of personal data and on the free movement
                    of such data, and repealing Directive 95/46/EC, also known
                    as the General Data Protection Regulation (hereinafter
                    referred to as “GDPR”).
                    <br />
                    <br />
                    In this Privacy Policy, the terminology shall have the same
                    meaning as in article 4 of GDPR.
                  </p>
                </div>

                <div className="col-1">
                  <div className="borderRight" />
                </div>
                <div className="col-5">
                  <div className="iconContainer violetColor">
                    <span>2</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Our Use of Your Personal Data"}</h3>
                  </div>
                  <p>
                    HorseMetro is provided by Devgarden AB, Swedish org. no.
                    559125-1441. HorseMetro is the “data controller” of any
                    personal data HorseMetro may collect, process and hold about
                    You, unless we inform You otherwise
                    <br />
                    <br />
                    In order to provide the HorseMetro-services, we collect, use
                    and share Your personal data. Some of our use can be
                    regulated by you in Your chosen privacy settings.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-5">
                  <div className="iconContainer violetColor">
                    <span>2.1</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"What Personal Data Do We Process?"}</h3>
                  </div>
                  <p>
                    We will process personal data about You and, if applicable,
                    Your physical representatives such as:
                  </p>
                  <ul className="list">
                    <li>
                      personal name, display name (user name), birth date,
                      photo, address, postal code, city, email address,
                      interests, geographic location, technical data and
                      metadata relating to photos, video, sound, and any other
                      personal data that You or the physical representative
                      provide Us with;
                    </li>
                    <li>
                      payments of HorseMetro subscription or other services at
                      HorseMetro (history: date, customer, transaction-id,
                      description, status);
                    </li>
                    <li>privacy settings;</li>
                    <li>purchases and sales made at Bazaar;</li>
                    <li>
                      all content You create at or uploaded to HorseMetro.com,
                      information of friends, share’s, like’s, follower’s and
                      wish-lists;
                    </li>
                    <li>
                      data sent by Your device: device type, IP-address,
                      browser, date/time and cookie data.
                    </li>
                  </ul>
                  <p>
                    If You do not set any display name when You create Your
                    account, other users will see Your full name instead. You
                    can always change it later in the profile settings.
                  </p>
                </div>

                <div className="col-1">
                  <div className="borderRight" />
                </div>
                <div className="col-5">
                  <div className="iconContainer violetColor">
                    <span>2.2</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Why and for How Long Do We Process Data?"}</h3>
                  </div>
                  <p>We process personal data for the following purposes:</p>
                  <ul className="list">
                    <li>
                      to administrate Your account, HorseMetro and the Services
                      and to otherwise fulfil the Agreement with You;
                    </li>
                    <li>
                      to personalize and improve Your experience with the
                      Services and to send You alerts or messages by e-mail or
                      otherwise, including promotional alerts and messages;
                    </li>
                    <li>
                      to inform You via e-mail or otherwise about updates of the
                      Services, including updates of the Agreement;
                    </li>
                    <li>development and research to improve the Services;</li>
                    <li>
                      to monitor and prevent use of the Services in breach of
                      the Agreement;
                    </li>
                    <li>to fulfil requirements by law; and</li>
                    <li>
                      to provide You with marketing of products and Services.
                    </li>
                  </ul>
                  <p>
                    We will keep Your personal data for as long as required by
                    law or, if applicable, for the period of time we need it for
                    the purpose it is being processed for. After that time has
                    passed, Your personal data will be deleted or anonymized.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-5">
                  <div className="iconContainer violetColor">
                    <span>2.3</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Legal Grounds for Processing Personal Data"}</h3>
                  </div>
                  <p>
                    Our processing is lawful due to the following legal grounds,
                    which are each applicable either on its own, or together
                    with other grounds:
                  </p>
                  <ul className="list">
                    <li>
                      When processing is necessary for ours and Your performance
                      of the agreement;
                    </li>
                    <li>For compliance with our legal obligations;</li>
                    <li>
                      When found necessary in order to protect the vital
                      interests of You or another natural person;
                    </li>
                    <li>
                      When found necessary for the purpose of our or a
                      third-party legitimate interest; or
                    </li>
                    <li>
                      Your given consent to our processing for the
                      above-mentioned purposes (section 2.2), where the Provider
                      does not rely on another legal ground.
                    </li>
                  </ul>
                </div>
                <div className="col-1">
                  <div className="borderRight" />
                </div>
                <div className="col-5">
                  <div className="iconContainer violetColor">
                    <span>2.4</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"With Who Do We Share Your Personal Data?"}</h3>
                  </div>
                  <p>
                    As a business operating globally, we share Your personal
                    data internally and with selected third parties, for example
                    service providers, sub-contractors and other third parties
                    as well as in case of business transfers or legal
                    disclosure.
                    <br />
                    <br />
                    We may need to transfer Your personal data to countries
                    outside the European Economic Area (EEA). The EEA consists
                    of countries in the European Union, Switzerland, Iceland,
                    Liechtenstein and Norway: they are considered to have
                    equivalent laws when it comes to data protection and
                    privacy. This kind of data transfer may happen if our
                    servers (i.e. where we store data) or our suppliers and
                    service providers are based outside the EEA, or if You use
                    our services and products while visiting countries outside
                    this area.
                    <br />
                    <br />
                    Currently, we share data with:
                  </p>
                  <ul className="list">
                    <li>
                      Service providers (such as providers of technical
                      infrastructure, analyzing tools or financial assistance);
                      and
                    </li>
                    <li>Law enforcement or due to legal requests</li>
                  </ul>
                </div>
              </div>
              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>3</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Security of Your Personal Data"}</h3>
                  </div>
                  <p>
                    We continuously review and improve our technical and
                    organizational measures of protection for Your personal data
                    from unauthorized access, accidental loss, disclosure or
                    destruction.
                    <br />
                    <br />
                    We strive to protect our users' personal data and encrypt it
                    so that it is difficult for unauthorized access. We have
                    also implemented various policies and techniques for good
                    protection of the data. You must though be aware of the
                    signification of keeping Your password safe and we recommend
                    using a unique and strong password, restrict the access to
                    Your computer and browser, and logging out after using
                    HorseMetro.com.
                    <br />
                    <br />
                    If we transfer personal data to a country outside of the
                    EEA, we will make sure that Your information is properly
                    protected. We will always ensure that there is a proper
                    legal agreement that covers the data transfer. In addition,
                    if the country is not considered to have laws that are
                    equivalent to EU data protection standards then we will ask
                    the third party to enter into a legal agreement that
                    reflects those standards. If applicable, we utilize the
                    standard contractual clauses approved by the European
                    Commission and further rely on the European Commission’s
                    recommendations on required measurements for transfer of
                    personal data to certain countries outside the EEA.
                    <br />
                    <br />
                    We would like to inform You that, communications over the
                    internet (such as e-mails) aren’t secure unless they’ve been
                    encrypted. Your communications may go through a number of
                    countries before being delivered, as this is the nature of
                    the internet.
                    <br />
                    <br />
                    We do not accept responsibility for any unauthorized access
                    or loss of personal information that is beyond our control,
                    including but not limited to, links to third-party websites,
                    plug-ins or third-party content. We do not take
                    responsibility for the security and content of any
                    third-party websites. The same applies to any third-party
                    websites or content You connect to using our products and
                    services. Social plug-ins and social applications are
                    operated by the social network themselves and are subject to
                    their own terms of use and privacy and cookies policies. You
                    are responsible for keeping Your personal and account
                    information secure and not sharing it with others.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>4</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Changing Your Preferences"}</h3>
                  </div>
                  <p>
                    We want You to be in charge of Your personal data and strive
                    to make that possible.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>4.1</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Posting in Your Private Gallery or messages"}</h3>
                  </div>
                  <p>
                    Through the Privacy Settings at HorseMetro Community, You
                    have the ability to decide who will be able to see Your
                    content, and make Your uploads public, private or only for
                    Your friends.
                    <br />
                    <br />
                    If You share something, others, which has the right to
                    access it, can share it on their account.
                    <br />
                    <br />
                    Comments or likes on other users’ posts can be accessed by
                    his/her friends/followers. If comments or likes are made on
                    a public post, anyone with an account at HorseMetro will be
                    able to see it.
                    <br />
                    <br />
                    If You delete content which You have uploaded or created,
                    for example in Your Timeline, we remove it from that
                    HorseMetro Service unless You have uploaded public pictures
                    and advertisements. When You upload pictures, for example,
                    to Your Timeline, it will also be placed in Your private
                    gallery. If You want it to be totally removed, You must
                    delete it through Your private gallery.
                    <br />
                    <br />
                    If You delete Your account, we remove content You have
                    posted in Your private gallery and You won’t be able to
                    recover that information at a later time. If Your account is
                    deactivated for any reason, Your content will not be
                    removed.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>4.2</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Posting in Groups or in the Public Gallery"}</h3>
                  </div>
                  <p>
                    If You decide to post content in a group, the settings of
                    the group determine who will see the content. Make sure to
                    check whether the group is private or public before making a
                    post. If the group is private, the content can only be seen
                    by the members. If the group is public, the content will be
                    accessible to every user who is logged in at HorseMetro.com.
                    <br />
                    <br />
                    Any content posted to the public gallery, or information
                    that other users have shared about You, will not be removed
                    if You delete Your account. Some content may be removed if
                    requested by You or if the content is in breach of our Terms
                    and Conditions
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>4.3</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"The Bazaar"}</h3>
                  </div>
                  <p>
                    Content posted on the Bazaar is accessible to anyone who has
                    an account on HorseMetro.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>4.4</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Other"}</h3>
                  </div>
                  <p>
                    Information about Your status or presence at HorseMetro will
                    be accessible to other users, logged in at HorseMetro.com
                    depending on Your Privacy Settings.
                    <br />
                    <br />
                    In the event that HorseMetro is involved in a merger,
                    bankruptcy, acquisition, reorganization or sale of assets,
                    Your information may be sold or transferred as part of that
                    transaction. You will though continue to own the content You
                    have created/uploaded as described in chapter 4. This
                    Privacy Policy will apply to Your information as transferred
                    to the new entity.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>5</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Your Rights under GDPR"}</h3>
                  </div>
                  <p>Under GDPR, You are entitled to:</p>
                  <ul className="list">
                    <li>
                      have Your data processed in a fair, lawful and transparent
                      way;
                    </li>
                    <li>
                      be informed about how Your personal data is being used, an
                      example being this privacy policy;
                    </li>
                    <li>access personal data we hold about You;</li>
                    <li>
                      require Us to correct any mistakes in Your personal data;
                    </li>
                    <li>
                      require Us to delete personal data concerning You in
                      certain situations where we have no lawful ground for Us
                      to continue to process it;
                    </li>
                    <li>
                      request that we transfer Your personal data to You or
                      another service provider in a simple, structured format;
                    </li>
                    <li>
                      object at any time to processing of Your personal data for
                      direct marketing purposes;
                    </li>
                    <li>
                      object to automated decision making which produces legal
                      effects concerning You or similarly significantly affects
                      You;
                    </li>
                    <li>
                      object in certain other situations to our continued
                      processing of Your personal data; and
                    </li>
                    <li>
                      otherwise restrict or temporarily stop our processing of
                      Your personal data in certain circumstances.
                    </li>
                  </ul>
                  <p>
                    If You have any questions about this Privacy Policy, have a
                    complaint or want to speak with Us in relation to any of
                    Your rights, please contact Us:
                    <br />
                    E-mail:
                    <a href="mailto:contact@horsemetro.com">
                      contact@horsemetro.com
                    </a>
                    <br />
                    <br />
                    If You are unhappy with our services, and we were unable to
                    help You, You can lodge a complaint at the Swedish Data
                    Protection Authority, which is the Swedish data protection
                    regulator. Their details can be found at{" "}
                    <a href="https://www.datainspektionen.se">
                      www.datainspektionen.se
                    </a>{" "}
                    , or contact them via e-mail at
                    datainspektionen@datainspektionen.se or via mail at
                    Datainspektionen, Box 8114, SE-104 20 Stockholm, Sweden.
                  </p>
                </div>
              </div>

              <div className="row sections">
                <div className="col-1" />
                <div className="col-11">
                  <div className="iconContainer violetColor">
                    <span>6</span>
                  </div>
                  <div className="subheaderContainer">
                    <h3>{"Changes to This Privacy Policy"}</h3>
                  </div>
                  <p>
                    This Privacy Policy may be subject to change and updates
                    from time to time. If any significant changes are being
                    made, we will inform our registered users. Please make sure
                    to regularly check this Privacy Policy to ensure that You
                    have the updated version. If You find that any of our
                    changes or amendments are not acceptable You must stop using
                    HorseMetro and inform Us whether You would like to exercise
                    any of Your rights under GDPR.
                  </p>
                </div>
              </div>
            </div>
          </FadeIn>
          <Footer />
        </div>
      </>
    );
  }
}
privacyPolicy.propTypes = {
  title: PropTypes.string.isRequired,
  changeTitle: PropTypes.func.isRequired
};
export default privacyPolicy;
