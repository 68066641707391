import React, { Component } from "react";
import getAllowedCountriesApi from "../../../api/localization/getAllowedCountriesApi";
import getStatesInCountryApi from "../../../api/localization/getStatesInCountryApi";
import getCurrentlyLoggedInProfileApi from "../../../api/profile/getCurrentlyLoggedInProfile_fullApi";
import getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi from "../../../api/payment/getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi";
// import checkProfileIsComplete from "../../../api/profile/isProfileInfoCompleteApi";
import { Previous, Next, Submit, Logout } from "./stepperButtons";
import { Step1, Step2, Step3, Step4 } from "./forms";
import { ProgressBar, ProgressBarBusiness } from "./ProgressBar";
import updateBusinessInfo from "../../../api/profile/info/initialLoginUpdateBusinessInfoApi";
import updateUserInfo from "../../../api/profile/info/initialLoginUpdateUserInfoApi";
import paymentApi from "../../../api/payment/payment";
import { withRouter } from "react-router-dom";
import validateDiscountCodeApi from "../../../api/payment/validateDiscountCodeApi";
import { setProfileInfoCompleteCookie } from "../../cookieAndAccessHandling";

import { ErrorWrapper, SuccessWrapper } from "../../../common/alerts";

import storeTempWizardProfileInfoForBusinessUserApi from "../../../api/account-service/stepperApis/storeTempProfileInfo";
import getTempWizzardProfileInfoForBusinessUserApi from "../../../api/account-service/stepperApis/getTempProfileInfo";
//import checkIfAccountEmailIsAlreadyUsedApi from "../../../api/account-service/checkIfAccountEmailIsAlreadyUsedApi";
class Steps extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  profileCompleted: false,
                  Message: "",
                  messageType: "",
                  errors: {},
                  formIsValid: true,
                  displayPrevious: false,
                  displayNext: null,
                  dispaySubmit: null,
                  currentStep: 1,
                  gender: "female",
                  companyNameInput: "",
                  address: "",
                  postalCode: "",
                  value: [],
                  label: [],
                  options: [],
                  /* Step1 vars */
                  step1SelectedValue: { label: "", value: "" },
                  step1SelectedDay: "",
                  step1IsEmpty: "",
                  step1IsDisabled: false,
                  step1UserBirthYear: "",
                  /* Step2 vars */
                  contactFirstName: "",
                  contactLastName: "",
                  phoneNumber: "",
                  vatNumber: "",
                  /* Step3 vars */

                  checkboxVals: {
                        /* private account */
                        Allround: false,
                        Distance: false,
                        Dressage: false,
                        Eventing: false,
                        Gallop: false,
                        Jumping: false,
                        Hunting: false,
                        Driving: false,
                        Trotting: false,
                        Vaulting: false,
                        Western: false,
                        Other: false,
                        /* business account */
                        /* breeding */
                        Breeding: false,
                        Breeding2: false,
                        Stallions: false,
                        Insamination: false,
                        Nutritionist: false,
                        breedingOther: false,
                        /* construction and maintenance */
                        ConstructionAndMaintenance: false,
                        ConstructionServices: false,
                        ConstructionStore: false,
                        ConstructionManufacturer: false,
                        ConstructionOther: false,
                        /* finance and law */
                        FinanceAndLaw: false,
                        LegalServices: false,
                        InsuranceCompany: false,
                        Bank: false,
                        LegalOther: false,
                        /* electronics computer technology */
                        ElectronicsComputerTech: false,
                        ElectronicsStore: false,
                        ElectronicsManufacturer: false,
                        ElectronicsOther: false,
                        FeedAndBait: false,
                        FeedAndBaitStore: false,
                        FeedAndBaitManufacturer: false,
                        FeedAndBaitOther: false,
                        /* vehicle */
                        Vehicle: false,
                        CarSales: false,
                        VehicleRepairs: false,
                        MachineDealers: false,
                        VehicleManufacturer: false,
                        VehicleOther: false,
                        Organization: false,
                        /* home household and garden */
                        HomeHouseholdGarden: false,
                        Architect: false,
                        GardenDesigner: false,
                        Broker: false,
                        Designer: false,
                        FarmShop: false,
                        HomeHouseholdGardenManufacturer: false,
                        HomeHouseholdGardenOther: false,
                        /* hotels and travel */
                        HotelsAndTravel: false,
                        BedAndBreakfast: false,
                        Hotel: false,
                        TravelAgents: false,
                        Vacations: false,
                        Accommodation: false,
                        Conferences: false,
                        HotelsAndTravelOther: false,
                        /* health and beauty */
                        HealthAndBeauty: false, //111111111111111111111111111111111111111111111
                        HealthAndBeautyStore: false,
                        HealthAndBeautyManufacturer: false,
                        HealthAndBeautyOther: false,
                        /* horse accesories */
                        HorseAccessories: false,
                        RidingHousesAndStallSupplies: false,
                        EquestrianShop: false,
                        Saddler: false,
                        HorseAccessoriesStore: false,
                        HorseAccessoriesManufacturer: false,
                        HorseAccessoriesOther: false,
                        /* toys */
                        Toys: false,
                        ToyStore: false,
                        ToyManufacturer: false,
                        ToyOther: false,
                        /* media and advertisement */
                        MediaAdvertisement: false,
                        MediaStore: false,
                        MediaEvents: false,
                        Photographer: false,
                        Journalist: false,
                        Advertiser: false,
                        Publisher: false,
                        Newspaper: false,
                        MediaOther: false,
                        /* fashion */
                        Fashion: false,
                        FashionStore: false,
                        FashionManufacturer: false,
                        FashionOther: false,
                        /* leasure */
                        Leisure: false,
                        LeisureEvents: false,
                        LeisureOther: false,
                        /* education */
                        SchoolEducation: false,
                        RidingSchool: false,
                        TrainigInstructor: false,
                        College: false,
                        University: false,
                        SchoolEducationOther: false,

                        /* horse related services */
                        HorseRelatedServices: false,
                        Breaking: false,
                        Dentist: false,
                        Driver: false,
                        Farrier: false,
                        InRunning: false,
                        Masseur: false,
                        hrNutritionist: false,
                        HorseRelatedServicesStore: false,
                        Therapist: false,
                        Transport: false,
                        Vet: false,
                        HorseRelatedServicesOther: false,

                        /*other business */
                        businessOther: false
                  },
                  associations: "",
                  otherInterests: "",
                  breedingOtherServices: "",
                  constructionOtherServices: "",
                  electronicsOtherServices: "",
                  feedAndBaitOtherServices: "",
                  vehicleOtherServices: "",
                  legalOtherServices: "",
                  homeHouseholdGardenOtherServices: "",
                  hotelsAndTraveOtherServices: "",
                  healthAndBeautyOthterServices: "",
                  horseAccessoriesOtherServices: "",
                  toysOtherServices: "",
                  mediaOtherServices: "",
                  fashionOtherServices: "",
                  leisureOtherServices: "",
                  schoolOtherServices: "",
                  horseRelatedOtherServices: "",
                  businessOtherServices: "",

                  competing: "yes",
                  subscription: "yearly",
                  withHorse: "",
                  /*user data */
                  profile: {
                        country: "",
                        originalRegistationCountry: "",
                        companyName: "",
                        userId: "",
                        displayName: "",
                        firstName: "",
                        lastName: "",
                        profileInfoComplete: false,
                        profileType: "",
                        userEmail: "",
                        userProfilePictureId: "",
                        subscriptionPaid: ""
                  },
                  //STATE INPUT FROM STEP1
                  stateOptions: [],
                  valueSelectedFromStep1StateInput: { label: "", value: "" },

                  discountCode: "",
                  discountCodeValid: "",
                  paymentData: {
                        buyerEmailAddress: "some@email.com",
                        buyerFirstName: "",
                        buyerLastName: "",
                        buyerPhoneNumber: "",
                        countryCode: "SE",
                        deliveryAddressCity: "----",
                        deliveryAddressCountryCode: "752",
                        deliveryAddressLineOne: "Ortviken",
                        deliveryAddressLineThree: "16B",
                        deliveryAddressLineTwo: "Klippgatan",
                        deliveryAddressPostalCode: "85637",
                        discountCode: "100003",
                        paymentLocale: "en_GB",

                        //direct payment fields
                        directPaymentServiceName: "bazar_service",
                        directPaymentType: "announcement",
                        directPaymentTypeId: "5c54400b0051cb37bcd1948f",

                        //subscription fields
                        subscriptionType: "account_subscription",
                        paymentType: "SubscriptionPayment"
                        //paymentType: "SubscriptionPayment"
                  },
                  subscriptionName: "business_yearly_subscription",
                  businessYearlySubscription: null,
                  businessYearlyNetPrice: null,
                  businessYearlyCurrency: null,
                  businessMonthlySubscription: null,
                  businessMonthlyNetPrice: null,
                  businessMonthlyCurrency: null,
                  //TM  edit 2020
                  tempWizardProfileData: {},
                  emailsExistInServicesPaymentAndOrAccount: {}
            };
      }

      //check profile.

      UNSAFE_componentWillMount = async () => {
            await this.getCountries();
            await this.getCurrentlyLoggedInProfile();
            //get the api on load
            await this.getSubscription();

            let { currentStep } = this.state;
            await this.getTempData(currentStep);
      };

      getStates(countryCode) {
            getStatesInCountryApi(countryCode).then((response) => {
                  let stateOptions = Object.assign(response.data).map(function (states) {
                        return { value: states.name, label: states.name };
                  });

                  this.setState({
                        stateOptions: stateOptions
                  });
                  //console.log(
                  //  "STATE KEY VALUE FROM API IN COMPONENT STEPS STATE " +
                  //    JSON.stringify(this.state.stateOptions)
                  //);
            });
      }

      getSubscription = async () => {
            await getPriceByCurrenctlyLoginUserAndSubscriptionTypeApi().then((response) => {
                  Object.assign(response).map(
                        function (subscription) {
                              //get the data needed from subscription api
                              if (subscription.subscriptionName === "business_yearly_subscription") {
                                    let _businessYearlySubscription = subscription.subscriptionName;
                                    this.setState({
                                          businessYearlySubscription: _businessYearlySubscription,
                                          businessYearlyNetPrice: subscription.netPrice,
                                          businessYearlyCurrency: subscription.currency
                                    });
                              }

                              if (subscription.subscriptionName === "business_monthly_subscription") {
                                    let _businessMonthlySubscription = subscription.subscriptionName;
                                    this.setState({
                                          businessMonthlySubscription: _businessMonthlySubscription,
                                          businessMonthlyNetPrice: subscription.netPrice,
                                          businessMonthlyCurrency: subscription.currency
                                    });
                              }
                        }.bind(this)
                  );
            });
      };
      handleStep1DayChange = (step1SelectedDay, modifiers, dayPickerInput) => {
            //console.log("From parent DateTimePicker");
            const input = dayPickerInput.getInput();
            this.setState({
                  step1SelectedDay,
                  step1IsEmpty: !input.value.trim(),
                  step1IsDisabled: modifiers.disabled === true
            });
            setTimeout(() => {
                  this.validate();
            }, 10);
      };

      handleStep1CountryChange = (valueFromStep1) => {
            console.group("Value COUNTRY Changed from Steps(PARENT)");
            console.log(valueFromStep1);
            this.setState({ step1SelectedValue: valueFromStep1 });

            console.groupEnd();

            this.getStates(valueFromStep1.value);

            setTimeout(() => {
                  this.validate();
            }, 10);

            return this.state.step1SelectedValue;
      };

      handleStep1Statechange = (valueFromStep1StateInput) => {
            //console.group("Value Changed from Step1(State Input dropDown)");
            //console.log(valueFromStep1StateInput);
            this.setState({
                  valueSelectedFromStep1StateInput: valueFromStep1StateInput
            });

            setTimeout(() => {
                  this.validate();
            }, 10);

            //console.groupEnd();
      };

      handleStep1UserBirthYear = (year) => {
            this.setState({ step1SelectedDay: "", step1UserBirthYear: year });
      };

      handleStep3Change = (event) => {
            const target = event.target;
            const value = target.type === "checkbox" ? target.checked : target.value;
            const name = target.name;
            //console.log("check changed : " + name);
            //console.log("check changed : " + value);
            //this.setState({ checkboxVals: name, value });

            this.setState((prevState) => ({
                  checkboxVals: {
                        // object that we want to update
                        ...prevState.checkboxVals, // keep all other key-value pairs
                        [target.name]: value // update the value of specific key
                  }
            }));
      };

      validate = async () => {
            let currentStep = this.state.currentStep;
            let errors = {};
            const companyName = this.state.companyNameInput,
                  streetAddress = this.state.address,
                  postalCode = this.state.postalCode,
                  country = this.state.step1SelectedValue,
                  state = this.state.valueSelectedFromStep1StateInput,
                  // contactFirstName = this.state.contactFirstName,
                  // contactLastName = this.state.contactLastName,
                  phoneNumber = this.state.phoneNumber,
                  vatNumber = this.state.vatNumber,
                  date = this.state.step1SelectedDay,
                  profileType = this.state.profile.profileType;
            console.log("profile type /////////////// " + profileType);
            console.log("dsfsdfsdf : " + this.state.postalCode);
            ////console.log("selected date /////////////" + this.state.step1SelectedDay);
            if (profileType === "business") {
                  if (currentStep == 1) {
                        this.setState({ formIsValid: true });
                        if (companyName === "") {
                              errors["companyName"] = "Company name is required.";
                              //console.log("error : " + this.state.errors.companyName);
                              this.setState({ formIsValid: false });
                        }

                        if (streetAddress === "") {
                              errors["streetAddress"] = "Street address is required.";
                              //console.log("error : " + this.state.errors.streetAddress);
                              this.setState({ formIsValid: false });
                        }
                        if (postalCode === "") {
                              errors["postalCode"] = "Postal code is required.";
                              this.setState({ formIsValid: false });
                        }

                        if (country.label === undefined) {
                              errors["country"] = "Please select your country.";
                              this.setState({ formIsValid: false });
                        }
                        if (state.label === undefined) {
                              errors["state"] = "Please select your state.";
                              this.setState({ formIsValid: false });
                        }
                        var count = Object.keys(errors).length;
                        if (count == 0) {
                              this.storeTempData();
                        }
                        // ADD STEP ONE
                  }
                  if (currentStep == 2) {
                        this.setState({ formIsValid: true });
                        // if (contactFirstName === "") {
                        //       errors["contactFirstName"] = "First name is required.";
                        //       this.setState({ formIsValid: false });
                        // }
                        // if (contactLastName === "") {
                        //       errors["contactLastName"] = "Last name is required.";
                        //       this.setState({ formIsValid: false });
                        // }
                        if (phoneNumber === "") {
                              errors["phoneNumber"] = "Please provide your phone number.";
                              this.setState({ formIsValid: false });
                        }
                        if (vatNumber === "") {
                              errors["vatNumber"] = "Please provide your vat number";
                              this.setState({ formIsValid: false });
                        }
                        var count = Object.keys(errors).length;
                        if (count == 0) {
                              this.storeTempData();
                        }
                  }
            }

            if (profileType === "user") {
                  if (currentStep == 1) {
                        this.setState({ formIsValid: true });

                        if (country.label === undefined) {
                              errors["country"] = "Please select your country.";
                              this.setState({ formIsValid: false });
                        }
                        if (state.label === undefined) {
                              errors["state"] = "Please select your state.";
                              this.setState({ formIsValid: false });
                        }
                        if (date === "") {
                              errors["date"] = "Please choose your date of birth.";
                              this.setState({ formIsValid: false });
                        }
                  }
            }

            this.setState({ errors });
      };

      goToPreviousStep = async () => {
            let { currentStep } = this.state;
            currentStep = currentStep <= 1 ? 1 : currentStep - 1;
            this.setState({ currentStep: currentStep });
      };

      goToNextStep = async () => {
            this.validate();
            /* timeout for getting the state change */

            //setTimeout(() => {
            if (this.state.formIsValid == true) {
                  let { currentStep } = this.state;

                  currentStep = currentStep >= 3 ? 4 : currentStep + 1;
                  await this.setState({ currentStep });
            }
            //}, 10);

            // add temp data
      };

      getTempData = async (currentStep) => {
            if (currentStep == 3 || currentStep == 4) return;
            const tempWizardProfileData = await getTempWizzardProfileInfoForBusinessUserApi(currentStep);

            //console.log("%cGET DATA FROM TEMP WIZARD : " + JSON.stringify(tempWizardProfileData.dataObject, null, "\t"), "color:#00C9A7");
            this.setState({ tempWizardProfileData: tempWizardProfileData.dataObject });

            if (tempWizardProfileData.dataObject == null || tempWizardProfileData.dataObject == undefined) return;
            let companyName = tempWizardProfileData.dataObject.companyName;
            if (companyName != null) {
                  this.setState({ companyNameInput: companyName });
            }

            let streetAddress = tempWizardProfileData.dataObject.streetAddress;
            if (streetAddress != null) {
                  this.setState({ address: streetAddress });
            }

            let postalCode = tempWizardProfileData.dataObject.postalCode;
            if (postalCode != null) {
                  this.setState({ postalCode: postalCode });
            }

            let country = tempWizardProfileData.dataObject.country;
            if (country != null) {
                  await this.setState({ step1SelectedValue: { label: country, value: country } });

                  // Get the countryCode stored in options
                  let { options } = this.state;
                  let countryOption = options.filter((countryItem) => countryItem.label === country);
                  //console.log("%ccountryOption : " + JSON.stringify(countryOption[0], null, "\t"), "color:#00c9a7");
                  await this.getStates(countryOption[0].value);
            }

            let region = tempWizardProfileData.dataObject.region;
            if (region != null) {
                  await this.setState({
                        valueSelectedFromStep1StateInput: { label: region, value: region }
                  });
            }
            let contactFirstName = tempWizardProfileData.dataObject.contactFirstName;
            if (contactFirstName != null) {
                  this.setState({ contactFirstName: contactFirstName });
            }

            let contactLastName = tempWizardProfileData.dataObject.contactLastName;
            if (contactLastName != null) {
                  this.setState({ contactLastName: contactLastName });
            }

            let phoneNumber = tempWizardProfileData.dataObject.phoneNumber;
            if (phoneNumber != null) {
                  this.setState({ phoneNumber: phoneNumber });
            }

            let vatNumber = tempWizardProfileData.dataObject.vatNumber;
            if (vatNumber != null) {
                  this.setState({ vatNumber: vatNumber });
            }
      };

      storeTempData = async () => {
            if (currentStep == 3 || currentStep == 4) return;
            let {
                  currentStep,
                  address,
                  companyNameInput,
                  postalCode,
                  valueSelectedFromStep1StateInput,
                  step1SelectedValue,
                  contactFirstName,
                  contactLastName,
                  phoneNumber,
                  vatNumber
            } = this.state;

            let { profileType } = this.state.profile;
            if (profileType === "business") {
                  // step 1
                  let payloadDATA = {};
                  if (currentStep == 1) {
                        payloadDATA = {
                              streetAddress: address,
                              companyName: companyNameInput,
                              postalCode: postalCode,
                              country: step1SelectedValue.label,
                              region: valueSelectedFromStep1StateInput.label
                        };
                  }
                  // step 2
                  if (currentStep == 2) {
                        payloadDATA = {
                              contactFirstName: contactFirstName,
                              contactLastName: contactLastName,
                              postalCode: postalCode,
                              phoneNumber: phoneNumber,
                              vatNumber: vatNumber
                        };
                  }
                  console.log("Data stored : " + JSON.stringify(payloadDATA));
                  console.log("current step : " + currentStep);
                  const storeTempWizardProfileData = await storeTempWizardProfileInfoForBusinessUserApi(payloadDATA, currentStep);
                  console.log("resp : " + storeTempWizardProfileData);
            }
      };

      get PreviousButton() {
            const currentStep = this.state.currentStep;
            if (currentStep !== 1) {
                  if (this.state.profile.profileInfoComplete == true) {
                        return false;
                  } else {
                        return <Previous goToPreviousStep={() => this.goToPreviousStep(currentStep)} />;
                  }
            }
            return null;
      }

      get NextButton() {
            const currentStep = this.state.currentStep;
            if (currentStep < 4) {
                  return <Next goToNextStep={this.goToNextStep} />;
            }
            return <></>;
      }

      handleSubmit = (event) => {
            event.preventDefault();
      };

      cleanErrorSuccessMessage = () => {
            this.setState({
                  messageType: null
            });
      };

      submitData = () => {
            const profileType = this.state.profile.profileType;
            const initialLoginBusinessInfoPayLoad = {
                  country: this.state.step1SelectedValue.label,
                  companyName: this.state.companyNameInput,
                  region: this.state.valueSelectedFromStep1StateInput.label,
                  streetAdress: this.state.address,
                  postalCode: this.state.postalCode,
                  phoneNumber: this.state.phoneNumber,
                  // contactFirstName: this.state.contactFirstName,
                  // contactLastName: this.state.contactLastName,
                  vatNumber: this.state.vatNumber,
                  providingServices: {
                        breeding: {
                              breeding: this.state.checkboxVals.Breeding2,
                              stallions: this.state.checkboxVals.Stallions,
                              insamination: this.state.checkboxVals.Insamination,
                              nutritionist: this.state.checkboxVals.Nutritionist,
                              other: this.state.breedingOtherServices
                        },
                        constructionAndMaintance: {
                              construction_Services_Craftsmen: this.state.checkboxVals.ConstructionServices,
                              store: this.state.checkboxVals.ConstructionStore,
                              manufacturer: this.state.checkboxVals.ConstructionManufacturer,
                              other: this.state.constructionOtherServices
                        },
                        financeAndLaw: {
                              legalServices: this.state.checkboxVals.LegalServices,
                              insuranceCompany: this.state.checkboxVals.InsuranceCompany,
                              bank: this.state.checkboxVals.Bank,
                              other: this.state.legalOtherServices
                        },
                        electronicsComputerTechnology: {
                              store: this.state.checkboxVals.ElectronicsStore,
                              manufacturer: this.state.checkboxVals.ElectronicsManufacturer,
                              other: this.state.electronicsOtherServices
                        },
                        feedAndBait: {
                              store: this.state.checkboxVals.FeedAndBaitStore,
                              manufacturer: this.state.checkboxVals.FeedAndBaitManufacturer,
                              other: this.state.feedAndBaitOtherServices
                        },
                        vehicles: {
                              carSales: this.state.checkboxVals.CarSales,
                              machineDealers: this.state.checkboxVals.MachineDealers,
                              vehicleRepairs: this.state.checkboxVals.VehicleRepairs,
                              manufacturer: this.state.checkboxVals.VehicleManufacturer,
                              other: this.state.vehicleOtherServices
                        },
                        organization: {
                              organization: this.state.checkboxVals.Organization
                        },
                        homeHouseholdAndGarden: {
                              architect: this.state.checkboxVals.Architect,
                              gardenDesigner: this.state.checkboxVals.GardenDesigner,
                              broker: this.state.checkboxVals.Broker,
                              farmShop: this.state.checkboxVals.FarmShop,
                              manufacturer: this.state.checkboxVals.HomeHouseholdGardenManufacturer,
                              other: this.state.homeHouseholdGardenOtherServices
                        },
                        hotelsAndTravel: {
                              hotel: this.state.checkboxVals.Hotel,
                              travelAgents: this.state.checkboxVals.TravelAgents,
                              accommodation_: {
                                    accommodation: this.state.checkboxVals.Accommodation,
                                    withOrWithoutHorse: this.state.withHorse
                              },
                              conferences: this.state.checkboxVals.Conferences,
                              b_and_B: this.state.checkboxVals.BedAndBreakfast,
                              other: this.state.hotelsAndTraveOtherServices
                        },
                        healthAndBeauty: {
                              store: this.state.checkboxVals.HealthAndBeautyStore,
                              manufacturer: this.state.checkboxVals.HealthAndBeautyManufacturer,
                              other: this.state.healthAndBeautyOthterServices
                        },
                        horseAccessories: {
                              ridingHousesAndStallSupplies: this.state.checkboxVals.RidingHousesAndStallSupplies,
                              equestrianShop: this.state.checkboxVals.EquestrianShop,
                              store: this.state.checkboxVals.HorseAccessoriesStore,
                              saddler: this.state.checkboxVals.Saddler,
                              manufacturer: this.state.checkboxVals.HorseAccessoriesManufacturer,
                              other: this.state.horseAccessoriesOtherServices
                        },
                        toys: {
                              store: this.state.checkboxVals.ToyStore,
                              manufacturer: this.state.checkboxVals.ToyManufacturer,
                              other: this.state.toysOtherServices
                        },
                        mediaAndAdvertising: {
                              store: this.state.checkboxVals.MediaStore,
                              events: this.state.checkboxVals.MediaEvents,
                              photographer: this.state.checkboxVals.Photographer,
                              adevertiser: this.state.checkboxVals.Advertiser,
                              journalist: this.state.checkboxVals.Journalist,
                              publisher: this.state.checkboxVals.Publisher,
                              newsPaper: this.state.checkboxVals.Newspaper,
                              other: this.state.mediaOtherServices
                        },
                        fashionClothingAndAccessories: {
                              store: this.state.checkboxVals.FashionStore,
                              manufacturer: this.state.checkboxVals.FashionManufacturer,
                              other: this.state.fashionOtherServices
                        },
                        funAndLeisure: {
                              events: this.state.checkboxVals.LeisureEvents,
                              other: this.state.leisureOtherServices
                        },
                        schoolAndEducation: {
                              ridingSchool: this.state.checkboxVals.RidingSchool,
                              trainingInstructor: this.state.checkboxVals.TrainigInstructor,
                              college: this.state.checkboxVals.College,
                              university: this.state.checkboxVals.University,
                              other: this.state.schoolOtherServices
                        },
                        horseRelatedServices: {
                              breaking: this.state.checkboxVals.Breaking,
                              denstist: this.state.checkboxVals.Dentist,
                              driver: this.state.checkboxVals.Driver,
                              farrier: this.state.checkboxVals.Farrier,
                              inRunning: this.state.checkboxVals.InRunning,
                              masseur: this.state.checkboxVals.Masseur,
                              nutritionist: this.state.checkboxVals.hrNutritionist, //-----------------------------!!!!!
                              store: this.state.checkboxVals.HorseRelatedServicesStore,
                              therapist: this.state.checkboxVals.Therapist,
                              transport: this.state.checkboxVals.Transport,
                              vet: this.state.checkboxVals.Vet,
                              other: this.state.horseRelatedOtherServices
                        },
                        otherServices: this.state.businessOtherServices
                  }
            };

            const initialLoginUserInfoPayload = {
                  country: this.state.step1SelectedValue.label,
                  region: this.state.valueSelectedFromStep1StateInput.label,
                  gender: this.state.gender,
                  dateOfBirth: this.state.step1SelectedDay,
                  interests: {
                        allround: this.state.checkboxVals.Allround,
                        distance: this.state.checkboxVals.Distance,
                        dressage: this.state.checkboxVals.Dressage,
                        eventing: this.state.checkboxVals.Eventing,
                        gallop: this.state.checkboxVals.Gallop,
                        jumping: this.state.checkboxVals.Jumping,
                        hunting: this.state.checkboxVals.Hunting,
                        driving: this.state.checkboxVals.Driving,
                        trotting: this.state.checkboxVals.Trotting,
                        vaulting: this.state.checkboxVals.Vaulting,
                        western: this.state.checkboxVals.Western,
                        other: this.state.otherInterests,
                        associations: this.state.associations,
                        competing: this.state.competing
                  }
            };

            if (this.state.profile.profileInfoComplete == true && this.state.profile.subscriptionPaid == false) {
                  if (profileType === "business") {
                        this.submitPayment();
                  }
            }

            // if paid and needs profile update

            if (
                  (this.state.profile.profileInfoComplete == false && this.state.profile.subscriptionPaid == true) ||
                  (this.state.profile.profileInfoComplete == true && this.state.profile.subscriptionPaid == true)
            ) {
                  if (profileType === "business") {
                        //----------------------------------------------------------------------------------------------------------------------------------------------->
                        updateBusinessInfo(initialLoginBusinessInfoPayLoad).then((response) => {
                              console.log("--->>>>" + JSON.stringify(response));
                              if (response.msgWrapper.msgType == "SUCCESS" || response.msgWrapper.msgType == "success") {
                                    setProfileInfoCompleteCookie(true);
                              }

                              const message = response.msgWrapper.msgDescription;
                              const messageType = response.msgWrapper.msgType;

                              this.setState({ Message: message });
                              this.setState({ messageType: messageType });
                              console.log("updating profile");
                              return message, messageType;
                        });
                        // this.redirectToLoginHome();

                        setTimeout(() => {
                              this.redirectToLoginHome();
                        }, 500);
                  }
            }

            //if business
            if (this.state.profile.profileInfoComplete == false && this.state.profile.subscriptionPaid == false) {
                  if (profileType === "business") {
                        //----------------------------------------------------------------------------------------------------------------------------------------------->
                        updateBusinessInfo(initialLoginBusinessInfoPayLoad).then(
                              function (response) {
                                    console.log("--->>>>" + JSON.stringify(response));
                                    if (response.msgWrapper.msgType == "SUCCESS" || response.msgWrapper.msgType == "success") {
                                          setProfileInfoCompleteCookie(true);
                                    }
                                    this.submitPayment();

                                    const message = response.msgWrapper.msgDescription;
                                    const messageType = response.msgWrapper.msgType;

                                    this.setState({ Message: message });
                                    this.setState({ messageType: messageType });

                                    return message, messageType;
                              }.bind(this)
                        );
                        //payment methods
                        // this.submitPayment();
                  }
            }
            //if user
            if (profileType === "user") {
                  updateUserInfo(initialLoginUserInfoPayload).then(
                        function (response) {
                              const message = response.msgWrapper.msgDescription;
                              const messageType = response.msgWrapper.msgType;

                              if (messageType == "SUCCESS" || messageType == "success") {
                                    setProfileInfoCompleteCookie(true);
                              }

                              this.setState({ Message: response.msgWrapper.msgDescription });

                              this.setState({ messageType: response.msgWrapper.msgType });
                              return message, messageType;
                        }.bind(this)
                  );
            }
      };

      submitPayment = async () => {
            console.log("profile info");
            console.log("profile info complete : " + this.state.profile.profileInfoComplete);
            console.log("profile data: " + JSON.stringify(this.state.profile), null, 2);
            console.log("===================================================");

            if (this.state.profile.profileInfoComplete == true) {
                  const subscriptionPayment = {
                        buyerEmailAddress: this.state.profile.userEmail,
                        buyerFirstName: this.state.profile.firstName,
                        buyerLastName: this.state.profile.lastName,
                        buyerPhoneNumber: this.state.profile.phoneNumber,

                        deliveryAddressCity: this.state.paymentData.deliveryAddressCity,
                        deliveryAddressCountryCode: this.state.paymentData.deliveryAddressCountryCode,

                        deliveryAddressLineOne: this.state.profile.country,
                        deliveryAddressLineTwo: this.state.profile.region,
                        deliveryAddressLineThree: this.state.profile.streetAdress,
                        deliveryAddressPostalCode: this.state.profile.postalCode,
                        //discountCode: this.state.discountCode,
                        discountCode_Or_couponCode: this.state.discountCode,
                        // paymentLocale: this.state.paymentData.paymentLocale,
                        subscriptionType: this.state.paymentData.subscriptionType,
                        subscriptionName: this.state.subscriptionName,
                        paymentType: "SubscriptionPayment",
                        redirectUrlOnFreeDiscountCode: window.location.origin + "/public-timeline",
                        frontEndBaseUrlWithPort: window.location.origin
                  };
                  console.log("JSON payment data : " + JSON.stringify(subscriptionPayment));
                  await paymentApi(subscriptionPayment).then(function (response) {
                        document.getElementById("paymentForm").innerHTML = response;
                  });
            } else {
                  const subscriptionPayment = {
                        buyerEmailAddress: this.state.profile.userEmail,
                        buyerFirstName: this.state.profile.firstName,
                        buyerLastName: this.state.profile.lastName,
                        buyerPhoneNumber: this.state.phoneNumber,
                        deliveryAddressCity: this.state.paymentData.deliveryAddressCity,
                        deliveryAddressCountryCode: this.state.paymentData.deliveryAddressCountryCode,
                        deliveryAddressLineOne: this.state.step1SelectedValue.label,
                        deliveryAddressLineTwo: this.state.valueSelectedFromStep1StateInput.label,
                        deliveryAddressLineThree: this.state.address,
                        deliveryAddressPostalCode: this.state.postalCode,
                        //discountCode: this.state.discountCode,
                        discountCode_Or_couponCode: this.state.discountCode,
                        // paymentLocale: this.state.paymentData.paymentLocale,
                        subscriptionType: this.state.paymentData.subscriptionType,
                        subscriptionName: this.state.subscriptionName,
                        paymentType: "SubscriptionPayment",
                        redirectUrlOnFreeDiscountCode: window.location.origin + "/public-timeline",
                        frontEndBaseUrlWithPort: window.location.origin
                  };
                  console.log("JSON payment data : " + JSON.stringify(subscriptionPayment));
                  await paymentApi(subscriptionPayment).then(function (response) {
                        document.getElementById("paymentForm").innerHTML = response;
                  });
            }
            setTimeout(() => {
                  this.submitPForm();
            }, 500);
      };

      submitPForm = () => {
            document.forms["integration-form"].submit();
      };

      handlePaymentSubscriptionChange = (event) => {
            const name = event.target.name,
                  val = event.target.value;
            this.setState({
                  [name]: val
            });

            if (event.target.value === "monthly") {
                  this.setState({
                        subscriptionName: this.state.businessMonthlySubscription
                  });
            }
            if (event.target.value === "yearly") {
                  this.setState({
                        subscriptionName: this.state.businessYearlySubscription
                  });
            }
      };

      handleChange = (event) => {
            const name = event.target.name,
                  val = event.target.value;
            this.setState({
                  [name]: val
            });
            setTimeout(() => {
                  this.validate();
            }, 10);
      };

      validateDiscountCode = async () => {
            console.log("DISCOUNT CODE VALIDATION, code: " + this.state.discountCode);
            const discountCodeValid = await validateDiscountCodeApi(this.state.discountCode);
            console.log("discountCodeValid= " + discountCodeValid);
            this.setState({ discountCodeValid: discountCodeValid });

            this.validateDiscountCodeAlert(discountCodeValid);
      };

      validateDiscountCodeAlert = async (discountCodeValid) => {
            if (discountCodeValid === "valid") {
                  this.setState({
                        Message: "Valid code",
                        messageType: "SUCCESS"
                  });
            } /* else {
                  this.setState({
                        Message: "Code is invalid",
                        messageType: "ERROR"
                  });
            } */
      };

      // When fetching temwizard data in stpe 4 we need to set this.state.couponCode
      // When customer has been created from salesClerk
      setCouponCode = async (couponCodeCreatedBySalesClerkSell) => {
            this.setState({ discountCode: couponCodeCreatedBySalesClerkSell });
      };

      /* Should hit the correct api get countries*/
      getCountries = () => {
            getAllowedCountriesApi().then((response) => {
                  let options = Object.assign(response.data).map(function (countries) {
                        return { value: countries.countryCode, label: countries.name };
                  });
                  //this.setState({ value: options.value });
                  //this.setState({ label: options.label });
                  this.setState({ options: options });
            });
      };

      getCurrentlyLoggedInProfile = () => {
            getCurrentlyLoggedInProfileApi().then((response) => {
                  //console.log("response profile : " + JSON.stringify(response.dataObject));

                  this.setState({
                        profile: {
                              userId: response.dataObject.userId,
                              displayName: response.dataObject.info.displayName,
                              firstName: response.dataObject.info.firstName,
                              lastName: response.dataObject.info.lastName,
                              profileInfoComplete: response.dataObject.info.profileInfoComplete,
                              profileType: response.dataObject.profileType,
                              companyName: response.dataObject.info.companyName,
                              userProfilePictureId: response.dataObject.userProfilePictureId,
                              userEmail: response.dataObject.info.email,
                              phoneNumber: response.dataObject.info.phoneNumber,
                              postalCode: response.dataObject.info.postalCode,
                              streetAdress: response.dataObject.info.streetAdress,
                              country: response.dataObject.info.originalRegistationCountry,
                              region: response.dataObject.info.region,
                              subscriptionPaid: response.dataObject.subscriptionPaid
                        }
                  });

                  //console.log("user id : " + this.state.profile.userId);
                  console.log("this state current step : " + this.state.currentStep);
                  console.log("this state current step : " + this.state.profile.profileInfoComplete);
                  if (this.state.profile.profileInfoComplete == true) {
                        this.setState({ currentStep: 4 });
                  }
            });
      };

      redirectToLoginHome = () => {
            window.location.replace("/public-timeline");

            //this.props.history.push("/en-UK/timeline/");
      };
      render() {
            let formIsValid = this.state.formIsValid;
            //console.log("form is valid > " + formIsValid);
            const profileType = this.state.profile.profileType;
            const profileInfoComplete = this.state.profile.profileInfoComplete;
            //console.log("profile info complete : " + profileInfoComplete);
            const updateAccountForm = (
                  // <form onSubmit={this.handleSubmit}>
                  <form onSubmit={this.handleSubmit}>
                        <div className="container textBackground">
                              <div className="row sections">
                                    <div className="col-3" />
                                    <div className="col-6" style={{ position: "relative" }}>
                                          {(() => {
                                                switch (profileType) {
                                                      case "user":
                                                            return <ProgressBar currentStep={this.state.currentStep} />;
                                                      case "business":
                                                            return <ProgressBarBusiness currentStep={this.state.currentStep} />;
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          <Step1
                                                profileType={this.state.profile.profileType}
                                                step1SelectedDay={this.state.step1SelectedDay}
                                                step1SelectedValue={this.state.step1SelectedValue}
                                                options={this.state.options}
                                                onChange={this.handleChange}
                                                gender={this.state.gender}
                                                handleChange={this.handleChange}
                                                currentStep={this.state.currentStep}
                                                handleStep1Change={this.handleStep1CountryChange}
                                                handleStep1DayChange={this.handleStep1DayChange}
                                                companyNameInput={this.state.companyNameInput}
                                                address={this.state.address}
                                                postalCode={this.state.postalCode}
                                                /*Select state props*/
                                                handleStep1StateChange={this.handleStep1Statechange}
                                                stateOptions={this.state.stateOptions}
                                                valueSelectedFromStep1StateInput={this.state.valueSelectedFromStep1StateInput}
                                                step1UserBirthYear={this.state.step1UserBirthYear}
                                                handleStep1UserBirthYear={this.handleStep1UserBirthYear}
                                                /* Errors */
                                                companyNameError={this.state.errors.companyName}
                                                streetAddressError={this.state.errors.streetAddress}
                                                postalCodeError={this.state.errors.postalCode}
                                                countryError={this.state.errors.country}
                                                stateError={this.state.errors.state}
                                                dateError={this.state.errors.date}
                                                /* tempWizardProfileData={this.state.tempWizardProfileData} */
                                          />
                                          <Step2
                                                userProfilePictureId={this.state.profile.userProfilePictureId}
                                                profileType={this.state.profile.profileType}
                                                handleChange={this.handleChange}
                                                currentStep={this.state.currentStep}
                                                contactFirstName={this.state.contactFirstName}
                                                contactLastName={this.state.contactLastName}
                                                phoneNumber={this.state.phoneNumber}
                                                vatNumber={this.state.vatNumber}
                                                userId={this.state.profile.userId}
                                                /* Errors */
                                                firstNameError={this.state.errors.contactFirstName}
                                                lastNameError={this.state.errors.contactLastName}
                                                phoneNumberError={this.state.errors.phoneNumber}
                                                vatNumberError={this.state.errors.vatNumber}
                                          />
                                          <Step3
                                                associations={this.state.associations}
                                                profileType={this.state.profile.profileType}
                                                checkboxVals={this.state.checkboxVals}
                                                handleChange={this.handleChange}
                                                competing={this.state.competing}
                                                withHorse={this.state.withHorse}
                                                otherInterests={this.state.otherInterests}
                                                breedingOtherServices={this.state.breedingOtherServices}
                                                constructionOtherServices={this.state.constructionOtherServices}
                                                legalOtherServices={this.state.legalOtherServices}
                                                electronicsOtherServices={this.state.electronicsOtherServices}
                                                feedAndBaitOtherServices={this.state.feedAndBaitOtherServices}
                                                vehicleOtherServices={this.state.vehicleOtherServices}
                                                homeHouseholdGardenOtherServices={this.state.homeHouseholdGardenOtherServices}
                                                hotelsAndTraveOtherServices={this.state.hotelsAndTraveOtherServices}
                                                healthAndBeautyOthterServices={this.state.healthAndBeautyOthterServices}
                                                horseAccessoriesOtherServices={this.state.horseAccessoriesOtherServices}
                                                toysOtherServices={this.state.toysOtherServices}
                                                mediaOtherServices={this.state.mediaOtherServices}
                                                fashionOtherServices={this.state.fashionOtherServices}
                                                leisureOtherServices={this.state.leisureOtherServices}
                                                schoolOtherServices={this.state.schoolOtherServices}
                                                horseRelatedOtherServices={this.state.horseRelatedOtherServices}
                                                businessOtherServices={this.state.businessOtherServices}
                                                handleStep3Change={this.handleStep3Change}
                                                currentStep={this.state.currentStep}
                                          />
                                          <Step4
                                                profileType={this.state.profile.profileType}
                                                getSubscription={this.getSubscription}
                                                handleChange={this.handleChange}
                                                handlePaymentSubscriptionChange={this.handlePaymentSubscriptionChange}
                                                currentStep={this.state.currentStep}
                                                submitData={this.submitData}
                                                profileType={this.state.profile.profileType}
                                                messageType={this.state.messageType}
                                                Message={this.state.Message}
                                                redirectToLoginHome={this.redirectToLoginHome}
                                                subscription={this.state.subscription}
                                                businessMonthlyNetPrice={this.state.businessMonthlyNetPrice}
                                                businessYearlyNetPrice={this.state.businessYearlyNetPrice}
                                                businessYearlyCurrency={this.state.businessYearlyCurrency}
                                                businessMonthlyCurrency={this.state.businessMonthlyCurrency}
                                                validateDiscountCode={this.validateDiscountCode}
                                                discountCodeValid={this.state.discountCodeValid}
                                                subscriptionPaid={this.state.profile.subscriptionPaid}
                                                setCouponCode={this.setCouponCode}
                                                //tempWizardProfileData={this.state.tempWizardProfileData}
                                          />{" "}
                                          <div className="col-3" />
                                    </div>
                              </div>

                              <div className="row sections">
                                    <div className="col-3" />
                                    <div className="col-6" style={{ height: "40px" }}>
                                          <div className="next">{this.NextButton}</div>
                                          <div className="logoutWiz">
                                                <Logout storeTempData={this.storeTempData} currentStep={this.state.currentStep} />
                                          </div>
                                          <div className="back">{this.PreviousButton}</div>
                                    </div>
                                    <div className="col-3" />
                              </div>
                        </div>
                  </form>
            );
            const messageType = this.state.messageType;
            const Alerts = (
                  <React.Fragment>
                        {(() => {
                              if (this.state.profile.profileType === "business") {
                                    switch (messageType) {
                                          case "ERROR": {
                                                return (
                                                      <ErrorWrapper
                                                            cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                            Message={this.state.Message}
                                                            messageType={this.state.messageType}
                                                      />
                                                );
                                          }
                                          case "SUCCESS":
                                                return (
                                                      <SuccessWrapper
                                                            Message={this.state.Message}
                                                            messageType={this.state.messageType}
                                                            cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                      />
                                                );
                                          default:
                                                return null;
                                    }
                              } else if (this.state.profile.profileType === "user") {
                                    return null;
                              }
                        })()}
                  </React.Fragment>
            );

            return (
                  <React.Fragment>
                        {updateAccountForm}
                        {Alerts}

                        <div style={{ overflow: "hidden", height: "0px" }} id="paymentForm" />
                        {/* <div id="paymentForm" /> */}
                  </React.Fragment>
            );
      }
}

export default withRouter(Steps);
