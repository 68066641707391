import axios from "axios";

import getApiURL from "../../common/getAPIUrl";
import {
      getAccessToken,
      getRefreshToken,
      isLoggedIn,
      _responseErrorInterceptor,
      removeCookiesAndRedirectToLoginPage
} from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const logout = async function() {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      const headers = {
            Authorization: "Bearer " + accessToken,
            "cache-control": "no-cache"
      };

      var params = new URLSearchParams();
      params.append("accessToken", accessToken);
      params.append("refreshToken", refreshToken);
      var rootURL = getApiURL();

      const apiEndPoint = "/api/v1/auth-service/users/logout";
      const apiName = "logout";
      const serviceName = "auth-service";

      const baseUrl = rootURL + apiEndPoint;
      //console.log("LOGOUT_API HEADERS:" + JSON.stringify(headers));
      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers,
            params: params
      })
            .then(function(response) {
                  removeCookiesAndRedirectToLoginPage();
                  //sessionStorage.removeItem("profile");
                  return true;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default logout;
