import React, { Component } from "react";
import PropTypes from "prop-types";
import getCurrentlyLoggedInUsersImages from "../../api/gallery-service/getCurrentlyLoggedInUsersImagesApi";
import getUrl from "../../common/getAPIUrl";
import debounce from "lodash.debounce";
import FadeIn from "react-fade-in";
import likeDislikeImageApi from "../../api/gallery-service/likeDislikeImageApi";
import LikeButton from "../../common/likeButton/likeButton";
class MyPictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "my pictures",
      itemsPerPage: 10,
      page: 1,
      galleryItems: [],
      link: ""
    };
  }

  UNSAFE_componentWillMount = async () => {
    await this.setState({ link: getUrl() });
    const changeTitle = this.props.changeTitle;
    changeTitle(this.state.title);

    await this.getAllImages(0);
  };

  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleOnScroll);
  };

  handleOnScroll = debounce(() => {
    if (this.state.noMoreDataToRetrieve == true) return;

    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - 200;

    this.getNextPage(scrolledToBottom);
  }, 200);

  getNextPage = scrolledToBottom => {
    this.setState({ preloading: true });
    var page = this.state.page + 1;
    this.setState({ page: page });
    //console.log("page number " + this.state.page);
    //console.log("scrolled to bottom: " + scrolledToBottom);
    this.getAllImages(page);
  };

  getAllImages = async page => {
    await getCurrentlyLoggedInUsersImages(page, this.state.itemsPerPage).then(
      async function(response) {
        if (response == null) return;
        console.log("gallerItems ==> " + JSON.stringify(response));
        await this.setState({
          galleryItems: [this.state.galleryItems, ...response],
          preloading: false
        });
      }.bind(this)
    );
  };

  likeDislikeImage = async event => {
    const ID = event.currentTarget.id;
    const likeDislikeImage = await likeDislikeImageApi(ID);

    let newGalleryItems = this.state.galleryItems;
    const galleryItemToLookFor = element =>
      element.id == likeDislikeImage.dataObject.id;

    let indexNumber = newGalleryItems.findIndex(galleryItemToLookFor);

    newGalleryItems[indexNumber] = likeDislikeImage.dataObject;
    await this.setState({ galleryItems: newGalleryItems });
  };

  render() {
    const currentlyLoggedInUsersImages = this.state.galleryItems.map(
      (galleryItem, index) => {
        return (
          <FadeIn key={index} transitionDuration={200} delay={200}>
            <li
              key={galleryItem.id}
              id={galleryItem.id}
              className="profileCardUsersWrapper"
            >
              <div
                style={{
                  backgroundImage:
                    "url( " +
                    this.state.link +
                    "/api/v1/gallery-service/" +
                    galleryItem.filename +
                    ")",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "200px",
                  borderRadius: "3px 3px 0px 0px"
                }}
              />
              <div className="galleryFooter" style={{ overflow: "hidden" }}>
                {(() => {
                  //return if dataObject is null
                  //   if (
                  //     galleryItems.minimalProfile.dataObject.companyName != ""
                  //   ) {
                  //     return (
                  //       <span className="newsTitle">
                  //         {galleryItems.minimalProfile.dataObject.companyName}
                  //       </span>
                  //     );
                  //   }
                  //   if (
                  //     galleryItems.minimalProfile.dataObject.displayName != ""
                  //   ) {
                  //     return (
                  //       <span className="newsTitle">
                  //         {galleryItems.minimalProfile.dataObject.displayName}
                  //       </span>
                  //     );
                  //   }
                  /*  if (galleryItems.minimalProfile.dataObject.firstName != "") {
                    return (
                      <span className="newsTitle">
                        {galleryItems.minimalProfile.dataObject.firstName +
                          " " +
                          galleryItems.minimalProfile.dataObject.lastName}
                      </span>
                    );
                  } */
                })()}
                {/* like button */}
                {/* <LikeButton
                  data={index}
                  id={galleryItem.id}
                  likesCount={galleryItem.imageLikeCount}
                  likeDislikeImage={this.likeDislikeImage}
                  likeUserIds={galleryItem.likeUserIds}
                /> */}
              </div>
            </li>
          </FadeIn>
        );
      }
    );
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="grid">{currentlyLoggedInUsersImages}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MyPictures;
