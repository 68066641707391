import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";
const getStatesInCountryApi = async function(countryCode) {
      const headers = {
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };
      //countryCode = "SE";
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/localization-service/state/getStatesInCountry";
      const apiName = "getStatesInCountryApi";
      const serviceName = "localization-service";
      const baseUrl = rootURL + "/api/v1/localization-service/state/getStatesInCountry";

      var params = new URLSearchParams();
      params.append("countryCode", countryCode);

      return await axios({
            method: "get",
            url: baseUrl,
            headers: headers,
            params
      })
            .then(function(response) {
                  ////console.log(JSON.stringify(response));

                  return response;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default getStatesInCountryApi;
