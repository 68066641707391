import React, { Component } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Select from "react-select";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";

import "moment/locale/en-gb";
import moment from "moment";

class Step1 extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  clearable: true,
                  selectedValue: "",
                  placeholder: "Please choose a country",
                  placeHolderState: "Please choose a state",
                  //selectedDay: undefined,
                  //isEmpty: true,
                  //isDisabled: false,
                  //// the horsemetro age limit is 13 years
                  thirteenYearsAgo: new Date(moment().subtract(13, "years").add(1, "days"))
            };
      }
      render() {
            //console.log(this.props.companyName);
            //console.log("profile type : " + this.props.profileType);
            //console.log("%cTHis props => " + JSON.stringify(this.props, null, "\t"), "color:#00C9A7");
            if (this.props.currentStep !== 1) {
                  return null;
            }
            const customStyles = {
                  option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? "red" : "blue",
                        borderBottom: "1px solid rgba(240,240,240,1)",
                        backgroundColor: "rgba(240,240,240,0.2)",
                        padding: "10px"
                  }),
                  control: () => ({
                        border: "1px solid rgba(240,240,240,1)",
                        webkitAlignItems: "center",
                        webkitBoxAlign: "center",
                        msFlexAlign: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(240,240,240,0.2)",
                        border: " 1px solid rgba(240,240,240,1)",
                        display: "-webkit-box",
                        display: "-webkit-flex",
                        display: "-ms-flexbox",
                        webkitFlexWrap: "wrap",
                        webkitJustifyContent: "space-between",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        minHeight: "48px",
                        position: "relative",
                        webkitTransition: "all 100ms",
                        transition: "all 100ms",
                        boxSizing: "border-box",
                        padding: "8px"
                  }),
                  indicatorsContainer: () => ({
                        position: "absolute",
                        top: "4px",
                        right: "10px"
                  }),
                  menu: () => ({
                        backgroundColor: "rgba(255,255,255,1)",
                        border: "1px solid rgba(204,204,204, 0.2)",
                        position: "absolute",
                        width: "99.7%",
                        zIndex: "1001"
                  }),
                  option: () => ({
                        padding: "10px",
                        borderBottom: "1px solid rgba(240, 240, 240, 1)",
                        "&:hover": { backgroundColor: "rgba(240,240,240,0.2)" }
                  })
            };
            const { selectedDay, isDisabled, isEmpty } = this.state;
            const profileType = this.props.profileType;
            let isValid = this.state.isValid ? "no-errors" : "errors";

            return (
                  <React.Fragment>
                        {(() => {
                              switch (profileType) {
                                    case "user":
                                          return (
                                                <React.Fragment>
                                                      <label className="formLabel alignLeft">* Country</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"test"}
                                                            value={this.props.step1SelectedValue}
                                                            label={this.props.step1SelectedValue}
                                                            onChange={this.props.handleStep1Change}
                                                            options={this.props.options}
                                                            placeholder={this.state.placeholder}
                                                      />
                                                      <span className={isValid}>{this.props.countryError}</span>
                                                      <label className="formLabel alignLeft">* State</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"state"}
                                                            label={this.props.valueSelectedFromStep1StateInput}
                                                            value={this.props.valueSelectedFromStep1StateInput}
                                                            onChange={this.props.handleStep1StateChange}
                                                            options={this.props.stateOptions}
                                                            placeholder={this.state.placeHolderState}
                                                      />
                                                      <span className={isValid}>{this.props.stateError}</span>

                                                      <label className="formLabel alignLeft">* Date of Birth</label>
                                                      <React.Fragment>
                                                            {(() => {
                                                                  const year = this.state.thirteenYearsAgo.getFullYear();
                                                                  return (
                                                                        <div className="select-wrapper">
                                                                              <select
                                                                                    className="costom"
                                                                                    defaultValue={
                                                                                          `${this.props.step1UserBirthYear}`
                                                                                                ? `${this.props.step1UserBirthYear.getFullYear()}`
                                                                                                : "Select the year you were born"
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                          this.props.handleStep1UserBirthYear(
                                                                                                new Date(e.target.value)
                                                                                          );
                                                                                    }}
                                                                              >
                                                                                    <option disabled hidden>
                                                                                          {`${this.props.step1UserBirthYear}`
                                                                                                ? `${this.props.step1UserBirthYear.getFullYear()}`
                                                                                                : "Select the year you were born"}
                                                                                    </option>
                                                                                    {Array.from(new Array(110), (v, i) => (
                                                                                          <option key={i} value={year - i}>
                                                                                                {year - i}
                                                                                          </option>
                                                                                    ))}
                                                                              </select>
                                                                        </div>
                                                                  );
                                                            })()}
                                                            {this.props.step1UserBirthYear && (
                                                                  <DayPickerInput
                                                                        value={this.props.step1SelectedDay}
                                                                        onDayClick={this.props.handleDayClick}
                                                                        placeholder="Select the month and day you were born"
                                                                        formatDate={formatDate}
                                                                        parseDate={parseDate}
                                                                        format="LL"
                                                                        // placeholder={`${formatDate(new Date(), "LL", "en-gb")}`}
                                                                        onDayChange={this.props.handleStep1DayChange}
                                                                        dayPickerProps={{
                                                                              locale: "en-gb",
                                                                              localeUtils: MomentLocaleUtils,
                                                                              initialMonth: this.props.step1UserBirthYear,
                                                                              fromMonth: new Date(moment(this.props.step1UserBirthYear)),
                                                                              toMonth: new Date(
                                                                                    moment(this.props.step1UserBirthYear).add(11, "months")
                                                                              ),
                                                                              disabledDays: {
                                                                                    from: this.state.thirteenYearsAgo,
                                                                                    to: new Date()
                                                                              }
                                                                        }}
                                                                  />
                                                            )}
                                                      </React.Fragment>
                                                      <div style={{ width: "100%", overflow: "hidden" }}>
                                                            <span className={isValid}>{this.props.dateError}</span>
                                                      </div>
                                                      <label className="formLabel alignLeft">Gender</label>
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="radio"
                                                                  value="male"
                                                                  name="gender"
                                                                  onChange={this.props.handleChange}
                                                                  checked={this.props.gender == "male"}
                                                            />
                                                            Male
                                                      </label>
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="radio"
                                                                  value="female"
                                                                  name="gender"
                                                                  onChange={this.props.handleChange}
                                                                  checked={this.props.gender == "female"}
                                                            />
                                                            Female
                                                      </label>
                                                      <label className="checkLabel">
                                                            <input
                                                                  type="radio"
                                                                  value="other"
                                                                  name="gender"
                                                                  onChange={this.props.handleChange}
                                                                  checked={this.props.gender == "other"}
                                                            />
                                                            Other
                                                      </label>
                                                </React.Fragment>
                                          );
                                    case "business":
                                          return (
                                                <React.Fragment>
                                                      <label className="formLabel alignLeft">* Company Name</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="companyNameInput"
                                                            placeholder="company name"
                                                            value={this.props.companyNameInput}
                                                            onChange={this.props.handleChange}
                                                      />
                                                      <span className={isValid}>{this.props.companyNameError}</span>
                                                      <label className="formLabel alignLeft">* Country</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"country"}
                                                            value={this.props.step1SelectedValue}
                                                            onChange={this.props.handleStep1Change}
                                                            options={this.props.options}
                                                            placeholder={this.state.placeholder}
                                                      />
                                                      <span className={isValid}>{this.props.countryError}</span>

                                                      <label className="formLabel alignLeft">* State</label>
                                                      <Select
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            name={"state"}
                                                            value={this.props.valueSelectedFromStep1StateInput}
                                                            onChange={this.props.handleStep1StateChange}
                                                            options={this.props.stateOptions}
                                                            placeholder={this.state.placeHolderState}
                                                      />
                                                      <span className={isValid}>{this.props.stateError}</span>

                                                      <label className="formLabel alignLeft">* Street address</label>
                                                      <input
                                                            type="text"
                                                            className="textinput"
                                                            name="address"
                                                            placeholder="street address"
                                                            value={this.props.address}
                                                            onChange={this.props.handleChange}
                                                      />
                                                      <span className={isValid}>{this.props.streetAddressError}</span>
                                                      <label className="formLabel alignLeft">* Postal code</label>
                                                      <input
                                                            type="text"
                                                            name="postalCode"
                                                            className="textinput"
                                                            placeholder="postal code"
                                                            value={this.props.postalCode}
                                                            onChange={this.props.handleChange}
                                                      />
                                                      <span className={isValid}>{this.props.postalCodeError}</span>
                                                </React.Fragment>
                                          );
                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );
      }
}

export { Step1 };
