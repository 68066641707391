import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";
import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";

const getMyAlbumsApi = async function(page, itemsPerPage) {
      const accessToken = getAccessToken();

      const headers = {
            Authorization: "Bearer " + accessToken,
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/gallery-service/getMyAlbums";
      const apiName = "getMyAlbumsApi";
      const serviceName = "gallery-service";
      const baseUrl = rootURL + "/api/v1/gallery-service/getMyAlbums?page=" + page + "&itemsPerPage=" + itemsPerPage;

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default getMyAlbumsApi;
