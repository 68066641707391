import axios from "axios";
import { getAccessToken, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
import getApiURL from "../../common/getAPIUrl";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const checkPaymentStatusByOrderNumber = async function(orderNumber, status) {
      const accessToken = getAccessToken();
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/payment-service/checkPaymentStatusByOrderNumber";
      const apiName = "checkPaymentStatusByOrderNumber";
      const serviceName = "payment-service";
      const baseUrl = rootURL + "/api/v1/payment-service/checkPaymentStatusByOrderNumber/?orderNumber=" + orderNumber + "&status=" + status;

      const headers = {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
            "cache-control": "no-cache"
      };

      return await axios({
            method: "GET",
            url: baseUrl,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
                  console.log("api ==> " + JSON.stringify(response, null, 2));
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default checkPaymentStatusByOrderNumber;
