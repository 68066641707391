/** This one loops through stateArray and removes element specified by fieldName
 * How to use:
 * 1. Specify a fieldname in the item for Ex "id" or "userId"(something unique) to look for as string
 * 2. Specify a value like a mongoId mongoId
 * 3. Specify an array in the state like here for ex "friendSuggestions" as string
 */

export const removeElementFromArray = (
  fieldName,
  fieldNameValue,
  stateArray
) => {
  var itemSpecifiedByFieldName = stateArray.find(element => {
    return element[fieldName] === fieldNameValue;
  });

  var newFilteredStateArray = stateArray.filter(
    item => item !== itemSpecifiedByFieldName
  );

  return newFilteredStateArray;
};
/** This function loops through stateArray and updates a field specified by fieldNameToFindBy
 * How to use:
 * 1. Specify a fieldname in the item for Ex "id" or "userId"(something unique) to look for as string
 * 2. Specify a value like a mongoId mongoId
 * 3. Specify an array arrayOfelements
 * 4. Specify fieldName where the value should be updated
 * 5. Specify a VAlue that the feildname should be set to
 * @param fieldNameTofindBy a name string for the field we are going to look for
 * @param fieldNameToFindByValue value to find by
 * @param stateArray the array to search for items in
 * @param fieldNameToBeUpdated the fieldname that should be updated
 * @param fieldNameToBeUpdatedValue the value for the fieldNameToBeUpdated
 * @return the new updated array
 */
export const updateFieldInElementInArray = (
  fieldNameTofindBy,
  fieldNameToFindByValue,
  stateArray,
  fieldNameToBeUpdated,
  fieldNameToBeUpdatedValue
) => {
  let index = stateArray.findIndex(
    element => element[fieldNameTofindBy] === fieldNameToFindByValue
  );

  stateArray[index][fieldNameToBeUpdated] = fieldNameToBeUpdatedValue;

  return stateArray;
};
/**  Fiends a element in an array and returns the item found
 * @param fieldNameTofindBy a name string for the field we are going to look for
 * @param fieldNameToFindByValue value to find by
 * @param stateArray the array to search for items in
 * @return the found item
 */
export const getElementInArrayByfieldNameTofindBy = (
  fieldNameTofindBy,
  fieldNameToFindByValue,
  stateArray
) => {
  return stateArray.find(
    element => element[fieldNameTofindBy] === fieldNameToFindByValue
  );
};

export const getStringArraOfSpecifiedFieldValues = (
  fieldNameToExtract,
  stateArray
) => {
  let elementarray = [];
  let i = 0;
  while (stateArray[i] != null) {
    elementarray[i] = stateArray[i][fieldNameToExtract];
    i++;
  }

  return elementarray;
};
