import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "./Tabs";
import PriceComparison from "./priceComparison";
import booleanCheckCountryAccessAPI from "../../api/login-logout/booleanCheckCountryAccessByIPApi";
class RegisterOptionsPopup extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  acccountType: null,
                  preloading: false,
                  isInAllowedCountry: null
            };
      }
      UNSAFE_componentWillMount = () => {
            booleanCheckCountryAccessAPI().then(
                  function (response) {
                        //console.log("loginApi response: " + JSON.stringify(response));
                        this.setState({ isInAllowedCountry: response });
                  }.bind(this)
            );
      };
      render() {
            const Options = () => {
                  return (
                        <div className="joinpopup_body">
                              <Tabs>
                                    <div label="Private user">
                                          <span className="popupmessage">create your private account</span>
                                          <span className="description">
                                                Create your private account and gain access to a new exiting platform for horse lovers.
                                          </span>
                                          <button
                                                id="user"
                                                className="popupgreenButton"
                                                type="submit"
                                                onClick={this.props.closeShowCreateUserAccountPopup}
                                          >
                                                {/* create user account prop */}
                                                private account
                                          </button>
                                    </div>
                                    <div label="Business account">
                                          <span className="popupmessage">create your business account</span>
                                          <span className="description">
                                                Create your business account and gain access to unique features for business owners.
                                          </span>
                                          <button
                                                id="business"
                                                className="popupgoldButton"
                                                type="submit"
                                                onClick={this.props.closeShowCreateUserAccountPopup}
                                          >
                                                {/* create business account prop */}
                                                business account
                                          </button>
                                          <PriceComparison />
                                    </div>
                              </Tabs>
                        </div>
                  );
            };

            const Message = () => {
                  return (
                        <div className="popup_body">
                              <span className="message">HORSEMETRO.COM IS NOT AVAILABLE IN YOUR COUNTRY</span>
                              <br />
                              <br />
                              <span className="description-country">
                                    Currently account registration is unavailable in your country. If you already have an account you can still login.
                              </span>
                        </div>
                  );
            };

            //console.log("is allowed in this country: " + this.state.isInAllowedCountry);
            const isInAllowedCountry = this.state.isInAllowedCountry;
            return (
                  <div className="popup">
                        <div className="joinpopup_inner">
                              <div className="popup_header">
                                    <span className="joinpopup_title">create account</span>
                                    <span className="closeButton" onClick={this.props.closeRegisterOptionsPopup}>
                                          &times;
                                    </span>
                              </div>
                              {(() => {
                                    switch (isInAllowedCountry) {
                                          case true:
                                                return <Options />;
                                          case false:
                                                return <Message />;
                                          default:
                                                return null;
                                    }
                              })()}
                        </div>
                  </div>
            );
      }
}

RegisterOptionsPopup.propTypes = {
      closeRegisterOptionsPopup: PropTypes.func.isRequired,
      closeShowCreateUserAccountPopup: PropTypes.func.isRequired
};

export default withRouter(RegisterOptionsPopup);
