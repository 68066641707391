import React from "react";
import resendActivationLink from "../../api/login-logout/resendActivationLinkApi";
import PropTypes from "prop-types";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import FadeIn from "react-fade-in";
class ResendActivationLinkPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
      isValid: true,
      Message: "",
      messageType: "",
      loadingButton: false
    };
  }
  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.validate();
  };
  validate = () => {
    let errors = {};
    let formIsValid = true;

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const email = this.state.email;

    if (!pattern.test(email)) {
      formIsValid = false;
      errors["email"] = "Check that your email is in a valid format";
    }
    if (email === "") {
      formIsValid = false;
      errors["email"] = "Your email is required.";
    }
    this.setState({ errors });
    this.setState({ isValid: formIsValid });
    this.sendValidatedData(formIsValid);
  };
  sendValidatedData = async formIsValid => {
    this.setState({ loadingButton: true });
    if (formIsValid == true) {
      await resendActivationLink(this.state.email).then(
        function(response) {
          //console.log(JSON.stringify(response));
          const message = response.msgWrapper.msgDescription,
            messageType = response.msgWrapper.msgType;
          //console.log("message : " + message);
          //console.log("message type : " + messageType);
          this.setState({ Message: message });
          this.setState({ messageType: messageType });
          this.setState({ loadingButton: false });
        }.bind(this)
      );
    }

    try {
      setTimeout(async () => {
        this.props.closeActivationLinkPopup();
      }, 2000);
    } catch (e) {
      console.log("error " + e);
    }
  };

  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
    //console.log("SET_MESSAGE_TYPE_TO_NULL : " + this.state.messageType);
  };

  render() {
    const uiTextFields = this.props.uiTextFields;

    const popupHeader = uiTextFields.map((header, index) => (
      <React.Fragment key={"header" + index}>
        {(() => {
          while (header.uiContentId === "LOGIN-SRAL-HEADER-00006")
            return (
              <div id={"header" + index} className="popup_header">
                <span
                  data-translate={header.uiContentId}
                  className="popup_title"
                >
                  {header.text}
                </span>

                <span
                  className="closeButton"
                  onClick={this.props.closeActivationLinkPopup}
                >
                  &times;
                </span>
              </div>
            );
        })()}
      </React.Fragment>
    ));

    const popupDescription = uiTextFields.map((description, index) => (
      <React.Fragment key={"description" + index}>
        {(() => {
          while (description.uiContentId === "LOGIN-SRPL-BODY-00021")
            return (
              <span
                key={description.uiContentId}
                data-translate={description.uiContentId}
                className="description"
              >
                {description.text}
              </span>
            );
        })()}
      </React.Fragment>
    ));

    const popupFormLabel = uiTextFields.map((formLabel, index) => (
      <React.Fragment key={"formLabel" + index}>
        {(() => {
          while (formLabel.uiContentId === "LOGIN-SRPL-FIELD-00003")
            return (
              <label
                key={formLabel.uiContentId}
                data-translate={formLabel.uiContentId}
                id="formLabel"
                className="message"
              >
                {formLabel.text}
              </label>
            );
        })()}
      </React.Fragment>
    ));

    //the input field
    const popupFormInput = uiTextFields.map((formInput, index) => (
      <React.Fragment key={"formInput" + index}>
        {(() => {
          while (formInput.uiContentId === "LOGIN-SRPL-PLACEHOLDER-00004")
            return (
              <React.Fragment>
                <input
                  key={formInput.uiContentId}
                  data-translate={formInput.uiContentId}
                  type="email"
                  name="username"
                  placeholder={formInput.text}
                  onChange={this.handleChangeEmail}
                />
                {/* <span className={isValid}>{this.state.errors.email}</span> */}
              </React.Fragment>
            );
        })()}
      </React.Fragment>
    ));

    // the submit button
    const popupButton = uiTextFields.map((formButton, index) => (
      <React.Fragment key={"formButton" + index}>
        {(() => {
          while (formButton.uiContentId === "LOGIN-SRAL-HEADER-00061")
            return (
              <button
                data-translate={formButton.uiContentId}
                className="goldButton"
                type="submit"
              >
                {this.state.loadingButton ? "loading..." : formButton.text}
              </button>
            );
        })()}
      </React.Fragment>
    ));

    let isValid = this.state.isValid ? "no-errors" : "errors";

    const ResendActivationLinkForm = (
      <div className="popup">
        <div className="popup_inner">
          {popupHeader}
          <div className="popup_body">
            {popupDescription}
            <form onSubmit={this.handleSubmit}>
              {popupFormLabel} {popupFormInput}
              <span className={isValid}>{this.state.errors.email}</span>
              {popupButton}
            </form>
          </div>
        </div>
      </div>
    );

    const messageType = this.state.messageType;

    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "ERROR": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "SUCCESS":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <FadeIn transitionDuration={100} delay={20}>
          {Alerts}
          {ResendActivationLinkForm}
        </FadeIn>
      </React.Fragment>
    );
  }
}
ResendActivationLinkPopup.propTypes = {
  closeActivationLinkPopup: PropTypes.func.isRequired
};
export default ResendActivationLinkPopup;
