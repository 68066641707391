import React, { Component } from "react";
import FadeIn from "react-fade-in";
import addImage from "../../api/gallery-service/addImageApi";
import { timingSafeEqual } from "crypto";
class setingsMenuDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDeletePopup: false,
      toggleEditPopup: false,
      toggleAddPopup: false,
      visibility: "",
      albumNameInput: "",
      albumDescriptionInput: "",
      imageNameInput: "",
      imageDescriptionInput: "",
      hashtags: ""
    };
  }
  UNSAFE_componentWillMount = () => {
    this.setState({
      albumNameInput: this.props.albumName,
      albumDescriptionInput: this.props.albumDescription,
      visibility: this.props.visibility.toString()
    });
  };
  deleteGalleryItem = async event => {
    event.preventDefault();
    var id = event.target.id;
    this.setState({ toggleDeletePopup: !this.state.toggleDeletePopup });
    console.group("DELETE");
    console.log("delete clicked " + id);
    console.log("toggle show delete popup : " + this.state.toggleDeletePopup);
    console.groupEnd();
    this.props.menuItemsDeactivate(id);
  };

  editGalleryItem = async event => {
    console.log("visibility : " + this.props.visibility);
    event.preventDefault();
    var id = event.target.id;
    await this.setState({ toggleEditPopup: !this.state.toggleEditPopup });
    console.group("EDIT");
    console.log("edit clicked " + id);
    console.log("toggle show edit popup : " + this.state.toggleEditPopup);
    console.groupEnd();
    //parent function
    this.props.menuItemsDeactivate(id);
  };

  addGalleryItems = async event => {
    event.preventDefault();
    var id = event.target.id;
    this.setState({ toggleAddPopup: !this.state.toggleAddPopup });
    console.group("ADD");
    console.log("edit clicked " + id);
    console.log("toggle show edit popup : " + this.state.toggleAddPopup);
    console.groupEnd();
    // parent function
    this.props.menuItemsDeactivate(id);
  };

  // add image to the gallery
  addImage = async event => {
    var id = event.target.id;

    // await addImage(data).then(
    //   async function(response) {
    //     console.log("response length : " + JSON.stringify(response));
    //   }.bind(this)
    // );

    this.setState({ toggleAddPopup: !this.state.toggleAddPopup });

    console.group(" ADD IMAGE DATA");
    console.log(" albumId : " + id);
    console.log("hashtags : " + this.state.hashtags);
    console.log("visibility : " + this.state.visibility);
    console.log("image title : " + this.state.imageNameInput);
    console.log("image description : " + this.state.imageDescriptionInput);
    console.log("image data : " + "none yet");
    console.groupEnd("-------------------");

    // const formData = new FormData();
    // formData.append("imgdata");
    // formData.append("visibility", this.state.visibility);
  };

  handleChange = event => {
    const name = event.target.name,
      val = event.target.value;
    this.setState({
      [name]: val
    });
  };

  render() {
    const deletePopup = (
      <>
        {(() => {
          if (this.state.toggleDeletePopup == true) {
            return (
              <div className="popup">
                <div className="popup_inner_light">
                  <div className="popup_header_light">
                    <span className="popup_title_light">
                      delete this album?
                    </span>
                    <span
                      className="closeButton closeButton_light"
                      onClick={this.deleteGalleryItem}
                    >
                      &times;
                    </span>
                  </div>
                  <div style={{ padding: "0px 30px 20px 30px" }}>
                    <label className="formLabel">
                      are you sure you want to delete this album ?
                    </label>
                    <div className="buttonLink greenButton">delete album</div>
                    <div
                      onClick={this.deleteGalleryItem}
                      className="buttonLink goldButton"
                    >
                      cancel
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })()}
      </>
    );

    const editPopup = (
      <>
        {(() => {
          if (this.state.toggleEditPopup == true) {
            return (
              <div className="popup">
                <div className="popup_inner_light">
                  <div className="popup_header_light">
                    <span className="popup_title_light">edit this album</span>
                    <span
                      className="closeButton closeButton_light"
                      onClick={this.editGalleryItem}
                    >
                      &times;
                    </span>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      overflow: "hidden",
                      padding: "0px 30px 30px 30px"
                    }}
                  >
                    <label className="formLabel">album visibility</label>
                    <label className="checkLabel">
                      <input
                        type="radio"
                        value="0"
                        name="visibility"
                        onChange={this.handleChange}
                        checked={this.state.visibility == "0"}
                      />
                      Private
                    </label>

                    <label className="checkLabel">
                      <input
                        type="radio"
                        value="1"
                        name="visibility"
                        onChange={this.handleChange}
                        checked={this.state.visibility == "1"}
                      />
                      Friends
                    </label>

                    <label className="checkLabel">
                      <input
                        type="radio"
                        value="2"
                        name="visibility"
                        onChange={this.handleChange}
                        checked={this.state.visibility == "2"}
                      />
                      Public
                    </label>
                    <label className="formLabel">Album name</label>
                    {(() => {
                      //console.log(">>>>>>>>>>>" + this.state.albumNameInput);
                      if (this.state.albumNameInput == "Uploads") {
                        return (
                          <input
                            type="text"
                            className="textinput"
                            name="albumNameInput"
                            placeholder="Album name"
                            value="uploads"
                            readOnly
                            //onChange={this.handleChange}
                          />
                        );
                      } else {
                        return (
                          <input
                            type="text"
                            className="textinput"
                            name="albumNameInput"
                            placeholder="Album name"
                            value={this.state.albumNameInput}
                            onChange={this.handleChange}
                          />
                        );
                      }
                    })()}

                    <label className="formLabel">Album description</label>

                    {(() => {
                      if (this.state.albumNameInput == "Uploads") {
                        return (
                          <input
                            type="text"
                            className="textinput"
                            name="albumDescriptionInput"
                            placeholder="Album description"
                            value={this.state.albumDescriptionInput}
                            onChange={this.handleChange}
                            readOnly
                          />
                        );
                      } else {
                        return (
                          <input
                            type="text"
                            className="textinput"
                            name="albumDescriptionInput"
                            placeholder="Album description"
                            value={this.state.albumDescriptionInput}
                            onChange={this.handleChange}
                          />
                        );
                      }
                    })()}

                    <div className="galleryBtnWrapper">
                      <label className="galleryUploadBtn goldButton">
                        choose photo
                        <input type="file" className="fileInput"></input>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })()}
      </>
    );

    const addPopup = (
      <>
        {(() => {
          if (this.state.toggleAddPopup == true) {
            return (
              <div className="popup">
                <div className="popup_inner_light">
                  <div className="popup_header_light">
                    <span className="popup_title_light">
                      add images to this album
                    </span>
                    <span
                      className="closeButton closeButton_light"
                      onClick={this.addGalleryItems}
                    >
                      &times;
                    </span>
                  </div>

                  <div
                    style={{
                      height: "auto",
                      overflow: "hidden",
                      padding: "0px 30px 30px 30px"
                    }}
                  >
                    <label className="formLabel">image visibility</label>
                    <label className="checkLabel">
                      <input
                        type="radio"
                        value="0"
                        name="visibility"
                        onChange={this.handleChange}
                        checked={this.state.visibility == "0"}
                      />
                      Private
                    </label>

                    <label className="checkLabel">
                      <input
                        type="radio"
                        value="1"
                        name="visibility"
                        onChange={this.handleChange}
                        checked={this.state.visibility == "1"}
                      />
                      Friends
                    </label>

                    <label className="checkLabel">
                      <input
                        type="radio"
                        value="2"
                        name="visibility"
                        onChange={this.handleChange}
                        checked={this.state.visibility == "2"}
                      />
                      Public
                    </label>
                    <label className="formLabel">Image name</label>

                    <input
                      type="text"
                      className="textinput"
                      name="imageNameInput"
                      placeholder="Image name"
                      value={this.state.imageNameInput}
                      onChange={this.handleChange}
                    />

                    <label className="formLabel">image description</label>

                    <input
                      type="text"
                      className="textinput"
                      name="imageDescriptionInput"
                      placeholder="Image description"
                      value={this.state.imageDescriptionInput}
                      onChange={this.handleChange}
                    />

                    <label className="formLabel">hashtags</label>

                    <input
                      type="text"
                      className="textinput"
                      name="hashtags"
                      placeholder="Image description"
                      value={this.state.hashtags}
                      onChange={this.handleChange}
                    />

                    <div className="galleryBtnWrapper">
                      <label className="galleryUploadBtn goldButton">
                        choose photo
                        <input type="file" className="fileInput"></input>
                      </label>
                    </div>

                    <div
                      id={this.props.id}
                      className=" buttonLink goldButton"
                      onClick={this.addImage}
                    >
                      add image
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })()}
      </>
    );

    return (
      <>
        {(() => {
          if (
            this.props.UserId === this.props.id &&
            this.props.clicked == true
          ) {
            return (
              <>
                <div
                  onClick={this.props.menuItemActivate}
                  id={this.props.id}
                  className="settingsMenuDropdownClicked"
                />
                <div className="gallerySettingsMenuContainer">
                  <div className="settingsMenu">
                    {/* delete */}
                    <div
                      className="gallery-navLink"
                      onClick={this.deleteGalleryItem}
                      id={this.props.id}
                    >
                      <span className="settingsMenuDelete" id={this.props.id} />
                      <a
                        href=""
                        className="gallery-linkTitle"
                        id={this.props.id}
                      >
                        delete
                      </a>
                      <span className="gallery-description" id={this.props.id}>
                        delete this album
                      </span>
                    </div>
                    {/* edit */}
                    <div
                      className="gallery-navLink"
                      onClick={this.editGalleryItem}
                      id={this.props.id}
                    >
                      <span className="settingsMenuEdit" id={this.props.id} />
                      <a
                        href=""
                        className="gallery-linkTitle"
                        id={this.props.id}
                      >
                        edit
                      </a>
                      <span className="gallery-description" id={this.props.id}>
                        edit this album
                      </span>
                    </div>
                    {/* add */}
                    <div
                      className="gallery-navLink"
                      onClick={this.addGalleryItems}
                      id={this.props.id}
                      style={{ borderRadius: "0px 0px 3px 3px" }}
                    >
                      <span className="settingsMenuAdd" id={this.props.id} />
                      <a
                        href=""
                        className="gallery-linkTitle"
                        id={this.props.id}
                      >
                        add
                      </a>
                      <span className="gallery-description" id={this.props.id}>
                        add images to this gallery
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          } else if (this.props.clicked == false) {
            return (
              <div
                onClick={this.props.menuItemActivate}
                id={this.props.id}
                className="settingsMenuDropdown"
              />
            );
          } else {
            return (
              <div
                onClick={this.props.menuItemActivate}
                id={this.props.id}
                className="settingsMenuDropdown"
              />
            );
          }
        })()}

        {deletePopup}
        {editPopup}
        {addPopup}
      </>
    );
  }
}

export default setingsMenuDropdown;
