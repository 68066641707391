import React, { Component } from "react";
import login from "../../api/login-logout/loginApi";
import { withRouter } from "react-router-dom";
import ResendActivationLinkPopup from "./ResendActivationLinkPopup";
import SendResetPasswordLinkPopup from "./SendResetPassordLinkPopup";
import PasswordField from "./SmallComponents/PasswordField";
import logout from "../../api/login-logout/logoutApi";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import UserAvatar from "../userAvatar";
import checkDevice from "../checkDevice";
import { getCurrentlyLoggedInProfileFunc } from "../getCurrentlyLoggedInProfile";
import { PROFILE_TYPES } from "../../../constants/ProfileTypes";
import {
      setProfileInfoCompleteCookie,
      setUserIdToCookie,
      setProfileTypeToCookie,
      setUserNameToCookie,
      removeCookiesAndRedirectAccountNotSupported
} from "../cookieAndAccessHandling";

import { checkBusinessAccess } from "../cookieAndAccessHandling";

class LoginForm extends Component {
      constructor(props) {
            super(props);

            this.state = {
                  hidePassword: true,
                  showSendResetPasswordLinkPopoup: false,
                  toggleActivationLinkPopup: false,
                  showLoginPopup: false,
                  password: "",
                  username: "",
                  //ERRORHNADLING
                  messageType: "",
                  Message: "",
                  deviceType: "",
                  loggingInButton: false,
                  uiTextFields: [
                        {
                              uiContentId: "LOGIN-SRPL-HEADER-00001",
                              text: "forgot your password?"
                        },
                        {
                              uiContentId: "LOGIN-SRPL-HEADER-00011",
                              text: "get password reset email"
                        },
                        {
                              uiContentId: "LOGIN-SRPL-BODY-00002",
                              text: "Let’s get you a new one. Enter your Email and we’ll send you a password reset email"
                        },
                        {
                              uiContentId: "LOGIN-SRPL-BODY-00021",
                              text: "Lets send you a new one. Enter your Email and we'll send you a new activation email"
                        },
                        {
                              uiContentId: "LOGIN-SRPL-FIELD-00003",
                              text: "username: (email)"
                        },
                        {
                              uiContentId: "LOGIN-SRPL-PLACEHOLDER-00004",
                              text: "enter your email"
                        },
                        {
                              uiContentId: "LOGIN-SRAL-HEADER-00006",
                              text: "Didn't get your activation email?"
                        },
                        {
                              uiContentId: "LOGIN-SRAL-HEADER-00061",
                              text: "get activation email"
                        },
                        {
                              uiContentId: "LOGIN-BUTTON-HEADER-00007",
                              text: "reset password"
                        },
                        {
                              uiContentId: "LOGIN-BUTTON-HEADER-00008",
                              text: "resend activation link"
                        },
                        {
                              uiContentId: "LOGIN-BUTTON-HEADER-00009",
                              text: "login"
                        },
                        {
                              uiContentId: "LOGIN-HEADER-FIELD-00010",
                              text: "password"
                        },
                        {
                              uiContentId: "LOGIN-HEADER-PLACEHOLER-00011",
                              text: "enter your password"
                        }
                  ]
            };
            this.toggleShowPassword = this.toggleShowPassword.bind(this);
            this.toggleSendResetPasswordLinkPopup = this.toggleSendResetPasswordLinkPopup.bind(this);
            this.toggleActivationLinkPopup = this.toggleActivationLinkPopup.bind(this);
      }

      UNSAFE_componentWillMount = () => {
            //this.props.loggedIn();
            //console.log("is logged in from parent : " + this.props.isLoggedIn);
            this.deviceCheck();
      };

      UNSAFE_componentDidUpdate = () => {
            //console.log("is logged in from parent : " + this.props.isLoggedIn);
            // this.deviceCheck();
      };

      deviceCheck = async () => {
            var device = checkDevice();
            //console.log("device ===== " + device);
            await this.setState({ deviceType: device });
      };

      cleanErrorSuccessMessage = () => {
            this.setState({
                  messageType: null
            });
            //console.log("SET_MESSAGE_TYPE_TO_NULL : " + this.state.messageType);
            this.props.history.push("/");
      };

      handlefieldChange = (event) => {
            this.setState({ [event.target.name]: event.target.value });
            //console.log(event.target.name + " : " + event.target.value);
      };
      toggleLoginPopup = () => {
            this.setState({
                  showLoginPopup: !this.state.showLoginPopup
            });
            //console.log(" : " + this.state.showLoginPopup);
      };
      toggleSendResetPasswordLinkPopup() {
            this.setState({
                  showSendResetPasswordLinkPopoup: !this.state.showSendResetPasswordLinkPopoup
            });
            //console.log("hidePassword : " + this.state.showSendResetPasswordLinkPopoup);
      }

      toggleActivationLinkPopup = () => {
            // event.preventDefault();
            this.setState({
                  showActivationLinkPopup: !this.state.showActivationLinkPopup
            });
            //console.log(
            //  "show activation link popup : " + this.state.showActivationLinkPopup
            //);
      };

      toggleShowPassword() {
            event.preventDefault();
            this.setState({ hidePassword: !this.state.hidePassword });
            //console.log("hidePassword : " + this.state.hidePassword);
      }

      handleSubmit = (event) => {
            event.preventDefault(); // stop the browser from reloading the page

            var formData = new FormData();
            formData.append("scope", "ui");
            formData.append("grant_type", "password");
            formData.append("password", this.state.password);
            formData.append("username", this.state.username);
            // console.log("password : " + this.state.password);
            console.log("username : " + this.state.username);
            this.setState({ loggingInButton: true });

            login(formData).then(
                  function (response) {
                        console.log("response from server: " + JSON.stringify(response));
                        this.setState({ loggingInButton: false });

                        // DONT COMMENT OUT FOLLOWING LOGIC, ITS NEEDED FOR ERROR ALERTS TO POPUP IF
                        // USER PROVIDES WRONG EMAIL OR PASSWORD /TM 2019-11-27
                        if (response == true) {
                              this.props.loggedInOut();
                              this.infoCompleteCheck();
                        } else {
                              this.setState({
                                    messageType: response.messageType,
                                    Message: response.Message
                              });
                        }
                  }.bind(this)
            );
      };

      // Check if profileinfo is complete if not send to stepper
      infoCompleteCheck = async () => {
            //  var paymentStatus = {};

            const profile = await getCurrentlyLoggedInProfileFunc();
            console.log("%cprofile info ==> " + JSON.stringify(profile, null, "\t"), "color:#00C9A7");

            if (profile.profileType !== PROFILE_TYPES.USER && profile.profileType !== PROFILE_TYPES.BUSINESS_USER) {
                  // I use return here to really break execution
                  return removeCookiesAndRedirectAccountNotSupported();
            }
            // profile info ==>
            //   {"userId":"5d356448612cae000bfa9ccf"
            //   "companyName"
            //   displayName":"CHRONOS2021"
            //   "firstName":"Lee"
            //   "lastName":"Gould"
            //   "email":"leegould119@gmail.com"
            //   "profileType":"user"
            //   "profileInfoComplete":true
            //   "userProfilePictureId":"5dc137daa7b11b00a7be8085"
            // }

            //const token = Cookies.get("token");

            setUserIdToCookie(profile.userId);
            setProfileTypeToCookie(profile.profileType);

            setProfileInfoCompleteCookie(profile.profileInfoComplete);

            if (profile.profileInfoComplete == true) {
                  if (profile.displayName === "" || profile.displayName == undefined || profile.displayName == null) {
                        setUserNameToCookie(profile.firstName + " " + profile.lastName);
                  } else {
                        setUserNameToCookie(profile.displayName);
                  }
                  if (profile.profileType == "user") {
                        window.location.replace("/public-timeline");
                  }

                  // PAYMENT FOR BUSINESS USERS
                  if (profile.profileType == "business") {
                        if (profile.companyName === "" || profile.companyName == undefined || profile.companyName == null) {
                              setUserNameToCookie(profile.firstName + " " + profile.lastName);
                        } else {
                              setUserNameToCookie(profile.companyName);
                        }

                        // If chackBusiness returns true go into site else "/create-profile"
                        const allowedToContinueDueToPayment = await checkBusinessAccess();
                        if (allowedToContinueDueToPayment == true) {
                              /* this.props.history.push(
            "/friends-and-followers/find-new-private-users"
          ); */
                              //this.props.history.push("/en-UK/timeline/");
                              window.location.replace("/public-timeline");
                        }
                  }
            } else {
                  this.props.history.push("/create-profile");
            }
      };

      handleOnLogOutButtonClick = async (event) => {
            event.preventDefault();
            setTimeout(() => {
                  logout().then(
                        function (response) {
                              // This is handled in removeCookiesAndRedirectToLoginPage in getToken.js
                              //this.props.loggedInOut();
                              //this.props.history.push("/");
                        }.bind(this)
                  );
            }, 100);
      };

      render() {
            const messageType = this.state.messageType;

            const Alerts = (
                  <React.Fragment>
                        {(() => {
                              switch (messageType) {
                                    case "ERROR": {
                                          return (
                                                <ErrorWrapper
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                      Message={this.state.Message}
                                                      messageType={this.state.messageType}
                                                />
                                          );
                                    }
                                    case "SUCCESS":
                                          return (
                                                <SuccessWrapper
                                                      Message={this.state.Message}
                                                      messageType={this.state.messageType}
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                />
                                          );
                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );

            const resetPasswordLink = this.state.uiTextFields.map((resetPasswordLink, index) => (
                  <React.Fragment key={"resetPasswordLink" + index}>
                        {(() => {
                              while (resetPasswordLink.uiContentId === "LOGIN-BUTTON-HEADER-00007")
                                    return (
                                          <>
                                                {(() => {
                                                      if (this.state.deviceType == "Computer") {
                                                            return (
                                                                  <span
                                                                        data-translate={resetPasswordLink.uiContentId}
                                                                        className="activeMessage"
                                                                        onClick={this.toggleSendResetPasswordLinkPopup.bind(this)}
                                                                  >
                                                                        {resetPasswordLink.text}
                                                                  </span>
                                                            );
                                                      }
                                                      if (this.state.deviceType == "Mobile") {
                                                            return (
                                                                  <span
                                                                        data-translate={resetPasswordLink.uiContentId}
                                                                        className="activeMessageMobile"
                                                                        onClick={this.toggleSendResetPasswordLinkPopup.bind(this)}
                                                                  >
                                                                        {resetPasswordLink.text}
                                                                  </span>
                                                            );
                                                      }
                                                })()}
                                          </>
                                    );
                        })()}
                  </React.Fragment>
            ));

            const resendActivationCode = this.state.uiTextFields.map((resendActivation, index) => (
                  <React.Fragment key={"resendActivation" + index}>
                        {(() => {
                              while (resendActivation.uiContentId === "LOGIN-BUTTON-HEADER-00008")
                                    return (
                                          <>
                                                {(() => {
                                                      if (this.state.deviceType == "Computer") {
                                                            return (
                                                                  <span
                                                                        data-translate={resendActivation.uiContentId}
                                                                        className="activeMessage"
                                                                        onClick={this.toggleActivationLinkPopup.bind(this)}
                                                                  >
                                                                        {resendActivation.text}
                                                                  </span>
                                                            );
                                                      }
                                                      if (this.state.deviceType == "Mobile") {
                                                            return (
                                                                  <span
                                                                        data-translate={resendActivation.uiContentId}
                                                                        className="activeMessageMobile"
                                                                        onClick={this.toggleActivationLinkPopup.bind(this)}
                                                                  >
                                                                        {resendActivation.text}
                                                                  </span>
                                                            );
                                                      }
                                                })()}
                                          </>
                                    );
                        })()}
                  </React.Fragment>
            ));

            const loginButton = this.state.uiTextFields.map((loginButton, index) => (
                  <React.Fragment key={"loginButton" + index}>
                        {(() => {
                              while (loginButton.uiContentId === "LOGIN-BUTTON-HEADER-00009")
                                    return (
                                          <div className="login">
                                                <button
                                                      data-translate={loginButton.uiContentId}
                                                      className="goldButton"
                                                      type="submit"
                                                      style={{ padding: "13px" }}
                                                >
                                                      {this.state.loggingInButton ? "loading..." : loginButton.text}
                                                </button>
                                          </div>
                                    );
                        })()}
                  </React.Fragment>
            ));

            const usernameFormLabel = this.state.uiTextFields.map((formLabel, index) => (
                  <React.Fragment key={"formLabel" + index}>
                        {(() => {
                              while (formLabel.uiContentId === "LOGIN-SRPL-FIELD-00003")
                                    return (
                                          <label
                                                key={formLabel.uiContentId}
                                                data-translate={formLabel.uiContentId}
                                                id="formLabel"
                                                className="message"
                                          >
                                                {formLabel.text}
                                          </label>
                                    );
                        })()}
                  </React.Fragment>
            ));

            const passwordFormLabel = this.state.uiTextFields.map((formLabel, index) => (
                  <React.Fragment key={"formLabel" + index}>
                        {(() => {
                              while (formLabel.uiContentId === "LOGIN-HEADER-FIELD-00010")
                                    return (
                                          <label
                                                key={formLabel.uiContentId}
                                                data-translate={formLabel.uiContentId}
                                                id="formLabel"
                                                className="message"
                                          >
                                                {formLabel.text}
                                          </label>
                                    );
                        })()}
                  </React.Fragment>
            ));

            const headerPasswordField = this.state.uiTextFields.map((passwordField, index) => (
                  <React.Fragment key={"password" + index}>
                        {(() => {
                              while (passwordField.uiContentId === "LOGIN-HEADER-PLACEHOLER-00011")
                                    return (
                                          <PasswordField
                                                data-translate={passwordField.uiContentId}
                                                name="password"
                                                placeholder={passwordField.text}
                                                onHandlefieldChange={this.handlefieldChange}
                                          />
                                    );
                        })()}
                  </React.Fragment>
            ));
            return (
                  <>
                        {(() => {
                              if (this.props.isLoggedIn == false && this.state.deviceType === "Computer") {
                                    return (
                                          <React.Fragment>
                                                {this.state.showActivationLinkPopup ? (
                                                      <ResendActivationLinkPopup
                                                            uiTextFields={this.state.uiTextFields}
                                                            closeActivationLinkPopup={this.toggleActivationLinkPopup.bind(this)}
                                                      />
                                                ) : null}

                                                {this.state.showSendResetPasswordLinkPopoup ? (
                                                      <SendResetPasswordLinkPopup
                                                            uiTextFields={this.state.uiTextFields}
                                                            closePopup={this.toggleSendResetPasswordLinkPopup.bind(this)}
                                                      />
                                                ) : null}

                                                <div className="form">
                                                      <form onSubmit={this.handleSubmit}>
                                                            <div className="username">
                                                                  {usernameFormLabel}
                                                                  <input
                                                                        type="email"
                                                                        name="username"
                                                                        placeholder="enter your email"
                                                                        onChange={this.handlefieldChange}
                                                                  />
                                                                  {resendActivationCode}
                                                            </div>
                                                            <div className="password">
                                                                  {/* <label className="message">password:</label> */}
                                                                  {passwordFormLabel}

                                                                  {headerPasswordField}
                                                                  {resetPasswordLink}
                                                            </div>
                                                            {loginButton}
                                                      </form>
                                                </div>
                                                {Alerts}
                                          </React.Fragment>
                                    );
                              }
                              if (
                                    (this.props.isLoggedIn == false && this.state.deviceType === "Mobile") ||
                                    (this.props.isLoggedIn == false && this.state.deviceType === "iPad")
                              ) {
                                    return (
                                          <React.Fragment>
                                                <div className="loginLinkMobile">
                                                      <span
                                                            data-translate={loginButton.uiContentId}
                                                            onClick={this.toggleLoginPopup}
                                                            className="goldButton"
                                                            type="submit"
                                                            style={{ padding: "13px", borderRadius: "3px" }}
                                                      >
                                                            login
                                                      </span>
                                                </div>

                                                {this.state.showLoginPopup ? (
                                                      <div className="popup">
                                                            <div className="popup_inner">
                                                                  <div className="popup_header">
                                                                        <span className="popup_title">{"login"}</span>

                                                                        <span className="closeButton" onClick={this.toggleLoginPopup}>
                                                                              &times;
                                                                        </span>
                                                                  </div>
                                                                  <div className="popup_body">
                                                                        <div className="form">
                                                                              <form onSubmit={this.handleSubmit}>
                                                                                    <div className="username">
                                                                                          {usernameFormLabel}
                                                                                          <input
                                                                                                type="email"
                                                                                                name="username"
                                                                                                placeholder="enter your email"
                                                                                                onChange={this.handlefieldChange}
                                                                                          />
                                                                                          {resendActivationCode}
                                                                                    </div>
                                                                                    <div className="password">
                                                                                          {/* <label className="message">password:</label> */}
                                                                                          {passwordFormLabel}

                                                                                          {headerPasswordField}
                                                                                          {resetPasswordLink}
                                                                                    </div>
                                                                                    {loginButton}
                                                                              </form>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {Alerts}
                                                      </div>
                                                ) : null}

                                                {this.state.showActivationLinkPopup ? (
                                                      <ResendActivationLinkPopup
                                                            uiTextFields={this.state.uiTextFields}
                                                            closeActivationLinkPopup={this.toggleActivationLinkPopup.bind(this)}
                                                      />
                                                ) : null}

                                                {this.state.showSendResetPasswordLinkPopoup ? (
                                                      <SendResetPasswordLinkPopup
                                                            uiTextFields={this.state.uiTextFields}
                                                            closePopup={this.toggleSendResetPasswordLinkPopup.bind(this)}
                                                      />
                                                ) : null}
                                          </React.Fragment>
                                    );
                              }

                              if (this.props.isLoggedIn == true) {
                                    return (
                                          <React.Fragment>
                                                <UserAvatar
                                                      handleOnLogOutButtonClick={this.handleOnLogOutButtonClick}
                                                      triggerAvatarToReloadProfilePictureUserId={this.props.triggerAvatarToReloadProfilePictureUserId}
                                                />
                                          </React.Fragment>
                                    );
                              }
                        })()}
                  </>
            );
      }
}
LoginForm.propTypes = {};

export default withRouter(LoginForm);
