import $ from "jquery";

const randomiseImages = () => {
  var headerImages = new Array();
  headerImages[0] = "../src/img/header-images/horse2.jpg";
  headerImages[1] = "../src/img/header-images/horse3.jpg";
  headerImages[2] = "../src/img/header-images/horse4.jpg";
  headerImages[3] = "../src/img/header-images/horse5.jpg";
  headerImages[4] = "../src/img/header-images/horse6.jpg";
  headerImages[5] = "../src/img/header-images/horse7.jpg";
  headerImages[6] = "../src/img/header-images/horse8.jpg";
  headerImages[7] = "../src/img/header-images/horse9.jpg";
  headerImages[8] = "../src/img/header-images/horse10.jpg";
  headerImages[9] = "../src/img/header-images/horse11.jpg";
  headerImages[10] = "../src/img/header-images/horse12.jpg";
  headerImages[11] = "../src/img/header-images/horse13.jpg";
  headerImages[12] = "../src/img/header-images/horse14.jpg";
  headerImages[13] = "../src/img/header-images/horse15.jpg";
  headerImages[14] = "../src/img/header-images/horse16.jpg";
  headerImages[15] = "../src/img/header-images/horse17.jpg";
  headerImages[16] = "../src/img/header-images/horse18.jpg";
  headerImages[17] = "../src/img/header-images/horse19.jpg";
  headerImages[18] = "../src/img/header-images/horse20.jpg";
  headerImages[19] = "../src/img/header-images/horse21.jpg";

  const size = headerImages.length,
    rand = Math.floor(size * Math.random());
  $("#controlHeader").css({
    background: "url(" + headerImages[rand] + ")",
    width: "100.5%",
    height: "250px",
    "background-size": "cover",
    "background-repeat": "no-repeat",
    "background-position": "center center",
    "-webkit-transition": "all ease .1s",
    "-moz-transition": "all ease .1s",
    transition: "all ease .1s"
  });
};
//set the interval for randomising the images
const randomiseImagesInterval = () => {
  window.setInterval(function() {
    randomiseImages();
  }, 50000);
};
export default randomiseImagesInterval;
