import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";
import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";
/**
 * @PostMapping("/likeDislikeImage")
	public GlobalWrapper likeDislikeImage(Principal principal, @RequestParam("targetImageId") String targetImageId) {

		return galleryService.likeDislikeImage(principal, targetImageId);
	}
 */
const likeDislikeImageApi = async function(targetImageId) {
      const accessToken = getAccessToken();

      const headers = {
            Authorization: "Bearer " + accessToken,
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/gallery-service/likeDislikeImage";
      const apiName = "likeDislikeImageApi";
      const serviceName = "gallery-service";
      const baseUrl = rootURL + apiEndPoint + "?targetImageId=" + targetImageId;

      return await axios({
            method: "post",
            url: baseUrl,
            headers: headers
      })
            .then(function(response) {
                  return response.data;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default likeDislikeImageApi;
