import React, { Component } from "react";
import getRootURL from "../../common/getRootURl";
class QuickLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLocation: ""
    };
  }
  componentDidMount = () => {
    var currentLocation = window.location.pathname;
    this.setState({ currentLocation: currentLocation });
  };
  friendRequests = async event => {
    event.preventDefault();
    const rootURL = await getRootURL();
    window.location.replace(rootURL + "/friendRequests");
    // window.location.replace(rootURL + "/friendRequestsAndSuggestions");
  };
  friendSuggestions = async event => {
    event.preventDefault();
    const rootURL = await getRootURL();
    window.location.replace(rootURL + "/friendSuggestions");
    // window.location.replace(rootURL + "/friendRequestsAndSuggestions");
  };
  render() {
    console.log("current location : " + this.state.currentLocation);

    return (
      <React.Fragment>
        {(() => {
          if (this.state.currentLocation === "/friendSuggestions") {
            // if (this.state.currentLocation === "/friendRequestsAndSuggestions") {
            return (
              <button
                className="tabsActive"
                onClick={this.friendSuggestions}
                style={{ width: "150px" }}
              >
                people you may know
              </button>
            );
          } else {
            return (
              <button
                className="tabs"
                onClick={this.friendSuggestions}
                style={{ width: "150px" }}
              >
                people you may know
              </button>
            );
          }
        })()}
        {(() => {
          if (this.state.currentLocation === "/friendRequests") {
            // if (this.state.currentLocation === "/friendRequestsAndSuggestions") {
            return (
              <button
                className="tabsActive"
                onClick={this.friendRequests}
                style={{ width: "150px" }}
              >
                new friend requests
              </button>
            );
          } else {
            return (
              <button
                className="tabs"
                onClick={this.friendRequests}
                style={{ width: "150px" }}
              >
                new friend requests
              </button>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

export default QuickLinks;
