import React, { Component } from "react";

export class ProgressBar extends Component {
      render() {
            let currentStep = this.props.currentStep;
            let profileType = this.props.profileType;
            //console.log("profile type : " + profileType);
            //console.log("current step: " + currentStep);

            if (this.props.currentStep == 1) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">personal information</li>
                                    <li>profile image</li>
                                    <li>interests</li>
                                    <li>create account</li>
                              </ul>
                        </div>
                  );
            }

            if (this.props.currentStep == 2) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">personal information</li>
                                    <li className="active">profile image</li>
                                    <li>interests</li>
                                    <li>create account</li>
                              </ul>
                        </div>
                  );
            }
            if (this.props.currentStep == 3) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">personal information</li>
                                    <li className="active">profile image</li>
                                    <li className="active">interests</li>
                                    <li>create account</li>
                              </ul>
                        </div>
                  );
            }
            if (this.props.currentStep == 4) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">personal information</li>
                                    <li className="active">profile image</li>
                                    <li className="active">interests</li>
                                    <li className="active">create account</li>
                              </ul>
                        </div>
                  );
            }
            return null;
      }
}

export class ProgressBarBusiness extends Component {
      render() {
            let currentStep = this.props.currentStep;
            let profileType = this.props.profileType;
            //console.log("profile type : " + profileType);
            //console.log("current step: " + currentStep);

            if (this.props.currentStep == 1) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">company details</li>
                                    <li>contact </li>
                                    <li> services</li>
                                    <li>payment</li>
                              </ul>
                        </div>
                  );
            }

            if (this.props.currentStep == 2) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">company details</li>
                                    <li className="active">contact </li>
                                    <li> services</li>
                                    <li>payment</li>
                              </ul>
                        </div>
                  );
            }
            if (this.props.currentStep == 3) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">company details</li>
                                    <li className="active">contact </li>
                                    <li className="active"> services</li>
                                    <li>payment</li>
                              </ul>
                        </div>
                  );
            }
            if (this.props.currentStep == 4) {
                  return (
                        <div className="progressContainer">
                              <ul style={{ marginLeft: "-40px" }} className="progressbar">
                                    <li className="active">company details</li>
                                    <li className="active">contact </li>
                                    <li className="active"> services</li>
                                    <li className="active">payment</li>
                              </ul>
                        </div>
                  );
            }
            return null;
      }
}
