import "../../css/alerts.css";

import React, { Component } from "react";
import PropTypes from "prop-types";

export class ErrorWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Message: "",
      messageType: this.props.messageType
    };
  }

  componentWillMount = async () => {
    document.addEventListener("mousedown", this.handleClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClick, false);
  };

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.props.cleanErrorSuccessMessage();
  };

  render() {
    console.log("message type : " + this.state.messageType);
    console.log("message " + this.props.Message);
    console.log("error");
    return (
      <div ref={node => (this.node = node)}>
        {(() => {
          if (!this.state.messageType) {
            return;
          } else {
            return (
              <div className="errorMsgWrapper">
                <div className="iconWrapper">
                  <div className="errorIcon" />
                </div>
                <div className="messageWrapper">
                  <span className="errorHeading">{this.props.messageType}</span>
                  <span className="errorMessage">{this.props.Message}</span>
                  <span
                    className="alertsClose"
                    onClick={this.props.cleanErrorSuccessMessage}
                  >
                    &times;
                  </span>
                </div>
              </div>
            );
          }
        })()}
      </div>
    );
  }
}

ErrorWrapper.propTypes = {
  Message: PropTypes.string,
  messageType: PropTypes.string
};

export class SuccessWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Message: "",
      messageType: this.props.messageType,
      hideWrapper: ""
    };
  }
  componentWillMount = async () => {
    document.addEventListener("mousedown", this.handleClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClick, false);
  };
  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };
  handleClickOutside = () => {
    this.props.cleanErrorSuccessMessage();
  };

  render() {
    console.log("message type : " + this.state.messageType);
    console.log("success");
    return (
      <div ref={node => (this.node = node)}>
        {(() => {
          if (!this.state.messageType) {
            return;
          } else {
            return (
              <div className="successMsgWrapper">
                <div className="iconWrapper">
                  <div className="successIcon" />
                </div>
                <div className="messageWrapper">
                  <span className="successHeading">
                    {this.props.messageType}
                  </span>
                  <span className="errorMessage">{this.props.Message}</span>
                  <span
                    className="alertsClose"
                    onClick={this.props.cleanErrorSuccessMessage}
                  >
                    &times;
                  </span>
                </div>
              </div>
            );
          }
        })()}
      </div>
    );
  }
}
SuccessWrapper.propTypes = {
  Message: PropTypes.string,
  messageType: PropTypes.string,
  cleanErrorSuccessMessage: PropTypes.func
};
