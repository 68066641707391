import React, { Component } from "react";

class PurchasesComponent extends Component {
  render() {
    return (
      <section className="section">
        <div className="header">
          <h2>Purchases</h2>
        </div>
        <div className="body" style={{ margin: "10px", minHeight: "339px" }}>
          <p>purchases</p>
        </div>
      </section>
    );
  }
}

export { PurchasesComponent };
