import React, { Component } from "react";
import Footer from "../../common/login/Footer";
import PropTypes from "prop-types";
import FadeIn from "react-fade-in";
import RegisterButton from "../../common/login/SmallComponents/RegisterButton";
import CreateNewUserAccountPopup from "../../common/login/CreateNewUserAccountPopup";
import RegisterOptionsPopup from "../../common/login/RegisterOptionsPopup";

import activateBusinessAccountSoldByASalesClerkApi from "../../api/account-service/activateBusinessAccountSoldByASalesClerkApi";

import loginApi from "../../api/login-logout/loginApi";
import getCurrentlyLoggedInProfile from "../../common/getCurrentlyLoggedInProfile";
import checkIfInitialPassWordChangedToTrueApi from "../../api/login-logout/checkIfInitialPassWordChangedToTrueApi";

import { sleep } from "../../common/goodieUtils";
class AutoActivateSalesClerkCustomerBusinessAccount extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  title: "Activate Business Account",
                  unit_message: "",
                  message: "",
                  showRegisterOptions: false,
                  showCreateNewUserAccountPopup: false,
                  successMessage: "",
                  countDownCounter: "",
                  couponCode: "",
                  password: "",
                  userName: "",
                  currentlyLoggedInProfile: {},
                  initalPasswordChanged: true
            };
            this.toggleShowCreateUserAccountPopup = this.toggleShowCreateUserAccountPopup.bind(this);
      }
      UNSAFE_componentWillMount = async () => {
            const changeTitle = this.props.changeTitle;
            changeTitle(this.state.title);
            //console.log("%ccouponCode => " + this.props.match.params.couponCode, "color:yellow");
            //console.log("%cusername => " + this.props.match.params.userName, "color:cyan");
            //console.log("%cpassword => " + this.props.match.params.password, "color:#00c9a7");

            let urlParams = new URLSearchParams(window.location.search);
            const username = urlParams.get("username");
            const couponCode = urlParams.get("couponCode");
            const password = urlParams.get("password");

            this.setState({ couponCode, username, password });

            console.log("%cusername => " + username, "color:#00c9a7");
            console.log("%couponcode => " + couponCode, "color:#00c9a7");
            console.log("%cpassword => " + password, "color:#00c9a7");

            // CreateAccount
            let accountServiceResponse = await activateBusinessAccountSoldByASalesClerkApi(couponCode);
            console.log("%cAccountServiceResponse " + JSON.stringify(accountServiceResponse, null, "\t"), "color:#00C9A7");
            if (accountServiceResponse.data.msgWrapper.msgType === "SUCCESS") {
                  this.setState({ message: "Successfully created your account HorseMetro.com. Will now login in: ", unit_message: " seconds" });
                  // Login after 2 sseconds*/
                  this.startLoginCounter(2);
            } else {
                  this.setState({ message: "Seems like account might already been created. Try to login. \n" });
            }
      };
      startLoginCounter = async (seconds) => {
            for (let i = seconds; i > 0; i--) {
                  this.setState({ countDownCounter: i });
                  await sleep(1000);
            }

            var payLoad = new FormData();
            payLoad.append("scope", "ui");
            payLoad.append("grant_type", "password");
            payLoad.append("password", this.state.password);
            payLoad.append("username", this.state.username);

            let authServiceResponse = await loginApi(payLoad);
            if (authServiceResponse === true) {
                  console.log("logged in");
                  await this.checkIfInitalPasswordIsChanged();
                  //let currentlyLoggedInProfile = await getCurrentlyLoggedInProfile();
                  //this.setState({ currentlyLoggedInProfile });
            } else {
                  console.log("error login in");
            }
      };
      checkIfInitalPasswordIsChanged = async () => {
            let profileServiceResponse = await checkIfInitialPassWordChangedToTrueApi();
            console.log("%cprofileServiceResponse: " + JSON.stringify(profileServiceResponse, null, "\t"), "color:#00c9a7");
            this.setState({ initalPasswordChanged: profileServiceResponse.dataObject });

            if (profileServiceResponse.dataObject) {
                  // route to create profile
                  window.location.replace("/create-profile");
            } else {
                  //route to change initial password
                  window.location.replace("/intialChangePassword?oldPass=" + this.state.password);
            }
      };
      toggleShowRegisterOptionsLinkPopup = () => {
            this.setState({
                  showRegisterOptions: !this.state.showRegisterOptions
            });
            //console.log("hidePassword : " + this.state.showRegisterOptions);
      };

      toggleShowCreateUserAccountPopup = (event) => {
            console.log("event.target.id: " + event.target.value);
            this.setState({
                  accountType: event.currentTarget.getAttribute("id")
            });

            this.setState({
                  showCreateNewUserAccountPopup: !this.state.showCreateNewUserAccountPopup,
                  showRegisterOptions: false
            });

            //console.log(
            //  "showCreateNewUserAccountPopup : " +
            //    this.state.showCreateNewUserAccountPopup
            //);
      };

      renderLogic = () => {
            return (
                  <React.Fragment>
                        <RegisterButton
                              style={{
                                    top: "12px",
                                    width: "69px",
                                    paddingLeft: "10px",
                                    right: "100px",
                                    left: "52.7%"
                              }}
                              showActivationLinkPopup={this.toggleShowRegisterOptionsLinkPopup.bind(this)}
                        />
                        {this.state.showRegisterOptions ? (
                              <RegisterOptionsPopup
                                    closeRegisterOptionsPopup={this.toggleShowRegisterOptionsLinkPopup}
                                    closeShowCreateUserAccountPopup={this.toggleShowCreateUserAccountPopup}
                              />
                        ) : null}
                        {this.state.showCreateNewUserAccountPopup ? (
                              <CreateNewUserAccountPopup
                                    accountType={this.state.accountType}
                                    closeCreateNewUserAccountPopup={this.toggleShowCreateUserAccountPopup}
                              />
                        ) : null}
                        <div>
                              <FadeIn transitionDuration={200} delay={200}>
                                    <div className="container textBackground">
                                          <div className="row sections">
                                                <div className="col-4" />
                                                <div className="col-5 ">
                                                      <div className="iconContainer greenColor">
                                                            <span className="keyFraturesIcon" />
                                                      </div>
                                                      <div className="subheaderContainer">
                                                            <h3>Creating Account ... </h3>
                                                      </div>

                                                      <p>
                                                            {this.state.message}
                                                            <span style={{ color: "red" }}>{this.state.countDownCounter}</span>
                                                            {this.state.unit_message}
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                              </FadeIn>
                              <Footer />
                        </div>
                  </React.Fragment>
            );
      };

      render() {
            return <React.Fragment>{this.renderLogic()}</React.Fragment>;
      }
}
AutoActivateSalesClerkCustomerBusinessAccount.propTypes = {
      title: PropTypes.string.isRequired,
      changeTitle: PropTypes.func.isRequired
};
export default AutoActivateSalesClerkCustomerBusinessAccount;
