import React, { Component } from "react";
import getSubscriptionListApi from "../../../api/payment/getSubscriptionListByUserIdApi";
import activatDeactivateSubscription from "../../../api/payment/activateDeactivateSubscription";
import { ErrorWrapper, SuccessWrapper } from "../../alerts";
class SubscriptionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionList: [],
      subscriptionActive: "",
      subscriptionType: "",
      subscriptionName: "",
      messageType: "",
      Message: "",
      subscriptionProcessingMsg: "",
      showModal: false,
      modalText: "",
      showAcceptButton: false
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      subscriptionProcessingMsg: this.props.subscriptionProcessingMsg
    });
    console.log("props==>" + this.props.subscriptionProcessingMsg);
  };
  UNSAFE_componentWillMount = async () => {
    this.getSubscriptionList();
  };

  getSubscriptionList = async () => {
    let globalWrapper = await getSubscriptionListApi();
    this.setState({ subscriptionList: globalWrapper.dataObject });

    if (this.props.handleSubscriptionActiveAndPaymentFalse != null) {
      this.props.handleSubscriptionActiveAndPaymentFalse(
        this.state.subscriptionList
      );
    }
    this.setSubscription();
  };

  confirmHandleSwitchChange = event => {
    console.log(
      "this.state.subscriptionActive " + this.state.subscriptionActive
    );
    const isFree = event.currentTarget.getAttribute("isfree");
    const freeUntil = event.currentTarget.getAttribute("freeuntil");
    const renewalDate = event.currentTarget.getAttribute("renewaldate");

    console.log("isFReeeeeeee " + isFree);

    const isFreeModalText =
      "This account has a free subscription until " +
      freeUntil +
      ". So there is no need for activation of this account yet. You will be rerouted through the account setup process when this free subscription expires";
    const modalNotFreeText =
      "Your subscription expires (" +
      renewalDate +
      "). By accepting this you will be charged with the amount that this subscription cost upon expire date ";

    if (isFree === "true") {
      this.setState({ modalText: isFreeModalText });
    } else {
      this.setState({ modalText: modalNotFreeText, showAcceptButton: true });
    }
    if (this.state.subscriptionActive == true) {
      this.setState({ showModal: true });
    } else {
      this.handleSwitchChange();
    }
  };

  handleSwitchChange = async event => {
    this.hideModal();

    this.setState({ subscriptionActive: !this.state.subscriptionActive });

    if (location.pathname == "/payment/renewSubscription") {
      if (this.state.subscriptionActive == true) {
        this.setState({
          subscriptionProcessingMsg:
            "Your account is being processed for activation. Check again after 24 hours."
        });
      } else {
        this.setState({ subscriptionProcessingMsg: "" });
      }
    }
    await this.state.subscriptionList.map(subscription => {
      this.setState({
        subscriptionType: subscription.subscriptionType,
        subscriptionName: subscription.subscriptionName
      });
    });

    const payloadData = new Object();
    payloadData.subscriptionType = this.state.subscriptionType;
    payloadData.subscriptionName = this.state.subscriptionName;

    if (this.state.subscriptionActive == false) {
      payloadData.activate = true;
    } else {
      payloadData.activate = false;
    }

    this.sendActivateDeactivateData(payloadData);
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  setSubscription = async () => {
    this.state.subscriptionList.map(async subscription => {
      if (subscription.isSubscriptionStopped === "false") {
        this.setState({
          subscriptionActive: false
        });
      } else {
        this.setState({
          subscriptionActive: true
        });
      }
    });
  };
  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
  };

  sendActivateDeactivateData = async payloadData => {
    await activatDeactivateSubscription(payloadData).then(
      function(response) {
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        this.setState({ Message: message });

        this.setState({ messageType: messageType });
        return message, messageType;
      }.bind(this)
    );
  };

  render() {
    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "ERROR": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "SUCCESS":
              return (
                <React.Fragment>
                  {(() => {
                    if (
                      this.props.activateMsg != null &&
                      this.state.subscriptionActive == false
                    ) {
                      return (
                        <SuccessWrapper
                          Message={this.props.activateMsg}
                          messageType={this.state.messageType}
                          cleanErrorSuccessMessage={
                            this.cleanErrorSuccessMessage
                          }
                        />
                      );
                    } else {
                      return (
                        <SuccessWrapper
                          Message={this.state.Message}
                          messageType={this.state.messageType}
                          cleanErrorSuccessMessage={
                            this.cleanErrorSuccessMessage
                          }
                        />
                      );
                    }
                  })()}
                </React.Fragment>
              );

            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    const subscritions = this.state.subscriptionList.map(
      (subscription, index) => {
        return (
          <React.Fragment key={"index" + index}>
            <div className="cols">
              <label className="formLabel alignLeft">subscription type</label>
              {(() => {
                if (subscription.subscriptionType === "account_subscription") {
                  return (
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "0px"
                      }}
                    >
                      account subscription
                    </p>
                  );
                }
              })()}
            </div>
            <div className="cols">
              <label className="formLabel alignLeft">subscription name</label>
              {(() => {
                if (
                  subscription.subscriptionName ===
                    "business_yearly_subscription" &&
                  subscription.isFree == "false"
                ) {
                  return (
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "0px"
                      }}
                    >
                      {" "}
                      business yearly subscription
                    </p>
                  );
                }
              })()}

              {(() => {
                if (
                  subscription.subscriptionName ===
                    "business_monthly_subscription" &&
                  subscription.isFree === "false"
                ) {
                  return (
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "0px"
                      }}
                    >
                      {" "}
                      business monthly subscription
                    </p>
                  );
                }
              })()}
              {(() => {
                if (subscription.isFree === "true") {
                  return (
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "0px"
                      }}
                    >
                      {" "}
                      Free{" "}
                    </p>
                  );
                }
              })()}
            </div>
            <div className="cols">
              <label className="formLabel alignLeft">created date</label>
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "0px"
                }}
              >
                {subscription.createdDateTime}
              </p>
            </div>
            <div className="cols">
              <label className="formLabel alignLeft">renewal date</label>
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "0px"
                }}
              >
                {(() => {
                  if (subscription.isFree === "true") {
                    return "Free until " + subscription.freeUntil;
                  } else subscription.isFree === "false";
                  {
                    return subscription.nextRecurringPaymentDateTime;
                  }
                })()}
              </p>
            </div>
            <div className="cols">
              <label className="formLabel alignLeft">subscription active</label>

              <label
                id="subscription"
                className="switch"
                isfree={subscription.isFree}
                freeuntil={subscription.freeUntil}
                renewaldate={subscription.nextRecurringPaymentDateTime}
                paymentstatus={subscription.nextRecurringPaymentDateTime}
                onClick={this.confirmHandleSwitchChange}
              >
                {(() => {
                  if (this.state.subscriptionActive == true) {
                    return <span className="slider round" />;
                  } else {
                    return <span className="sliderActive round" />;
                  }
                })()}
              </label>
            </div>
          </React.Fragment>
        );
      }
    );
    const modal = (
      <React.Fragment>
        {(() => {
          if (this.state.showModal) {
            return (
              <div className="modal display-block">
                <section className="modal-main">
                  <p
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "160%"
                    }}
                  >
                    Confirm
                  </p>
                  <p
                    style={{
                      align: "center",
                      width: "300px",
                      margin: "10px auto"
                    }}
                  >
                    {this.state.modalText}{" "}
                  </p>

                  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  >
                    <button
                      className="goldButton"
                      style={{
                        width: "150px",
                        margin: "auto",
                        margin: "20px 20px",
                        display: "inline-block"
                      }}
                      onClick={this.hideModal}
                    >
                      cancel
                    </button>
                    {(() => {
                      if (this.state.showAcceptButton) {
                        //if (true) {
                        return (
                          <button
                            className="goldButton"
                            style={{
                              width: "150px",
                              margin: "auto",
                              display: "inline-block"
                            }}
                            onClick={this.handleSwitchChange}
                          >
                            accept
                          </button>
                        );
                      }
                    })()}
                  </div>
                </section>
              </div>
            );
          } else {
            return null;
          }
        })()}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        {modal}
        {Alerts}
        <section className="section">
          <div className="header">
            <h2> Subscriptions</h2>
          </div>{" "}
          <p
            style={{
              textAlign: "center",
              color: "rgba(255,0,0,1)",
              fontFamily: "Open Sans Condensed, sans-serif",
              textTransform: "uppercase",
              fontWeight: "800"
            }}
          >
            {this.state.subscriptionProcessingMsg
              ? this.state.subscriptionProcessingMsg
              : ""}
          </p>
          <div className="body" style={{ margin: "10px", minHeight: "339px" }}>
            {subscritions}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export { SubscriptionsComponent };
