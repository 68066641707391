import React from "react";
import { withRouter } from "react-router-dom";
//import components required in login
import LoginNavigation from "../../common/login/loginNavigation";
import Footer from "../../common/login/Footer";
import PasswordField from "../../common/login/SmallComponents/PasswordField";
import PropTypes from "prop-types";
import resetPasswordApi from "../../api/login-logout/resetPassWordApi";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import FadeIn from "react-fade-in";

class ResetPasswordLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordVerify: "",
      /* success/error-handling */
      errors: {},
      Message: "",
      messageType: "",
      isPassWordLengthValid: true,
      isPasswordMatchValid: true,
      passwordMinimumLength: 6,
      readyToSubmit: false,
      validationAttempts: 0
    };
  }

  goToLoginPage = () => {
    this.props.history.push("/");
  };
  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
    //console.log("SET_MESSAGE_TYPE_TO_NULL : " + this.state.messageType);
    this.props.history.push("/");
  };

  handlefieldChangeP = event => {
    event.preventDefault();

    //this.setState({ [event.target.name]: event.target.value });
    this.setState({ password: event.target.value });
    if (event.target.value.length >= this.state.passwordMinimumLength) {
      this.setState({ isPassWordLengthValid: true });
    }
    if (this.state.passwordVerify == event.target.value) {
      this.setState({ isPasswordMatchValid: true });
    }
  };

  handlefieldChangeV = event => {
    this.setState({ passwordVerify: event.target.value });

    if (this.state.password == event.target.value) {
      this.setState({ isPasswordMatchValid: true });
    }
  };
  validate = async event => {
    event.preventDefault(); // stop the browser from reloading the page
    let errors = {};

    const password = this.state.password;

    let isPassWordLengthValid = true;
    let isPasswordMatchValid = true;

    if (password.length <= this.state.passwordMinimumLength - 1) {
      isPassWordLengthValid = false;
      errors["passwordlength"] =
        "Your password must be at least 6 characters long.";

      this.setState({ errors: errors });
    }

    const passwordVerify = this.state.passwordVerify;
    if (password != passwordVerify) {
      isPasswordMatchValid = false;
      errors["passwordverify"] = "Passwords do not match.";
      this.setState({ errors: errors });
    }
    await this.setState({
      isPassWordLengthValid,
      isPasswordMatchValid,
      validationAttempts: this.state.validationAttempts + 1
    });

    if (
      this.state.isPassWordLengthValid &&
      this.state.isPasswordMatchValid &&
      this.state.validationAttempts > 0
    ) {
      console.log("ready to submit");
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    resetPasswordApi(
      this.state.passwordVerify,
      this.props.match.params.passwordResetToken
    ).then(
      function(response) {
        this.setState({
          messageType: response.msgType,
          Message: response.msgDescription
        });
      }.bind(this)
    );

    try {
      setTimeout(async () => {
        this.goToLoginPage();
      }, 3000);
    } catch (e) {
      console.log("error " + e);
    }
  };

  render() {
    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "ERROR": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "SUCCESS":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    const password = "password";
    const passWordVerify = "passwordVerify";

    let isPassWordLengthValidClass = this.state.isPassWordLengthValid
      ? "no-errors"
      : "errors";
    let isPasswordMatchValidClass = this.state.isPasswordMatchValid
      ? "no-errors"
      : "errors";
    {
      /* render the message */
    }

    return (
      <div className="horseBackground-support">
        {/*  <LoginNavigation /> */}
        <FadeIn transitionDuration={250}>
          <div className="popupResetPassword">
            <div className="popup_inner">
              <div className="popup_header">
                <span className="popup_title">RESET PASSWORD</span>
                <span className="closeButton" onClick={this.goToLoginPage}>
                  &times;
                </span>
              </div>

              <div className="popup_body">
                <form onSubmit={this.validate}>
                  <span className="message">New Password</span>
                  <PasswordField
                    name={password}
                    onHandlefieldChange={this.handlefieldChangeP}
                  />
                  <span className={isPassWordLengthValidClass}>
                    {this.state.errors.passwordlength}
                  </span>
                  <span className="message">Verify Password</span>

                  <PasswordField
                    name={passWordVerify}
                    onHandlefieldChange={this.handlefieldChangeV}
                  />
                  <span className={isPasswordMatchValidClass}>
                    {this.state.errors.passwordverify}
                  </span>
                  <div className="submit">
                    <button className="goldButton" type="submit">
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FadeIn>
        {Alerts}

        {/* <Footer /> */}
      </div>
    );
  }
}
ResetPasswordLandingPage.propTypes = {
  match: PropTypes.object.isRequired
};
export default withRouter(ResetPasswordLandingPage);
