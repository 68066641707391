import React, { Component } from "react";

class Submit extends Component {
  render() {
    return (
      <button className="goldButton" type="submit">
        Create Account
      </button>
    );
  }
}

export { Submit };
