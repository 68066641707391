import React, { Component } from "react";
import PropTypes from "prop-types";
import SideBar from "../../common/friends-and-followers/SideBar/Sidebar";
import getCurrentlyLoggedInProfileApi from "../../api/profile/getCurrentlyLoggedInProfileApi";
import {
  getSessionStorageItem,
  getCurrentlyLoggedInProfileFunc
} from "../../common/getCurrentlyLoggedInProfile";
import {
  ChangeAvatarImageComponent,
  NavigationComponent,
  ProfileComponent,
  SubscriptionsComponent,
  PurchasesComponent
} from "../../common/friends-and-followers/my-profile";

import "../../../css/profile-page.css";
class AcountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "account settings",
      basicProfileInfo: true,
      userInterests: false,
      horseInterests: false,
      aboutMe: false,
      subscriptions: false,
      purchases: false,
      profile: {
        userId: "",
        displayName: "",
        firstName: "",
        lastName: "",
        profileInfoComplete: false,
        profileType: "",
        companyName: "",
        userProfilePictureId: ""
      }
    };
  }
  componentDidMount = async () => {
    const changeTitle = this.props.changeTitle;
    changeTitle(this.state.title);
    const profile = await getCurrentlyLoggedInProfileFunc();
    //const getSessionStorage = await getSessionStorageItem("profile");
    this.setState({
      profile: profile
    });
  };

  handleNavChange = event => {
    // //console.log("id : " + event.target.id);
    const id = event.target.id;

    if (id === "basicProfileInfo") {
      this.setState({
        basicProfileInfo: true,
        userInterests: false,
        horseInterests: false,
        aboutMe: false,
        subscriptions: false,
        purchases: false
      });
    }

    if (id === "userInterests") {
      this.setState({
        userInterests: true,
        basicProfileInfo: false,
        horseInterests: false,
        aboutMe: false,
        subscriptions: false,
        purchases: false
      });
    }

    if (id === "horseInterests") {
      this.setState({
        horseInterests: true,
        userInterests: false,
        basicProfileInfo: false,
        aboutMe: false,
        subscriptions: false,
        purchases: false
      });
    }

    if (id === "aboutMe") {
      this.setState({
        aboutMe: true,
        horseInterests: false,
        userInterests: false,
        basicProfileInfo: false,
        subscriptions: false,
        purchases: false
      });
    }

    if (id === "subscriptions") {
      this.setState({
        aboutMe: false,
        horseInterests: false,
        userInterests: false,
        basicProfileInfo: false,
        subscriptions: true,
        purchases: false
      });
    }
    if (id === "purchases") {
      this.setState({
        aboutMe: false,
        horseInterests: false,
        userInterests: false,
        basicProfileInfo: false,
        subscriptions: false,
        purchases: true
      });
    }
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3">
            <ChangeAvatarImageComponent
              profileType={this.state.profile.profileType}
              userId={this.state.profile.userId}
              userProfilePictureId={this.state.profile.userProfilePictureId}
              triggerAvatarToReloadProfilePicture={this.props.triggerAvatarToReloadProfilePicture}
            />
            <NavigationComponent
              profileType={this.state.profile.profileType}
              userId={this.state.profile.userId}
              userProfilePictureId={this.state.profile.userProfilePictureId}
              handleChange={this.handleNavChange}
              subscriptions={this.state.subscriptions}
              purchases={this.state.purchases}
              basicProfileInfo={this.state.basicProfileInfo}
              userInterests={this.state.userInterests}
              horseInterests={this.state.horseInterests}
              aboutMe={this.state.aboutMe}
            />
          </div>

          <div className="col-9 noMarginLeft">
            <ProfileComponent
              basicProfileInfo={this.state.basicProfileInfo}
              userInterests={this.state.userInterests}
              horseInterests={this.state.horseInterests}
              aboutMe={this.state.aboutMe}
            />
            {(() => {
              if (this.state.subscriptions == true) {
                return <SubscriptionsComponent />;
              }
              if (this.state.purchases == true) {
                return <PurchasesComponent />;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}

AcountSettings.propTypes = {
  title: PropTypes.string.isRequired,
  changeTitle: PropTypes.func.isRequired
};

export default AcountSettings;
