// Author: Oscar Persson

import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { getIsLoggedIn, checkAccess } from "../common/cookieAndAccessHandling";
import { ErrorWrapper, SuccessWrapper } from "../common/alerts";

import bugReportApi from "../api/bugReportApi";

export default function BugReport(props) {
      const [title, setTitle] = useState("Report a Bug");

      // set to false if you enable the captcha
      const [captchaComplete, setCaptchaComplete] = useState(true);
      const googleCaptchaApiKeyV2 = "6LdFW8EUAAAAAFyjL5VIeSXo74YE9XnUsCQaLPkG";

      const [messageType, setMessageType] = useState(null);

      const [isUserLoggedIn, setIsUserLoggedIn] = useState();

      const [loading, setLoading] = useState(false);

      useEffect(() => {
            checkAccess();
            setIsUserLoggedIn(getIsLoggedIn());
            props.changeTitle(title);
      }, []);

      function cleanErrorSuccessMessage() {
            setMessageType(null);
            //console.log("SET_MESSAGE_TYPE_TO_NULL : " + this.state.messageType);
            // this.props.history.push("/");
      }

      function resetForm() {
            document.getElementById("bugreport-form").reset();
      }

      function handleSubmit(e) {
            e.preventDefault();

            const message = document.getElementById("message").value;
            setLoading(true);
            bugReportApi(message).then(response => {
                  if (response === true) {
                        resetForm();
                        setMessageType("SUCCESS");
                        setLoading(false);
                  } else {
                        setMessageType("ERROR");
                  }
            });
      }

      function onCaptchaSuccess(value) {
            // console.log("Captcha value:", value);
            if (!value) {
                  setCaptchaComplete(false);
            } else {
                  setCaptchaComplete(true);
            }
      }

      return (
            <>
                  <div className="container">
                        <div className="row">
                              <div className="col-12">
                                    {isUserLoggedIn === "true" ? (
                                          <form id="bugreport-form" onSubmit={handleSubmit} method="POST" style={{ marginTop: "25px" }}>
                                                <div className="form-group">
                                                      <label className="formLabel" style={{ marginBottom: "10px" }} htmlFor="message">
                                                            Describe the bug with as much details as possible:
                                                      </label>
                                                      <div style={{ width: "97.2%", overflow: "none" }}>
                                                            <textarea className="form-control" rows="20" id="message"></textarea>
                                                      </div>
                                                </div>
                                                {captchaComplete ? (
                                                      <button
                                                            type="submit"
                                                            className="greenButton"
                                                            style={{
                                                                  marginBottom: "25px",
                                                                  width: "200px",
                                                                  float: "right"
                                                            }}
                                                      >
                                                            {loading ? "Sending..." : "Submit Bug Report"}
                                                      </button>
                                                ) : (
                                                      <h4>You must complete the reCAPTCHA before you can submit the Bug Report!</h4>
                                                )}
                                                {/* <ReCAPTCHA sitekey={ googleCaptchaApiKeyV2 } onChange={ onCaptchaSuccess } /> */}
                                          </form>
                                    ) : (
                                          <h3 style={{ marginTop: "25px" }}>You need to login before you can report a bug!</h3>
                                    )}
                              </div>
                        </div>
                  </div>
                  {messageType === "SUCCESS" && (
                        <SuccessWrapper
                              Message="Bug Report successfully sent!"
                              messageType="SUCCESS"
                              cleanErrorSuccessMessage={cleanErrorSuccessMessage}
                        />
                  )}
                  {messageType === "ERROR" && (
                        <ErrorWrapper Message="Bug Report failed to send!" messageType="ERROR" cleanErrorSuccessMessage={cleanErrorSuccessMessage} />
                  )}
            </>
      );
}
