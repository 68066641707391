import React, { Component } from "react";

class notificationsPreloader extends Component {
  render() {
    return (
      <>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
        <span
          className="formLabel"
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          loading please wait...
        </span>
      </>
    );
  }
}

export default notificationsPreloader;
