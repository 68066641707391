import React, { Component } from "react";
import LoginForm from "./loginForm";
import MainNavigation from "../../common/login/SmallComponents/mainNavigation";
import { Link } from "react-router-dom";
import { getAccessToken, getRefreshToken, getIsLoggedIn } from "../../common/cookieAndAccessHandling";

class LoginNavigation extends Component {
      constructor(props) {
            super(props);
            this.loggedInOut = this.loggedInOut.bind(this);

            this.state = {
                  isLoggedIn: ""
            };
      }

      componentDidMount = async () => {
            const LoggedIn = getIsLoggedIn();
            //console.log("logged in  ==============================>: " + LoggedIn);

            if (LoggedIn === "true") {
                  await this.setState({ isLoggedIn: true });
            }

            if (LoggedIn === "false") {
                  await this.setState({ isLoggedIn: false });
            }
      };

      loggedInOut = async () => {
            const LoggedIn = getIsLoggedIn();
            //console.log("logged in  ==============================>: " + LoggedIn);

            if (LoggedIn === "true") {
                  await this.setState({ isLoggedIn: true });
            }

            if (LoggedIn === "false") {
                  await this.setState({ isLoggedIn: false });
            }
            //return this.state.isLoggedIn;
      };

      rediectToUrl = url => {
            this.props.history.push(url);
      };
      renderLogoLink = () => {
            let logoLink;
            if (location.pathname == "/create-profile") {
                  logoLink = (
                        <Link to="#">
                              <div className={this.state.isLoggedIn ? "hmLogoSmall" : "hmLogoSide"} />
                        </Link>
                  );
            } else
                  logoLink = (
                        <Link to="/public-timeline">
                              <div className={this.state.isLoggedIn ? "hmLogoSmall" : "hmLogoSide"} />
                        </Link>
                  );
            return logoLink;
      };

      render() {
            return (
                  <React.Fragment>
                        <div className={this.state.isLoggedIn ? "navWrapperLoggedin transparentBg" : "navWrapper transparentBg"}>
                              <div className="container">
                                    {this.renderLogoLink()}

                                    <MainNavigation isLoggedIn={this.state.isLoggedIn} />

                                    <LoginForm
                                          loggedInOut={this.loggedInOut}
                                          isLoggedIn={this.state.isLoggedIn}
                                          triggerAvatarToReloadProfilePictureUserId={this.props.triggerAvatarToReloadProfilePictureUserId}
                                    />
                              </div>
                        </div>

                        {/* <Preloader /> */}
                  </React.Fragment>
            );
      }
}

export default LoginNavigation;
