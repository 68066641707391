//import getSubscriptionListByUserIdApi from "../../api/payment/getSubscriptionListByUserIdApi";
//import getSubscriptionPaymentStatusApi from "../../api/payment/getSubscriptionPaymentStatusApi";
import { getCurrentlyLoggedInProfileFunc } from "../getCurrentlyLoggedInProfile";

/**
 * Returnes the first occurance of a subscription thats not paid
 * So if a user has multiple subscriptions he will be caught in this loop
 * until each and every subscription is paid. This happens during login after
 * profile completion
 * currently existing subscriptions
 *
 * BUSINESS
 * subscriptionType = "account_subscription"
 * subscriptionName = "business_monthly_subscription"
 * and
 * subscriptionType == "account_subscription"
 * subscriptionName == "business_yearly_subscription"
 *
 * PRIVATE
 * subscriptionType = "account_subscription"
 * subscriptionName = "private_monthly_subscription"
 * and
 * subscriptionType == "account_subscription"
 * subscriptionName == "private_yearly_subscription"
 */
export const checkPaymentStatusFunc = async () => {
      //const globalWrapperWithSubscriptionList = await getSubscriptionListByUserIdApi();
      const currentlyLoggedInProfile = await getCurrentlyLoggedInProfileFunc();

      console.log("%ccurrentlyLoggedInProfile paymentstatusfunction =>" + JSON.stringify(currentlyLoggedInProfile, null, "\t"), "color:#00c9a7");

      return await createSubscriptionStatusAndReturn(currentlyLoggedInProfile.subscriptionPaid, "notUsed", "notUsed");

      ///////////////////////////// BACKUP CODE DO NOT REMOVE ////////////////////////////////////////////////////////////

      //console.group("%cgetSubscriptionListByUserIdApi", "color:cyan");
      //console.log("%c" + JSON.stringify(globalWrapperWithSubscriptionList, null, "\t"), "color:#00C9A7");
      /* if (globalWrapperWithSubscriptionList.msgWrapper.msgType == "ERROR") {
    console.log(
      "handle error" + globalWrapperWithSubscriptionList.msgWrapper.msgType
    );
    createSubscriptionStatusAndReturn(
      "false",
      "noSubscriptionYet",
      "noSubscriptionYet"
    );
  } */
      //console.log("%cmsgType = " + globalWrapperWithSubscriptionList.msgWrapper.msgType, "color:#00C9A7");
      //console.log("%cmsgDescription: " + globalWrapperWithSubscriptionList.msgWrapper.msgDescription, "color:#00C9A7");
      //console.groupEnd();
      /* if (globalWrapperWithSubscriptionList.msgWrapper.msgType == "ERROR") {
    return createSubscriptionStatusAndReturn(
      "false",
      "noSubscriptionYet",
      "noSubscriptionYet"
    );
  } */
      // IF THERE IS NO SUBSCRIPTION PRESENT BACKEND RETURNS ERROR
      // AND FOR NOW I USE THIS OTHERWISE WE CANT LOG IN WITH OUR TEST ACCOUNTS
      // THIS NEEDS TO BE COMMENTED OUT WHEN RUNNING IN PROD
      /* if (globalWrapperWithSubscriptionList.dataObject == null) {
            return createSubscriptionStatusAndReturn("false", "noSubscriptionYet", "noSubscriptionYet");
      }

      const subscriptionList = globalWrapperWithSubscriptionList.dataObject;

      let i = 0; */

      // YOU CAN ONLY HAVE ONE OF THESE SUBSCRITPTIONS
      // FOR NOW ALSO ONLY
      /* while (subscriptionList[i] != null) {
            if (
                  subscriptionList[i].subscriptionType == "account_subscription" &&
                  subscriptionList[i].subscriptionName == "business_monthly_subscription"
            ) {
                  const subscriptionPaid = await getSubscriptionPaymentStatusApi(
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
                  return createSubscriptionStatusAndReturn(
                        subscriptionPaid,
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
            } else if (
                  subscriptionList[i].subscriptionType == "account_subscription" &&
                  subscriptionList[i].subscriptionName == "business_yearly_subscription"
            ) {
                  const subscriptionPaid = await getSubscriptionPaymentStatusApi(
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
                  console.log("checkPaymentStatus: " + subscriptionPaid);
                  return createSubscriptionStatusAndReturn(
                        subscriptionPaid,
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
            }
            //PRIVATE ACCOUNTS
            else if (
                  subscriptionList[i].subscriptionType == "account_subscription" &&
                  subscriptionList[i].subscriptionName == "private_yearly_subscription"
            ) {
                  const subscriptionPaid = await getSubscriptionPaymentStatusApi(
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
                  return createSubscriptionStatusAndReturn(
                        subscriptionPaid,
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
            } else if (
                  subscriptionList[i].subscriptionType == "account_subscription" &&
                  subscriptionList[i].subscriptionName == "private_monthly_subscription"
            ) {
                  const subscriptionPaid = await getSubscriptionPaymentStatusApi(
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
                  return createSubscriptionStatusAndReturn(
                        subscriptionPaid,
                        subscriptionList[i].subscriptionType,
                        subscriptionList[i].subscriptionName
                  );
            }
            i++;
      } */

      ///////////////////////////// BACKUP CODE DO NOT REMOVE END////////////////////////////////////////////////////////////
};

export const createSubscriptionStatusAndReturn = async (paidStatus, subscriptionType, subscriptionName) => {
      let paymentStatus = {};
      paymentStatus.status = paidStatus;
      paymentStatus.subscriptionType = subscriptionType;
      paymentStatus.subscriptionName = subscriptionName;
      return paymentStatus;
};
