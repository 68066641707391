import axios from "axios";
import getApiURL from "../../../common/getAPIUrl";
import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../../common/getErrorResponseEntity";

const getTempProfileInfo = async function(currentStep) {
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/account-service/wizard/getTempWizardProfileInfo_ForBusinessUser?wizardStepNumber=" + currentStep;
      const apiName = "getTempWizardProfileInfo_ForBusinessUser";
      const serviceName = "profile-service";
      const baseUrl = rootURL + apiEndPoint;
      const token = getAccessToken();

      // //console.log("apin full token : " + token);
      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };

      return await axios
            .get(baseUrl, { headers: headers })
            .then(response => {
                  // //console.log(response.data);
                  return response.data; // returns GlobalWrapper
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default getTempProfileInfo;
