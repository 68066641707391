import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";
import checkIfAccountEmailIsAlreadyUsedApi from "../api/account-service/checkIfAccountEmailIsAlreadyUsedApi";
// START VALIDATION OF FIELD FUNCTIONS ---------------------------------------------------------------------------------

/**
 * Validates string  currencyInput and returns errors  if error occurs
 * @param {*} currencyInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.contracNumber if errors occurs
 */
export const validateCurrency = async (currencyInput, errors) => {
      if (currencyInput.value === "") {
            errors["currency"] = "Currency is required";
      } else {
            delete errors["currency"];
      }

      return errors;
};

/**
 * Validates string  postalCodeInput and returns errors  if error occurs
 * @param {*} postalCodeInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.contracNumber if errors occurs
 */
export const validatePostalCode = async (postalCodeInput, errors) => {
      if (postalCodeInput === "") {
            errors["postalCode"] = "Postal code is required";
      } else if (!postalCodeInput.match(/^[0-9 ]*$/)) {
            errors["postalCode"] = "Please enter numeric characters only.";
      } else {
            delete errors["postalCode"];
      }

      return errors;
};
/**
 * Validates string  postalCodeInput and returns errors  if error occurs
 * @param {*} postalCodeInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.contracNumber if errors occurs
 */
export const validateOrganisationNumber = async (organisationNumberInput, errors) => {
      if (organisationNumberInput === "") {
            errors["organisationNumber"] = "Organisation number is required";
      } else {
            delete errors["organisationNumber"];
      }

      return errors;
};
/**
 * Validates string  contractNumberInput and returns errors  if error occurs
 * @param {*} contractNumberInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.contracNumber if errors occurs
 */
export const validateContractNumber = async (contractNumberInput, errors) => {
      //console.log("%cValidating firstNameInput", "color:green");

      if (contractNumberInput === "") {
            errors["contractNumber"] = "Contract number is required";
      } else if (contractNumberInput.length < 2 || contractNumberInput.length > 50) {
            errors["contractNumber"] = "2->50 characters.";
      } else {
            delete errors["contractNumber"];
      }

      return errors;
};
/**
 * Validates string  firstNameInput and returns errors  if error occurs
 * @param {*} firstNameInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.firstName if errors occurs
 */
export const validateFirstName = async (firstNameInput, errors) => {
      //console.log("%cValidating firstNameInput", "color:green");

      if (firstNameInput === "") {
            errors["firstName"] = "First name is required.";
      } else if (firstNameInput.length < 2 || firstNameInput.length > 50) {
            errors["firstName"] = "2->50 characters.";
      } else if (!firstNameInput.match(/^[a-öA-Ö ]*$/)) {
            errors["firstName"] = "Please enter alphabetical characters only.";
      } else {
            delete errors["firstName"];
      }

      return errors;
};
/**
 * Validates string  companyNameInput and returns errors  if error occurs
 * @param {*} companyNameInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.companyName if errors occurs
 */
export const validateCompanyName = async (companyNameInput, errors) => {
      if (!companyNameInput || companyNameInput === "") {
            errors["companyName"] = "Company name is required.";
      } else if (companyNameInput.length < 2 || companyNameInput.length > 100) {
            errors["companyName"] = "2->50 characters.";
      } else if (!companyNameInput.match(/^[a-öA-Ö ]*$/)) {
            errors["companyName"] = "Please enter alphabetical characters only.";
      } else {
            delete errors["companyName"];
      }
      return errors;
};
/**
 * Validates string  lastNameInput and returns errors  if error occurs
 * @param {*} lastNameInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.lastName if errors occurs
 */
export const validateLastName = async (lastNameInput, errors) => {
      if (lastNameInput === "") {
            errors["lastName"] = "Last name is required.";
      } else if (!lastNameInput.match(/^[a-öA-Ö ]*$/)) {
            errors["lastName"] = "Please enter alphabetical characters only.";
      } else if (lastNameInput.length < 2 || lastNameInput.length > 50) {
            errors["lastName"] = "2-50 characters.";
      } else {
            delete errors["lastName"];
      }

      return errors;
};

/**
 * Validates string  countrySelectedValue and returns errors  if error occurs
 * @param {*} countrySelectedValue
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.country if errors occurs
 */
export const validateCountry = async (countrySelectedValue, errors) => {
      if (countrySelectedValue.label === "") {
            errors["country"] = "Please select country.";
      } else {
            delete errors["country"];
      }

      return errors;
};
/**
 * Validates string  languageSelectedValue and returns errors  if error occurs
 * @param {*} languageSelectedValue
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.language if errors occurs
 */
export const validateLanguage = async (languageSelectedValue, errors) => {
      if (languageSelectedValue.label === "") {
            errors["language"] = "Please select language.";
      } else {
            delete errors["language"];
      }

      return errors;
};

/**
 * Validates string  regionSelectedValue and returns errors  if error occurs
 * @param {*} regionSelectedValue
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.region if errors occurs
 */
export const validateRegion = async (regionSelectedValue, errors) => {
      if (regionSelectedValue.value === "") {
            errors["region"] = "Please select region.";
      } else {
            delete errors["region"];
      }
      return errors;
};

/**
 * Validates string  regionSelectedValue and returns errors  if error occurs
 * @param {*} paymentOptionsSelectedValue
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.region if errors occurs
 */
export const validatePaymentOptions = async (paymentOptionsSelectedValue, errors) => {
      if (paymentOptionsSelectedValue.value === "") {
            errors["paymentOption"] = "Please payment options.";
      } else {
            delete errors["paymentOption"];
      }
      return errors;
};

/**
 * Validates string  cityInput and returns errors  if error occurs
 * @param {*} cityInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.city if errors occurs
 */
export const validateCity = async (cityInput, errors) => {
      if (cityInput === "") {
            errors["city"] = "City is required.";
      } else if (!cityInput.match(/^[a-öA-Ö ]*$/)) {
            errors["city"] = "Please enter alphabetical characters only.";
      } else {
            delete errors["city"];
      }

      return errors;
};
/**
 * Validates string  contactAdressInput and returns errors if error occurs
 * @param {*} contactAdressInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.address if errors occurs
 */
export const validateAddress = async (contactAdressInput, errors) => {
      if (contactAdressInput === "") {
            errors["address"] = "Address is required.";
      } else {
            delete errors["address"];
      }
      return errors;
};
/**
 * Validates string  eMailInput and returns errors if error occurs
 * @param {*} eMailInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.email if errors occurs
 */
export const validateEmailSingle = async (eMailInput, errors) => {
      var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(eMailInput)) {
            errors["email"] = "Please check that email is in a valid format";
      } else if (eMailInput === "") {
            errors["email"] = "Email is required.";
      } else if (eMailInput.length < 6 || eMailInput.length > 100) {
            errors["email"] = "6->100 characters.";
      } else {
            delete errors["email"];
      }

      return errors;
};
/**
 * Validates string  eMailVerifyInput and emailInput to compare against and returns errors if error occurs
 * @param {*} eMailVerifyInput
 * @param {*} eMailVerify
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.email if errors occurs
 */
export const validateEmails = async (eMailVerifyInput, eMailInput, errors) => {
      var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(eMailVerifyInput)) {
            errors["verifyEmail"] = "Please check that email is in a valid format";
      } else if (eMailVerifyInput.length < 6 || eMailVerifyInput.length > 100) {
            errors["verifyEmail"] = "6->100 characters.";
      } else if (eMailVerifyInput !== eMailInput) {
            errors["verifyEmail"] = "Emails not equal. Please verify email";
      } else {
            delete errors["verifyEmail"];
      }

      if (!pattern.test(eMailInput)) {
            errors["email"] = "Please check that email is in a valid format";
      } else if (eMailInput.length < 6 || eMailInput.length > 100) {
            errors["email"] = "6->100 characters.";
      } else if (eMailVerifyInput !== eMailInput) {
            errors["email"] = "Emails not equal. Please verify email";
      } else {
            delete errors["email"];
      }

      return errors;
};

/**
 * Validates string  eMailVerifyInput and emailInput to compare against and returns errors if error occurs
 * Also when all errors has disappeared one more check is executed here which is checking if email already
 * exists in the backend system
 *
 * @param {*} eMailVerifyInput
 * @param {*} eMailVerify
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.email if errors occurs
 */
export const validateEmailsWithAccountEmailExistsCheck = async (eMailVerifyInput, eMailInput, errors) => {
      var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(eMailVerifyInput)) {
            errors["verifyEmail"] = "Please check that email is in a valid format";
      } else if (eMailVerifyInput.length < 6 || eMailVerifyInput.length > 100) {
            errors["verifyEmail"] = "6->100 characters.";
      } else if (eMailVerifyInput !== eMailInput) {
            errors["verifyEmail"] = "Emails not equal. Please verify email";
      } else {
            delete errors["verifyEmail"];
      }

      if (!pattern.test(eMailInput)) {
            errors["email"] = "Please check that email is in a valid format";
      } else if (eMailInput.length < 6 || eMailInput.length > 100) {
            errors["email"] = "6->100 characters.";
      } else if (eMailVerifyInput !== eMailInput) {
            errors["email"] = "Emails not equal. Please verify email";
      } else {
            delete errors["email"];
      }

      // Lastly check this
      if (errors.email == undefined && errors.verifyEmail == undefined) {
            //console.log("%cUNDEFINED", "color:#00C9A7");
            let accountServiceResponse = await checkIfAccountEmailIsAlreadyUsedApi(eMailVerifyInput);
            console.log("%ccheck if email exists => " + JSON.stringify(accountServiceResponse, null, "\t"), "color:#00C9A7");
            if (accountServiceResponse.emailExistInAccount || accountServiceResponse.emailExistInBrokerCustomerRecord) {
                  errors["email"] = "Email is already in use!";
                  errors["verifyEmail"] = "Email is already in use!";
            } else {
                  delete errors["email"];
                  delete errors["verifyEmail"];
            }
      }

      return errors;
};
/**
 * Validates string  emailInput to compare against and returns errors if error occurs
 * Also when all errors has disappeared one more check is executed here which is checking if email already
 * exists in the backend system
 *
 * @param {*} eMailVerifyInput
 * @param {*} eMailVerify
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.email if errors occurs
 */

export const validateEmailSingleAccountEmailExistsCheck = async (eMailInput, errors) => {
      var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(eMailInput)) {
            errors["email"] = "Please check that email is in a valid format";
      } else if (eMailInput.length < 6 || eMailInput.length > 100) {
            errors["email"] = "6->100 characters.";
      } else {
            delete errors["email"];
      }

      // Lastly check this
      if (errors.email == undefined) {
            //console.log("%cUNDEFINED", "color:#00C9A7");
            let accountServiceResponse = await checkIfAccountEmailIsAlreadyUsedApi(eMailInput);
            console.log("%ccheck if email exists => " + JSON.stringify(accountServiceResponse, null, "\t"), "color:#00C9A7");
            if (accountServiceResponse.emailExistInAccount || accountServiceResponse.emailExistInBrokerCustomerRecord) {
                  errors["email"] = "Email is already in use!";
            } else {
                  delete errors["email"];
            }
      }

      return errors;
};

/////////////////////// PASSWORDS ////////////////////////////////////////////////////////////////////////////////////////
/**Is used when 2 passwords needs to be validated.
 * Validates string password and string passwordVerify  and returns errors if error occurs
 * @param {*} password
 * @param {*} passwordVerify
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.email if errors occurs
 */
export const validatePasswords = async (password, passwordVerify, errors) => {
      if (password.length < 6) {
            errors["password"] = "Password with at least 6 characters is required";
      } else if (password !== passwordVerify) {
            errors["password"] = "Passwords are not equal.";
            //errors["password"] = "Passwords are not equal.";
      } else {
            delete errors["password"];
      }

      if (passwordVerify.length < 6) {
            errors["passwordVerify"] = "Password with at least 6 characters is required";
      } else if (password !== passwordVerify) {
            errors["passwordVerify"] = "Passwords are not equal.";
            //errors["password"] = "Passwords are not equal.";
      } else {
            delete errors["passwordVerify"];
      }

      return errors;
};
/** Is used when only one password field needs to be validated.
 * Validates string password and returns errors if error occurs
 * @param {*} password
 *
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.email if errors occurs
 */
export const validateSinglePassword = async (password, errors) => {
      if (password.length < 6) {
            errors["passwordSingle"] = "Password with at least 6 characters is required";
      } else {
            delete errors["passwordSingle"];
      }

      return errors;
};
/////////////////////// PASSWORDS END////////////////////////////////////////////////////////////////////////////////////////

/**
 *
 * Validates string phoneNumberInput. Returns a wrapper object that has both the correctly parsed international number
 * and the errors created ex.)
 * wrapper:{
 *    phoneNumber: phoneNumberInput.phoneNumber,
 *    errors:errors
 * }
 *
 * Error field property created is errors.phoneNumber
 *
 * @param {*} phoneNumberInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} wrapper: {errors:"", phoneNumber:""}
 */
export const validatePhoneNumber = async (phoneNumberInput, errors) => {
      let wrapper = {};

      const phoneNumber = await parsePhoneNumberFromString(phoneNumberInput);
      if (phoneNumber) {
            console.log(phoneNumber.formatInternational());
      }

      if (!phoneNumber) {
            errors["phoneNumber"] = "Please enter a international phone number, ex +46 00 00 00 0000";
            wrapper = { errors: errors, phoneNumber: "" };
            return wrapper;
      } else if (!phoneNumber.isValid()) {
            errors["phoneNumber"] = "Please enter a international phone number, ex +46 00 00 00 0000";
            wrapper = { errors: errors, phoneNumber: phoneNumber.formatInternational() };
            return wrapper;
      } else {
            delete errors["phoneNumber"];
            wrapper = { errors: errors, phoneNumber: phoneNumber.formatInternational() };
            return wrapper;
      }
};
/**
 * Validates string  vatNumberInput and returns errors if error occurs
 * @param {*} vatNumberInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.vatNumber if errors occurs
 */
export const validateVatNumber = async (vatNumberInput, errors) => {
      if (vatNumberInput === "") {
            errors["vatNumber"] = "Vat number is required.";
      } else {
            delete errors["vatNumber"];
      }
      return errors;
};

/**
 * Validates string  dateOfContractInput and returns errors  if error occurs
 * @param {*} dateOfContractInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.dateOfContract if errors occurs
 */
export const validateDateOfContract = async (dateOfContractInput, errors) => {
      console.log("%c:: dateOfContract :: " + dateOfContractInput, "background:black;color:cyan");
      if (dateOfContractInput === undefined) {
            errors["dateOfContract"] = "Please select date of contract.";
      } else {
            delete errors["dateOfContract"];
      }

      return errors;
};

/**
 * Validates string  discoutPercentageInput and returns errors  if error occurs
 * @param {*} discountPercentageInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.dateOfContract if errors occurs
 */
export const validateDiscountPercentage = async (discountPercentageInput, errors) => {
      // Convert to string because otherwise match wont work
      discountPercentageInput.value = discountPercentageInput.value.toString();
      if (discountPercentageInput.value === "") {
            errors["discountPercentage"] = "Please select discount percentage.";
      } else if (!discountPercentageInput.value.match(/^[0-9]*$/)) {
            errors["discountPercentage"] = "Only numbers.";
      } else {
            delete errors["discountPercentage"];
      }

      return errors;
};

/**
 * Validates string  commissionPercentageInput and returns errors  if error occurs
 * @param {*} commissionPercentageInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.commission if errors occurs
 */
export const validateCommissionPercentage = async (commissionPercentageInput, errors) => {
      console.log("%ccommissionPercentageInput => " + commissionPercentageInput.value, "color:cyan");
      // Convert to string because otherwise match wont work
      commissionPercentageInput.value = commissionPercentageInput.value.toString();
      if (commissionPercentageInput.value === "") {
            errors["commission"] = "Please select commission percentage.";
      } else if (!commissionPercentageInput.value.match(/^[0-9]*$/)) {
            errors["commission"] = "Only numbers.";
      } else {
            delete errors["commission"];
      }

      return errors;
};

/**
 * Validates string  customerOfferedPeriodInput and returns errors  if error occurs
 * @param {*} customerOfferedPeriodInput
 * @param {*} errors existing errors from other fields if any
 * @return {*} errors.dateOfContract if errors occurs
 */
export const validateCustomerOfferedPeriod = async (customerOfferedPeriodInput, errors) => {
      // Convert to string because otherwise match wont work
      customerOfferedPeriodInput.value = customerOfferedPeriodInput.value.toString();
      if (customerOfferedPeriodInput.value === "") {
            errors["customerOfferedPeriod"] = "Please select customer offered period.";
      } else if (!customerOfferedPeriodInput.value.match(/^[0-9]*$/)) {
            errors["customerOfferedPeriod"] = "Only numbers.";
      } else {
            delete errors["customerOfferedPeriod"];
      }

      return errors;
};

/* errors = await validateCommissionPercentage(this.state.commissionPercentageSelectedValue, errors);
await this.setErrorsAndIsValidToState(errors);

errors = await validateCustomerOfferedPeriod(this.state.customerOfferedPeriodSelectedValue, errors);
await this.setErrorsAndIsValidToState(errors); */

/**
 * Checks if param errors (is an object) has any error fields, and if exists,
 * meaning there are errors, return false. Else return true
 * @param {*} errors
 *
 * @return {*} true/false
 */
export const isFormValid = async (errors) => {
      console.log("%c:: isFormValid() :: PRINTING ERRORS ::", "color:cyan");
      let count = 0;

      for (let [key, value] of Object.entries(errors)) {
            count++;
            console.log(`%c${key} : ${value}`, "color:cyan");
            console.log(`%cTOTAL ERRORS COUNT : ${count}`, "color:cyan");
      }
      console.log("%c:: ERRORS END ::", "color:cyan");
      if (count === 0) {
            return true;
      } else {
            return false;
      }
};
