import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import PasswordField from "../login/SmallComponents/PasswordField";
import createNewAccountApi from "../../api/login-logout/createNewAccountApi";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import getAllowedCountryLanguagesApi from "../../api/localization/getAllowedCountryLanguagesApi";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
class createNewUserAccount extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  firstName: "",
                  lastName: "",
                  language: "",
                  languageCode: "",
                  password: "",
                  verificationPassWord: "",
                  email: "",
                  termsAndConditions: false,
                  accountType: null,
                  errors: {},
                  isValid: true,
                  Message: "",
                  messageType: "",
                  languageCodesAndNamesWrapper: [],
                  googleCaptchaApiKeyV2: "6LdFW8EUAAAAAFyjL5VIeSXo74YE9XnUsCQaLPkG",
                  // googleCaptchaApiKeyV3: "6Lc9psUUAAAAANYZWWlbrdg9yHZlxqIVX3bcJ4zU",
                  captchaComplete: false
            };
            this.handlefieldChange = this.handlefieldChange.bind(this);
      }
      UNSAFE_componentWillMount = () => {
            this.setState({
                  accountType: this.props.accountType
            });
            getAllowedCountryLanguagesApi().then((response) => {
                  //console.log(
                  //  "getAllowedCountryLanguagesApi response: " +
                  //    response.languageCodesAndNamesList
                  // );
                  this.setState({
                        languageCodesAndNamesWrapper: response.languageCodesAndNamesList
                  });
            });
            //console.log(
            //  "getAllowedCountryLanguagesApi response: " +
            //    JSON.stringify(this.state.languageCodesAndNamesWrapper)
            //);
      };

      handlefieldChange = (event) => {
            //console.log([event.target.name] + " : " + event.target.value);
            this.setState({ [event.target.name]: event.target.value });
            //console.log(event.target.name + " : " + event.target.value);
      };

      handleSelectLanguageField = (valueFromSelectLanguageField) => {
            //console.group("");
            //console.log(valueFromSelectLanguageField);
            this.setState({
                  language: valueFromSelectLanguageField.label,
                  languageCode: valueFromSelectLanguageField.value
            });
            //console.groupEnd();
            return this.state.valueFromSelectLanguageField;
      };

      handleSubmit = (event) => {
            event.preventDefault(); // stop the browser from reloading the page

            //validation
            this.validate();
            /* this.props.cls(); */
      };

      validate = () => {
            let errors = {};
            let formIsValid = true;
            const firstName = this.state.firstName;
            if (firstName === "") {
                  formIsValid = false;
                  errors["firstName"] = "Your first name is required.";
            }
            if (!firstName.match(/^[a-öA-Ö ]*$/)) {
                  formIsValid = false;
                  errors["firstName"] = "Please enter alphabetical characters only.";
            }

            const lastName = this.state.lastName;
            if (lastName === "") {
                  formIsValid = false;
                  errors["lastName"] = "Your last name is required.";
            }
            if (!lastName.match(/^[a-öA-Ö ]*$/)) {
                  formIsValid = false;
                  errors["lastName"] = "Please enter alphabetical characters only.";
            }

            const language = this.state.language;
            if (language === "") {
                  formIsValid = false;
                  errors["language"] = "Please choose your language.";
            }

            const password = this.state.password;
            if (password.length <= 5) {
                  formIsValid = false;
                  errors["password"] = "Your password must be at least 6 characters long.";
            }

            const verificationPassWord = this.state.verificationPassWord;
            if (password != verificationPassWord) {
                  formIsValid = false;
                  errors["verificationPassWord"] = "Passwords do not match.";
            }
            var pattern = new RegExp(
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            const email = this.state.email;

            if (!pattern.test(email)) {
                  formIsValid = false;
                  errors["email"] = "Check that your email is in a valid format";
            }
            if (email === "") {
                  formIsValid = false;
                  errors["email"] = "Your email is required.";
            }

            const termsAndConditions = this.state.termsAndConditions;
            if (termsAndConditions == false) {
                  formIsValid = false;
                  errors["termsAndConditions"] = "Please agree to our terms and conditions before creating an account with us.";
            }

            this.setState({ errors });
            this.setState({ isValid: formIsValid });
            //return formIsValid;
            this.sendData(formIsValid);
      };

      sendData = async (formIsValid) => {
            //console.log("this is valid : " + formIsValid);
            const activationBaseUrl = window.location.origin + "/activateAccount/";

            if (formIsValid == true) {
                  //console.log("sending data : ");

                  const jsonData = {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        password: this.state.password,
                        language: this.state.language,
                        languageCode: this.state.languageCode,
                        activationBaseUrl: activationBaseUrl
                  };

                  const accountType = this.state.accountType;

                  createNewAccountApi(jsonData, accountType).then(
                        async function (response) {
                              //console.log("loginApi response: " + JSON.stringify(response));

                              const message = response.msgWrapper.msgDescription,
                                    messageType = response.msgWrapper.msgType;
                              //console.log("message : " + message);
                              //console.log("message type : " + messageType);
                              this.setState({ Message: message });
                              this.setState({ messageType: messageType });
                              // if (messageType === "SUCCESS") {
                              //   await this.props.closeCreateNewUserAccountPopup();
                              // }
                              return message, messageType;
                        }.bind(this)
                  );
            }
      };
      //checkbox
      handleCheck = () => {
            this.setState({ termsAndConditions: !this.state.termsAndConditions });
      };

      cleanErrorSuccessMessage = () => {
            this.setState({
                  messageType: null
            });
            //console.log("SET_MESSAGE_TYPE_TO_NULL : " + this.state.messageType);
      };

      onCaptchaSuccess = (value) => {
            // console.log("Captcha value:", value);
            if (!value) {
                  this.setState({ captchaComplete: false });
            } else {
                  this.setState({ captchaComplete: true });
            }
      };

      render() {
            let options = this.state.languageCodesAndNamesWrapper.map(function (language) {
                  return { value: language.languageCode, label: language.name };
            });
            const customStyles = {
                  option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? "red" : "blue",
                        borderBottom: "1px solid rgba(240,240,240,1)",
                        backgroundColor: "rgba(240,240,240,0.2)",
                        padding: "10px"
                  }),
                  control: () => ({
                        border: "1px solid rgba(240,240,240,1)",
                        webkitAlignItems: "center",
                        webkitBoxAlign: "center",
                        msFlexAlign: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,1)",
                        border: " 1px solid rgba(240,240,240,1)",
                        display: "-webkit-box",
                        display: "-webkit-flex",
                        display: "-ms-flexbox",
                        webkitFlexWrap: "wrap",
                        webkitJustifyContent: "space-between",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        minHeight: "48px",
                        position: "relative",
                        webkitTransition: "all 100ms",
                        transition: "all 100ms",
                        boxSizing: "border-box",
                        padding: "8px"
                  }),
                  indicatorsContainer: () => ({
                        position: "absolute",
                        top: "4px",
                        right: "10px"
                  }),
                  menu: () => ({
                        backgroundColor: "rgba(255,255,255,1)",
                        border: "1px solid rgba(204,204,204, 0.2)",
                        width: "99.4%",
                        position: "absolute",
                        zIndex: "1002"
                  }),
                  option: () => ({
                        padding: "10px",
                        borderBottom: "1px solid rgba(240, 240, 240, 1)",
                        "&:hover": { backgroundColor: "rgba(240,240,240,0.2)" }
                  })
            };
            let isValid = this.state.isValid ? "no-errors" : "errors";
            /*  const defaultFieldValue = this.state.defaultLanguageFieldValue; */
            const accountType = this.state.accountType;
            {
                  /* create private business form  */
            }
            const CreateAccountForm = (
                  <div className="popup">
                        <div className="popup_inner_390">
                              <div className="popup_header">
                                    <span className="popup_title">
                                          {(() => {
                                                switch (accountType) {
                                                      case "user":
                                                            return "create a user account";
                                                      case "business":
                                                            return "create a business account";
                                                      default:
                                                            return null;
                                                }
                                          })()}
                                          create account
                                    </span>
                                    <span className="closeButton" onClick={this.props.closeCreateNewUserAccountPopup}>
                                          &times;
                                    </span>
                              </div>

                              <div className="popup_body">
                                    <form onSubmit={this.handleSubmit}>
                                          <label className="message">* Language</label>
                                          <Select
                                                isSearchable={true}
                                                styles={customStyles}
                                                options={options}
                                                onChange={this.handleSelectLanguageField}
                                                theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 0,
                                                      colors: {
                                                            ...theme.colors,
                                                            primary: "rgba(240,240,240,1)"
                                                      }
                                                })}
                                          />
                                          <span className={isValid}>{this.state.errors.language}</span>
                                          <label className="message">* Firstname</label>
                                          <input type="text" name="firstName" placeholder="Enter your first name" onChange={this.handlefieldChange} />
                                          <span className={isValid}>{this.state.errors.firstName}</span>
                                          <label className="message">* Lastname</label>
                                          <input type="text" name="lastName" placeholder="Enter your last name" onChange={this.handlefieldChange} />
                                          <span className={isValid}>{this.state.errors.lastName}</span>
                                          <label className="message">* Password</label>
                                          <PasswordField
                                                name="password"
                                                placeholder="Enter your pasword"
                                                onHandlefieldChange={this.handlefieldChange}
                                          />
                                          <span className={isValid}>{this.state.errors.password}</span>
                                          <label className="message">* Verify Password</label>
                                          <PasswordField
                                                name="verificationPassWord"
                                                placeholder="Verify your pasword"
                                                onHandlefieldChange={this.handlefieldChange}
                                          />
                                          <span className={isValid}>{this.state.errors.verificationPassWord}</span>
                                          <label className="message">* Email</label>
                                          <input type="email" name="email" placeholder="Enter your email address" onChange={this.handlefieldChange} />
                                          <span className={isValid}>{this.state.errors.email}</span>
                                          <div
                                                style={{
                                                      display: "block",
                                                      overflow: "hidden",
                                                      marginBottom: "5px"
                                                }}
                                          >
                                                <label className="message">* Terms And Conditions</label>
                                                <a href="/user-terms" target="Blank" className="termsAndConditions">
                                                      {"Have you read our terms and conditions?"}
                                                </a>

                                                <input className="termsCheck" type="checkbox" name="termsAndConditions" onChange={this.handleCheck} />
                                          </div>
                                          <span className={isValid}>{this.state.errors.termsAndConditions}</span>

                                          <ReCAPTCHA
                                                sitekey={this.state.googleCaptchaApiKeyV2}
                                                onChange={this.onCaptchaSuccess}
                                                theme="dark"
                                                size="normal"
                                          />

                                          {this.state.captchaComplete && this.state.accountType && (
                                                <div
                                                      className="submit"
                                                      style={{
                                                            paddingBottom: "40px"
                                                      }}
                                                >
                                                      <button
                                                            /*  onMouseDown={
                      this.props.closeCreateNewUserAccountPopup
                    } */

                                                            className="goldButton"
                                                            type="submit"
                                                      >
                                                            create {this.state.accountType} account
                                                      </button>
                                                </div>
                                          )}
                                    </form>
                              </div>
                        </div>
                  </div>
            );
            {
                  /* error success message wrapper */
            }

            const messageType = this.state.messageType;

            const Alerts = (
                  <React.Fragment>
                        {(() => {
                              switch (messageType) {
                                    case "ERROR": {
                                          return (
                                                <ErrorWrapper
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                      Message={this.state.Message}
                                                      messageType={this.state.messageType}
                                                />
                                          );
                                    }
                                    case "SUCCESS":
                                          return (
                                                <SuccessWrapper
                                                      Message={this.state.Message}
                                                      messageType={this.state.messageType}
                                                      cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                                                />
                                          );
                                    default:
                                          return null;
                              }
                        })()}
                  </React.Fragment>
            );
            {
                  /* render the message */
            }
            return (
                  <React.Fragment>
                        {CreateAccountForm}
                        {Alerts}
                  </React.Fragment>
            );
      }
}

createNewUserAccount.propTypes = {
      closeCreateNewUserAccountPopup: PropTypes.func.isRequired,
      accountType: PropTypes.string.isRequired
};
export default withRouter(createNewUserAccount);
