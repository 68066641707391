import React, { Component } from "react";
import FadeIn from "react-fade-in";
import getFriendRequestsApi from "../../api/profile/notifications/getFriendRequestsApi";
import acceptFriendRequestApi from "../../api/profile/friends/acceptFriendRequestApi";
import getNotificationsApi from "../../api/profile/notifications/getNotificationsApi";
import getMarkFriendSuggestionAsRemovedApi from "../../api/profile/notifications/getMarkFriendSuggestionAsRemovedApi";
import getAvatarThumbOrOthersUrlByUserId from "../../common/getAvatarThumbOrOthersUrlByUserId";
import declineFriendRequestApi from "../../api/profile/friends/declineFriendRequestApi";
import CommonFriendsPopup from "../../common/login/SmallComponents/commonFriendsPopup";

import debounce from "lodash.debounce";
import getApiURL from "../../common/getAPIUrl";
import getRootURL from "../../common/getRootURl";

import QuickLinks from "../notifications/QuickLinks";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import {
  removeElementFromArray,
  updateFieldInElementInArray
} from "../../common/stateArrayUtils";
import { getCurrentlyLoggedInProfileFunc } from "../../common/getCurrentlyLoggedInProfile";
class FriendsAndFriendSuggestionNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Friends",
      Message: "",
      messageType: "",
      friendRequestCount: 0,
      friendRequests: [],
      friendSuggestions: [],
      newNotifications: [],
      friendRequestNotificationClicked: false,
      rootURL: "",
      friendRequestNotificationsIsLoading: false,
      friendSuggestionsIsLoading: false,
      toggleMutualFriendsPopup: false,
      notificationsIsLoading: false,
      friendSuggestionId: "",
      page: 0,
      itemsPerPage: 10,
      noMoreDataToRetrieve: false,

      currentlyLoggedInProfile: ""
    };
  }

  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
  };

  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleOnScroll);
  };

  handleOnScroll = debounce(() => {
    if (this.state.noMoreDataToRetrieve == true) return;
    let offset = 1000;
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement &&
        document.documentElement.scrollHeight - offset) ||
      document.body.scrollHeight - offset;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;

    var scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - offset;

    this.getNextPage(scrolledToBottom);
  }, 50);

  getNextPage = scrolledToBottom => {
    if (!scrolledToBottom) return false;
    let { page } = this.state;
    let _page = page + 1;
    this.setState({ page: _page });
    this.getNewFriendRequestNotifications(_page);
  };

  UNSAFE_componentWillMount = async () => {
    const profile = await getCurrentlyLoggedInProfileFunc();

    const changeTitle = this.props.changeTitle;
    const baseUrl = getApiURL();
    await this.setState({
      rootURL: baseUrl,
      currentlyLoggedInProfile: profile
    });
    changeTitle(this.state.title);
    this.getNewFriendRequestNotifications(0);
  };

  getNewFriendRequestNotifications = async page => {
    const itesPerPage = this.state.itemsPerPage;

    await getFriendRequestsApi(page, itesPerPage).then(
      function(response) {
        if (response.dataObject.length < 5) {
          this.setState({
            noMoreDataToRetrieve: true
          });
        }
        this.setState({
          friendRequests: [...this.state.friendRequests, ...response.dataObject]
        });
      }.bind(this)
    );
  };

  // remove friend suggestion
  markFriendSuggestionAsRemoved = async event => {
    const friendSuggestionId = event.target.getAttribute("id");

    console.log("ID = " + friendSuggestionId);

    await getMarkFriendSuggestionAsRemovedApi(friendSuggestionId).then(
      function(response) {
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;

        if (messageType == "success") {
          const newfriendSuggestionStateArray = removeElementFromArray(
            "id",
            friendSuggestionId,
            this.state.friendSuggestions
          );

          this.setState({ friendSuggestions: newfriendSuggestionStateArray });
          let { page } = this.state;
          this.getNewFriendSuggestions(page);
        }

        this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        });
        return message, messageType;
      }.bind(this)
    );
  };

  getNewNotifications = async page => {
    // const page = this.state.pageNumberNewNotifications;
    const itemsPerPage = this.state.itemsPerPage;
    this.setState({ notificationsIsLoading: true });
    await getNotificationsApi(page, itemsPerPage).then(
      function(response) {
        if (response.dataObject.length < 1) {
          this.setState({
            showNewNotificationButton: false
          });
        }

        this.setState({
          newNotifications: [
            ...this.state.newNotifications,
            ...response.dataObject
          ]
        });
      }.bind(this)
    );
    this.setState({ notificationsIsLoading: false });
  };

  getAvatarUrl = (userId, scale) => {
    var url = getAvatarThumbOrOthersUrlByUserId(userId, scale);
    ////console.log(url);
    return url;
  };

  getTimeLinePost = async () => {
    const rootURL = await getRootURL();
    const timeLinePostId = event.target.id;
    //console.log("GET TO VIEW POST IN OLD FRONT POSTID = " + timeLinePostId);
    window.location.replace(rootURL + "/timeline/?postid=" + timeLinePostId);
  };

  //used
  getTargetUserProfile = event => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    const userId = event.target.id;
    window.location.replace("/friends-and-followers/user-profile/" + userId);
  };
  //used
  acceptFriendRequest = async event => {
    const targetUserId = event.target.getAttribute("id");

    var newfriendRequestsArray = removeElementFromArray(
      "friendRequestSourceUserId",
      targetUserId,
      this.state.friendRequests
    );

    await this.setState({
      friendRequests: newfriendRequestsArray,
      friendRequestCount: this.state.friendRequestCount - 1
    });

    //console.log("taraget user id :" + targetUserId);
    await acceptFriendRequestApi(targetUserId).then(
      function(response) {
        //console.log("response : " + JSON.stringify(response));
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        });
        return message, messageType;
      }.bind(this)
    );
  };
  //used
  declineFriendRequest = async event => {
    const targetUserId = event.target.getAttribute("id");

    var newfriendRequestsArray = removeElementFromArray(
      "friendRequestSourceUserId",
      targetUserId,
      this.state.friendRequests
    );

    await this.setState({
      friendRequests: newfriendRequestsArray,
      friendRequestCount: this.state.friendRequestCount - 1
    });

    await declineFriendRequestApi(targetUserId).then(
      function(response) {
        //console.log("response : " + JSON.stringify(response));
        const message = response.msgWrapper.msgDescription,
          messageType = response.msgWrapper.msgType;
        if (messageType == "success") {
        }
        this.setState({
          Message: response.msgWrapper.msgDescription,
          messageType: response.msgWrapper.msgType
        });
        return message, messageType;
      }.bind(this)
    );
  };

  toggleMutualFriendsPopup = async event => {
    await this.setState({
      toggleMutualFriendsPopup: !this.state.toggleMutualFriendsPopup,
      friendSuggestionId: event.target.id
    });
  };

  render() {
    const { rootURL } = this.state;
    const friendRequests = this.state.friendRequests.map((requests, index) => (
      <React.Fragment key={"newfriends" + index}>
        <div className="notificationNavLink">
          <div
            id={requests.friendRequestSourceUserId}
            onClick={this.getTargetUserProfile}
            className="avatarsWrapperNotification"
            style={{
              backgroundImage:
                "url(" +
                rootURL +
                "/api/v1/gallery-service/" +
                requests.friendRequestSourceUserProfilePictureThumbUrl +
                ")",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              float: "left",
              margin: "0px 0px 5px 10px"
            }}
          />
          <span
            style={{ float: "left", width: "auto", marginTop: "6px" }}
            className="linkTitle"
            id={requests.friendRequestSourceUserId}
            onClick={this.getTargetUserProfile}
          >
            {(requests.friendRequestSourceName + "").length > 20
              ? requests.friendRequestSourceName.substring(0, 20) + "..."
              : requests.friendRequestSourceName + ""}
          </span>
          <p
            style={{
              float: "left",
              width: "100%",
              marginLeft: "48px",
              marginTop: "-14px",
              display: "block",
              fontSize: "0.7em"
            }}
          ></p>
          <button
            style={{
              width: "55px",
              position: "absolute",
              right: "5px",
              top: "13px",
              margin: "0px 0px 0px 0px",
              padding: "5px",
              fontSize: "0.8em",
              color: "rgba(140,140,140,1)"
            }}
            id={requests.friendRequestSourceUserId}
            onClick={this.declineFriendRequest}
            className="greyButton"
          >
            decline
          </button>

          <button
            style={{
              width: "60px",
              position: "absolute",
              right: "65px",
              top: "13px",
              margin: "0px 0px 0px 0px",
              padding: "5px",
              fontSize: "0.8em"
            }}
            // loading={this.state.loading}
            id={requests.friendRequestSourceUserId}
            onClick={this.acceptFriendRequest}
            className="greenButton"
          >
            confirm
          </button>
        </div>
      </React.Fragment>
    ));

    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "error": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "success":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {(() => {
          if (this.state.toggleMutualFriendsPopup == true) {
            return (
              <CommonFriendsPopup
                friendSuggestionId={this.state.friendSuggestionId}
                toggleMutualFriendsPopup={this.toggleMutualFriendsPopup}
              />
            );
          }
        })()}
        <FadeIn transitionDuration={200} delay={200}>
          <div className="container">
            {Alerts}
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <QuickLinks />
                {(() => {
                  if (
                    this.state.currentlyLoggedInProfile &&
                    this.state.currentlyLoggedInProfile.profileType == "user"
                  ) {
                    return (
                      <FadeIn transitionDuration={200} delay={200}>
                        <section
                          className="section"
                          style={{ borderRadius: "0px 3px 3px 3px" }}
                        >
                          <div className="header">
                            <h2>friend requests</h2>
                          </div>
                          <div className="body">{friendRequests}</div>
                        </section>
                      </FadeIn>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </FadeIn>
      </React.Fragment>
    );
  }
}

export default FriendsAndFriendSuggestionNotifications;
