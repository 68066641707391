import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";

const addImage = async function(formData) {
      const accessToken = getAccessToken();

      for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
      }

      const headers = {
            Authorization: "Bearer " + accessToken,
            "cache-control": "no-cache"
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/gallery-service/addImage";
      const apiName = "addImage";
      const serviceName = "gallery-service";
      const baseUrl = rootURL + "/api/v1/gallery-service/addImage";

      return await axios({
            method: "POST",
            url: baseUrl,
            headers: headers,
            data: formData
      })
            .then(function(response) {
                  return response.data.dataObject;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default addImage;
