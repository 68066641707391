import React, { Component } from "react";
import Footer from "../../common/login/Footer";
import PropTypes from "prop-types";
import FadeIn from "react-fade-in";
import RegisterButton from "../../common/login/SmallComponents/RegisterButton";
import CreateNewUserAccountPopup from "../../common/login/CreateNewUserAccountPopup";
import RegisterOptionsPopup from "../../common/login/RegisterOptionsPopup";
class AccountTypeNotSupported extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  title: "Account type not supported",
                  showRegisterOptions: false,
                  showCreateNewUserAccountPopup: false
            };
            this.toggleShowCreateUserAccountPopup = this.toggleShowCreateUserAccountPopup.bind(this);
      }
      toggleShowRegisterOptionsLinkPopup = () => {
            this.setState({
                  showRegisterOptions: !this.state.showRegisterOptions
            });
            //console.log("hidePassword : " + this.state.showRegisterOptions);
      };

      toggleShowCreateUserAccountPopup = (event) => {
            console.log("event.target.id: " + event.target.value);
            this.setState({
                  accountType: event.currentTarget.getAttribute("id")
            });

            this.setState({
                  showCreateNewUserAccountPopup: !this.state.showCreateNewUserAccountPopup,
                  showRegisterOptions: false
            });

            //console.log(
            //  "showCreateNewUserAccountPopup : " +
            //    this.state.showCreateNewUserAccountPopup
            //);
      };
      UNSAFE_componentWillMount() {
            const changeTitle = this.props.changeTitle;
            changeTitle(this.state.title);
      }
      render() {
            return (
                  <>
                        <RegisterButton
                              style={{
                                    top: "12px",
                                    width: "69px",
                                    paddingLeft: "10px",
                                    right: "100px",
                                    left: "52.7%"
                              }}
                              showActivationLinkPopup={this.toggleShowRegisterOptionsLinkPopup.bind(this)}
                        />
                        {this.state.showRegisterOptions ? (
                              <RegisterOptionsPopup
                                    closeRegisterOptionsPopup={this.toggleShowRegisterOptionsLinkPopup}
                                    closeShowCreateUserAccountPopup={this.toggleShowCreateUserAccountPopup}
                              />
                        ) : null}
                        {this.state.showCreateNewUserAccountPopup ? (
                              <CreateNewUserAccountPopup
                                    accountType={this.state.accountType}
                                    closeCreateNewUserAccountPopup={this.toggleShowCreateUserAccountPopup}
                              />
                        ) : null}
                        <div>
                              <FadeIn transitionDuration={200} delay={200}>
                                    <div className="container textBackground">
                                          <div className="row sections">
                                                <div className="col-4" />
                                                <div className="col-5 ">
                                                      <div className="iconContainer greenColor">
                                                            <span className="keyFraturesIcon" />
                                                      </div>
                                                      <div className="subheaderContainer">
                                                            <h3>Account type not supported</h3>
                                                      </div>
                                                      {/* <ul className="list"> */}
                                                      <p>
                                                            Why did this happen? <br /> <br />
                                                            You have probably created an account in HorseMetro admin site, and the main HorseMetro
                                                            site currently doesn't have support for these kinds of accounts.
                                                      </p>
                                                      {/* </ul> */}
                                                </div>
                                                {/*  <div className="col-1">
                                                      <div className="borderRight" />
                                                </div>
                                                <div className="col-5 ">
                                                      <div className="iconContainer blueColor">
                                                            <span className="resetPasswordIcon" />
                                                      </div>
                                                      <div className="subheaderContainer">
                                                            <h3>Reset password</h3>
                                                      </div>
                                                      <p>
                                                            Enter reset password button next to login and enter your email address. An email will be
                                                            sent to your email address and from there you can change the password. Follow the
                                                            instruction in the email.
                                                      </p>
                                                </div> */}
                                          </div>
                                    </div>
                              </FadeIn>
                              <Footer />
                        </div>
                  </>
            );
      }
}
AccountTypeNotSupported.propTypes = {
      title: PropTypes.string.isRequired,
      changeTitle: PropTypes.func.isRequired
};
export default AccountTypeNotSupported;
