import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { getAccessToken, getRefreshToken, isLoggedIn, _responseErrorInterceptor } from "../../common/cookieAndAccessHandling";

import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

/**
 * Used to check if an email is used in backend system
 *
 * @param {*} email customer email
 * @return responseWrapper{ emailExistInAccount:true/false, emailExistInBrokerCustomerRecord:true/false}
 * @author Torbjörn Markusson
 */
const checkIfAccountEmailIsAlreadyUsedApi = async function (email) {
      const token = getAccessToken();

      const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "cache-control": "no-cache"
      };

      var rootUrl = getApiURL();
      const apiEndPoint = "/api/v1/account-service/checkIfAccountEmailIsAlreadyUsed";
      const apiName = "checkIfAccountEmailIsAlreadyUsedApi";
      const serviceName = "account-service";
      const baseUrl = rootUrl + apiEndPoint + "?email=" + email;

      return await axios({
            method: "get",
            url: baseUrl,
            /* data: payloadData, */
            headers: headers
      })
            .then(function (response) {
                  return response.data; // returns GlobalWrapper
            })
            .catch(function (error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};

export default checkIfAccountEmailIsAlreadyUsedApi;
