//import React from "react";
import axios from "axios";
import getApiURL from "../../common/getAPIUrl";
import { _responseErrorInterceptor, setAccessTokensToCookie } from "../../common/cookieAndAccessHandling";
import { getErrorResponseEntity } from "../../common/getErrorResponseEntity";

const loginApi = async function(payloadData) {
      const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic YnJvd3Nlcjo="
      };
      var rootURL = getApiURL();
      const apiEndPoint = "/api/v1/auth-service/oauth/token";
      const apiName = "loginApi";
      const serviceName = "auth-service";
      const baseUrl = rootURL + apiEndPoint;
      console.log("root url " + rootURL);
      console.log("api endpoint " + apiEndPoint);
      console.log("base url : " + baseUrl);
      return await axios({
            method: "post",
            url: baseUrl,
            data: payloadData,
            //params:payloadData,
            headers: headers
      })
            .then(function(response) {
                  console.log("response : " + JSON.stringify(response));
                  return setAccessTokensToCookie(response);

                  //return true;
            })
            .catch(function(error) {
                  if (error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, error.response.status, apiName);

                        return _responseErrorInterceptor(errorResponseEntity);
                  }
                  if (!error.response) {
                        let errorResponseEntity = getErrorResponseEntity(apiEndPoint, serviceName, "connectionError", apiName);
                        return _responseErrorInterceptor(errorResponseEntity);
                  }
            });
};
export default loginApi;
