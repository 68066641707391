/**
 * Entity that handles the response errors that is sent to responseErrorInterceptor as a parameter
 * @param {*} requestedUrl - the url that responded the error
 * @param {*} serviceName - the service that responded with the error
 * @param {*} errorStatusCode - the error status code that the service responded with
 */
export const getErrorResponseEntity = (
  requestedUrl,
  serviceName,
  errorStatusCode,
  apiName
) => {
  let errorResponseEntity = {};

  errorResponseEntity.requestedUrl = requestedUrl;
  errorResponseEntity.serviceName = serviceName;
  errorResponseEntity.errorStatusCode = errorStatusCode;
  errorResponseEntity.apiName = apiName;

  return errorResponseEntity;
};
