import React, { Component } from "react";
import checkDevice from "../../checkDevice";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import getRootUrl from "../../../common/getRootURl";

class mainNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceType: "",
      rootUrl: "",
      activeTimeline: false,
      activeNews: false,
      activeFeeds: false,
      activeBazaar: false,
      activeBlog: false
    };
  }
  //   set the device
  UNSAFE_componentWillReceiveProps = () => {
    this.setState({ rootUrl: getRootUrl() });
    var device = checkDevice();
    this.setState({ deviceType: device });

    //CHECK ACTIVE STYLES
    var path = location.pathname;
    console.log("PATH ====>" + path);

    if (
      path === "/public-timeline" ||
      path === "/private-timeline" ||
      path === "/friends-timeline"
    ) {
      this.setState({
        activeTimeline: true,
        activeNews: false,
        activeFeeds: false,
        activeBazaar: false,
        activeBlog: false
      });
    }

    if (path === "/en-UK/gallery") {
      this.setState({
        activeTimeline: false,
        activeNews: true,
        activeFeeds: false,
        activeBazaar: false,
        activeBlog: false
      });
    }

    if (path === "/news") {
      this.setState({
        activeTimeline: false,
        activeNews: false,
        activeFeeds: true,
        activeBazaar: false,
        activeBlog: false
      });
    }

    if (path === "/en-UK/bazaar/") {
      this.setState({
        activeTimeline: false,
        activeNews: false,
        activeFeeds: false,
        activeBazaar: true,
        activeBlog: false
      });
    }
    if (path === "/blog") {
      this.setState({
        activeTimeline: false,
        activeNews: false,
        activeFeeds: false,
        activeBazaar: false,
        activeBlog: true
      });
    }
  };

  routeToTimeLine = event => {
    if (location.pathname != "/create-profile") {
      window.location.replace(this.state.rootUrl + "/public-timeline");
    }
  };
  routeToGallery = event => {
    if (location.pathname != "/create-profile") {
      window.location.replace(this.state.rootUrl + "/en-UK/gallery/");
    }
  };

  routeToBazar = event => {
    if (location.pathname != "/create-profile") {
      window.location.replace(this.state.rootUrl + "/en-UK/bazaar/");
    }
  };
  routeToNewsFeed = event => {
    if (location.pathname != "/create-profile") {
      window.location.replace(this.state.rootUrl + "/news");
    }
  };

  render() {
    //console.log("device type : " + this.state.deviceType);
    /* console.group("Active styles");
    console.log("timeline : " + this.state.activeTimeline);
    console.log("gallert : " + this.state.activeMedia);
    console.log("news feeds : " + this.state.activeFeeds);
    console.log("bazaar : " + this.state.activeBazaar);
    console.groupEnd(); */
    const MainNav = (
      <React.Fragment>
        {(() => {
          if (this.props.isLoggedIn == true) {
            return (
              <div className="mainNavigation">
                <a className="mainNavLinks" onClick={this.routeToTimeLine}>
                  <span
                    className={
                      this.state.activeTimeline
                        ? "timelineIconActive"
                        : "timelineIcon"
                    }
                  ></span>
                  <span
                    className={
                      this.state.activeTimeline
                        ? "navigationTextActive"
                        : "navigationText"
                    }
                  >
                    timeline
                  </span>
                </a>

                <a className="mainNavLinks" onClick={this.routeToGallery}>
                  <span
                    className={
                      this.state.activeMedia
                        ? "galleryIconActive"
                        : "galleryIcon"
                    }
                  ></span>
                  <span
                    className={
                      this.state.activeMedia
                        ? "navigationTextActive"
                        : "navigationText"
                    }
                  >
                    gallery
                  </span>
                </a>

                {/* <a className="mainNavLinks" onClick={this.routeToNewsFeed}> */}
                <a onClick={this.routeToNewsFeed} className="mainNavLinks">
                  <span
                    className={
                      this.state.activeFeeds ? "feedsIconActive" : "feedsIcon"
                    }
                  ></span>
                  <span
                    className={
                      this.state.activeFeeds
                        ? "navigationTextActive"
                        : "navigationText"
                    }
                  >
                    NEWS
                  </span>
                </a>

                <a className="mainNavLinks" onClick={this.routeToBazar}>
                  <span
                    className={
                      this.state.activeBazaar
                        ? "bazaarIconActive"
                        : "bazaarIcon"
                    }
                  ></span>
                  <span
                    className={
                      this.state.activeBazaar
                        ? "navigationTextActive"
                        : "navigationText"
                    }
                  >
                    bazaar
                  </span>
                </a>

                {/* <Link to="/blog" className="mainNavLinks ">
                    <span
                      className={
                        this.state.activeBlog ? "blogIconActive" : "blogIcon"
                      }
                    ></span>
                    <span
                      className={
                        this.state.activeBlog
                          ? "navigationTextActive"
                          : "navigationText"
                      }
                    >
                      my blog
                    </span>
                  </Link> */}
              </div>
            );
          }
        })()}
      </React.Fragment>
    );

    const MainNavMobile = (
      <React.Fragment>
        {(() => {
          if (
            location.pathname === "/about-horsemetro" ||
            location.pathname === "/business" ||
            location.pathname === "/pricing" ||
            location.pathname === "/terms-of-service" ||
            location.pathname === "/help" ||
            location.pathname === "/user-terms" ||
            location.pathname === "/user-terms" ||
            location.pathname === "/bazaar-user-terms" ||
            location.pathname === "/cookie-policy"
          )
            return null;

          if (this.props.isLoggedIn == true) {
            return (
              <div
                style={{
                  padding: "7px 10px",
                  paddingBottom: "2px",
                  height: "30px",
                  zIndex: "10000"
                }}
                className="footer transparentBg"
              >
                <div style={{ margin: "auto", width: "245px" }}>
                  <a onClick={this.routeToTimeLine} className="mainNavLinks">
                    <span
                      className={
                        this.state.activeTimeline
                          ? "timelineIconActive"
                          : "timelineIcon"
                      }
                    ></span>
                    {/* <span
                      className={
                        this.state.activeTimeline
                          ? "navigationTextActive"
                          : "navigationText"
                      }
                    >
                      timeline
                    </span> */}
                  </a>

                  <a onClick={this.routeToGallery} className="mainNavLinks">
                    <span
                      className={
                        this.state.activeMedia
                          ? "galleryIconActive"
                          : "galleryIcon"
                      }
                    ></span>
                    {/* <span
                      className={
                        this.state.activeMedia
                          ? "navigationTextActive"
                          : "navigationText"
                      }
                    >
                      gallery
                    </span> */}
                  </a>

                  <a onClick={this.routeToNewsFeed} className="mainNavLinks">
                    <span
                      className={
                        this.state.activeFeeds ? "feedsIconActive" : "feedsIcon"
                      }
                    ></span>
                    {/* <span
                      className={
                        this.state.activeFeeds
                          ? "navigationTextActive"
                          : "navigationText"
                      }
                    >
                      media
                    </span> */}
                  </a>

                  <a className="mainNavLinks" onClick={this.routeToBazar}>
                    <span
                      className={
                        this.state.activeBazaar
                          ? "bazaarIconActive"
                          : "bazaarIcon"
                      }
                    ></span>
                    {/* <span
                      className={
                        this.state.activeBazaar
                          ? "navigationTextActive"
                          : "navigationText"
                      }
                    >
                      bazaar
                    </span> */}
                  </a>

                  {/* <Link to="/blog" className="mainNavLinks ">
                      <span
                        className={
                          this.state.activeBlog ? "blogIconActive" : "blogIcon"
                        }
                      ></span>
                      <span
                        className={
                          this.state.activeBlog
                            ? "navigationTextActive"
                            : "navigationText"
                        }
                      >
                        blog
                      </span>
                    </Link> */}
                </div>
              </div>
            );
          }
        })()}
      </React.Fragment>
    );
    const Nav = (
      <React.Fragment>
        {(() => {
          if (
            this.state.deviceType === "Computer" ||
            this.state.deviceType === "iPad"
          ) {
            return <React.Fragment>{MainNav}</React.Fragment>;
          }
        })()}
        {(() => {
          if (this.state.deviceType === "Mobile") {
            return <React.Fragment> {MainNavMobile}</React.Fragment>;
          }
        })()}
      </React.Fragment>
    );

    return <React.Fragment>{Nav}</React.Fragment>;
  }
}

export default mainNavigation;
