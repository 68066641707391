import Cookies from "cookies-js";
import history from "./history";
import refreshTokenApi from "../api/login-logout/refreshTokenApi";
import { checkPaymentStatusFunc } from "./payment/checkPaymentStatusFunc";

export const getAccessToken = () => {
      const token = Cookies.get("token");
      return Cookies.get("token");
};
export const getRefreshToken = () => {
      return Cookies.get("refresh_token");
};

export const getCurrentlyLoggedInUserIdFromCookie = () => {
      return Cookies.get("userId");
};

export const getCurrentlyLoggedInProfileTypeFromCookie = () => {
      return Cookies.get("profileType");
};

export const setProfileInfoCompleteCookie = (booleanValue) => {
      Cookies.set("profileInfoComplete", booleanValue, {
            path: "/"
      });
      //Cookies.set("isLoggedIn", false, { path: "/" });
};
export const getIsLoggedIn = () => {
      const isLoggedIn = Cookies.get("isLoggedIn");
      ////console.log("is logged in : " + isLoggedIn);
      if (isLoggedIn === undefined) {
            Cookies.set("isLoggedIn", false, { path: "/" });
      }
      return Cookies.get("isLoggedIn");
};
export const setAccessTokensToCookie = async (authServiceResponse) => {
      Cookies.set("token", authServiceResponse.data.access_token, {
            path: "/"
      });
      Cookies.set("refresh_token", authServiceResponse.data.refresh_token, {
            path: "/"
      });
      Cookies.set("isLoggedIn", true, { path: "/" });
      return true;
};

export const setUserNameToCookie = async (userNameString) => {
      Cookies.set("userName", userNameString, {
            path: "/"
      });
};

export const setUserIdToCookie = async (userId) => {
      Cookies.set("userId", userId, {
            path: "/"
      });
};
export const setProfileTypeToCookie = async (profileType) => {
      Cookies.set("profileType", profileType, {
            path: "/"
      });
};

export const setUserProfilePictureIdToCookie = async (userProfilePictureId) => {
      Cookies.set("userProfilePictureId", userProfilePictureId, {
            path: "/"
      });
};

export const checkAccess = async () => {
      console.log("%ccheckAccess executed", "color:#00C9A7");
      var currentUrl = window.location.href; //current absolute url
      var baseUrl = window.location.origin;
      const token = Cookies.get("token");

      if (
            !currentUrl.startsWith(baseUrl + "/resetPassword/") &&
            !currentUrl.startsWith(baseUrl + "/activateAccount/") &&
            !currentUrl.startsWith(baseUrl + "/about-horsemetro") &&
            !currentUrl.startsWith(baseUrl + "/help") &&
            !currentUrl.startsWith(baseUrl + "/terms-of-service") &&
            !currentUrl.startsWith(baseUrl + "/pricing") &&
            !currentUrl.startsWith(baseUrl + "/business") &&
            !currentUrl.startsWith(baseUrl + "/user-terms") &&
            !currentUrl.startsWith(baseUrl + "/privacy-policy") &&
            !currentUrl.startsWith(baseUrl + "/bazaar-user-terms") &&
            !currentUrl.startsWith(baseUrl + "/cookie-policy") &&
            !currentUrl.startsWith(baseUrl + "/serviceUnavailable") &&
            !currentUrl.startsWith(baseUrl + "/bugreport") &&
            !currentUrl.startsWith(baseUrl + "/payment/status") &&
            !currentUrl.startsWith(baseUrl + "/account-type-not-supported") &&
            !currentUrl.startsWith(baseUrl + "/autoCreateAndActivateAccount_ForBrokerCustomer")
      ) {
            if (token == null || token == "undefined" || token == "") {
                  Cookies.set("isLoggedIn", false, { path: "/" });

                  history.push("/");
                  //window.location.replace("/");
            } else {
                  await checkProfileInfoCompleteAccess();
                  await checkBusinessAccess();
            }
      }
};
export const checkProfileInfoCompleteAccess = async () => {
      console.log("%ccheckProfileInfoCompleteAccess executed", "color:#00C9A7");
      let profileType = Cookies.get("profileType");

      let profileInfoComplete = Cookies.get("profileInfoComplete");
      console.log("profileINFOCOMPLETE " + profileInfoComplete);
      console.log("window.location.pathname" + location.pathname);

      if (window.location.pathname != "/create-profile") {
            if (profileInfoComplete == "false") {
                  window.location.replace("/create-profile");
            }
      }
};
/**
 * This function return true if we are allowed to login to main site due to payment
 *
 */
export const checkBusinessAccess = async () => {
      //const profile = await getCurrentlyLoggedInProfileFunc();
      //let profileType = profile.profileType;
      let profileType = Cookies.get("profileType");

      if (profileType == "business") {
            const paymentStatus = await checkPaymentStatusFunc();

            if (paymentStatus.status === false) {
                  if (location.pathname != "/create-profile") {
                        setTimeout(() => {
                              window.location.href = "/create-profile";
                              //history.push("/create-profile");
                        }, 200);
                  }
            } else {
                  return true;
            }
      }
};

/* export const checkBusinessAccess_bak = async () => {
      //const profile = await getCurrentlyLoggedInProfileFunc();
      //let profileType = profile.profileType;
      let profileType = Cookies.get("profileType");

      if (profileType == "business") {
            const paymentStatus = await checkPaymentStatusFunc();
          
            //      paymentStatusTrue, isFreeTree, paySubscriptionTrue
            
            console.group("=====> paymentStatus ===>");
            console.log(paymentStatus.status);
            console.groupEnd();
            if (
                  paymentStatus.status == "createSubscription" ||
                  paymentStatus.status == "updateAndPaySubscription" ||
                  paymentStatus.status == "false"
            ) {
                  if (location.pathname != "/create-profile") {
                        setTimeout(() => {
                              window.location.href = "/create-profile";
                              //history.push("/create-profile");
                        }, 200);
                  }
            } else if (paymentStatus.status == "renewSubscription") {
                  if (location.pathname != "/payment/renewSubscription") {
                        //setTimeout(() => {
                        // Sandra no ninja super spagetti change
                        // this is not used anymore because when subsctipnButtom is stopped and next recurring date is expired
                        // paymentstus  should be set to false in the dbase and now payment-service is retruning paymentStatus.status == "updateAndPaySubscription" instead
                        // So that we get routed to payment page directly instead of renewSubscriptionpage in accountsetttings
                        //
                        //window.location.href = "/payment/renewSubscription";
                        //history.push("/payment/renewSubscription");
                        //}, 200);
                  }
            } else {
                  return true;
            }
      }
}; */

/**
 * Centralized function for handling error codes coming in from the api responses
 * @param {*}  errorResponseEntity Object
 */
export const _responseErrorInterceptor = async (errorResponseEntity) => {
      /* 
  errorResponseEntity
  {
    requestedUrl:"",
    serviceName:"",
    errorStatusCode:"",
    apiName:""
  } 
  */

      // ERROR 304 CACHING INFORMATION/ISSUES
      /////////////////////////////////////////////////////////////// ERROR 304 //////////////////////////////////////////////////////////////////////
      if (errorResponseEntity.errorStatusCode == "304") {
            console.error("https://www.checkupdown.com/status/E304.html");
            renderConsoleError(errorResponseEntity);
      }
      //
      ///////// net::ERROR (ex net::ERR_NAME_NOT_RESOLVED or net::ERR_CONNECTION_REFUSED ////////////////////////////////////////////////////////////
      //if (errorResponseEntity.errorStatusCode == "connectionError") {
      // net::ERROR ///// PROFILE-SERVICE ///////////////////////////////////
      /* if (errorResponseEntity.serviceName == "profile-service") {
      //renderConsoleError(errorResponseEntity);
      removeCookiesAndRedirectToServiceUnavailablePage(
        errorResponseEntity.serviceName
      );
    } */
      //}

      // WHEN U GET A 401 BACK WE CAN SAFELY SEND PEOPLE TO LOGIN
      // THIS APPLIES TO ALL ENDPOINTS
      // ERROR 401 IS THROWN WHEN A USER HASNT AUTHORIZATION, THIS ONE IS GLOBAL FOR EVERY API. MEANS THAT TOKEN IS NOTT VALID OR NONEXISTING
      /////////////////////////////////////////////////////////////// ERROR 401 //////////////////////////////////////////////////////////////////////
      if (errorResponseEntity.errorStatusCode == "401") {
            const refreshToken = getRefreshToken();

            if (refreshToken != null) {
                  const response = await refreshTokenApi(refreshToken);
            } else {
                  renderConsoleError(errorResponseEntity);
                  removeCookiesAndRedirectToLoginPage();
            }
      }
      // ERROR 403 IS THROWN WHEN A USER HASNT GOT ENOUGH PERMISSONS
      /////////////////////////////////////////////////////////////// ERROR 403 //////////////////////////////////////////////////////////////////////
      if (errorResponseEntity.errorStatusCode == "403") {
            // TODO
            // Check where 403 coming from and maybe route to payment for that service
            // ie console.log(errorResponseEntity.serviceName or errorResponseEntity.serviceApi )
      }

      //
      /////////////////////////////////////////////////////////////// ERROR 400 //////////////////////////////////////////////////////////////////////
      if (errorResponseEntity.errorStatusCode == "400") {
            /////////////////// AUTH-SERVICE ////////////////////////////////////////////////////////////////////////////
            // ERROR 400 ///////////////////LOGIN API //////////////////////
            if (errorResponseEntity.apiName == "loginApi") {
                  console.error("Wrong username");
                  renderConsoleError(errorResponseEntity);

                  const msgWrapper = {
                        messageType: "ERROR",
                        Message: "Please check your credentials!"
                  };
                  return msgWrapper;
            }
            // ERROR 400 /////////////////// REFRESH TOKEN API //////////////////////
            if (errorResponseEntity.apiName == "refreshTokenApi") {
                  renderConsoleError(errorResponseEntity);
                  removeCookiesAndRedirectToLoginPage();
            }
      }
      /////////////////////////////////////////////////////////////// ERROR 500 //////////////////////////////////////////////////////////////////////
      if (errorResponseEntity.errorStatusCode == "500") {
            /////////////////// AUTH-SERVICE ////////////////////////////////////////////////////////////////////////////
            // ERROR 500 ///////// LOGIN API ///////////////////////////
            if (errorResponseEntity.apiName == "loginApi") {
                  console.error("Wrong password");
                  renderConsoleError(errorResponseEntity);
                  const msgWrapper = {
                        messageType: "ERROR",
                        Message: "Please check your credentials!"
                  };
                  return msgWrapper;
            }
            // ERROR 500 ///////// REFRESH TOKEN API /////////////////////
            if (errorResponseEntity.apiName == "refreshTokenApi") {
                  removeCookiesAndRedirectToLoginPage();
            }
            // ERROR 500 ///////// GET CURRENTLY LOGGED IN PROFILE API /////////////////////
            if (errorResponseEntity.apiName == "getCurrentlyLoggedInProfileApi") {
                  renderConsoleError(errorResponseEntity);
                  //removeCookiesAndRedirectToServiceUnavailablePage(errorResponseEntity.serviceName);
            }
      }
};

export const renderConsoleError = (errorResponseEntity) => {
      console.group("Error");
      console.error(
            "request_url: " +
                  errorResponseEntity.requestedUrl +
                  "\n errorCode: " +
                  errorResponseEntity.errorStatusCode +
                  "\n apiName: " +
                  errorResponseEntity.apiName +
                  "\n serviceName: " +
                  errorResponseEntity.serviceName
      );
      console.groupEnd();
};
export const removeCookies = () => {
      /* await sleep(1000); */
      Cookies.set(
            "token",
            {
                  path: "/"
            },
            { expires: -1 }
      );
      Cookies.set(
            "refresh_token",
            {
                  path: "/"
            },
            { expires: -1 }
      );
      Cookies.set(
            "userId",
            {
                  path: "/"
            },
            { expires: -1 }
      );
      Cookies.set(
            "profileType",
            {
                  path: "/"
            },
            { expires: -1 }
      );
      Cookies.set(
            "profileInfoComplete",
            {
                  path: "/"
            },
            { expires: -1 }
      );
      Cookies.set(
            "userName",
            {
                  path: "/"
            },
            { expires: -1 }
      );
      Cookies.set(
            "userProfilePictureId",
            {
                  path: "/"
            },
            { expires: -1 }
      );

      Cookies.set("isLoggedIn", false, { path: "/" });

      //window.location.replace(window.location.origin + "/");
      //window.location.replace("/");
};

/**
 * Is called for now when we get a 500 on getcurrently logged in profile,,
 * Because without profile service everything fails regardsless
 * @param {*} serviceName
 */
export const removeCookiesAndRedirectToServiceUnavailablePage = async (serviceName) => {
      removeCookies();

      window.location.replace("/serviceUnavailable/");
};
/**
 *    If we are logging in with salesClerk, admin or broker accounts this will happen.
 *    Cokkies will be removed and user gets redirected to "account-type-not-supported"
 * @param {*} serviceName
 */
export const removeCookiesAndRedirectAccountNotSupported = async () => {
      console.log("%cHitting removeCookiesAndRedirectAccountNotSupported", "color:#00C9A7");
      removeCookies();
      window.location.replace("/account-type-not-supported");
};

export const removeCookiesAndRedirectToLoginPage = async () => {
      removeCookies();
      window.location.replace("/");
};
