import React, { Component } from "react";
import FadeIn from "react-fade-in";
import getNotificationsApi from "../../api/profile/notifications/getNotificationsApi";
import getAvatarThumbOrOthersUrlByUserId from "../../common/getAvatarThumbOrOthersUrlByUserId";
import CommonFriendsPopup from "../../common/login/SmallComponents/commonFriendsPopup";
import markNotificationAsReadApi from "../../api/profile/notifications/markNotificationAsReadApi";
import markAllNotificationAsReadApi from "../../api/profile/notifications/markAllNotificationAsReadApi";
import debounce from "lodash.debounce";
import getRootURL from "../../common/getRootURl";
import { ErrorWrapper, SuccessWrapper } from "../../common/alerts";
import { splitStringAndReturnSubStringFunc } from "../../common/splitStringFunc";
import { removeElementFromArray } from "../../common/stateArrayUtils";
import { getCurrentlyLoggedInProfileFunc } from "../../common/getCurrentlyLoggedInProfile";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "notifications",
      Message: "",
      messageType: "",
      friendRequestCount: 0,
      friendRequests: [],
      friendSuggestions: [],
      newNotifications: [],
      friendRequestNotificationClicked: false,
      rootURL: "",
      friendRequestNotificationsIsLoading: false,
      friendSuggestionsIsLoading: false,
      toggleMutualFriendsPopup: false,
      notificationsIsLoading: false,
      friendSuggestionId: "",
      // pagination
      page: 0,
      itemsPerPage: 10,
      noMoreDataToRetrieve: false,
      //show hide buttons flags
      showNewNotificationButton: true,
      showNewFriendRequestsButton: true,
      showNewFriendSuggestionsButton: true,
      notificationLength: "",
      //
      currentlyLoggedInProfile: ""
    };
  }

  cleanErrorSuccessMessage = () => {
    this.setState({
      messageType: null
    });
  };

  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleOnScroll);
  };

  handleOnScroll = debounce(() => {
    if (this.state.noMoreDataToRetrieve == true) return;
    let offset = 1000;
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement &&
        document.documentElement.scrollHeight - offset) ||
      document.body.scrollHeight - offset;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;

    var scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - offset;

    this.getNextPage(scrolledToBottom);
  }, 50);

  getNextPage = scrolledToBottom => {
    if (!scrolledToBottom) return false;
    let { page } = this.state;
    let _page = page + 1;
    this.setState({ page: _page });
    this.getNewNotifications(_page);
  };

  UNSAFE_componentWillMount = async () => {
    //var profile = await getSessionStorageItem("profile");
    const profile = await getCurrentlyLoggedInProfileFunc();
    this.setState({ currentlyLoggedInProfile: profile });

    console.log(JSON.stringify(this.state.currentlyLoggedInProfile));
    const changeTitle = this.props.changeTitle;
    changeTitle(this.state.title);

    this.getNewNotifications(0);
  };
  // get the notifications
  getNewNotifications = async page => {
    let { itemsPerPage } = this.state;
    await getNotificationsApi(page, itemsPerPage).then(
      async function(response) {
        if (
          // response.dataObject.length == 0 ||
          response.dataObject.length <= 9
        ) {
          await this.setState({
            noMoreDataToRetrieve: true
          });
        }

        this.setState({
          newNotifications: [
            ...this.state.newNotifications,
            ...response.dataObject
          ]
        });
      }.bind(this)
    );
  };

  markNotificationAsRead = async event => {
    const notificationId = event.currentTarget.getAttribute("notification_id");

    console.log("notificationId" + notificationId);

    var newNotifications = removeElementFromArray(
      "id",
      notificationId,
      this.state.newNotifications
    );

    await this.setState({
      newNotifications: newNotifications,
      unreadNotificationsCount: this.state.unreadNotificationsCount - 1
    });

    await markNotificationAsReadApi(notificationId).then(
      function(response) {
        console.log("markNotificationasREad response " + response);
        if (response != null || response != "") {
          let { page } = this.state;
          this.getNewNotifications(page);
        }
      }.bind(this)
    );
  };

  getAvatarUrl = (userId, scale) => {
    var url = getAvatarThumbOrOthersUrlByUserId(userId, scale);
    return url;
  };

  getTimeLinePost = async event => {
    event.persist();
    const rootURL = await getRootURL();
    const timeLinePostId = event.target.getAttribute("data-postid");
    const port = location.port;
    //console.log("rootUrl===>" + rootURL);
    window.location.replace(
      rootURL + "/viewSinglePost-timeline/" + timeLinePostId + "/commentId"
    );
  };
  getTimeLineComment = async event => {
    event.persist();
    const rootURL = await getRootURL();
    const timeLineCommentId = event.target.getAttribute("data-commentid");
    //console.log("rootUrl===>" + rootURL);
    window.location.replace(
      rootURL + "/viewSinglePost-timeline/postId/" + timeLineCommentId
    );
  };

  getTargetUserProfile = event => {
    this.setState({
      friendRequestNotificationClicked: false
    });
    const userId = event.currentTarget.getAttribute("id");
    const profileType = event.currentTarget.getAttribute("data-profiletype");
    //console.log("profile type ========== : " + profileType);
    if (profileType === "user") {
      window.location.replace("/friends-and-followers/user-profile/" + userId);
    }
    if (profileType === "business") {
      window.location.replace(
        "/friends-and-followers/business-user-profile/" + userId
      );
    }
  };

  markAllNotificationsAsRead = async () => {
    const response = await markAllNotificationAsReadApi();

    if (response == true) {
      this.setState({
        unreadNotificationsCount: 0,
        newNotifications: []
      });
    }
    //this.getNewNotifications(false);
  };

  /// RENDERS
  renderRemoveButton = notificationId => {
    //console.log("==================>" + notificationId);
    const removeButton = (
      <button
        style={{
          width: "55px",
          position: "absolute",
          right: "5px",
          bottom: "3px",
          display: "block",
          margin: "0px 0px 0px 0px",
          padding: "5px",
          fontSize: "0.8em",
          color: "rgba(140,140,140,1)",
          zIndex: "2000",
          overflow: "none"
        }}
        notification_id={notificationId}
        onClick={this.markNotificationAsRead}
        className="greyButton"
      >
        remove
      </button>
    );
    return removeButton;
  };

  renderAvatar = (fromUserId, fromUserProfileType) => {
    const avatar = (
      <div
        id={fromUserId}
        data-profiletype={fromUserProfileType}
        onClick={this.getTargetUserProfile}
        className="avatarsWrapperNotification"
        style={{
          backgroundImage:
            "url(" + getAvatarThumbOrOthersUrlByUserId(fromUserId, 0) + ")",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          float: "left",
          margin: "10px 0px 5px 10px"
        }}
      />
    );
    return avatar;
  };

  renderNotificationMessage = (
    fromUserProfileType,
    fromUserId,
    notificationMessage
  ) => {
    let subStringMessage = splitStringAndReturnSubStringFunc(
      notificationMessage,
      0,
      ":"
    );
    const notificationMessageSpan = (
      <span
        data-profiletype={fromUserProfileType}
        onClick={this.getTargetUserProfile}
        style={{
          heigth: "auto",
          overflow: "none",
          float: "left",
          width: "70%",
          marginTop: "6px"
        }}
        className="notificationLinkTitle"
        id={fromUserId}
      >
        {subStringMessage}
      </span>
    );
    return notificationMessageSpan;
  };

  renderViewPost = (targetEntityId, id) => {
    const viewPost = (
      <p
        style={{
          float: "left",
          width: "100%",
          margin: "-9px 0px 0px 60px",

          fontSize: "0.8em",
          textDecoration: "underline",
          cursor: "pointer"
        }}
        id={id}
        data-postid={targetEntityId}
        onClick={this.getTimeLinePost}
      >
        view post
      </p>
    );
    return viewPost;
  };

  renderViewComment = (targetEntityId, id) => {
    //console.log("asdklfjasdflkjlaksdf =>>>" + targetEntityId);
    const viewPostCommentItem = (
      <p
        style={{
          float: "left",
          width: "100%",
          margin: "-9px 0px 0px 60px",

          fontSize: "0.8em",
          textDecoration: "underline",
          cursor: "pointer"
        }}
        id={id}
        data-commentid={targetEntityId}
        onClick={this.getTimeLineComment}
      >
        view comment
      </p>
    );
    return viewPostCommentItem;
  };
  render() {
    const newNotifications = this.state.newNotifications.map(notification => (
      <React.Fragment key={notification.id}>
        <FadeIn transitionDuration={200} delay={200}>
          {(() => {
            //FRIEND ACCEPT AND FOLLOWER NOTIFICATIONS
            if (
              notification.notificationType === "friend-accept" ||
              notification.notificationType === "follow" ||
              notification.notificationType === "follower"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}

                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div style={{ width: "70%", float: "right" }}>
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            /////////////////////////////////////////////////////////// GALLERY-SERVICE ///////////////////////////////////////////////////////////////////////////////////////////////////
            // IMAGE LIKE NOTIFICATIONS
            if (notification.notificationType === "image-like") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}

                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // ALBUM-UPDATE NOTIFICATION
            if (notification.notificationType === "album-update") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            //ALBUM FOLLOW
            if (notification.notificationType === "album-follow") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // ALBUM-UNFOLLOW
            if (notification.notificationType === "album-unfollow") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // ALBUM LIKES
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "album"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}

                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            ////////////////////////////////////////////////////////////// TIMELINE-SERVICE   /////////////////////////////////////////////////////////////////////
            // POST LIKE NOTIFICATIONS
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "post"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewPost(
                      notification.targetEntityId,
                      notification.id
                    )}

                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // COMMENT LIKE NOTIFICATIONS
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "comment"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // SUB COMMENT LIKE NOTIFICATIONS
            if (
              notification.notificationType === "like" &&
              notification.targetEntityType === "subcomment"
            ) {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            // COMMENT  NOTIFICATIONS
            if (notification.notificationType === "comment") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}

                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            if (notification.notificationType === "share") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewPost(
                      notification.targetEntityId,
                      notification.id
                    )}

                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            if (notification.notificationType === "taggedInPost") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewPost(
                      notification.targetEntityId,
                      notification.id
                    )}

                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
            if (notification.notificationType === "taggedInComment") {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderViewComment(
                      notification.targetEntityId,
                      notification.id
                    )}
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="notificationNavLink">
                  {this.renderAvatar(
                    notification.fromUserId,
                    notification.fromUserProfileType
                  )}
                  {this.renderNotificationMessage(
                    notification.fromUserProfileType,
                    notification.fromUserId,
                    notification.message
                  )}
                  <div
                    style={{
                      width: "100%",
                      float: "right",
                      padding: "5px 0px 10px 10px",
                      overflow: "hidden"
                    }}
                  >
                    {this.renderRemoveButton(notification.id)}
                  </div>
                </div>
              );
            }
          })()}
        </FadeIn>
      </React.Fragment>
    ));

    const messageType = this.state.messageType;
    const Alerts = (
      <React.Fragment>
        {(() => {
          switch (messageType) {
            case "error": {
              return (
                <ErrorWrapper
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                />
              );
            }
            case "success":
              return (
                <SuccessWrapper
                  Message={this.state.Message}
                  messageType={this.state.messageType}
                  cleanErrorSuccessMessage={this.cleanErrorSuccessMessage}
                />
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );

    return (
      <>
        {(() => {
          if (this.state.toggleMutualFriendsPopup == true) {
            return (
              <CommonFriendsPopup
                friendSuggestionId={this.state.friendSuggestionId}
                toggleMutualFriendsPopup={this.toggleMutualFriendsPopup}
              />
            );
          }
        })()}
        <FadeIn transitionDuration={100} delay={100}>
          <div className="container">
            {Alerts}
            <div className="row">
              <div className="col-3" />
              <div className="col-6">
                <section className="section">
                  {" "}
                  <div className="header">
                    <h2>notifications</h2>
                    {(() => {
                      if (this.state.newNotifications.length > 0) {
                        return (
                          <button
                            style={{
                              width: "75px",
                              float: "right",
                              //right: "5px",
                              //bottom: "3px",
                              display: "inline-block",
                              marginTop: "-25px",
                              marginRight: "5px",
                              padding: "0px",
                              fontSize: "0.8em",
                              color: "rgba(140,140,140,1)",
                              zIndex: "2000"
                            }}
                            onClick={this.markAllNotificationsAsRead}
                            className="greyButton"
                          >
                            remove all
                          </button>
                        );
                      }
                    })()}
                  </div>
                  <div className="body">
                    {(() => {
                      if (this.state.notificationsIsLoading) {
                        // return <NotificationsPreloader />;
                      } else {
                        return (
                          <React.Fragment>
                            {/* <FadeIn transitionDuration={10} delay={10}> */}
                            {newNotifications}
                            {/* </FadeIn> */}
                          </React.Fragment>
                        );
                      }
                    })()}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </FadeIn>
      </>
    );
  }
}

export default Notifications;
