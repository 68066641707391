import React, { Component } from "react";
import { getCurrentlyLoggedInUserIdFromCookie } from "../cookieAndAccessHandling";
class likeButton extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  like: false
            };
      }

      UNSAFE_componentWillMount() {
            let userId = getCurrentlyLoggedInUserIdFromCookie();
            let likeUserIds = this.props.likeUserIds;
            if (likeUserIds == null) return;

            let l;
            // FOR LOOP IS FAST AS HELL
            for (l = 0; l < likeUserIds.length; l++) {
                  if (likeUserIds[l] == userId) {
                        this.setState({ like: true });
                        break;
                  }
            }
      }

      likeDisLike = event => {
            this.props.likeDislikeImage(event);
            this.setState({ like: !this.state.like });
      };
      formatCount = n => {
            if (n < 1e3) return n;
            if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K ";
            if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M ";
            if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B ";
            if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
      };

      render() {
            const { likesCount } = this.props;
            // const count = parseInt(likesCount);

            // let likesCount = 5500000;
            // let likesCount = 55000;

            let count = this.formatCount(this.props.likesCount);
            // console.log("likes count: " + count);
            // console.log("user ids : " + this.state.likeUserIds);
            return (
                  <div className="likeContainer">
                        <div
                              data={this.props.data}
                              className={this.state.like ? "likeButtonActive" : "likeButton"}
                              id={this.props.id}
                              onClick={this.likeDisLike}
                        >
                              {(() => {
                                    if (likesCount == 0) {
                                          return <div className="likeCounterBubble">{count.toString() + " Likes"}</div>;
                                    }
                                    if (likesCount == 1) {
                                          return <div className="likeCounterBubble">{count.toString() + " Like"}</div>;
                                    } else {
                                          return <div className="likeCounterBubble">{count.toString() + " Likes"}</div>;
                                    }
                              })()}
                        </div>
                        {(() => {
                              if (likesCount == 0) {
                                    return <span className="likeCounter">{"0 Likes"}</span>;
                              }

                              if (likesCount == 1) {
                                    return <span className="likeCounter">{count.toString() + " Like"}</span>;
                              } else {
                                    return <span className="likeCounter">{count.toString() + " Likes"}</span>;
                              }
                        })()}
                  </div>
            );
      }
}

export default likeButton;
