import React, { Component } from "react";

import getAPIUrl from "../components/common/getAPIUrl";
import { Router, Route, Switch } from "react-router-dom";
import PageTitle from "./common/pageTitle";
import Login from "./wrappers/Login/AsyncLogin";
import AboutHorsemetro from "./wrappers/Login/AsyncAbout";
import Advertisements from "./pages/login-logout/Advertisements";
import Business from "./wrappers/Login/AsyncBusiness";
import Help from "./wrappers/Login/AsyncHelp";
import Pricing from "./wrappers/Login/AsyncPricing";
import TermsOfService from "./wrappers/Login/AsyncTerms";
import AccountTypeNotSupported from "./pages/login-logout/UserAccountNotSupported";
import AutoActivateSalesClerkCustomerBusinessAccount from "./pages/login-logout/AutoActivateSalesClerkCustomerBusinessAccountAndLogin";

import ResetPasswordLandingPage from "./pages/login-logout/ResetPasswordLandingPage";
import ProfileCreation from "./pages/login-logout/ProfileCreation";
import LoginNavigation from "./common/login/loginNavigation";
import ActivationLandingPage from "./pages/login-logout/ActivationLandingPage";
import PrivacyPolicy from "./pages/login-logout/termsOfService/privacyPolicy";
import UserTerms from "./wrappers/Login/Terms/AsynUserTerms";
import BazaarUserTerms from "./wrappers/Login/Terms/AsynBazarTerms";
import CookiePolicy from "./wrappers/Login/Terms/AsyncCookiePolicy";
import PaymentStatus from "./pages/payment/paymentStatus";
import ServiceUnavailable from "./pages/login-logout/ServiceUnavailable";
import BugReport from "./pages/BugReport";
import InitialChangePasswordLandingPage from "../components/pages/login-logout/InitialChangePasswordLandingPage";

/* profile page */
import AsyncAccountSettings from "./wrappers/friends-and-followers/AccountSettingsAsyncWrapper";
import AccountSettings from "./pages/profile/AccountSettings";

/* find friends and users */
import AsyncBusinessUserProfile from "./wrappers/friends-and-followers/BusinessUserProfileAsyncWrapper";
import AsyncMyBusinessesThatIFollow from "./wrappers/friends-and-followers/MyBusinessesThatIFollowAsyncWrapper";
import AsyncMyFollowers from "./wrappers/friends-and-followers/MyFollowersAsyncWrapper";
import AsyncMyFriends from "./wrappers/friends-and-followers/MyFriendsAsyncWrapper";
import AsyncMyUsersThatIFollow from "./wrappers/friends-and-followers/MyUsersThatIFollowAsyncWrapper";
import AsyncNewestActiveUserProfiles from "./wrappers/friends-and-followers/NewestActiveUserProfilesAsyncWrapper";
import AsyncNewestActiveBusinessProfiles from "./wrappers/friends-and-followers/NewestBusinessUserProfileAsyncWrapper";

// business profile page
import AsyncBusinessProfileTimeline from "./wrappers/friends-and-followers/BusinessProfileTimelineAsyncWrapper";
import AsyncBusinessProfileGallery from "./wrappers/friends-and-followers/BusinessProfileGalleryAsyncWrapper";
import AsyncBusinsessProfileBazaar from "./wrappers/friends-and-followers/BusinessProfileBazaarAsyncWrapper";
import AsyncBusinessProfileFollowers from "./wrappers/friends-and-followers/BusinessProfileFollowersAsyncWrapper";
// user profile page
import AsyncUserProfile from "./wrappers/friends-and-followers/UserProfileAsyncWrapper";
import AsyncUserProfileTimeline from "./wrappers/friends-and-followers/UserProfileTimelineAsyncWrapper";
import AsyncUserProfileGallery from "./wrappers/friends-and-followers/UserProfileGalleryAsyncWrapper";
import AsyncRenewSubscription from "./wrappers/Payment/PaymentAsyncWrapper";
import AsyncTimeline from "./wrappers/timeline/TimelineAsyncWrapper";
import AsyncUserFriends from "./wrappers/friends-and-followers/UserProfileFriendsAsyncWrapper";
import AsyncBazaarUserProfile from "./wrappers/friends-and-followers/UserProfileBazaarAsyncWrapper";
import ReactGA from "react-ga";

// timeline
//import Timeline from "./pages/timeline/timeline";

//newsFeeds
import NewsFeeds from "./wrappers/newsFeeds/newsFeedsAsyncWrapper";

// blog
import Blog from "./pages/blog/blog";

// gallery
import MyGallery from "./pages/gallery/myGallery";
import PublicGallery from "./wrappers/gallery/AsyncPublicGallery";
import MyPictures from "./pages/gallery/MyPictures";

//notifications
import Notifications from "./pages/notifications/notifications";
import FriendsSuggestionNotifications from "./pages/notifications/FriendsAndFriendSuggestionNotifications";
import FriendRequestNotifications from "./pages/notifications/FriendNotifications";
import checkDevice from "./common/checkDevice";

//preloader
import { checkAccess } from "./common/cookieAndAccessHandling";
import history from "./common/history";

//footer
import Footer from "../components/common/login/Footer";

import "../css/main.css";
import "../css/simple-grid.css";
import "../css/alerts.css";
import "../css/preloader.css";

//import { NonceProvider } from "react-select";

class App extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  title: "",
                  page: "",
                  deviceType: "",
                  triggerAvatarToReloadProfilePictureUserId: ""
            };
      }
      //this sets the page title
      changeTitle = (data) => {
            this.setState({ title: data });
      };
      onUnload(event) {}

      UNSAFE_componentWillUpdate = async () => {
            await checkAccess(this.props);
      };

      UNSAFE_componentDidUpdate = async () => {};
      UNSAFE_componentWillMount = async () => {
            await this.deviceCheck();
            await checkAccess(this.props);
            var api = getAPIUrl();

            var location = window.location.pathname;
            var result = location.substring(0, location.lastIndexOf("/") + 0);
            await this.setState({ page: result });

            // await this.detectMobile();
      };
      componentDidMount = async () => {
            window.addEventListener("beforeunload", this.onUnload);

            this.initializeReactGA();
      };

      initializeReactGA = () => {
            ReactGA.initialize("UA-132760518-1");
            ReactGA.pageview("/public-timeline");
            // ReactGA.pageview(window.location.pathname + window.location.search);
      };
      componentWillUnmount = async () => {
            window.removeEventListener("beforeunload", this.onUnload);
      };

      // check the device
      deviceCheck = async () => {
            var device = checkDevice();
            console.log("device app.js ===== " + device);
            await this.setState({ deviceType: device });
      };

      triggerAvatarToReloadProfilePicture = async (userId) => {
            console.log("%ctriggerAvatarToReloadProfilePicture", "background:black;color:cyan");
            await this.setState({ triggerAvatarToReloadProfilePictureUserId: userId });
      };
      render() {
            //const { match } = this.props;
            return (
                  <React.Fragment>
                        {(() => {
                              if (this.state.deviceType === "Edge") {
                                    return (
                                          <section className="horseBackground-support">
                                                <div className="introWrapper">
                                                      <div className="hmLogoSide" style={{ float: "none", margin: "auto", padding: "20px" }} />
                                                      <h1 style={{ lineHeight: "2em !important" }}>
                                                            <b>We don't support this version of microsoft edge.</b>
                                                      </h1>
                                                      <h2
                                                            style={{
                                                                  color: "rgba(255,255,255,1)",
                                                                  fontSize: "1em",
                                                                  lineHeight: "2em"
                                                            }}
                                                      >
                                                            We recommed that you click on the below links to install an alternative browser which we
                                                            support <br />
                                                            until the latest version of Microsoft edge has been released
                                                            <a href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV"></a>
                                                      </h2>
                                                      <section
                                                            style={{
                                                                  width: "600px",
                                                                  marginRight: "auto",
                                                                  marginLeft: "auto"
                                                            }}
                                                      >
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "140px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        margin: "10px",
                                                                        float: "left"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.google.com/chrome/?brand=CHBF&ds_kid=43700018455413661&utm_source=bing&utm_medium=cpc&utm_campaign=1005992%20%7C%20Chrome%20Win10%20%7C%20DR%20%7C%20ESS01%20%7C%20EMEA%20%7C%20EMEA%20%7C%20en%20%7C%20Desk%20%7C%20BING%20SEM%20%7C%20BKWS%20~%20Top%20KWDS%20-%20Exact&utm_term=chrome&utm_content=Chrome%20-%20Exact&gclid=CIzJpfKI6uUCFVZ_GwodGLQDnw&gclsrc=ds"
                                                                        target="blank"
                                                                  >
                                                                        google chrome
                                                                  </a>
                                                            </div>
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "140px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        float: "left",
                                                                        margin: "10px"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.mozilla.org/en-US/firefox/new/"
                                                                        target="blank"
                                                                  >
                                                                        mozilla firefox
                                                                  </a>
                                                            </div>
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "150px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        margin: "10px",
                                                                        float: "left"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV"
                                                                        target="blank"
                                                                  >
                                                                        latest microsoft edge
                                                                  </a>
                                                            </div>
                                                      </section>
                                                </div>
                                          </section>
                                    );
                              }

                              if (this.state.deviceType === "InternetExpolorer") {
                                    return (
                                          <section className="horseBackground-support">
                                                <div className="introWrapper">
                                                      <div className="hmLogoSide" style={{ float: "none", margin: "auto", padding: "20px" }} />
                                                      <h1 style={{ lineHeight: "2em !important" }}>
                                                            <b>We don't support Internet explorer.</b>
                                                      </h1>
                                                      <h2
                                                            style={{
                                                                  color: "rgba(255,255,255,1)",
                                                                  fontSize: "1em",
                                                                  lineHeight: "2em"
                                                            }}
                                                      >
                                                            We recommed that you click on the below links to install an alternative browser which we
                                                            support <br />
                                                            <a href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV"></a>
                                                      </h2>
                                                      <section
                                                            style={{
                                                                  width: "600px",
                                                                  marginRight: "auto",
                                                                  marginLeft: "auto"
                                                            }}
                                                      >
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "140px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        margin: "10px",
                                                                        float: "left"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.google.com/chrome/?brand=CHBF&ds_kid=43700018455413661&utm_source=bing&utm_medium=cpc&utm_campaign=1005992%20%7C%20Chrome%20Win10%20%7C%20DR%20%7C%20ESS01%20%7C%20EMEA%20%7C%20EMEA%20%7C%20en%20%7C%20Desk%20%7C%20BING%20SEM%20%7C%20BKWS%20~%20Top%20KWDS%20-%20Exact&utm_term=chrome&utm_content=Chrome%20-%20Exact&gclid=CIzJpfKI6uUCFVZ_GwodGLQDnw&gclsrc=ds"
                                                                        target="blank"
                                                                  >
                                                                        google chrome
                                                                  </a>
                                                            </div>
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "140px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        float: "left",
                                                                        margin: "10px"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.mozilla.org/en-US/firefox/new/"
                                                                        target="blank"
                                                                  >
                                                                        mozilla firefox
                                                                  </a>
                                                            </div>
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "150px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        margin: "10px",
                                                                        float: "left"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV"
                                                                        target="blank"
                                                                  >
                                                                        latest microsoft edge
                                                                  </a>
                                                            </div>
                                                      </section>
                                                </div>
                                          </section>
                                    );
                              }

                              if (this.state.deviceType === "iPad") {
                                    return (
                                          <section className="horseBackground-support">
                                                <div className="introWrapper" style={{ left: "calc(50% - 30%)", top: "37%" }}>
                                                      <div className="hmLogoSide" style={{ float: "none", margin: "auto", padding: "20px" }} />
                                                      <h1 style={{ lineHeight: "2em !important" }}>
                                                            <b>We don't support this device.</b>
                                                      </h1>
                                                      <h2
                                                            style={{
                                                                  color: "rgba(255,255,255,1)",
                                                                  fontSize: "1em",
                                                                  lineHeight: "2em"
                                                            }}
                                                      >
                                                            but we recommed that you use your computer or mac with the following browsers. <br />
                                                      </h2>
                                                      <section
                                                            style={{
                                                                  width: "600px",
                                                                  marginRight: "auto",
                                                                  marginLeft: "auto"
                                                            }}
                                                      >
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "140px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        margin: "10px",
                                                                        float: "left"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.google.com/chrome/?brand=CHBF&ds_kid=43700018455413661&utm_source=bing&utm_medium=cpc&utm_campaign=1005992%20%7C%20Chrome%20Win10%20%7C%20DR%20%7C%20ESS01%20%7C%20EMEA%20%7C%20EMEA%20%7C%20en%20%7C%20Desk%20%7C%20BING%20SEM%20%7C%20BKWS%20~%20Top%20KWDS%20-%20Exact&utm_term=chrome&utm_content=Chrome%20-%20Exact&gclid=CIzJpfKI6uUCFVZ_GwodGLQDnw&gclsrc=ds"
                                                                        target="blank"
                                                                  >
                                                                        google chrome
                                                                  </a>
                                                            </div>
                                                            <div
                                                                  style={{
                                                                        display: "block",
                                                                        textDecoration: "none",
                                                                        width: "140px",
                                                                        fontFamily: "Open Sans Condensed, sans-serif",
                                                                        textTransform: "uppercase",
                                                                        textRendering: "geometricPrecision",
                                                                        fontWeight: "800",
                                                                        color: " rgba(255,255,255,1)",
                                                                        borderRadius: "3px",
                                                                        padding: "10px",
                                                                        float: "left",
                                                                        margin: "10px"
                                                                  }}
                                                                  className="goldButton"
                                                            >
                                                                  <a
                                                                        style={{
                                                                              textDecoration: "none",
                                                                              color: "rgba(255,255,255,1)",
                                                                              fontSize: "0.8em",
                                                                              lineHeight: "1.2em"
                                                                        }}
                                                                        href="https://www.mozilla.org/en-US/firefox/new/"
                                                                        target="blank"
                                                                  >
                                                                        mozilla firefox
                                                                  </a>
                                                            </div>
                                                      </section>
                                                </div>
                                          </section>
                                    );
                              }

                              if (this.state.deviceType === "Computer" || this.state.deviceType === "Mobile") {
                                    return (
                                          <React.Fragment>
                                                <Router history={history}>
                                                      <LoginNavigation
                                                            triggerAvatarToReloadProfilePictureUserId={
                                                                  this.state.triggerAvatarToReloadProfilePictureUserId
                                                            }
                                                      />
                                                      <PageTitle title={this.state.title} location={this.state.page} />

                                                      <Switch>
                                                            <Route
                                                                  exact
                                                                  //path={`${match.url}/login`}
                                                                  path="/"
                                                                  render={(props) => (
                                                                        <Login {...props} title={this.state.title} changeTitle={this.changeTitle} />
                                                                  )}
                                                            />
                                                            <Route path="/resetPassword/:passwordResetToken" component={ResetPasswordLandingPage} />
                                                            <Route path="/activateAccount/:activationToken" component={ActivationLandingPage} />
                                                            <Route
                                                                  path="/about-horsemetro"
                                                                  render={(props) => (
                                                                        <AboutHorsemetro
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/advertisements"
                                                                  render={(props) => (
                                                                        <Advertisements
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/bugreport"
                                                                  render={(props) => (
                                                                        <BugReport
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/business"
                                                                  render={(props) => (
                                                                        <Business
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/help"
                                                                  render={(props) => (
                                                                        <Help {...props} title={this.state.title} changeTitle={this.changeTitle} />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/terms-of-service"
                                                                  render={(props) => (
                                                                        <TermsOfService
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/pricing"
                                                                  render={(props) => (
                                                                        <Pricing {...props} title={this.state.title} changeTitle={this.changeTitle} />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/create-profile"
                                                                  render={(props) => (
                                                                        <ProfileCreation
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/account-settings"
                                                                  render={(props) => (
                                                                        <AccountSettings
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                              triggerAvatarToReloadProfilePicture={
                                                                                    this.triggerAvatarToReloadProfilePicture
                                                                              }
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/find-new-private-users"
                                                                  render={(props) => (
                                                                        <AsyncNewestActiveUserProfiles
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/find-new-business-users"
                                                                  render={(props) => (
                                                                        <AsyncNewestActiveBusinessProfiles
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/privacy-policy"
                                                                  render={(props) => (
                                                                        <PrivacyPolicy
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/user-terms"
                                                                  render={(props) => (
                                                                        <UserTerms
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/bazaar-user-terms"
                                                                  render={(props) => (
                                                                        <BazaarUserTerms
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/cookie-policy"
                                                                  render={(props) => (
                                                                        <CookiePolicy
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/user-profile/:userId"
                                                                  render={(props) => (
                                                                        <AsyncUserProfile
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friends-and-followers/user-timeline/:userId"
                                                                  render={(props) => (
                                                                        <AsyncUserProfileTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friends-and-followers/user-gallery/:userId"
                                                                  render={(props) => (
                                                                        <AsyncUserProfileGallery
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/user-friends/:userId"
                                                                  render={(props) => (
                                                                        <AsyncUserFriends
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/user-bazaar/:userId"
                                                                  render={(props) => (
                                                                        <AsyncBazaarUserProfile
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            {/* business user profile page */}
                                                            <Route
                                                                  path="/friends-and-followers/business-user-profile/:userId"
                                                                  render={(props) => (
                                                                        <AsyncBusinessUserProfile
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friends-and-followers/business-timeline/:userId"
                                                                  render={(props) => (
                                                                        <AsyncBusinessProfileTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friends-and-followers/business-gallery/:userId"
                                                                  render={(props) => (
                                                                        <AsyncBusinessProfileGallery
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/business-bazaar/:userId"
                                                                  render={(props) => (
                                                                        <AsyncBusinsessProfileBazaar
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/business-followers/:userId"
                                                                  render={(props) => (
                                                                        <AsyncBusinessProfileFollowers
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friends-and-followers/my-friends"
                                                                  render={(props) => (
                                                                        <AsyncMyFriends
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/my-followers"
                                                                  render={(props) => (
                                                                        <AsyncMyFollowers
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-and-followers/my-users-following"
                                                                  render={(props) => (
                                                                        <AsyncMyUsersThatIFollow
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friends-and-followers/my-business-following"
                                                                  render={(props) => (
                                                                        <AsyncMyBusinessesThatIFollow
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/payment/status"
                                                                  render={(props) => (
                                                                        <PaymentStatus
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/payment/renewSubscription"
                                                                  render={(props) => (
                                                                        <AsyncRenewSubscription
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/public-timeline"
                                                                  render={(props) => (
                                                                        <AsyncTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                              level={"public"}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/private-timeline"
                                                                  render={(props) => (
                                                                        <AsyncTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                              level={"private"}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friends-timeline"
                                                                  render={(props) => (
                                                                        <AsyncTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                              level={"friends"}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/viewSinglePost-timeline/:postId/:commentId"
                                                                  render={(props) => (
                                                                        <AsyncTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                              level={"singlePost"}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/singleUser-timeline/:userId"
                                                                  render={(props) => (
                                                                        <AsyncTimeline
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                              level={"singleUser"}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/notifications"
                                                                  render={(props) => (
                                                                        <Notifications
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friendSuggestions"
                                                                  render={(props) => (
                                                                        <FriendsSuggestionNotifications
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/friendRequests"
                                                                  render={(props) => (
                                                                        <FriendRequestNotifications
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/friendRequestsAndSuggestions"
                                                                  render={(props) => (
                                                                        // <FriendsAndFriendSuggestionNotifications
                                                                        <FriendsSuggestionNotifications
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  /* path="/serviceUnavailable/:serviceName" */
                                                                  path="/serviceUnavailable/"
                                                                  render={(props) => (
                                                                        <ServiceUnavailable
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/blog"
                                                                  render={(props) => (
                                                                        <Blog {...props} title={this.state.title} changeTitle={this.changeTitle} />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/news"
                                                                  render={(props) => (
                                                                        <NewsFeeds
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/public-gallery"
                                                                  render={(props) => (
                                                                        <PublicGallery
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/my-pictures"
                                                                  render={(props) => (
                                                                        <MyPictures
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />

                                                            <Route
                                                                  path="/my-gallery"
                                                                  render={(props) => (
                                                                        <MyGallery
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/account-type-not-supported"
                                                                  render={(props) => (
                                                                        <AccountTypeNotSupported
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/autoCreateAndActivateAccount_ForBrokerCustomer"
                                                                  //path="/autoCreateAndActivateAccount_ForBrokerCustomer/:couponCode/:userName/:password"
                                                                  render={(props) => (
                                                                        <AutoActivateSalesClerkCustomerBusinessAccount
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                            <Route
                                                                  path="/intialChangePassword"
                                                                  //path="/autoCreateAndActivateAccount_ForBrokerCustomer/:couponCode/:userName/:password"
                                                                  render={(props) => (
                                                                        <InitialChangePasswordLandingPage
                                                                              {...props}
                                                                              title={this.state.title}
                                                                              changeTitle={this.changeTitle}
                                                                        />
                                                                  )}
                                                            />
                                                      </Switch>
                                                </Router>
                                          </React.Fragment>
                                    );
                              }
                        })()}
                  </React.Fragment>
            );
      }
}

export default App;
