import React, { Component } from "react";
import loadable from "react-loadable";
import StaticPreLoader from "../../common/login/SmallComponents/staticPreloader";
// contact route component
const LoadingComponent = () => {
      return <StaticPreLoader />;
};

const ComponentPromise = () => {
      return import("../../pages/friends-and-followers/BusinessProfileFollowers");
};
const AsyncUserProfile = loadable({
      loader: ComponentPromise,
      loading: LoadingComponent
});
export default AsyncUserProfile;
