import React, { Component } from "react";

export default class staticPreloader extends Component {
  render() {
    return (
      <div className={"loading-container visible"}>
        <div className="circle">
          <div className="circle1 child"></div>
          <div className="circle2 child"></div>
          <div className="circle3 child"></div>
          <div className="circle4 child"></div>
          <div className="circle5 child"></div>
          <div className="circle6 child"></div>
          <div className="circle7 child"></div>
          <div className="circle8 child"></div>
          <div className="circle9 child"></div>
          <div className="circle10 child"></div>
          <div className="circle11 child"></div>
          <div className="circle12 child"></div>
        </div>

        <div className="loading-text-container">
          <span className="loading-text" style={{ margin: "auto" }}>
            Loading please wait...
          </span>
        </div>
      </div>
    );
  }
}
