import React, { Component } from "react";

import DropdownNavigation from "../../components/common/login/SmallComponents/dropdownNavigation";
import NotificationNavigation from "../../components/common/login/SmallComponents/notificationNavigation";
import FriendNotificationNavigation from "../../components/common/login/SmallComponents/friendNotificationNavigation";
import Avatar from "../../components/common/login/SmallComponents/Avatar";

import {
  getSessionStorageItem,
  getCurrentlyLoggedInProfileFunc
} from "../common/getCurrentlyLoggedInProfile";

class userAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        companyName: "",
        userId: "",
        displayName: "",
        firstName: "",
        lastName: "",
        profileInfoComplete: false,
        profileType: "",
        userProfilePictureId: ""
      },
      friendCounterIcon: false,
      avatarLink: "",
      dropDownNotificationSwitch: false,
      friendNotificationNavigationSwitch: false,
      notificationNavigationSwitch: false
    };
  }
  componentDidMount = async () => {
    const profile = await getCurrentlyLoggedInProfileFunc();
    this.setState({ profile: profile });
  };

  handleCloseOtherDropDowns = (async (dropDownType, status) => {
    if (dropDownType === "frequest" && status === true) {
      await this.setState({
        dropDownNotificationSwitch: false,
        friendNotificationNavigationSwitch: true,
        notificationNavigationSwitch: false
      });
    }
    if (dropDownType === "notification" && status === true) {
      await this.setState({
        dropDownNotificationSwitch: false,
        friendNotificationNavigationSwitch: false,
        notificationNavigationSwitch: true
      });
    }
    if (dropDownType == "dropdown" && status == true) {
      await this.setState({
        dropDownNotificationSwitch: true,
        friendNotificationNavigationSwitch: false,
        notificationNavigationSwitch: false
      });
    }
    if (dropDownType == "notification" && status == false) {
      await this.setState({
        dropDownNotificationSwitch: false
      });
    }
    if (dropDownType == "frequest" && status == false) {
      await this.setState({
        dropDownNotificationSwitch: false
      });
    }
    if (dropDownType == "dropdown" && status == false) {
      await this.setState({
        dropDownNotificationSwitch: false
      });
    }
  }).bind(this);

  render() {
    //console.log("avatar link : " + this.state.avatarLink);
    return (
      <React.Fragment>
        {(() => {
          if (this.state.profile.profileType === "user") {
            if (
              location.pathname != "/create-profile" //&&
              //location.pathname != "/"
            ) {
              return (
                <FriendNotificationNavigation
                  friendNotificationNavigationSwitch={
                    this.state.friendNotificationNavigationSwitch
                  }
                  handleCloseOtherDropDowns={this.handleCloseOtherDropDowns}
                />
              );
            }
          }
        })()}
        {(() => {
          if (
            location.pathname != "/create-profile" //&&
            //location.pathname != "/"
          ) {
            return (
              <>
                <NotificationNavigation
                  notificationNavigationSwitch={
                    this.state.notificationNavigationSwitch
                  }
                  handleCloseOtherDropDowns={this.handleCloseOtherDropDowns}
                />
                <DropdownNavigation
                  handleOnLogOutButtonClick={
                    this.props.handleOnLogOutButtonClick
                  }
                  dropDownNotificationSwitch={
                    this.state.dropDownNotificationSwitch
                  }
                  handleCloseOtherDropDowns={this.handleCloseOtherDropDowns}
                />
              </>
            );
          }
        })()}
        <Avatar  triggerAvatarToReloadProfilePictureUserId={this.props.triggerAvatarToReloadProfilePictureUserId}/>
        {/* <span className="userBadge">
          <span className="mainNav-userName">
            {this.state.profile.companyName
              ? this.state.profile.companyName
              : this.state.profile.displayName
              ? this.state.profile.displayName
              : this.state.profile.firstName +
                " " +
                this.state.profile.lastName}
          </span>

          <div
            className="avatarsWrapper"
            style={{
              backgroundImage: "url(" + this.state.avatarLink + ")",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              position: "relative",
              display: "block"
              // top: "16px"
            }}
          />
        </span> */}
      </React.Fragment>
    );
  }
}

export default userAvatar;
